import React, { Component } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
// Tooltip component
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import ReactPaginate from 'react-paginate';
// import RechartLineChart, { RechartBarChart} from "./../../layout/Rechart/Rechart"

// import { getLocationData , diffDates} from '../../../utils/helper'
// import ReactCountryFlag from "react-country-flag"

import { getItemStorage, removeItemStorage } from './../../../utils/storage';
import { getOpsgenieRegionName } from './../../../utils/helper';
// load Components
import LoadingSpinner from './../../layout/LoadingSpinner/LoadingSpinner';
import Navbar from './../../layout/Navbar/Navbar';
import DropDownMenu from './../../layout/DropDownMenu/DropDownMenu';
import Modal from './../../layout/Modal/Modal';

import AppContext from '../../../context/app-context';

// Import icons
import icon_no_alert_channel from './../../../assets/icons/mailbox.svg';
import { ReactComponent as EditPenIcon } from './../../../assets/icons/3643749_edit_pen_pencil_write_writing_icon.svg';

export default class AlertSettings extends Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      alertChannelId: null,
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Alert Settings',
          link: '#',
          isLink: false,
        },
      ],
      channelList: [],
      alertChannels_options: [
        {
          name: 'Email',
          description:
            'Get alerts when a check fails and recovers in your inbox.',
          link: '/settings/alert/channels/new/email',
          icon: 'email',
          bigIcon: true,
          isNew: false,
        },
        {
          name: 'Slack',
          description:
            'Get detailed failure and recovery reports in your Slack channel as they happen.',
          link: '/settings/alert/channels/new/slack',
          icon: 'slack',
          bigIcon: true,
          isNew: false,
        },
        {
          name: 'SMS',
          description:
            'Get a text message on your phone on each failure and recovery.',
          link: '/settings/alert/channels/new/sms',
          icon: 'sms',
          bigIcon: true,
          isNew: false,
        },
        {
          name: 'Webhooks',
          description:
            'Get a custom formatted failure / recovery message on any endpoint you want.',
          link: '/settings/alert/channels/new/webhook',
          icon: 'webhooks',
          bigIcon: true,
          isNew: false,
        },
        {
          name: 'Telegram',
          description:
            'Get a custom formatted failure / recovery message on any telegram group/profile you want.',
          link: '/settings/alert/channels/new/telegram',
          icon: 'telegram',
          bigIcon: true,
          isNew: moment() < moment('2021-08-24').add(30, 'days'),
        },
        {
          name: 'PagerDuty',
          description:
            'Send failure or recovery messages to any Pagerduty channel.',
          link: '/settings/alert/channels/new/pagerduty',
          icon: 'pagerduty',
          bigIcon: true,
          isNew: moment() < moment('2021-09-04').add(30, 'days'),
        },
        {
          name: 'Opsgenie',
          description: 'Create and close alerts in any Opsgenie team.',
          link: '/settings/alert/channels/new/opsgenie',
          icon: 'opsgenie',
          bigIcon: true,
          isNew: moment() < moment('2021-08-26').add(30, 'days'),
        },
        {
          name: 'Discord',
          description:
            'Get detailed failure and recovery alerts in any Discord channel.',
          link: '/settings/alert/channels/new/discord',
          icon: 'discord',
          bigIcon: true,
          isNew: moment() < moment('2021-08-27').add(30, 'days'),
        },
      ],
      alertChannels_width: 'medium-width',
      isOpenMenu_addNewAlertChannel: false,
      showModal_deleteAlertChannels: false,
      modalResponse: '',
      modalResponse_status: 'warning',
      hasActiveAccount: true,
      responseList_message: null,
      responseList_status: 'warning',
      offset: 0,
      perPage: 10,
      currentPage: 0,
      pageCount: 0,
    };

    this.openMenu__addNewAlertChannel =
      this.openMenu__addNewAlertChannel.bind(this);
    this.closeMenu__addNewAlertChannel =
      this.closeMenu__addNewAlertChannel.bind(this);
    this.openModal_deleteAlertChannels =
      this.openModal_deleteAlertChannels.bind(this);
    this.closeModal_deleteAlertChannels =
      this.closeModal_deleteAlertChannels.bind(this);
    this.deleteAlerts = this.deleteAlerts.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  /**
   *
   */
  getAlertChannels = async () => {
    this.setState({
      isloadingAlertChannels: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/alert-channels`,
          {
            params: {
              page: this.state.offset,
              per_page: this.state.perPage,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`, //'JWT token...'
              'X-Odown-Account': accountId,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            isloadingAlertChannels: false,
            channelList: data.data,
            pageCount: Math.ceil(data.total / this.state.perPage),
            responseList_message: null,
            responseList_status: 'success',
          });
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              isLoading: false,
              responseList_message: error.response.data.message,
              responseList_status: 'danger',
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            if (
              typeof error.message === 'undefined' ||
              error.message === null ||
              error.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.setState({
                isLoading: false,
                responseList_message:
                  'Something went wrong, please try again later.',
                responseList_status: 'danger',
              });
            }
          }
          // this.setState({
          // 	isloadingAlertChannels: false,
          // 	responseList_message : err.message,
          // 	responseList_status :"danger"
          // })

          // //console.log(err)
        });
    } else {
      this.setState({
        isloadingAlertChannels: false,
        hasActiveAccount: false,
      });
      // remote current account id from storage ( connectedUserData element )
      this.context.cleanUserAccount(accountId);
    }
  };

  openMenu__addNewAlertChannel() {
    this.setState(
      {
        isOpenMenu_addNewAlertChannel: true,
      },
      () => {
        document.addEventListener('click', this.closeMenu__addNewAlertChannel);
      }
    );
  }

  closeMenu__addNewAlertChannel() {
    this.setState(
      {
        isOpenMenu_addNewAlertChannel: false,
      },
      () => {
        document.removeEventListener(
          'click',
          this.closeMenu__addNewAlertChannel
        );
      }
    );
    // document.removeEventListener('click', this.closeMenu__addNewAlertChannel);
    // this.setState({ isOpenMenu_addNewAlertChannel: false }, () => {
    // });
  }

  openModal_deleteAlertChannels(event) {
    this.setState({
      showModal_deleteAlertChannels: true,
      alertChannelId: event.currentTarget.dataset.id,
      modalResponse: '',
    });
  }

  closeModal_deleteAlertChannels(event) {
    this.setState({
      showModal_deleteAlertChannels: !this.state.showModal_deleteAlertChannels,
      modalResponse: '',
    });
  }

  deleteAlerts = async (event) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/alert-channels/${this.state.alertChannelId}`,
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const selectedAlertChannelId = this.state.alertChannelId;

            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse: 'Alert channel removed successfully',
              modalResponse_status: 'success',
              channelList: this.state.channelList.filter(function (obj) {
                return obj.id !== selectedAlertChannelId;
              }),
              showModal_deleteAlertChannels: false,
            });
          } else {
            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse: 'Something went wrong removing your alert channel',
              modalResponse_status: 'danger',
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              modalResponse: 'Something went wrong removing your alert channel',
              modalResponse_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        modalResponse: 'You are not authorized to do this operation',
        modalResponse_status: 'danger',
      });
      return;
    }
  };

  render__itemsChannelLogo(__TYPE) {
    return (
      <div className="channel-logo-icon">
        <span className={`ch-lg ${__TYPE.toLowerCase()}`}></span>
      </div>
    );
  }

  render__itemsButtonEdit(__TYPE, __ID) {
    switch (__TYPE) {
      case 'EMAIL':
        return (
          <Link to={`/settings/alert/channels/edit/email/${__ID}`}>
            <EditPenIcon />
          </Link>
        );

      case 'SLACK':
        return (
          <Link to={`/settings/alert/channels/edit/slack/${__ID}`}>
            <EditPenIcon />
          </Link>
        );

      case 'SMS':
        return (
          <Link to={`/settings/alert/channels/edit/sms/${__ID}`}>
            <EditPenIcon />
          </Link>
        );

      case 'WEBHOOK':
        return (
          <Link to={`/settings/alert/channels/edit/webhook/${__ID}`}>
            <EditPenIcon />
          </Link>
        );

      case 'TELEGRAM':
        return (
          <Link to={`/settings/alert/channels/edit/telegram/${__ID}`}>
            <EditPenIcon />
          </Link>
        );

      case 'PAGERDUTY':
        return (
          <Link to={`/settings/alert/channels/edit/pagerduty/${__ID}`}>
            <EditPenIcon />
          </Link>
        );

      case 'OPSGENIE':
        return (
          <Link to={`/settings/alert/channels/edit/opsgenie/${__ID}`}>
            <EditPenIcon />
          </Link>
        );

      case 'DISCORD':
        return (
          <Link to={`/settings/alert/channels/edit/discord/${__ID}`}>
            <EditPenIcon />
          </Link>
        );
    }
  }

  render__itemsDetailsDescription(item) {
    switch (item.type) {
      case 'EMAIL':
        return (
          <>
            <span className="text-muted">Email to </span>
            <span className="text-gray-dark">{item.config.address}</span>
          </>
        );
      case 'SLACK':
        return (
          <>
            <span className="text-muted">Slack hook to </span>
            {item.config.channel !== null ? (
              <span className="text-gray-dark">{`${item.config.channel} `}</span>
            ) : (
              ''
            )}
            <span className="text-muted small">
              <code>{item.config.webhook}</code>
            </span>
          </>
        );
      case 'SMS':
        return (
          <>
            <span className="text-muted">SMS to </span>
            <span className="text-gray-dark">{item.config.phoneNumber}</span>
          </>
        );
      case 'WEBHOOK':
        return (
          <>
            <span className="text-muted">Webhook </span>
            {item.config.name !== null ? (
              <span className="text-gray-dark">{item.config.name}</span>
            ) : (
              ''
            )}
            <span className="text-muted"> URL: </span>
            <span className="text-muted small">
              <code>{item.config.url}</code>
            </span>
          </>
        );
      case 'TELEGRAM':
        return (
          <>
            <span className="text-muted">Telegram to chat ID : </span>
            <span className="text-gray-dark">{item.config.chatid}</span>
          </>
        );
      case 'PAGERDUTY':
        return (
          <>
            <span className="text-muted">Pagerduty to service : </span>
            <span className="text-gray-dark">{item.config.service_name}</span>
            <span className="text-muted"> at </span>
            <span className="text-gray-dark">{item.config.account}</span>
            <span className="text-muted"> account</span>
          </>
        );
      case 'OPSGENIE':
        return (
          <>
            <span className="text-muted">Opsgenie API integration </span>
            <span className="text-gray-dark">{item.config.name}</span>
            <span className="text-muted"> - region : </span>
            <span className="text-gray-dark">
              {getOpsgenieRegionName(item.config.region)}
            </span>
          </>
        );
      case 'DISCORD':
        return (
          <>
            <span className="text-muted">Discord </span>
            {item.config.name !== null ? (
              <span className="text-gray-dark">{item.config.name}</span>
            ) : (
              ''
            )}
            <span className="text-muted"> URL: </span>
            <span className="text-muted small">
              <code>{`${item.config.url.substring(0, 54)}...`}</code>
            </span>
          </>
        );
    }
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getAlertChannels(offset);
      }
    );
  };

  sortDataByDate = (items) =>
    [...items].sort(
      (itemA, itemB) =>
        new Date(itemA.selectedDate) - new Date(itemB.selectedDate)
    );

  componentDidMount() {
    this.getAlertChannels();

    // ! TEST
    // if( typeof this.context.connectedUserData!=='undefined' &&
    // 	this.context.connectedUserData !==null &&
    // 	this.context.connectedUserData.email !== 'support@odown.io'){

    // 	const alertChannels_options = this.state.alertChannels_options;

    // 	const indexPagerDuty = alertChannels_options.findIndex(x=> x.icon ==='pagerduty')
    // 	delete alertChannels_options[indexPagerDuty]
    // 	// const indexDiscord = alertChannels_options.findIndex(x=> x.icon ==='discord')
    // 	// delete alertChannels_options[indexDiscord]
    // 	this.setState({
    // 		alertChannels_options
    // 	})
    // }
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    const { isloadingAlertChannels } = this.state;

    if (this.state.hasActiveAccount === false) {
      // remote current account id from strage
      removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      return <Redirect to="/intro" />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Alert settings | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__blockInner">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>Alert channels</h2>
                </div>
                <div className="l_header__btnaction">
                  <div
                    className="btn btn-primary dropdown"
                    onClick={this.openMenu__addNewAlertChannel}
                  >
                    Add more channels
                    <span className="avatar-wrapper">
                      <span className="avatar-content"></span>
                    </span>
                    {this.state.isOpenMenu_addNewAlertChannel && (
                      <DropDownMenu
                        width={this.state.alertChannels_width}
                        items={this.state.alertChannels_options}
                        customDesign={`dropdown-menu-grid-channel`}
                        descriptionAsTooltip={true}
                      />
                    )}
                  </div>
                </div>
                <div className="l_header__description">
                  These are your global alerting channels for the checks in your
                  account. Any check failure and recovery will be send to these
                  channels.
                </div>
              </div>
              <div className="layout__body">
                <div className="white-card">
                  <table
                    className={`default-table alert-channels-items ${
                      this.state.channelList.length <= 0 ? 'empty' : ''
                    }`}
                  >
                    <thead
                      className={
                        this.state.channelList.length <= 0 ? 'hide' : ''
                      }
                    >
                      <tr>
                        <th></th>
                        <th align="left">Details</th>
                        <th align="center">Alert types</th>
                        <th align="center">Subscribers</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloadingAlertChannels ? (
                        <tr>
                          <td colSpan="5">
                            <div className="loading-wrapper">
                              <LoadingSpinner />
                            </div>
                          </td>
                        </tr>
                      ) : this.state.responseList_status !== 'success' ? (
                        <tr>
                          <td colSpan="5">
                            <div className="alert alert-danger text-center">
                              {this.state.responseList_message}
                            </div>
                          </td>
                        </tr>
                      ) : this.state.channelList.length <= 0 ? (
                        <tr>
                          <td colSpan="5">
                            <div className="home-empty-state">
                              <img
                                alt="No alert channel"
                                src={icon_no_alert_channel}
                                width="100px"
                              />
                              <h3>No Active Alert Channels found</h3>
                              <p className="text-muted">
                                Different alert channels to instantly alert your
                                team and communicate incidents
                              </p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        this.state.channelList.map((item, index) => {
                          return (
                            <tr data-id={item.id} key={index}>
                              <td>
                                {this.render__itemsChannelLogo(item.type)}
                              </td>
                              <td align="left">
                                <div className="field-content">
                                  {this.render__itemsDetailsDescription(item)}
                                </div>
                              </td>
                              <td align="center">
                                <div className="field-content">
                                  <div className="alert-types">
                                    <Tooltip
                                      placement="top"
                                      trigger={['hover']}
                                      overlay={
                                        <span>
                                          Receive alerts when the the check is
                                          recovered
                                        </span>
                                      }
                                    >
                                      <span
                                        className={`alert-type alert-recovery ${
                                          item.send_recovery === true
                                            ? 'active'
                                            : ''
                                        }`}
                                      >
                                        <svg
                                          data-v-2614f063=""
                                          viewBox="0 0 30 30"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            data-v-2614f063=""
                                            d="m0 5.14285714 1.28571429-1.28571428 3.21428571 3.21428571 7.0714286-7.07142857 1.2857143 1.28571429-8.3571429 8.35714285z"
                                            fill="#fff"
                                            transform="translate(8.571429 10.285714)"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Tooltip>
                                    {/* <Tooltip placement="top" trigger={['hover']} 
																			overlay={<span>Receive alerts when the the check is degraded</span>}>
																			<span className={`alert-type alert-degraded ${item.send_degraded=== true ? 'active' : ''}`}><svg data-v-2614f063="" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path data-v-2614f063="" d="m14.8962832 18.244809c-.1719277 0-.3080352-.0573084-.4083264-.1719269-.1002912-.1146186-.1575996-.2650531-.1719269-.4513082l-.472799-4.8139535c-.0286546-.2005824-.0573088-.4620519-.0859635-.7844165-.0286546-.3223646-.0429817-.6268154-.0429817-.9133617v-2.38548589c0-.32952822.0823808-.58025246.2471449-.75218023.1647642-.17192777.4190702-.25789037.7629257-.25789037h.6017442c.3295282 0 .5802525.0859626.7521802.25789037.1719278.17192777.2578904.42265201.2578904.75218023v2.38548589c0 .2865463-.0179089.5909971-.0537272.9133617-.0358182.3223646-.0608907.5909977-.075218.8059074l-.472799 4.7924626c-.0143273.1862551-.0716357.3366896-.1719269.4513082-.1002912.1146185-.2435622.1719269-.4298173.1719269zm-1.1605066 3.2881021v-.6447259c0-.3152009.0859626-.5551798.2578903-.7199439.1719278-.1647641.4154885-.247145.7306894-.247145h.6017442c.3152009 0 .5551798.0823809.7199439.247145s.247145.404743.247145.7199439v.6447259c0 .3152009-.0823809.5551799-.247145.719944s-.404743.2471449-.7199439.2471449h-.6017442c-.3152009 0-.5587616-.0823808-.7306894-.2471449-.1719277-.1647641-.2578903-.4047431-.2578903-.719944z" fill="#fff"></path></svg></span> 
																		</Tooltip> */}
                                    <Tooltip
                                      placement="top"
                                      trigger={['hover']}
                                      overlay={
                                        <span>
                                          Receive alerts when the the check
                                          fails
                                        </span>
                                      }
                                    >
                                      <span
                                        className={`alert-type alert-failure ${
                                          item.send_failure === true
                                            ? 'active'
                                            : ''
                                        }`}
                                      >
                                        <svg
                                          data-v-2614f063=""
                                          viewBox="0 0 30 30"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            data-v-2614f063=""
                                            d="m5.78571429 4.83443522-4.75706811-4.75706811-.95127907.95127907 4.75706811 4.75706811-4.75706811 4.75706811.95127907.9512791 4.75706811-4.75706814 4.75706811 4.75706814.9512791-.9512791-4.75706814-4.75706811 4.75706814-4.75706811-.9512791-.95127907z"
                                            fill="#fff"
                                            transform="translate(9.428571 9.428571)"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Tooltip>
                                    <Tooltip
                                      placement="top"
                                      trigger={['hover']}
                                      overlay={
                                        <span>
                                          Receive alerts when the SSL
                                          certificate of the domain used the
                                          check is about to expire
                                        </span>
                                      }
                                    >
                                      <span
                                        className={`alert-type alert-ssl ${
                                          item.ssl_expiry === true
                                            ? 'active'
                                            : ''
                                        }`}
                                      >
                                        <svg
                                          version="1.1"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          className="octicon octicon-lock"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M4 4v2h-.25A1.75 1.75 0 002 7.75v5.5c0 .966.784 1.75 1.75 1.75h8.5A1.75 1.75 0 0014 13.25v-5.5A1.75 1.75 0 0012.25 6H12V4a4 4 0 10-8 0zm6.5 2V4a2.5 2.5 0 00-5 0v2h5zM12 7.5h.25a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25H12z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>
                              </td>
                              <td align="center">
                                <div className="field-content nb-subscribers">
                                  {`${
                                    item.subscriptions.filter(
                                      (e) => e.activated === true
                                    ).length
                                  } / ${item.subscriptions.length}`}
                                </div>
                              </td>
                              <td>
                                <div className="btns_action">
                                  <div className="btn_action-item edit">
                                    {this.render__itemsButtonEdit(
                                      item.type,
                                      item.id
                                    )}
                                  </div>
                                  <div
                                    className="btn_action-item remove"
                                    data-id={item.id}
                                    onClick={this.openModal_deleteAlertChannels}
                                  >
                                    <svg
                                      version="1.1"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      className="octicon octicon-trash"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                                      ></path>
                                      <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                                      <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                                    </svg>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  {this.state.pageCount > 0 && (
                    <ReactPaginate
                      previousLabel={''}
                      nextLabel={''}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  )}
                  {this.state.showModal_deleteAlertChannels ? (
                    <Modal
                      title="Deleting of alert channels"
                      closeModal={this.closeModal_deleteAlertChannels}
                      fixedWidthWindow="small-window"
                    >
                      <div>
                        Deleting this check will erase all historical data like
                        check results and alerts.
                      </div>
                      <div className="d-flex justify-content-flex-end margin-top-20">
                        <button
                          className="btn btn-info float-left margin-left-10"
                          onClick={this.closeModal_deleteAlertChannels}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-danger float-right margin-left-10"
                          onClick={this.deleteAlerts}
                        >
                          Delete
                        </button>
                      </div>
                      <div
                        className={`modal-response alert alert-${
                          this.state.modalResponse_status
                        } ${this.state.modalResponse === '' ? 'hide' : ''}`}
                      >
                        {this.state.modalResponse}
                      </div>
                    </Modal>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
