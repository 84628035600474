import React from 'react';
import Tooltip from 'rc-tooltip';
import './Button.css';
import { Link } from 'react-router-dom';

import { ReactComponent as UpgradeIcon } from './../../../assets/icons/upgrade.svg';

export default class Button extends React.Component {
  render() {
    return <button type="button">{this.props.value}</button>;
  }
}

/**
 * Upgrade Button
 * - props :
 * 		- billing link ( props.url)
 * 		- Tooltip ( props.tooltip)
 */
export class UpgradeButton extends React.Component {
  render() {
    return (
      <div className="btn-upgrade-wrapper">
        <Tooltip
          placement="top"
          trigger={['hover']}
          overlay={
            <span>
              {typeof this.props.tooltip !== 'undefined'
                ? this.props.tooltip
                : ''}
            </span>
          }
        >
          <Link
            to={
              typeof this.props.url !== 'undefined'
                ? this.props.url
                : '/settings/billing'
            }
            className={`btn btn-upgrade-feature`}
          >
            <UpgradeIcon />
            {this.props.children}
          </Link>
        </Tooltip>
      </div>
    );
  }
}
