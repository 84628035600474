import React, { Component } from 'react';
import AppContext from '../../../context/app-context';

// left sidebar
import LeftSidebar from '../LeftSidebar/LeftSidebar';

export default class MainDashboard extends Component {
  static contextType = AppContext;

  render() {
    //console.log('context from MainDashboard >>> ' , this.context);
    return (
      <div
        id="app"
        className={`appContainer ${
          this.context.isAuth === true ? 'logged' : ''
        }`}
      >
        <div className="main-wrapper">
          <LeftSidebar />
          <main className="mainContainer">{this.props.children}</main>
        </div>
      </div>

      // <main className={`mainContainer ${this.context.isAuth === true ? 'logged' : ''}`}></main>
    );
  }
}
