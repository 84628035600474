import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { getItemStorage, setItemStorage } from '../../../../utils/storage';
import { verifySession } from './../../../authentification/SecurityController';
import { Helmet } from 'react-helmet';
// context
import AppContext from '../../../../context/app-context';

//css
// import { TextField, Button } from '@material-ui/core';
import './../auth-interface.css';

import { ReactComponent as Logo } from './../../../../assets/logo/logo.svg';

export default class ForgotPassword extends React.Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      signin_email: '',
      signin_email_placeholder: 'Email Address',
      signin_email_validate: true,
      signin_response: '',
      signin_response_status: 'warning',
      signin_email_start_tapping: false,
      isLoading: false,
      isConnected: false,
      sessionToken: null,
      email_regex_validator:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange_signinEmail = this.handleChange_signinEmail.bind(this);
  }

  handleChange_signinEmail(event) {
    let signin_email_validate = false;
    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== '' &&
      event.target.value.match(this.state.email_regex_validator) != null
    ) {
      signin_email_validate = true;
    }
    this.setState({
      signin_email_start_tapping: true,
      signin_email: event.target.value,
      signin_email_validate: signin_email_validate,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { signin_email, signin_email_validate } = this.state;

    this.setState({
      isLoading: true,
    });

    // ! Check the email address field
    if (
      typeof signin_email === 'undefined' ||
      signin_email === null ||
      signin_email_validate === false
    ) {
      this.setState({
        isLoading: false,
        signin_response: 'Email address must have a valid form',
        signin_response_status: 'danger',
      });
      return;
    }

    //post request to api
    let paramsPOST = {
      email: this.state.signin_email,
    };

    //post request to api
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}users/password/forgot`,
        paramsPOST,
        {
          cancelToken: this.signal.token,
        }
      )
      .then((res) => res.data)
      .then(async (result) => {
        //console.log('result.accounts : ' , result.accounts);
        this.setState(
          {
            isLoading: false,
            signin_response: result.message,
            signin_response_status: 'success',
          },
          async () => {}
        );
      })
      .catch((error) => {
        //console.log('error : ' , error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //console.log(error.response.data);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          this.setState({
            isLoading: false,
            signin_response: error.response.data.message,
            signin_response_status: 'danger',
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              signin_response:
                'An error occurred while forgetting your password, please try again later',
              signin_response_status: 'danger',
            });
          }
        }
      });
  };

  componentDidMount() {}

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    let submitButtonContent = (
      <span className="btn-inner load-spinner">
        <span>Send Reset instructions</span>
      </span>
    );
    if (this.state.isLoading) {
      submitButtonContent = (
        <span className="btn-inner load-spinner">
          <span className="loader_spinner--content"></span>
          <span>Please wait</span>
        </span>
      );
    }

    const { signin_email_start_tapping, signin_email_validate } = this.state;

    return (
      <>
        <Helmet>
          <title>Forgot Password | Odown</title>
        </Helmet>
        <div className="layout__session layout__form--login bg-gris">
          <div className="layout__session--container">
            <div className="logo">
              <Logo />
            </div>
            <h1>Reset Password</h1>
            <p>To reset your password, please provide your odown email.</p>
            <form method="POST" action="" onSubmit={this.handleSubmit}>
              <div className="form-container">
                <div
                  className={`response-group ${
                    this.state.signin_response_status
                  } message ${this.state.signin_response === '' ? 'hide' : ''}`}
                >
                  <div className="">{this.state.signin_response}</div>
                </div>
                {this.state.signin_response_status !== 'success' && (
                  <>
                    <div className="field-group">
                      <label htmlFor="" className="fluid">
                        Email
                      </label>
                      <input
                        type="email"
                        className={`textField fluid ${
                          signin_email_start_tapping === true &&
                          signin_email_validate === false
                            ? 'error'
                            : ''
                        }`}
                        placeholder={this.state.signin_email_placeholder}
                        value={this.state.signin_email}
                        onChange={this.handleChange_signinEmail}
                      />
                    </div>
                    <div className="field-group submit-fields-group fluid">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!this.state.signin_email_validate}
                      >
                        {submitButtonContent}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </form>
            <div className="other-sign-block">
              <label htmlFor="">Don’t have an odown account?</label>
              <Link to="/signup">Sign up for free</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
