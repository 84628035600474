import React, { Component } from 'react';

import './StatusIndicator.css';

export default class StatusIndicator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status:
        typeof this.props.status !== 'undefined' &&
        ['danger', 'warning', 'success'].includes(this.props.status)
          ? this.props.status
          : 'none',
      title:
        typeof this.props.title !== 'undefined' && this.props.title !== ''
          ? this.props.title
          : '',
      size:
        typeof this.props.size !== 'undefined' &&
        this.props.size !== null &&
        ['xsmall', 'small', 'medium', 'big'].includes(this.props.size)
          ? this.props.size
          : 'small',
      sizeIds: {
        xsmall: 'xs',
        small: 'sm',
        medium: 'md',
        big: 'bg',
      },
    };
  }

  render() {
    return (
      <div
        data-variation={this.state.status}
        data-status=""
        className={`status-indicator status-indicator--${
          this.state.status
        } status-indicator--${
          this.state.sizeIds[this.state.size]
        }  status-indicator--infinite`}
        data-last-checked-at=""
        data-frequency=""
        title={this.state.title}
      >
        <div
          className={`circle ${
            this.state.status !== 'none' ? 'circle--animated' : ''
          } circle-main`}
        ></div>
        <div
          className={`circle ${
            this.state.status !== 'none' ? 'circle--animated' : ''
          } circle-secondary`}
        ></div>
        <div
          className={`circle ${
            this.state.status !== 'none' ? 'circle--animated' : ''
          } circle-tertiary`}
        ></div>
      </div>
    );
  }
}
