import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';
import { getItemStorage } from '../../../utils/storage';
import Navbar from '../../layout/Navbar/Navbar';
import NavbarSettings from './NavbarSettings';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';

import Modal from './../../layout/Modal/Modal';
import GA from './../../../components/Tracking/GoogleAnalytics';

// Invoke the global state
import AppContext from './../../../context/app-context';

export default class TeamSettings extends Component {
  // permission to use the global state
  static contextType = AppContext;

  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      isLoadingTeamData: false,
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Team',
          link: '#',
          isLink: false,
        },
      ],
      teamUsers: [],
      invitesUsers: [],
      showModal_revokeUserAccess: false,
      showModal_inviteTeamUser: false,
      formIsSubmited: false,
      form_teamEmail: '',
      form_teamEmail_placeholder: 'Email address',
      form_teamEmail_validaFormat: false,
      modalResponse_revokeUserAccess_status: 'warning',
      modalResponse_revokeUserAccess: '',
      modalResponse_inviteTeamUser_status: 'warning',
      modalResponse_inviteTeamUser: '',
      revoke_id: null,
      revoke_invitedUser: false,
      revoke_teamUserType: null,
      email_regex_validator:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

      exceed_account_user_limit: false,
      isAuthorized: true,
      hasActiveAccount: true,
      responseList_message: null,
      responseList_status: 'warning',
      offset: 0,
      perPage: 10,
      currentPage: 0,
      pageCount: 0,
      isLoadingInviteTeamUser: false,
      isLoadingRevokeUserAccess: false,
    };

    this.openModal_revokeUserAccess =
      this.openModal_revokeUserAccess.bind(this);
    this.closeModal_revokeUserAccess =
      this.closeModal_revokeUserAccess.bind(this);
    this.revokeUserAccess = this.revokeUserAccess.bind(this);

    this.openModal_inviteTeamUser = this.openModal_inviteTeamUser.bind(this);
    this.closeModal_inviteTeamUser = this.closeModal_inviteTeamUser.bind(this);
    this.inviteTeamUser = this.inviteTeamUser.bind(this);

    this.handleChange_formTeamEmail =
      this.handleChange_formTeamEmail.bind(this);
  }

  /**
   *
   *
   * @memberof EditMonitorForm
   */
  getAccountData = async () => {
    this.setState({
      isLoadingTeamData: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => {
          //console.log(res);
          res = res.data;
          if (typeof res !== 'undefined' && res !== null) {
            const data = res;
            let stateItems = {};

            // check the account users limit :
            if (
              data.usage.account_users >= data.max_account_users &&
              data.max_account_users >= 0
            ) {
              stateItems.exceed_account_user_limit = true;
            }

            if (
              typeof data.plan === 'undefined' ||
              data.plan === null ||
              (typeof data.plan.is_active !== 'undefined' &&
                data.plan.is_active === false) ||
              (typeof data.plan.is_trial !== 'undefined' &&
                data.plan.is_trial === true &&
                moment().diff(
                  moment(data.created_at).add(data.plan.trial_period, 'days'),
                  'minutes'
                ) > 0)
            ) {
              stateItems.isAuthorized = false;
            }

            this.setState(stateItems);
          }
        })
        .catch((err) => {
          // console.log('getAccountData > catch > err ' , err)
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              hasActiveAccount: false,
            });
            // remote current account id from storage ( connectedUserData element )
            this.context.cleanUserAccount(accountId);
          }
        });
    } else {
    }
  };

  getTeamItems = async () => {
    this.setState({
      isLoadingTeamData: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}/team`, {
          params: {
            page: this.state.offset,
            per_page: this.state.perPage,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => {
          //console.log(res);
          // res = res.data;
          const data = res.data;
          if (
            typeof res !== 'undefined' &&
            res !== null &&
            res.status === 200
          ) {
            //console.log('team users : ' , data.data);
            let stateItems = {
              isLoadingTeamData: false,
              teamUsers: data.data,
              pageCount: Math.ceil(data.total / this.state.perPage),
              responseList_message: null,
              responseList_status: 'success',
            };

            this.setState(stateItems);
          } else {
            this.setState({
              isLoadingTeamData: false,
              teamUsers: [],
              pageCount: 0,
              responseList_message: data.message,
              responseList_status: 'danger',
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoadingTeamData: false,
              responseList_message: err.message,
              responseList_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoadingTeamData: false,
        hasActiveAccount: false,
      });
      // remote current account id from storage ( connectedUserData element )
      this.context.cleanUserAccount(accountId);
    }
  };

  openModal_revokeUserAccess(event) {
    if (
      typeof event.currentTarget.dataset.type !== 'undefined' &&
      event.currentTarget.dataset.type !== null &&
      ['MEMBER', 'INVITE'].includes(event.currentTarget.dataset.type)
    ) {
      this.setState({
        showModal_revokeUserAccess: true,
        revoke_id: event.currentTarget.dataset.id,
        revoke_invitedUser:
          typeof event.currentTarget.dataset.invited !== 'undefined' &&
          event.currentTarget.dataset.invited !== null &&
          event.currentTarget.dataset.invited === true
            ? true
            : false,
        revoke_teamUserType: event.currentTarget.dataset.type,
        modalResponse_revokeUserAccess: '',
        modalResponse_revokeUserAccess_status: 'warning',
      });
    }
  }

  closeModal_revokeUserAccess(event) {
    this.setState({
      showModal_revokeUserAccess: !this.state.showModal_revokeUserAccess,
      modalResponse_revokeUserAccess: '',
    });
  }

  revokeUserAccess = async (event) => {
    this.setState({
      isLoadingRevokeUserAccess: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/team/${this.state.revoke_id}`,
          {
            params: {
              type: this.state.revoke_teamUserType,
            },
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const selectedRevokeId = this.state.revoke_id;

            // ? Google Analytics Event : Revoke Team User
            GA.GAevent('Team', 'Revoke Team User');

            // stop loading effect
            this.setState({
              isLoadingRevokeUserAccess: false,
              modalResponse_revokeUserAccess: 'Member removed successfully',
              modalResponse_revokeUserAccess_status: 'success',
              teamUsers: this.state.teamUsers.filter(function (obj) {
                return obj.id !== selectedRevokeId;
              }),
              invitesUsers: this.state.invitesUsers.filter(function (obj) {
                return obj.id !== selectedRevokeId;
              }),
              showModal_revokeUserAccess: false,
            });
          } else {
            // stop loading effect
            this.setState({
              isLoadingRevokeUserAccess: false,
              modalResponse_revokeUserAccess:
                'Something went wrong revoking your member',
              modalResponse_revokeUserAccess_status: 'danger',
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            const errorContent = err.response.data;
            this.setState({
              isLoadingRevokeUserAccess: false,
              modalResponse_revokeUserAccess:
                typeof errorContent.message !== 'undefined' &&
                errorContent.message !== null
                  ? errorContent.message
                  : 'Something went wrong revoking your member',
              modalResponse_revokeUserAccess_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoadingRevokeUserAccess: false,
        modalResponse_revokeUserAccess:
          'You are not authorized to do this operation',
        modalResponse_revokeUserAccess_status: 'danger',
      });
      return;
    }
  };

  handleChange_formTeamEmail(event) {
    let isValid = false;

    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== ''
    ) {
      if (event.target.value.match(this.state.email_regex_validator) !== null)
        isValid = true;
    }

    //console.log('event.target.value : ' , event.target.value);
    //console.log('isValid : ' , isValid);

    this.setState({
      form_teamEmail: event.target.value,
      form_teamEmail_validaFormat: isValid,
    });
  }

  openModal_inviteTeamUser(event) {
    this.setState({
      showModal_inviteTeamUser: true,
      // initialise the main parameters of the Modal
      form_teamEmail: '',
      formIsSubmited: false,
      modalResponse_inviteTeamUser: '',
      modalResponse_inviteTeamUser_status: 'warning',
    });
  }

  closeModal_inviteTeamUser(event) {
    this.setState({
      showModal_inviteTeamUser: !this.state.showModal_inviteTeamUser,
      modalResponse_inviteTeamUser: '',
    });
  }

  /**
   *
   *
   * @param {*} event
   * @returns
   * @memberof TeamSettings
   */
  inviteTeamUser = async (event) => {
    event.preventDefault();

    const { form_teamEmail } = this.state;
    // loading ... effect
    this.setState({
      isLoadingInviteTeamUser: true,
      formIsSubmited: true,
    });

    // we check the email ( email is required field )
    if (
      typeof form_teamEmail === 'undefined' ||
      form_teamEmail === null ||
      form_teamEmail === ''
    ) {
      this.setState({
        isLoadingInviteTeamUser: false,
        modalResponse_inviteTeamUser_status: 'warning',
        modalResponse_inviteTeamUser: 'Please complete all required fields',
      });

      return;
    } else {
      if (this.state.form_teamEmail_validaFormat === false) {
        this.setState({
          isLoadingInviteTeamUser: false,
          modalResponse_inviteTeamUser_status: 'danger',
          modalResponse_inviteTeamUser:
            'The email field does not have a valid format.',
        });

        return;
      } else {
        let isInvitedEmail = false;
        const in_teamList = this.state.teamUsers.findIndex(
          (x) => x.user.email === form_teamEmail
        );
        if (typeof in_teamList !== 'undefined' && in_teamList >= 0) {
          isInvitedEmail = true;
          this.setState({
            isLoadingInviteTeamUser: false,
            modalResponse_inviteTeamUser_status: 'danger',
            modalResponse_inviteTeamUser: 'Email is already invited',
          });
          return;
        }

        const in_invitesList = this.state.invitesUsers.findIndex(
          (x) => x.email === form_teamEmail
        );
        if (typeof in_invitesList !== 'undefined' && in_invitesList >= 0) {
          isInvitedEmail = true;
          this.setState({
            isLoadingInviteTeamUser: false,
            modalResponse_inviteTeamUser_status: 'danger',
            modalResponse_inviteTeamUser: 'Email is already invited',
          });
          return;
        }

        const localStorageData = await getItemStorage(
          `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
        );
        const accountId = await getItemStorage(
          `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
          'string'
        );

        if (localStorageData && localStorageData.token) {
          const { token } = localStorageData;

          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          };

          await axios
            .post(
              `${process.env.REACT_APP_API_URL}users/me/accounts/${accountId}/invites`,
              {
                access: 'READ_WRITE',
                recipient_email: form_teamEmail,
                sender_email: this.context.connectedUserData.email,
              },
              {
                headers: headers,
                cancelToken: this.signal.token,
              }
            )
            .then((res) => res.data)
            .then((result) => {
              // ? Google Analytics Event : Invite Team User
              GA.GAevent('Team', 'Invite Team User');

              // if (result.status === 201){
              // prepare invited list
              let invites = this.state.invitesUsers;
              invites.push({
                id: result.id,
                email: result.email,
                access: result.access,
                created_at: result.created_at,
                type: 'INVITE',
              });
              // update success data state
              this.setState({
                isLoadingInviteTeamUser: false,
                modalResponse_inviteTeamUser: 'Invitation sent successfully',
                modalResponse_inviteTeamUser_status: 'success',
                invitesUsers: invites,
              });
              // close the model after 1 second
              setTimeout(() => {
                this.setState({ showModal_inviteTeamUser: false });
              }, 1000);
            })
            .catch((error) => {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                //console.log(error.response.data);
                //console.log(error.response.status);
                //console.log(error.response.headers);

                this.setState({
                  isLoadingInviteTeamUser: false,
                  modalResponse_inviteTeamUser:
                    error.response.data.message !== 'undefined'
                      ? error.response.data.message
                      : 'Something went wrong, please try later',
                  modalResponse_inviteTeamUser_status: 'danger',
                });
              } else {
                // Something happened in setting up the request that triggered an Error
                if (
                  typeof error.message === 'undefined' ||
                  error.message === null ||
                  error.message !== 'API_HAS_BEEN_CANCELED'
                ) {
                  this.setState({
                    isLoadingInviteTeamUser: false,
                    modalResponse_inviteTeamUser:
                      'Something went wrong, please try later',
                    modalResponse_inviteTeamUser_status: 'danger',
                  });
                }
              }
              //console.log(error.config);

              return;
            });
        } else {
          this.setState({
            isLoadingInviteTeamUser: false,
            modalResponse_inviteTeamUser:
              'You are not authorized to do this operation',
            modalResponse_inviteTeamUser_status: 'danger',
          });
          return;
        }
      }
    }
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;
    //console.log('offset : ' , offset);

    this.setState(
      {
        offset: offset,
      },
      () => {
        this.getTeamItems(offset);
      }
    );
  };

  componentDidMount() {
    // Load account data
    this.getAccountData();
    this.getTeamItems();
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    const {
      isLoadingTeamData,
      formIsSubmited,
      form_teamEmail,
      form_teamEmail_placeholder,
    } = this.state;
    // if (this.state.hasActiveAccount === false ){
    // 	// remote current account id from storage
    // 	removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);

    // 	// return <Redirect to="/intro" />
    // }

    //console.log('this.context.connectedUserData : ' , this.context.connectedUserData)

    return (
      <React.Fragment>
        <Helmet>
          <title>Team | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__monitor--form layout__blockInner">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>Team settings</h2>
                </div>
                <div className="l_header__btnaction">
                  {this.state.exceed_account_user_limit === false &&
                  this.state.isAuthorized === true ? (
                    <button
                      className="btn btn-primary"
                      onClick={(event) => {
                        this.openModal_inviteTeamUser(event);
                      }}
                    >
                      Invite users
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled={true}>
                      Invite users
                    </button>
                  )}
                </div>
                <div className="l_header__description">
                  {this.state.exceed_account_user_limit === true && (
                    <div className="alert alert-warning">
                      You've hit your plan limit of users. Remove users or
                      outstanding invites, or{' '}
                      <Link to="/settings/billing">upgrade your plan</Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="layout__body">
                <form method="POST" action="" onSubmit={this.handleSubmit}>
                  <NavbarSettings selectedTab="team" />
                  <div className="tab-settings__content">
                    <div className="ts_content-item white-card margin-bottom-20 active">
                      {isLoadingTeamData ? (
                        <div className="loading-wrapper">
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <>
                          <h2 className="title">Team</h2>
                          <p className="sub-title">
                            Invite team members by sending them an invite and
                            manage existing ones.
                          </p>
                          <table className="default-table team-members-items">
                            <thead>
                              <tr>
                                <th></th>
                                <th align="left">Name</th>
                                <th align="left">Email</th>
                                <th align="center">Role</th>
                                <th align="center">Created</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.responseList_status !== 'success' ||
                              typeof this.state.teamUsers === 'undefined' ? (
                                <tr>
                                  <td colSpan="5">
                                    <div className="alert alert-danger text-center">
                                      {this.state.responseList_message}
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <React.Fragment>
                                  {this.state.invitesUsers.length > 0 &&
                                    this.state.invitesUsers.map(
                                      (item, index) => {
                                        return (
                                          <tr
                                            className=""
                                            data-id={item.id}
                                            key={index}
                                          >
                                            <td></td>
                                            <td align="left">
                                              <div className="field-content">
                                                <span className="text-muted">
                                                  {'Pending invite'}
                                                </span>
                                              </div>
                                            </td>
                                            <td align="left">
                                              <div className="field-content">
                                                <span className="text-muted">
                                                  {item.email}
                                                </span>
                                              </div>
                                            </td>
                                            <td align="center">
                                              <div className="field-content">
                                                <span className="text-muted">
                                                  {item.access}
                                                </span>
                                              </div>
                                            </td>
                                            <td align="center">
                                              <div className="field-content">
                                                <span className="text-muted">
                                                  {moment(
                                                    item.created_at
                                                  ).format(
                                                    'MMM Do, YYYY HH:mm'
                                                  )}
                                                </span>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="btns_action">
                                                {item.access !== 'OWNER' && (
                                                  <div
                                                    className="btn_action-item remove"
                                                    data-type={item.type}
                                                    data-id={item.id}
                                                    data-invited={true}
                                                    onClick={
                                                      this
                                                        .openModal_revokeUserAccess
                                                    }
                                                  >
                                                    <svg
                                                      version="1.1"
                                                      width="16"
                                                      height="16"
                                                      viewBox="0 0 24 24"
                                                      className="octicon octicon-trash"
                                                      aria-hidden="true"
                                                    >
                                                      <path
                                                        fillRule="evenodd"
                                                        d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                                                      ></path>
                                                      <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                                                      <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                                                    </svg>
                                                  </div>
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  {typeof this.state.teamUsers !==
                                    'undefined' &&
                                    this.state.teamUsers !== null &&
                                    this.state.teamUsers.length > 0 &&
                                    this.context.connectedUserData !== null &&
                                    this.state.teamUsers.map((item, index) => {
                                      return (
                                        <tr
                                          className={`${
                                            item.type === 'MEMBER'
                                              ? 'validated'
                                              : ''
                                          }`}
                                          data-id={item.id}
                                          key={index}
                                        >
                                          <td>
                                            {item.type === 'MEMBER' &&
                                              this.context.connectedUserData
                                                .id === item.user.id && (
                                                <span className="connected-user-row">
                                                  You
                                                </span>
                                              )}
                                          </td>
                                          <td align="left">
                                            <div className="field-content">
                                              <span className="text-muted">
                                                {item.type === 'INVITE'
                                                  ? 'Pending invite'
                                                  : item.user.name !== null
                                                  ? item.user.name
                                                  : '-'}
                                              </span>
                                            </div>
                                          </td>
                                          <td align="left">
                                            <div className="field-content">
                                              <span className="text-muted">
                                                {item.type === 'INVITE'
                                                  ? item.invited_email
                                                  : item.user.email}
                                              </span>
                                            </div>
                                          </td>
                                          <td align="center">
                                            <div className="field-content">
                                              <span className="text-muted">
                                                {item.access}
                                              </span>
                                            </div>
                                          </td>
                                          <td align="center">
                                            <div className="field-content">
                                              <span className="text-muted">
                                                {moment(item.created_at).format(
                                                  'MMM Do, YYYY HH:mm'
                                                )}
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="btns_action">
                                              {item.access !== 'OWNER' &&
                                                (item.type === 'INVITE' ||
                                                  (item.type === 'MEMBER' &&
                                                    this.context
                                                      .connectedUserData.id !==
                                                      item.user.id)) && (
                                                  <div
                                                    className="btn_action-item remove"
                                                    data-type={item.type}
                                                    data-id={item.id}
                                                    data-invited={true}
                                                    onClick={
                                                      this
                                                        .openModal_revokeUserAccess
                                                    }
                                                  >
                                                    <svg
                                                      version="1.1"
                                                      width="16"
                                                      height="16"
                                                      viewBox="0 0 24 24"
                                                      className="octicon octicon-trash"
                                                      aria-hidden="true"
                                                    >
                                                      <path
                                                        fillRule="evenodd"
                                                        d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                                                      ></path>
                                                      <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                                                      <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                                                    </svg>
                                                  </div>
                                                )}
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </React.Fragment>
                              )}
                            </tbody>
                          </table>
                          {this.state.pageCount > 0 && (
                            <ReactPaginate
                              previousLabel={''}
                              nextLabel={''}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              subContainerClassName={'pages pagination'}
                              activeClassName={'active'}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {this.state.showModal_revokeUserAccess ? (
          <Modal
            title="Revoke access for this user?"
            closeModal={this.closeModal_revokeUserAccess}
            fixedWidthWindow="small-window"
          >
            <div>
              Are you sure you want to revoke access to this account for this
              user?
            </div>
            <div className="d-flex justify-content-flex-end margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_revokeUserAccess}
              >
                Cancel
              </button>
              <button
                disabled={this.state.isLoadingRevokeUserAccess}
                className="btn btn-danger float-right margin-left-10"
                onClick={this.revokeUserAccess}
              >
                {this.state.isLoadingRevokeUserAccess === true
                  ? 'Revoking...'
                  : 'Revoke user access'}
              </button>
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_revokeUserAccess_status
              } ${
                this.state.modalResponse_revokeUserAccess === '' ? 'hide' : ''
              }`}
            >
              {this.state.modalResponse_revokeUserAccess}
            </div>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showModal_inviteTeamUser ? (
          <Modal
            title="Invite users"
            closeModal={this.closeModal_inviteTeamUser}
            fixedWidthWindow="small-window"
          >
            <div>
              They'll receive an invitation email to join the current account.
            </div>
            <form action="" onSubmit={this.inviteTeamUser}>
              <div className="field-group">
                <label htmlFor="" className="fluid">
                  Team member
                </label>
                <input
                  type="text"
                  className={`textField fluid ${
                    formIsSubmited === true && form_teamEmail === ''
                      ? 'error'
                      : ''
                  }`}
                  value={form_teamEmail}
                  placeholder={form_teamEmail_placeholder}
                  onChange={this.handleChange_formTeamEmail}
                />
              </div>
            </form>
            <div className="d-flex justify-content-flex-end margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_inviteTeamUser}
              >
                Cancel
              </button>
              <button
                disabled={this.state.isLoadingInviteTeamUser}
                className="btn btn-primary float-right margin-left-10"
                onClick={this.inviteTeamUser}
              >
                {this.state.isLoadingInviteTeamUser === true
                  ? 'Sending...'
                  : 'Send invitation'}
              </button>
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_inviteTeamUser_status
              } ${
                this.state.modalResponse_inviteTeamUser === '' ? 'hide' : ''
              }`}
            >
              {this.state.modalResponse_inviteTeamUser}
            </div>
          </Modal>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}
