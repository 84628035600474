// import { BehaviorSubject } from 'rxjs';

export function getItemStorage(key, type = 'json') {
  if (!key) return null;

  try {
    const valueStr = localStorage.getItem(key);
    if (valueStr) {
      if (type === 'json') return JSON.parse(valueStr);
      return valueStr;
      //return valueStr;
    }
    return null;
  } catch (err) {
    return null;
  }
  /* return new Promise( async (resolve , reject ) => {
		if (!key) reject({
			error : 'Key not found'
		});
		
		try {
			const valueStr = localStorage.getItem(key);
			if (valueStr) {
				if (type === 'json') resolve(JSON.parse(valueStr)) ;
				else resolve(valueStr);
				//return valueStr;
			}else resolve()
			// reject({
			// 	error : 'Unknown error !'
			// });
		} catch (err) {
			reject({
				error : err
			});
		}
	}) */
}

export function setItemStorage(key, obj, type = 'json') {
  // return new Promise( async (resolve , reject ) => {

  // 	if (!key) reject({
  // 		error : 'Key not found'
  // 	});

  // 	try {
  // 		let value = (type === 'json') ? JSON.stringify(obj) : obj;
  // 		localStorage.setItem(key, value);
  // 		resolve()
  // 	} catch (err) {
  // 		console.error(err);
  // 		reject({
  // 			error : err
  // 		});
  // 	}
  // })

  if (!key) return null;

  try {
    let value = type === 'json' ? JSON.stringify(obj) : obj;
    localStorage.setItem(key, value);
    return value;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export function removeItemStorage(key) {
  return new Promise(async (resolve, reject) => {
    if (!key)
      reject({
        error: 'Key not found',
      });
    try {
      localStorage.removeItem(key);
      resolve();
    } catch (err) {
      console.error(err);
      reject({
        error: err,
      });
    }
  });
}

// const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem(`${process.env.REACT_APP_AUTH_NAME_STORAGE}`)));

// export const authenticationService = {

// 	currentUser : currentUserSubject.asObservable(),
// }
