import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { Helmet } from 'react-helmet';
// context
import AppContext from '../../../../context/app-context';

import { setItemStorage, getItemStorage } from '../../../../utils/storage';

// import css
import './JoinTeam.css';

import { ReactComponent as LogoJoinTeamCard } from './../../../../assets/logo/logo.svg';

export default class JoinTeam extends React.Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      inviteId: props.match.params.inviteId,
      account_name: '',
      sender_email: '',
      recipient_email: '',
      joined: false,
      redirected_to_authPage: false,
      // isForConnectedUser: false,
      invite_expiryDate: null,
      response: '',
      response_status: 'danger',
      invite_isClaimed: false,
      checUserConnection: false,
    };

    this.validateJoinTeam = this.validateJoinTeam.bind(this);
    this.getInvitationData = this.getInvitationData.bind(this);
  }

  validateJoinTeam = async (event) => {
    // Add join Token ( invite id ) to storage ( then in signin / signup process, we get this id and pass it to api for validation)
    setItemStorage(
      `${process.env.REACT_APP_STORAGE__INVITE_TOKEN}`,
      this.state.inviteId,
      'string'
    );

    if (
      this.context.isAuth === true &&
      this.state.recipient_email === this.context.connectedUserData.email
    ) {
      // validation immediately of invitation by invite id (token)
      //console.log('validation ......');
      if (moment(this.state.invite_expiryDate).diff(moment(), 'minutes') > 0) {
        const localStorageData = await getItemStorage(
          `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
        );

        if (localStorageData && localStorageData.token) {
          const { token } = localStorageData;

          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
          };

          await axios
            .post(
              `${process.env.REACT_APP_API_URL}invites/${this.state.inviteId}/validate`,
              {
                email: this.state.recipient_email,
              },
              {
                headers: headers,
                cancelToken: this.signal.token,
              }
            )
            .then((res) => {
              if (res.status === 200) {
                const data = res.data;

                // move the current user to the new account team ( invited )
                setItemStorage(
                  `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
                  data.accountId,
                  'string'
                );

                this.setState({
                  error_code: 0,
                  response: data.message,
                  response_status: 'success',
                  redirected_to_account: true,
                });
              } else {
                this.setState({
                  error_code: 100,
                  response: 'Something went wrong, please try again later',
                  response_status: 'danger',
                });
              }

              //console.log(res);
            })
            .catch((err) => {
              if (
                typeof err.message === 'undefined' ||
                err.message === null ||
                err.message !== 'API_HAS_BEEN_CANCELED'
              ) {
                this.setState({
                  error_code: 101,
                  response: err.response.data.error_description,
                  response_status: 'danger',
                });
              }
            });
        } else {
          this.setState({
            error_code: 102,
            response: 'You are not authorized to do this operation',
            response_status: 'danger',
          });
          return;
        }
      } else if (this.state.invite_isClaimed === true) {
        this.setState({
          error_code: 0,
          response:
            'Congratulations, the invite you are using is already valid, you are now a member in this account team.',
          response_status: 'danger',
        });
      } else {
        this.setState({
          error_code: 104,
          response:
            'Sorry, the invite you are using has expired. Ask the person that invited you to send another invite.',
          response_status: 'danger',
        });
      }
    } else if (this.context.isAuth === true) {
      this.setState({
        // isForConnectedUser : false,
        response:
          'Sorry, the invitation you are using is valid, but not for the current user.',
        response_status: 'danger',
      });
    } else {
      this.setState({
        redirected_to_authPage: true,
      });
    }
  };

  getInvitationData() {
    axios
      .get(`${process.env.REACT_APP_API_URL}invites/${this.state.inviteId}`, {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        return res.data;
      })
      .then((result) => {
        //console.log(result);
        this.setState({
          account_name: result.account.name,
          sender_email: result.inviter.email,
          recipient_email: result.email,
          invite_expiryDate: result.expiry_date,
          invite_isClaimed: result.claimed,
        });
      })
      .catch((err) => {
        if (
          typeof err.message === 'undefined' ||
          err.message === null ||
          err.message !== 'API_HAS_BEEN_CANCELED'
        ) {
          this.setState({
            joined: true,
            response:
              'Sorry, the invite you are using is either invalid or expired. Ask the person that invited you to send another invite.',
            response_status: 'danger',
          });
        }
      });
  }

  componentDidMount() {
    // call : get the current invitation info by inviteId
    this.getInvitationData();
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    if (this.state.redirected_to_authPage === true) {
      return <Redirect to="/signin" />;
    }
    if (this.state.redirected_to_account === true) {
      return <Redirect to="/" />;
    }

    //console.log(this.context)

    let buttonString = (
      <button className="btn btn-primary" onClick={this.validateJoinTeam}>
        Login or signup to join <b>{this.state.account_name}</b> account
      </button>
    );
    if (
      this.context.connectedUserData !== null &&
      this.context.connectedUserData.email === this.state.recipient_email
    )
      buttonString = (
        <button className="btn btn-primary" onClick={this.validateJoinTeam}>
          Join now to <b>{this.state.account_name}</b>
        </button>
      );

    return (
      <React.Fragment>
        <Helmet>
          <title>Join Team | Odown</title>
        </Helmet>
        <div className="layout__join-team bg-gris">
          <div className="layout__join-team--container">
            <div className="logo">
              <LogoJoinTeamCard />
            </div>
            <h1>
              {this.state.joined === true
                ? 'That invite URL is not valid'
                : 'Join your team on Odown'}
            </h1>
            {this.state.response !== '' ? (
              <p
                className={`alert alert-${this.state.response_status} text-${this.state.response_status}`}
              >
                {this.state.response}
              </p>
            ) : (
              <>
                <div className="invitation-details">
                  <label htmlFor="">You were invited by</label>
                  <span>{this.state.sender_email}</span>
                </div>
                {buttonString}
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
