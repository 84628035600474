import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';
import { getItemStorage, removeItemStorage } from '../../../utils/storage';
import Navbar from '../../layout/Navbar/Navbar';
import NavbarSettings from './NavbarSettings';
import { Helmet } from 'react-helmet';

import AppContext from '../../../context/app-context';

// css
import './General.css';

export default class SmsSettings extends React.Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      isLoadingAccountData: false,
      formIsSubmited: false,
      sms_provider: 'none',
      twilio_sender: null,
      twilio_account_id: null,
      twilio_token: null,
      nexmo_sender: null,
      nexmo_key: null,
      nexmo_secret: null,
      form_accountId: '',
      form_response_status: 'warning',
      form_response: '',
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'SMS Settings',
          link: '#',
          isLink: false,
        },
      ],

      hasActiveAccount: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange_formAccountName =
      this.handleChange_formAccountName.bind(this);
    this.handleChange_smsProviderInput =
      this.handleChange_smsProviderInput.bind(this);
    this.handleChange_smsProviderChoice =
      this.handleChange_smsProviderChoice.bind(this);
  }

  /**
   *
   *
   * @memberof EditMonitorForm
   */
  getAccountData = async () => {
    this.setState({
      isLoadingAccountData: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            isLoadingAccountData: false,
            sms_provider:
              data.sms_provider !== null ? data.sms_provider : 'none',
            twilio_account_id: data.twilio_account_id,
            twilio_sender: data.twilio_sender,
            twilio_token: data.twilio_token,
            nexmo_key: data.nexmo_key,
            nexmo_secret: data.nexmo_secret,
            nexmo_sender: data.nexmo_sender,
          });
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoadingAccountData: false,
              hasActiveAccount: false,
            });
            // remote current account id from storage ( connectedUserData element )
            this.context.cleanUserAccount(accountId);
          }
        });
    } else {
    }
  };

  /**
   *
   * @param {*} event
   */
  handleChange_formAccountName(event) {
    this.setState({
      form_accountName: event.target.value,
    });
  }

  /**
   *
   * @param {*} event
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    const {
      sms_provider,
      twilio_account_id,
      twilio_token,
      twilio_sender,
      nexmo_key,
      nexmo_secret,
      nexmo_sender,
    } = this.state;
    // loading ... effect
    this.setState({
      isLoading: true,
      formIsSubmited: true,
    });
    // we check the name ( name is required field )
    if (
      (sms_provider === 'twilio' &&
        (twilio_account_id === null ||
          twilio_account_id === '' ||
          twilio_token === null ||
          twilio_token === '' ||
          twilio_sender === null ||
          twilio_sender === '')) ||
      (sms_provider === 'nexmo' &&
        (nexmo_key === null ||
          nexmo_key === '' ||
          nexmo_secret === null ||
          nexmo_secret === '' ||
          nexmo_sender === null ||
          nexmo_sender === ''))
    ) {
      this.setState({
        isLoading: false,
        form_response: 'Please complete all required fields',
        form_response_status: 'warning',
      });
      return;
    }

    // * Continue submitting ...

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      let config = {
        sms_provider: sms_provider,
      };
      if (sms_provider === 'twilio') {
        config.twilio_sender = twilio_sender;
        config.twilio_account_id = twilio_account_id;
        config.twilio_token = twilio_token;
      } else if (sms_provider === 'nexmo') {
        config.nexmo_key = nexmo_key;
        config.nexmo_secret = nexmo_secret;
        config.nexmo_sender = nexmo_sender;
      }

      axios
        .put(`${process.env.REACT_APP_API_URL}accounts/${accountId}`, config, {
          headers: headers,
          cancelToken: this.signal.token,
        })
        .then((res) => {
          // stop loading effect
          this.setState({
            isLoading: false,
            form_response: 'Your SMS provider has been successfully updated',
            form_response_status: 'success',
          });
          //console.log(res);
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              form_response:
                'an error occurred while updating your SMS provider, please try again',
              form_response_status: 'success',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  /**
   *
   * @param {*} event
   * @param {*} name
   */
  handleChange_smsProviderInput(event, name) {
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  }

  handleChange_smsProviderChoice(event) {
    this.setState({
      sms_provider: event.target.value,
    });
  }

  componentDidMount() {
    // Load account data
    this.getAccountData();
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    const {
      isLoadingAccountData,
      formIsSubmited,
      form_response_status,
      form_response,
    } = this.state;

    // Check if the user has an active account, else redirect the user to /intro page to create a new account
    if (this.state.hasActiveAccount === false) {
      // remote current account id from strage
      removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      return <Redirect to="/intro" />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>SMS settings | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__monitor--form layout__blockInner">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>SMS settings</h2>
                </div>
                <div className="l_header__btnaction">
                  <div
                    className="btn btn-primary"
                    onClick={(event) => {
                      this.handleSubmit(event);
                    }}
                  >
                    Save SMS Settings
                  </div>
                </div>
                <div className="l_header__description">
                  <div className="field-group submit-fields-group fluid">
                    <div
                      className={`alert alert-${form_response_status} ${
                        form_response === '' ? 'hide' : ''
                      }`}
                    >
                      <div className="">{form_response}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="layout__body">
                <form method="POST" action="" onSubmit={this.handleSubmit}>
                  <NavbarSettings selectedTab="sms" />
                  <div className="tab-settings__content">
                    <div className="ts_content-item white-card margin-bottom-20 active">
                      {isLoadingAccountData ? (
                        <div className="loading-wrapper">
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <>
                          <h2 className="title">SMS Provider Accounts</h2>
                          <p className="sub-title">
                            Give your account a recognisable name, so your team
                            mates can easily switch between accounts.
                          </p>
                          <div className="field-group">
                            <label htmlFor="">Select your provider</label>
                            <div className="radio-group fluid">
                              <label htmlFor="sms-select-provider--none">
                                <input
                                  id="sms-select-provider--none"
                                  type="radio"
                                  className="textField"
                                  checked={
                                    this.state.sms_provider === `none`
                                      ? true
                                      : false
                                  }
                                  value={`none`}
                                  onChange={this.handleChange_smsProviderChoice}
                                />{' '}
                                None
                              </label>
                              <label htmlFor="sms-select-provider--twilio">
                                <input
                                  id="sms-select-provider--twilio"
                                  type="radio"
                                  className="textField"
                                  checked={
                                    this.state.sms_provider === `twilio`
                                      ? true
                                      : false
                                  }
                                  value={`twilio`}
                                  onChange={this.handleChange_smsProviderChoice}
                                />{' '}
                                Twilio
                              </label>
                              <label htmlFor="sms-select-provider--nexmo">
                                <input
                                  id="sms-select-provider--nexmo"
                                  type="radio"
                                  className="textField"
                                  checked={
                                    this.state.sms_provider === `nexmo`
                                      ? true
                                      : false
                                  }
                                  value={`nexmo`}
                                  onChange={this.handleChange_smsProviderChoice}
                                />{' '}
                                Nexmo (Vonage)
                              </label>
                            </div>
                          </div>
                          {this.state.sms_provider === 'twilio' ? (
                            <>
                              <div className="field-group">
                                <label htmlFor="" className="fluid">
                                  Account SID *
                                </label>
                                <input
                                  type="text"
                                  className={`textField fluid ${
                                    this.state.sms_provider === 'twilio' &&
                                    formIsSubmited === true &&
                                    this.state.twilio_account_id === null
                                      ? 'error'
                                      : ''
                                  }`}
                                  value={
                                    this.state.twilio_account_id !== null
                                      ? this.state.twilio_account_id
                                      : ''
                                  }
                                  placeholder={`Twilio account id`}
                                  onChange={(event) =>
                                    this.handleChange_smsProviderInput(
                                      event,
                                      'twilio_account_id'
                                    )
                                  }
                                />
                              </div>
                              <div className="field-group">
                                <label htmlFor="" className="fluid">
                                  Auth Token *
                                </label>
                                <input
                                  type="text"
                                  className={`textField fluid ${
                                    this.state.sms_provider === 'twilio' &&
                                    formIsSubmited === true &&
                                    this.state.twilio_token === null
                                      ? 'error'
                                      : ''
                                  }`}
                                  value={
                                    this.state.twilio_token !== null
                                      ? this.state.twilio_token
                                      : ''
                                  }
                                  placeholder={`Twilio auth token`}
                                  onChange={(event) =>
                                    this.handleChange_smsProviderInput(
                                      event,
                                      'twilio_token'
                                    )
                                  }
                                />
                              </div>
                              <div className="field-group">
                                <label htmlFor="" className="fluid">
                                  Sender ID *{' '}
                                  <span>Phone number or Brand name</span>
                                </label>
                                <input
                                  type="text"
                                  className={`textField fluid ${
                                    this.state.sms_provider === 'twilio' &&
                                    formIsSubmited === true &&
                                    this.state.twilio_sender === null
                                      ? 'error'
                                      : ''
                                  }`}
                                  value={
                                    this.state.twilio_sender !== null
                                      ? this.state.twilio_sender
                                      : ''
                                  }
                                  placeholder={`Twilio sender id`}
                                  onChange={(event) =>
                                    this.handleChange_smsProviderInput(
                                      event,
                                      'twilio_sender'
                                    )
                                  }
                                />
                              </div>
                            </>
                          ) : this.state.sms_provider === 'nexmo' ? (
                            <>
                              <div className="field-group">
                                <label htmlFor="" className="fluid">
                                  Api Key *
                                </label>
                                <input
                                  type="text"
                                  className={`textField fluid ${
                                    this.state.sms_provider === 'nexmo' &&
                                    formIsSubmited === true &&
                                    this.state.nexmo_key === null
                                      ? 'error'
                                      : ''
                                  }`}
                                  value={
                                    this.state.nexmo_key !== null
                                      ? this.state.nexmo_key
                                      : ''
                                  }
                                  placeholder={`Nexmo Api key`}
                                  onChange={(event) =>
                                    this.handleChange_smsProviderInput(
                                      event,
                                      'nexmo_key'
                                    )
                                  }
                                />
                              </div>
                              <div className="field-group">
                                <label htmlFor="" className="fluid">
                                  Api Secret *
                                </label>
                                <input
                                  type="text"
                                  className={`textField fluid ${
                                    this.state.sms_provider === 'nexmo' &&
                                    formIsSubmited === true &&
                                    this.state.nexmo_secret === null
                                      ? 'error'
                                      : ''
                                  }`}
                                  value={
                                    this.state.nexmo_secret !== null
                                      ? this.state.nexmo_secret
                                      : ''
                                  }
                                  placeholder={`Nexmo Api secret`}
                                  onChange={(event) =>
                                    this.handleChange_smsProviderInput(
                                      event,
                                      'nexmo_secret'
                                    )
                                  }
                                />
                              </div>
                              <div className="field-group">
                                <label htmlFor="" className="fluid">
                                  Sender ID *{' '}
                                  <span>Phone number or Brand name</span>
                                </label>
                                <input
                                  type="text"
                                  className={`textField fluid ${
                                    this.state.sms_provider === 'nexmo' &&
                                    formIsSubmited === true &&
                                    this.state.nexmo_sender === null
                                      ? 'error'
                                      : ''
                                  }`}
                                  value={
                                    this.state.nexmo_sender !== null
                                      ? this.state.nexmo_sender
                                      : ''
                                  }
                                  placeholder={`Nexmo sender id`}
                                  onChange={(event) =>
                                    this.handleChange_smsProviderInput(
                                      event,
                                      'nexmo_sender'
                                    )
                                  }
                                />
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
