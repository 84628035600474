import React, { Component } from 'react';

import AppContext from './app-context';
import { isLoggedIn } from './../components/authentification/SecurityController';
import {
  getItemStorage,
  setItemStorage,
  removeItemStorage,
} from './../utils/storage';
import axios from 'axios';

export default class GlobalState extends Component {
  state = {
    isAuth: false,
    connectedUserData: null,
    hiddenLeftSideBar: false,
    accountData: null,
    isActiveMobileSideBar: false,
  };

  constructor() {
    super();
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setConnectedUserData = this.setConnectedUserData.bind(this);
    this.getConnectedUser = this.getConnectedUser.bind(this);
    this.emptyConnectedUser = this.emptyConnectedUser.bind(this);
    this.cleanUserAccount = this.cleanUserAccount.bind(this);
    this.hideLeftSideBar = this.hideLeftSideBar.bind(this);
    this.showLeftSideBar = this.showLeftSideBar.bind(this);
    this.getCurrentAccountData = this.getCurrentAccountData.bind(this);
    this.setCurrentAccountData = this.setCurrentAccountData.bind(this);
    this.emptyCurrentAccount = this.emptyCurrentAccount.bind(this);
    this.closeMobileSidebar = this.closeMobileSidebar.bind(this);
    this.openMobileSidebar = this.openMobileSidebar.bind(this);
  }

  login() {
    //console.log('logging....');
    this.setState(
      {
        isAuth: true,
      },
      () => {
        // get connected user Data
        // this.getConnectedUser();
      }
    );
  }

  logout() {
    this.setState({ isAuth: false });
  }

  /**
   * * checked
   */
  getConnectedUser() {
    return new Promise(async (resolve, reject) => {
      //console.log('Call: Get current user data');

      const obj = await getItemStorage(
        `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
      );
      if (obj && obj.token) {
        const token = obj.token;

        axios
          .get(`${process.env.REACT_APP_API_URL}users/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(async (res) => {
            //console.log('before setConnectedUserData : ' , res)

            const data = res.data; // main axios content
            if (data && data !== null && typeof data.error === 'undefined') {
              await this.setConnectedUserData(data);

              if (
                typeof data.accounts !== 'undefined' &&
                data.accounts.length > 0
              ) {
                // Get the current account id
                const currentAccountKey = await getItemStorage(
                  `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
                  'string'
                );
                //console.log('currentAccountKey : ' , currentAccountKey);

                if (
                  typeof currentAccountKey !== 'undefined' &&
                  currentAccountKey !== null
                ) {
                  // account key in storage is not the default account and for the current user
                  if (
                    data.accounts.findIndex((x) => x.id === currentAccountKey) <
                    0
                  ) {
                    //&& x.is_default === true
                    let defaultAccount = data.accounts.find(
                      (x) => x.is_default === true
                    );
                    if (
                      typeof defaultAccount === 'undefined' ||
                      defaultAccount === null
                    )
                      defaultAccount = data.accounts[0];
                    // save storage : current account id
                    setItemStorage(
                      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
                      defaultAccount.id,
                      'string'
                    );
                    // this.setConnectedUserData(data)
                    resolve(data);
                  } else {
                    resolve(data);
                  }
                } else {
                  // Get the default account -> select it as the current account for the current user
                  let defaultAccount = data.accounts.find(
                    (x) => x.is_default === true
                  );
                  if (
                    typeof defaultAccount === 'undefined' ||
                    defaultAccount === null
                  )
                    defaultAccount = data.accounts[0];

                  // save storage : current account id
                  setItemStorage(
                    `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
                    defaultAccount.id,
                    'string'
                  );
                  // this.setConnectedUserData(data)

                  resolve(data);
                }
              }

              /* if (typeof data.accounts !== 'undefined' && data.accounts.length > 0) {
								
								// Get the current account id 
								const currentAccountKey = await getItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}` , 'string')
								if (currentAccountKey){
									
									await this.getCurrentAccountData()
									// account key in storage not for the current user 
									if(data.accounts.findIndex(x => x.id === currentAccountKey) < 0 ){ 
										// save storage : current account id 
										setItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`, data.accounts[0].id, 'string')
										resolve(data);
									}else{
										resolve(data);
									}



								}else {
									await this.getCurrentAccountData()

									// save storage : current account id 
									setItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`, data.accounts[0].id, 'string')
									resolve(data);
								}
								
							}	 */
            }
          })
          .catch((err) => {
            //console.log(err);
            reject(err);
          });
      }
    });
  }

  saveAuthentificationInfo() {}

  setConnectedUserData(data) {
    this.setState({ connectedUserData: data });
  }

  emptyConnectedUser() {
    this.setState({ connectedUserData: null });
  }

  cleanUserAccount(selectedAccountId) {
    let connectedUserData = this.state.connectedUserData;
    if (
      typeof connectedUserData !== 'undefined' &&
      connectedUserData !== null
    ) {
      //console.log('cleanUserData > connectedUserData > ' , connectedUserData);
      if (
        typeof connectedUserData.accounts !== 'undefined' &&
        connectedUserData.accounts !== null &&
        connectedUserData.accounts.length > 0
      ) {
        connectedUserData.accounts = connectedUserData.accounts.filter(
          (x) => x.id !== selectedAccountId
        );
      }
      //console.log('cleanUserData > connectedUserData > AFTER > ' , connectedUserData);

      // update connectedUserData state
      this.setState({
        connectedUserData,
      });
    }
  }

  getCurrentAccountData = async () => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (
      localStorageData &&
      localStorageData.token &&
      accountId &&
      accountId !== null
    ) {
      const { token } = localStorageData;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            res = res.data;
            if (
              typeof res !== 'undefined' &&
              res !== null &&
              typeof res.error === 'undefined'
            ) {
              const data = res;
              //console.log('setCurrentAccountData : ' , data);
              // Save currentId in stroge ( accountData element )
              this.setCurrentAccountData(data);
            }
          }
        })
        .catch((err) => {
          //console.log(err)
          let stateItems = {};
          // Current account id is not found
          if (err.response.status === 404) {
            // Empty global State > accountData
            stateItems.accountData = null;
            // Clean account id from global State > connectedUserData
            let connectedUserData = this.state.connectedUserData;
            if (
              typeof connectedUserData.accounts !== 'undefined' &&
              connectedUserData.accounts !== null &&
              connectedUserData.accounts.length > 0
            ) {
              connectedUserData.accounts = connectedUserData.accounts.filter(
                (x) => x.id !== accountId
              );
            }
            stateItems.connectedUserData = connectedUserData;

            // Remote AccountId from storage
            removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
          }
          stateItems.isloadingAlertChannels = false;

          // Update global state
          this.setState(stateItems);
          //console.log(err)
        });
    }
  };

  emptyCurrentAccount() {
    this.setState({ accountData: null });
  }

  setCurrentAccountData(data) {
    this.setState({ accountData: data });
  }

  hideLeftSideBar() {
    this.setState({ hiddenLeftSideBar: true });
  }

  showLeftSideBar() {
    this.setState({ hiddenLeftSideBar: false });
  }

  closeMobileSidebar() {
    this.setState({ isActiveMobileSideBar: false });
  }

  openMobileSidebar() {
    //console.log('openMobileSidebar.....')
    this.setState({ isActiveMobileSideBar: true });
  }

  async componentDidMount() {
    const isLogged = await isLoggedIn();
    //console.log('isLogged : ' + isLogged);
    if (isLogged) {
      this.getConnectedUser().then(() => {
        if (
          typeof this.state.accountData === 'undefined' ||
          this.state.accountData === null
        )
          this.getCurrentAccountData();
      });
    }
    this.setState({
      isAuth: isLogged,
    });
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          isAuth: this.state.isAuth,
          connectedUserData: this.state.connectedUserData,
          setConnectedUserData: this.setConnectedUserData,
          getConnectedUser: this.getConnectedUser,
          emptyConnectedUser: this.emptyConnectedUser,
          cleanUserAccount: this.cleanUserAccount,
          accountData: this.state.accountData,
          getCurrentAccountData: this.getCurrentAccountData,
          setCurrentAccountData: this.setCurrentAccountData,
          emptyCurrentAccount: this.emptyCurrentAccount,
          login: this.login,
          logout: this.logout,
          hiddenLeftSideBar: this.state.hiddenLeftSideBar,
          hideLeftSideBar: this.hideLeftSideBar,
          showLeftSideBar: this.showLeftSideBar,
          isActiveMobileSideBar: this.state.isActiveMobileSideBar,
          closeMobileSidebar: this.closeMobileSidebar,
          openMobileSidebar: this.openMobileSidebar,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
