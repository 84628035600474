import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

// Tooltip component
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import Navbar from '../../../layout/Navbar/Navbar';
import LoadingSpinner from './../../../layout/LoadingSpinner/LoadingSpinner';

import { getItemStorage } from '../../../../utils/storage';

export default class AlertSettingsTelegramEdit extends Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      alertChannelId: props.match.params.alertChannelId, // capture from url (/settings/alert/channels/edit/email/:alertChannelId)

      config_pagerDuty: null,

      form_field_alertCase__recovered: false,
      form_field_alertCase__degraded: false,
      form_field_alertCase__failed: false,
      form_field_alertCase__expiredSSL: false,
      form_field_alertCase__expiredSSL_threshold: 30,
      isLoading: false,
      isLoadingGetData: false,
      form_response: '',
      form_response_status: 'warning',
      formIsSubmited: false,
      chatID_regex_validator: /^[0-9]+$/,
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Alert Settings',
          link: '/settings/alert',
          isLink: true,
        },
        {
          name: 'Edit PagerDuty Channel',
          link: '#',
          isLink: false,
        },
      ],
      isFound: false,
    };

    this.handleChange_form_fields_alert_sendCase =
      this.handleChange_form_fields_alert_sendCase.bind(this);
    this.handleChange_form_fields_alert_sslExpireThreshold =
      this.handleChange_form_fields_alert_sslExpireThreshold.bind(this);
    this.getGlobalAlertChannelData = this.getGlobalAlertChannelData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange_form_fields_alert_sendCase(event) {
    let isChecked = event.target.checked;

    switch (event.target.value) {
      case 'check-recovered':
        this.setState({
          form_field_alertCase__recovered: isChecked,
        });
        break;
      case 'check-degraded':
        this.setState({
          form_field_alertCase__degraded: isChecked,
        });
        break;
      case 'check-failed':
        this.setState({
          form_field_alertCase__failed: isChecked,
        });
        break;
      case 'check-expiredSSL':
        this.setState({
          form_field_alertCase__expiredSSL: isChecked,
        });
        break;
      default:
        break;
    }
  }

  handleChange_form_fields_alert_sslExpireThreshold(event) {
    let value = parseInt(event.target.value);
    if (typeof value === 'undefined' || value === null || value <= 0)
      value = 30; // as default value

    this.setState({
      form_field_alertCase__expiredSSL_threshold: value,
    });
  }

  getGlobalAlertChannelData = async () => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      this.setState({
        isLoadingGetData: true,
      });

      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/alert-channels/${this.state.alertChannelId}`,
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          const axios_status = res.status;
          const data = res.data;
          if (
            typeof res !== 'undefined' &&
            res !== null &&
            axios_status === 200
          ) {
            this.setState({
              config_pagerDuty: data.config,
              // form_field_chatID_value : ( typeof data.config.chatid!=='undefined') ? data.config.chatid : "",
              form_field_alertCase__recovered: data.send_recovery,
              form_field_alertCase__degraded: data.send_degraded,
              form_field_alertCase__failed: data.send_failure,
              form_field_alertCase__expiredSSL: data.ssl_expiry,
              form_field_alertCase__expiredSSL_threshold:
                data.ssl_expiry_threshold,
              isFound: true,
              isLoadingGetData: false,
            });
          } else {
            this.setState({
              isFound: false,
              isLoadingGetData: false,
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isFound: false,
              isLoadingGetData: false,
            });
          }
        });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const {
      form_field_alertCase__recovered,
      form_field_alertCase__degraded,
      form_field_alertCase__failed,
      form_field_alertCase__expiredSSL,
    } = this.state;

    // loading ... effect
    this.setState({
      isLoading: true,
      formIsSubmited: true,
    });

    if (
      form_field_alertCase__recovered === false &&
      form_field_alertCase__degraded === false &&
      form_field_alertCase__failed === false &&
      form_field_alertCase__expiredSSL === false
    ) {
      this.setState({
        isLoading: false,
        form_response:
          'You must at least choose one case so that we can send you a notification.',
        form_response_status: 'warning',
      });
      return;
    }

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/alert-channels/${this.state.alertChannelId}`,
          {
            type: 'PAGERDUTY',
            config:
              this.state.config_pagerDuty !== null
                ? this.state.config_pagerDuty
                : {},
            sendRecovery: form_field_alertCase__recovered,
            sendDegraded: form_field_alertCase__degraded,
            sendFailure: form_field_alertCase__failed,
            sslExpiry: form_field_alertCase__expiredSSL,
            sslExpiryThreshold:
              this.state.form_field_alertCase__expiredSSL_threshold,
            updatedAt: new Date(),
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          const data = res.data;
          if (res.status === 200) {
            // stop loading effect
            this.setState({
              isLoading: false,
              form_response: 'Alert channel updated successfully',
              form_response_status: 'success',
            });
          } else {
            // stop loading effect
            this.setState({
              isLoading: false,
              form_response:
                typeof data.message !== 'undefined' && data.message !== null
                  ? data.message
                  : 'Something went wrong saving your alert settings',
              form_response_status: 'danger',
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              form_response:
                typeof err.message !== 'undefined' && err.message !== null
                  ? err.message
                  : 'Something went wrong saving your alert settings',
              form_response_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  componentDidMount() {
    // Get Channel data
    this.getGlobalAlertChannelData();
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    let submitButtonContent = (
      <span className="btn-inner load-spinner">
        <span>Save!</span>
      </span>
    );
    if (this.state.isLoading) {
      submitButtonContent = (
        <span className="btn-inner load-spinner">
          <span className="loader_spinner--content"></span>
          <span>Please wait</span>
        </span>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Edit PagerDuty Channel | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__blockInner">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>Edit PagerDuty Channel</h2>
                </div>
                <div className="l_header__btnaction">
                  {/* <div className="btn btn-primary"><Link  to={`/monitors/5578/settings`}>Add more channels</Link></div> */}
                </div>
                <div className="l_header__description">
                  Manage all Odown alerts with Pagerduty. Read more about
                  integrating with Pagerduty{' '}
                  <a href="https://odown.io/docs/alerting/pagerduty">
                    in our docs
                  </a>
                </div>
                <div className="l_header__description">
                  Odown uses this data to deliver alerting events to your
                  Pagerduty account. To change these settings, first remove the
                  integration and then step through the setup sequence again.
                </div>
              </div>
              <div className="layout__body">
                <div className="white-card">
                  {this.state.isLoadingGetData === true && (
                    <div className="loading-wrapper">
                      <LoadingSpinner />
                    </div>
                  )}
                  {this.state.isFound === true &&
                    this.state.isLoadingGetData === false && (
                      <form
                        method="POST"
                        action=""
                        onSubmit={this.handleSubmit}
                      >
                        <div className="alert-multiple-services">
                          <div className="field-group fluid">
                            <label className="" htmlFor="">
                              Account
                            </label>
                            <div className="text">
                              {this.state.config_pagerDuty.account}
                            </div>
                          </div>
                          <div className="field-group fluid">
                            <label className="" htmlFor="">
                              Service name
                            </label>
                            <div className="text">
                              {this.state.config_pagerDuty.service_name}
                            </div>
                          </div>
                          <div className="field-group fluid">
                            <label className="" htmlFor="">
                              Service key
                            </label>
                            <div className="text">
                              {this.state.config_pagerDuty.integration_key}
                            </div>
                          </div>
                        </div>
                        <div className="field-group fluid">
                          <label htmlFor="" className="fluid">
                            Sending cases
                          </label>
                          <div className="checkbox-group">
                            <label htmlFor={`check-recovered`}>
                              <span
                                className={`alert-type alert-recovery active `}
                              >
                                <svg
                                  viewBox="0 0 30 30"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m0 5.14285714 1.28571429-1.28571428 3.21428571 3.21428571 7.0714286-7.07142857 1.2857143 1.28571429-8.3571429 8.35714285z"
                                    fill="#fff"
                                    transform="translate(8.571429 10.285714)"
                                  ></path>
                                </svg>
                              </span>
                              <input
                                type="checkbox"
                                id={`check-recovered`}
                                value={`check-recovered`}
                                checked={
                                  this.state.form_field_alertCase__recovered
                                }
                                onChange={
                                  this.handleChange_form_fields_alert_sendCase
                                }
                              />
                              A check recovers
                              <Tooltip
                                placement="top"
                                trigger={['hover']}
                                overlay={
                                  <span>
                                    Receive alerts when the the check is
                                    recovered
                                  </span>
                                }
                              >
                                <span className="question-badge"></span>
                              </Tooltip>
                            </label>
                            <label className="hide" htmlFor={`check-degraded`}>
                              <span
                                className={`alert-type alert-degraded active}`}
                              >
                                <svg
                                  viewBox="0 0 30 30"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m14.8962832 18.244809c-.1719277 0-.3080352-.0573084-.4083264-.1719269-.1002912-.1146186-.1575996-.2650531-.1719269-.4513082l-.472799-4.8139535c-.0286546-.2005824-.0573088-.4620519-.0859635-.7844165-.0286546-.3223646-.0429817-.6268154-.0429817-.9133617v-2.38548589c0-.32952822.0823808-.58025246.2471449-.75218023.1647642-.17192777.4190702-.25789037.7629257-.25789037h.6017442c.3295282 0 .5802525.0859626.7521802.25789037.1719278.17192777.2578904.42265201.2578904.75218023v2.38548589c0 .2865463-.0179089.5909971-.0537272.9133617-.0358182.3223646-.0608907.5909977-.075218.8059074l-.472799 4.7924626c-.0143273.1862551-.0716357.3366896-.1719269.4513082-.1002912.1146185-.2435622.1719269-.4298173.1719269zm-1.1605066 3.2881021v-.6447259c0-.3152009.0859626-.5551798.2578903-.7199439.1719278-.1647641.4154885-.247145.7306894-.247145h.6017442c.3152009 0 .5551798.0823809.7199439.247145s.247145.404743.247145.7199439v.6447259c0 .3152009-.0823809.5551799-.247145.719944s-.404743.2471449-.7199439.2471449h-.6017442c-.3152009 0-.5587616-.0823808-.7306894-.2471449-.1719277-.1647641-.2578903-.4047431-.2578903-.719944z"
                                    fill="#fff"
                                  ></path>
                                </svg>
                              </span>
                              <input
                                type="checkbox"
                                id={`check-degraded`}
                                value={`check-degraded`}
                                checked={
                                  this.state.form_field_alertCase__degraded
                                }
                                onChange={
                                  this.handleChange_form_fields_alert_sendCase
                                }
                              />
                              A check degrades
                              <Tooltip
                                placement="top"
                                trigger={['hover']}
                                overlay={
                                  <span>
                                    Receive alerts when the the check is
                                    degraded
                                  </span>
                                }
                              >
                                <span className="question-badge"></span>
                              </Tooltip>
                            </label>
                            <label htmlFor={`check-failed`}>
                              <span
                                className={`alert-type alert-failure active}`}
                              >
                                <svg
                                  viewBox="0 0 30 30"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m5.78571429 4.83443522-4.75706811-4.75706811-.95127907.95127907 4.75706811 4.75706811-4.75706811 4.75706811.95127907.9512791 4.75706811-4.75706814 4.75706811 4.75706814.9512791-.9512791-4.75706814-4.75706811 4.75706814-4.75706811-.9512791-.95127907z"
                                    fill="#fff"
                                    transform="translate(9.428571 9.428571)"
                                  ></path>
                                </svg>
                              </span>
                              <input
                                type="checkbox"
                                id={`check-failed`}
                                value={`check-failed`}
                                checked={
                                  this.state.form_field_alertCase__failed
                                }
                                onChange={
                                  this.handleChange_form_fields_alert_sendCase
                                }
                              />
                              A check fails
                              <Tooltip
                                placement="top"
                                trigger={['hover']}
                                overlay={
                                  <span>
                                    Receive alerts when the the check fails
                                  </span>
                                }
                              >
                                <span className="question-badge"></span>
                              </Tooltip>
                            </label>
                            <label htmlFor={`check-expiredSSL`}>
                              <span className={`alert-type alert-ssl active}`}>
                                <svg
                                  version="1.1"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  className="octicon octicon-lock"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4 4v2h-.25A1.75 1.75 0 002 7.75v5.5c0 .966.784 1.75 1.75 1.75h8.5A1.75 1.75 0 0014 13.25v-5.5A1.75 1.75 0 0012.25 6H12V4a4 4 0 10-8 0zm6.5 2V4a2.5 2.5 0 00-5 0v2h5zM12 7.5h.25a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25H12z"
                                  ></path>
                                </svg>
                              </span>
                              <input
                                type="checkbox"
                                id={`check-expiredSSL`}
                                value={`check-expiredSSL`}
                                checked={
                                  this.state.form_field_alertCase__expiredSSL
                                }
                                onChange={
                                  this.handleChange_form_fields_alert_sendCase
                                }
                              />
                              An SSL certificate is due to expire in
                              <input
                                type="number"
                                className={`textField small-number margin-left-10`}
                                value={
                                  this.state
                                    .form_field_alertCase__expiredSSL_threshold
                                }
                                placeholder={'30'}
                                min={1}
                                onChange={
                                  this
                                    .handleChange_form_fields_alert_sslExpireThreshold
                                }
                              />
                              {this.state
                                .form_field_alertCase__expiredSSL_threshold ===
                              1
                                ? ' day'
                                : ' days'}
                              <Tooltip
                                placement="top"
                                trigger={['hover']}
                                overlay={
                                  <span>
                                    Receive alerts when the SSL certificate of
                                    the domain used the check is about to expire
                                  </span>
                                }
                              >
                                <span className="question-badge"></span>
                              </Tooltip>
                            </label>
                          </div>
                        </div>
                        <div className="field-group submit-fields-group fluid">
                          <Link to="/settings/alert">
                            <button className="btn btn-info ">Back</button>
                          </Link>
                          <button
                            type="submit"
                            className="btn btn-primary margin-left-10"
                          >
                            {submitButtonContent}
                          </button>
                          <div
                            className={`response-group text-left ${
                              this.state.form_response_status
                            } message ${
                              this.state.form_response === '' ? 'hide' : ''
                            }`}
                          >
                            <div className="">{this.state.form_response}</div>
                          </div>
                        </div>
                      </form>
                    )}
                  {this.state.isFound === false &&
                    this.state.isLoadingGetData === false && (
                      <>
                        <div className="alert alert-danger margin-left-none margin-right-none">
                          This alert channel does not exist, please check your
                          global channel list
                        </div>
                        <Link to="/settings/alert">
                          <button className="btn btn-info">
                            Back to Alert settings
                          </button>
                        </Link>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
