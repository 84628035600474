import jwtDecode from 'jwt-decode';
import { getItemStorage, setItemStorage } from '../../utils/storage';

import axios from 'axios';
import moment from 'moment';

// Check if the user is logged in
export const isLoggedIn = () => {
  //console.log('isLoggedIn start function ...');
  const obj = getItemStorage(`${process.env.REACT_APP_AUTH_NAME_STORAGE}`);

  //console.log('isLoggedIn > getItemStorage > ' , obj);

  if (obj && obj.token) {
    const { token } = obj;
    let tokenExpiration = jwtDecode(token).exp;

    if (tokenExpiration < new Date().getTime() / 1000) {
      // delete token from storage
      // await removeItemStorage(`${process.env.REACT_APP_AUTH_NAME_STORAGE}`);
      localStorage.removeItem(`${process.env.REACT_APP_AUTH_NAME_STORAGE}`);
      // delete current account id from storage
      // await removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      localStorage.removeItem(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      return false;
    } else {
      return true;
    }
  }
  return false;
};

export const HasAccountId = () => {
  const account_key = getItemStorage(
    `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
    'string'
  );
  if (
    typeof account_key !== 'undefined' &&
    account_key !== null &&
    account_key !== 'null'
  )
    return true;

  return false;
};

/**
 *
 */
export const getDefaultValidAccountId = async (connectedUserData) => {
  // Get the current account id from storage
  const currentAccountId = await getItemStorage(
    `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
    'string'
  );

  // Default targeted account id
  let targetedAccountId = currentAccountId;

  if (
    typeof connectedUserData !== 'undefined' &&
    connectedUserData !== null &&
    typeof connectedUserData.accounts !== 'undefined' &&
    connectedUserData.accounts != null &&
    connectedUserData.accounts.length > 0
  ) {
    const userAccounts = connectedUserData.accounts;

    // ? Check if the current account id exist and includes in user's accounts
    const isValidAccountId = userAccounts.findIndex(
      (x) => x.id === currentAccountId
    );

    if (typeof isValidAccountId !== 'undefined' && isValidAccountId >= 0) {
      // Keep the current account
      targetedAccountId = currentAccountId;
    } else {
      // Get the default account id from the user accounts list
      const defaultAccount = userAccounts.find((x) => x.is_default === true);
      if (typeof defaultAccount !== 'undefined' && defaultAccount.id !== null) {
        // Replace the account id in storage with the default account id
        targetedAccountId = defaultAccount.id;
      } else {
        // ! Replace the account id in storage with the first account
        targetedAccountId = userAccounts[0].id;
      }
    }

    // Update the storage if there is something different
    if (targetedAccountId !== currentAccountId) {
      setItemStorage(
        `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
        targetedAccountId,
        'string'
      );
    }
  }

  return targetedAccountId;

  // // Check if the current account id exist and includes in user's accounts
  // // ! we khow the default account id for the connected user
  // if(typeof defaultAccount !=='undefined' && defaultAccount.id !==null){
  // 	if( typeof currentAccountId !=='undefined' && currentAccountId !==null  && defaultAccount.id === currentAccountId ){

  // 	}else{
  // 		//replace account id in storage with the default account id
  // 		targetedAccountId = defaultAccount.id ;
  // 		// // Choose the first account id in the user account list
  // 		// targetedAccountId = userAccounts[0].id ;
  // 	}
  // }else{
  // 	// Choose the first account id in the user account list
  // 	targetedAccountId = userAccounts[0].id ;
  // }
  // // if( typeof currentAccountId !=='undefined' && currentAccountId !==null &&
  // // 	typeof defaultAccount!=='undefined' && defaultAccount.id !== currentAccountId ){

  // // 		//replace account id in storage with the default account id
  // // 		targetedAccountId = defaultAccount.id ;

  // // 	}else{

  // // 		// Choose the first account id in the user account list
  // // 		targetedAccountId = userAccounts[0].id ;
  // // 	}
  // update account id in storage
  // setItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}` ,targetedAccountId ,  'string');

  /* 	// get the current account id from storage 
    const currentAccountId = await getItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}` , 'string');
    // //console.log('getDefaultValidAccountId function > currentAccountId : ', currentAccountId)
    let targetAccountId = currentAccountId; 
    if(typeof currentAccountId!=='undefined' && currentAccountId!==null ){
  
      const found = (typeof connectedUserData!=='undefined' && connectedUserData!==null) ? connectedUserData.accounts.findIndex(x => x.id === currentAccountId) : -1;
      // //console.log('found : ' , found)
      if(found >=0 ){ // account id exsit in my accounts list and  valid 
        targetAccountId = currentAccountId;
      }else{
        if(typeof connectedUserData!=='undefined' && connectedUserData!==null){
        	
          if(typeof connectedUserData.accounts!=='undefined' && connectedUserData.accounts!=null && connectedUserData.accounts.length > 0){
  
            targetAccountId = connectedUserData.accounts[0].id ;
            // update account id in storage 
            setItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}` ,targetAccountId ,  'string');
          }
        }
      	
      }
    }else{
      if(typeof connectedUserData!=='undefined' && connectedUserData!==null){
        if(typeof connectedUserData.accounts!=='undefined' && connectedUserData.accounts!=null && connectedUserData.accounts.length > 0){
          targetAccountId = connectedUserData.accounts[0].id ;
          // update account id in storage 
          setItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}` ,targetAccountId ,  'string');
        }
      }
    	
    }
  
    return targetAccountId; */
};

export function verifySession() {
  return new Promise(async (resolve, reject) => {
    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );

    if (obj && obj.token) {
      const { token } = obj;
      // Verify token
      await axios
        .post(`${process.env.REACT_APP_API_URL}users/session/verify`, {
          token: token,
        })
        .then((res) => res.data)
        .then((res) => {
          const expiredTime = res.exp;
          if (moment().diff(moment(expiredTime * 1000), 'minutes') < 0) {
            resolve(token);
          } else {
            reject({
              error: 'expired_session',
            });
          }
        })
        .catch((err) => {
          reject({
            error: err,
          });
        });
    } else {
      reject({
        error: 'no_token',
      });
    }
  });
}
