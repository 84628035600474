import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';
import { SketchPicker } from 'react-color';
import { getItemStorage, removeItemStorage } from '../../../utils/storage';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';
import Navbar from '../../layout/Navbar/Navbar';
import ModalRight from './../../layout/Modal/ModalRight';
import StatusIndicator from '../../layout/StatusIndicator/StatusIndicator';
import GA from './../../../components/Tracking/GoogleAnalytics';
import Tooltip from 'rc-tooltip';
import AppContext from '../../../context/app-context';
// Style
import './StatusPageForm.css';
import {sendDiscordNotification} from '../../../utils/helper';

// Import icons
import icon_no_monitors from './../../../assets/icons/cactus.svg';
import { ReactComponent as AreaChartTypeIcon } from './../../../assets/icons/area-chart-type.svg';
import { ReactComponent as BarsChartTypeIcon } from './../../../assets/icons/bars-chart-type.svg';

export default class NewStatusPage extends Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      hasActiveAccount: true,
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Status Pages',
          link: '/statuspages',
          isLink: true,
        },
        {
          name: 'New',
          link: '/',
          isLink: false,
        },
      ],
      form_response_status: 'warning',
      form_response: '',
      formIsSubmited: false,
      showModal_selectMonitors: false,
      modalResponse_status: 'warning',
      modalResponse: '',
      monitorRows: [],
      monitorRowsErrors: null,
      monitorRowsErrors_status: 'warning',
      isLoading: false,
      //
      offset: 0,
      data: [],
      perPage: 10,
      currentPage: 0,
      pageCount: 0,
      statusPageSelectedMonitors: [],
      form_statusPage_chartColor: '#003bff',
      form_statusPage_backgroundColor: '#ffffff',
      colorPicker__chartColor_isOpen: false,
      colorPicker__backgroundColor_isOpen: false,

      form_logoUrl_placeholder: 'Url to company logo',
      form_logoUrl_value: '',
      form_title_placeholder: '',
      form_title_value: '',
      form_title_error: '',
      form_hostedSubdomain_placeholder: 'companyname',
      form_hostedSubdomain_value: '',
      form_hostedSubdomain_error: '',
      form_hostedSubdomain_regex_validator: /^[A-Za-z0-9\-]+$/,
      form_customDomain_placeholder: 'status.companyname.com',
      form_customDomain_value: '',
      form_customDomain_error: '',
      form_homepageLink_placeholder: 'https://companyname.com',
      form_homepageLink_value: '',
      createdStatusId: null,
      display_open_incidents: {
        realtime: true,
        scheduled: true,
      },
      chartTypesConfig: [
        {
          id: '24hours',
          range_name: '24 hours',
          is_active: true,
          uptime_chart_type: 'area',
          responsetime_chart_type: 'area',
        },
        {
          id: '1week',
          range_name: '1 week',
          is_active: true,
          uptime_chart_type: 'area',
          responsetime_chart_type: 'area',
        },
        {
          id: '1month',
          range_name: '1 month',
          is_active: true,
          uptime_chart_type: 'area',
          responsetime_chart_type: 'area',
        },
        {
          id: '3months',
          range_name: '3 months',
          is_active: true,
          uptime_chart_type: 'bar',
          responsetime_chart_type: 'area',
        },
        {
          id: '12months',
          range_name: '12 months',
          is_active: true,
          uptime_chart_type: 'area',
          responsetime_chart_type: 'area',
        },
      ],
      chartTypeUnchangeble: [
        {
          id: '24hours',
          range_name: '24 hours',
          uptime: 'area',
          responsetime: 'area',
        },
        {
          id: '1week',
          range_name: '1 week',
          responsetime: 'area',
        },
        {
          id: '1month',
          range_name: '1 month',
          responsetime: 'area',
        },
        {
          id: '3months',
          range_name: '3 months',
          responsetime: 'area',
        },
        {
          id: '12months',
          range_name: '12 months',
          uptime: 'area',
          responsetime: 'area',
        },
      ],
    };
    this.getAccountData = this.getAccountData.bind(this);
    this.openModal_selectMonitors = this.openModal_selectMonitors.bind(this);
    this.closeModal_selectMonitors = this.closeModal_selectMonitors.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAvailableMonitors = this.getAvailableMonitors.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.attachMonitor = this.attachMonitor.bind(this);
    this.confirmAttachedMonitors = this.confirmAttachedMonitors.bind(this); // after choose of monitors, we back and add them to main form
    this.handleChange__removeAttachedMonitor =
      this.handleChange__removeAttachedMonitor.bind(this);
    this.handleChange__editChartColor =
      this.handleChange__editChartColor.bind(this);
    this.handleChange__editBackgroundColor =
      this.handleChange__editBackgroundColor.bind(this);
    this.openColorPicker__chartColor =
      this.openColorPicker__chartColor.bind(this);
    this.openColorPicker__backgroundColor =
      this.openColorPicker__backgroundColor.bind(this);
    this.handleClose_colorPicker = this.handleClose_colorPicker.bind(this);
    this.handleChange__editLogoUrl = this.handleChange__editLogoUrl.bind(this);
    this.handleChange__editTitle = this.handleChange__editTitle.bind(this);
    this.handleChange__editHostedSubdomain =
      this.handleChange__editHostedSubdomain.bind(this);
    this.handleChange__editCustomDomain =
      this.handleChange__editCustomDomain.bind(this);
    this.handleChange__editHomepagelink =
      this.handleChange__editHomepagelink.bind(this);
    this.handleChange__displayResponseTimeMonitor =
      this.handleChange__displayResponseTimeMonitor.bind(this);
    this.handleChange__displayUptimeMonitor =
      this.handleChange__displayUptimeMonitor.bind(this);
    this.handleChange__displayUptimeIndicesMonitor =
      this.handleChange__displayUptimeIndicesMonitor.bind(this);
    this.handleChange_formDisplayOpenIncidents =
      this.handleChange_formDisplayOpenIncidents.bind(this);
    this.handleChange__displayRange =
      this.handleChange__displayRange.bind(this);
    this.handleChange__selectRangeChartType =
      this.handleChange__selectRangeChartType.bind(this);
    this.handleChange_paginationMonitors =
      this.handleChange_paginationMonitors.bind(this);
  }

  /**
   *
   *
   * @memberof EditMonitorForm
   */
  getAccountData = async () => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    //console.log('accountID : ' , accountId)
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => {
          //console.log(res);
          res = res.data;
          if (typeof res !== 'undefined' && res !== null) {
            const data = res;
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              hasActiveAccount: false,
            });
            // remote current account id from storage ( connectedUserData element )
            this.context.cleanUserAccount(accountId);
          }
        });
    } else {
    }
  };
  /**
   *
   * @param {*} event
   */
  handleSubmit = async (event) => {
    event.preventDefault();
    //console.log(event)
    const {
      statusPageSelectedMonitors,
      form_hostedSubdomain_value,
      form_customDomain_value,
      form_title_value,
    } = this.state;
    this.setState({
      isLoading: true,
      formIsSubmited: true,
    });

    if (
      typeof statusPageSelectedMonitors === 'undefined' ||
      statusPageSelectedMonitors === null ||
      statusPageSelectedMonitors.length <= 0 ||
      (form_hostedSubdomain_value === '' && form_customDomain_value === '') ||
      form_title_value === ''
    ) {
      let tmp_stateItems = {};
      // check the title
      if (
        typeof form_title_value === 'undefined' ||
        form_title_value === null ||
        form_title_value === ''
      ) {
        tmp_stateItems.form_title_error = 'Title is required.';
      } else if (form_title_value.length < 5) {
        tmp_stateItems.form_title_error =
          "'Title' must be a valid format ( at least 5 characters )";
      }

      // check the hosted domain name
      if (
        typeof form_customDomain_value === 'undefined' ||
        form_customDomain_value === null ||
        form_customDomain_value === ''
      ) {
        if (
          typeof form_hostedSubdomain_value === 'undefined' ||
          form_hostedSubdomain_value === null ||
          form_hostedSubdomain_value === ''
        ) {
          tmp_stateItems.form_hostedSubdomain_error =
            'Hosted Subdomain is required.';
        } else if (
          form_hostedSubdomain_value.match(
            this.state.form_hostedSubdomain_regex_validator
          ) === null
        ) {
          tmp_stateItems.form_hostedSubdomain_error =
            'Please check the format of the hosted domain name.';
        }
      } else {
        tmp_stateItems.form_hostedSubdomain_error = '';
      }
      // check the custom domain

      tmp_stateItems.form_response = 'Please fill in all mandatory fields';
      tmp_stateItems.form_response_status = 'danger';

      this.setState(tmp_stateItems);
      return;
    }

    // Save a new status page
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      //console.log(this.state);

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/statuspages`,
          {
            is_active: true,
            name: this.state.form_title_value,
            hosted_subdomain: this.state.form_hostedSubdomain_value,
            custom_domain: this.state.form_customDomain_value,
            logo: this.state.form_logoUrl_value,
            color: this.state.form_statusPage_chartColor,
            background_color: this.state.form_statusPage_backgroundColor,
            favicon: null,
            homepage_link: this.state.form_homepageLink_value,
            monitors: this.state.statusPageSelectedMonitors,
            metric_types: this.state.chartTypesConfig,
            display_open_incidents: this.state.display_open_incidents,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then(async(data) => {
          //console.log(res)

          // ? Google Analytics Event : Create Status page
          GA.GAevent('Status Pages', 'Create Status Page');

          const result = await axios
            .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, //'JWT token...'
                'X-Odown-Account': accountId,
              },
              cancelToken: this.signal.token,
            })

          sendDiscordNotification({statusPageName: this.state.form_title_value, accountName: result.data.name})

          this.setState({
            form_response: 'Your new status page has been successfully created',
            form_response_status: 'success',
            form_customDomain_error: '',
            form_hostedSubdomain_error: '',
            createdStatusId: data.id,
          });
        })
        .catch((error) => {
          let stateItems = {};
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const status = error.response.status;
            const data = error.response.data;

            // default message
            stateItems.form_response = data.message;

            if (status === 422) {
              stateItems.form_response = data.message;
              if (data.validation.length > 0) {
                data.validation.map((validation_item) => {
                  switch (validation_item.key) {
                    case 'hosted_subdomain':
                      stateItems.form_hostedSubdomain_error =
                        validation_item.error;
                      break;
                    case 'name':
                      stateItems.form_title_error = validation_item.error;
                      break;
                    case 'custom_domain':
                      stateItems.form_customDomain_error =
                        validation_item.error;
                      break;
                  }
                });
              }
            } else if (
              status === 403 &&
              typeof data.data !== 'undefined' &&
              data.data !== null
            ) {
              stateItems.form_customDomain_error =
                data.data.isAvailableCustomDomain === false
                  ? 'This custom domain is not available, please try a different one'
                  : '';
              stateItems.form_hostedSubdomain_error =
                data.data.isAvailableHostedsubdomain === false
                  ? 'This subdomain is not available, please try a different one'
                  : '';
            }
            stateItems.form_response_status = 'danger';
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log('Error', error.message);
          }
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            // Save data in state
            this.setState(stateItems);
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  getAvailableMonitors = async () => {
    this.setState({
      isLoading: true,
    });
    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (obj && obj.token) {
      const token = obj.token;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors`, {
          params: {
            page: this.state.offset,
            per_page: this.state.perPage,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            pageCount: Math.ceil(data.total / this.state.perPage),
            monitorRows: data.data,
            isLoading: false,
            monitorRowsErrors_status: 'success',
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            //console.log('Error: ', error.message); // => prints: Api is being canceled
          } else {
            if (error.response) {
              this.setState({
                pageCount: 0,
                monitorRows: [],
                isLoading: false,
                monitorRowsErrors:
                  error.response.status === 401
                    ? 'Authentication failed, please log in again'
                    : error.response.data.message,
                monitorRowsErrors_status: 'danger',
              });
            } else {
              // Something happened in setting up the request that triggered an Error
              if (
                typeof error.message === 'undefined' ||
                error.message === null ||
                error.message !== 'API_HAS_BEEN_CANCELED'
              ) {
                this.setState({
                  pageCount: 0,
                  monitorRows: [],
                  isLoading: false,
                  monitorRowsErrors:
                    'Something went wrong, please try again later.',
                  monitorRowsErrors_status: 'danger',
                });
              }
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  attachMonitor(event) {
    const monitorValue = JSON.parse(event.currentTarget.dataset.value);

    const statusPageSelectedMonitors = [
      ...this.state.statusPageSelectedMonitors,
    ];
    // ! Affect the selected monitor to the affected monitors
    if (event.currentTarget.dataset.checked === 'false') {
      this.setState(
        {
          statusPageSelectedMonitors: [
            ...this.state.statusPageSelectedMonitors,
            monitorValue,
          ],
        },
        () => {}
      );
      // ! Remove the selected monitor to the affected monitors
    } else {
      const found = statusPageSelectedMonitors.find(
        (element) => element.id === monitorValue.id
      );
      const index = statusPageSelectedMonitors.indexOf(found);
      statusPageSelectedMonitors.splice(index, 1);
      this.setState(
        {
          statusPageSelectedMonitors,
        },
        () => {}
      );
    }
  }

  confirmAttachedMonitors(event) {
    //console.log(event)

    this.setState({
      showModal_selectMonitors: !this.state.showModal_selectMonitors,
      modalResponse: '',
    });
  }

  handleChange__removeAttachedMonitor(event) {
    const selectedMonitorId = event.currentTarget.dataset.id;
    const statusPageSelectedMonitors = this.state.statusPageSelectedMonitors;

    const found = statusPageSelectedMonitors.find(
      (element) => element.id === selectedMonitorId
    );
    const index = statusPageSelectedMonitors.indexOf(found);
    statusPageSelectedMonitors.splice(index, 1);

    // update state :
    this.setState({
      statusPageSelectedMonitors,
    });
  }

  handleChange__editChartColor(event) {
    this.setState({ form_statusPage_chartColor: event.hex });
  }
  handleChange__editBackgroundColor(event) {
    this.setState({ form_statusPage_backgroundColor: event.hex });
  }

  openColorPicker__chartColor(event) {
    //console.log('call > openColorPicker__chartColor..')
    this.setState({ colorPicker__chartColor_isOpen: true });
  }
  openColorPicker__backgroundColor(event) {
    //console.log('call > openColorPicker__backgroundColor..')
    this.setState({ colorPicker__backgroundColor_isOpen: true });
  }
  handleClose_colorPicker(event) {
    //console.log(event);

    this.setState(
      {
        colorPicker__chartColor_isOpen: false,
        colorPicker__backgroundColor_isOpen: false,
      },
      () => {
        //console.log('colorPicker__chartColor_isOpen : ' , this.state.colorPicker__chartColor_isOpen)
        //console.log('colorPicker__backgroundColor_isOpen : ' , this.state.colorPicker__backgroundColor_isOpen)
      }
    );
  }

  handleChange__editLogoUrl(event) {
    this.setState({
      form_logoUrl_value: event.target.value,
    });
  }
  handleChange__editTitle(event) {
    const value = event.target.value;

    let error = '';
    if (typeof value === 'undefined' || value === null || value === '') {
      error = 'Title is required.';
    } else if (value.length < 5) {
      error = "'Title' must be a valid format ( at least 5 characters )";
    }
    this.setState({
      form_title_value: event.target.value,
      form_title_error: error,
    });
  }
  handleChange__editHostedSubdomain(event) {
    const value = event.target.value;

    let error = '';
    if (
      typeof this.state.form_customDomain_value === 'undefined' ||
      this.state.form_customDomain_value === null ||
      this.state.form_customDomain_value === ''
    ) {
      if (typeof value === 'undefined' || value === null || value === '') {
        error = 'Hosted Subdomain is required.';
      } else if (
        value.match(this.state.form_hostedSubdomain_regex_validator) === null
      ) {
        error = 'Please check the format of the hosted domain name.';
      }
    }
    this.setState({
      form_hostedSubdomain_value: event.target.value,
      form_hostedSubdomain_error: error,
    });
  }
  handleChange__editCustomDomain(event) {
    let stateItems = {
      form_customDomain_value: event.target.value.replace(
        /(http:\/\/|https:\/\/)/i,
        ''
      ),
    };

    if (
      event.target.value !== '' &&
      this.state.form_hostedSubdomain_value === ''
    ) {
      stateItems.form_hostedSubdomain_error = '';
    }

    this.setState(stateItems);
  }
  handleChange__editHomepagelink(event) {
    this.setState({
      form_homepageLink_value: event.target.value,
    });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getAvailableMonitors(offset);
      }
    );
  };

  sortDataByDate = (items) =>
    [...items].sort(
      (itemA, itemB) =>
        new Date(itemA.selectedDate) - new Date(itemB.selectedDate)
    );

  handleChange_paginationMonitors = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getAvailableMonitors(offset);
      }
    );
  };

  openModal_selectMonitors(event) {
    this.setState(
      {
        showModal_selectMonitors: true,
      },
      () => {
        // Get monitors list
        this.getAvailableMonitors();
      }
    );
  }

  closeModal_selectMonitors(event) {
    this.setState({
      showModal_selectMonitors: !this.state.showModal_selectMonitors,
      modalResponse: '',
    });
  }

  handleChange__displayResponseTimeMonitor(event) {
    let statusPageSelectedMonitors = [...this.state.statusPageSelectedMonitors];
    let isChecked = event.target.checked;
    const monitorId = event.target.value;

    const monitorBlockIndex = statusPageSelectedMonitors.findIndex(
      (x) => x.id === monitorId
    );
    if (
      typeof monitorBlockIndex !== 'undefined' &&
      monitorBlockIndex !== null &&
      monitorBlockIndex >= 0
    ) {
      // edit the value of display of responsetime chart for the selected monitor id
      if (
        typeof statusPageSelectedMonitors[monitorBlockIndex][
          'displayCharts'
        ] === 'undefined'
      )
        statusPageSelectedMonitors[monitorBlockIndex]['displayCharts'] = {};

      statusPageSelectedMonitors[monitorBlockIndex][
        'displayCharts'
      ].responseTime = isChecked;
    }
    // update state
    this.setState({
      statusPageSelectedMonitors,
    });
  }

  handleChange__displayUptimeMonitor(event) {
    let statusPageSelectedMonitors = [...this.state.statusPageSelectedMonitors];
    let isChecked = event.target.checked;
    const monitorId = event.target.value;

    const monitorBlockIndex = statusPageSelectedMonitors.findIndex(
      (x) => x.id === monitorId
    );
    if (
      typeof monitorBlockIndex !== 'undefined' &&
      monitorBlockIndex !== null &&
      monitorBlockIndex >= 0
    ) {
      // edit the value of display of uptime chart for the selected monitor id
      if (
        typeof statusPageSelectedMonitors[monitorBlockIndex][
          'displayCharts'
        ] === 'undefined'
      )
        statusPageSelectedMonitors[monitorBlockIndex]['displayCharts'] = {};

      statusPageSelectedMonitors[monitorBlockIndex]['displayCharts'].uptime =
        isChecked;
    }
    // update state
    this.setState({
      statusPageSelectedMonitors,
    });
  }
  handleChange__displayUptimeIndicesMonitor(event) {
    let statusPageSelectedMonitors = [...this.state.statusPageSelectedMonitors];
    let isChecked = event.target.checked;
    const monitorId = event.target.value;

    const monitorBlockIndex = statusPageSelectedMonitors.findIndex(
      (x) => x.id === monitorId
    );
    if (
      typeof monitorBlockIndex !== 'undefined' &&
      monitorBlockIndex !== null &&
      monitorBlockIndex >= 0
    ) {
      // edit the value of display of uptime chart for the selected monitor id
      if (
        typeof statusPageSelectedMonitors[monitorBlockIndex][
          'displayCharts'
        ] === 'undefined'
      )
        statusPageSelectedMonitors[monitorBlockIndex]['displayCharts'] = {};

      statusPageSelectedMonitors[monitorBlockIndex]['displayCharts'].indices =
        isChecked;
    }
    // update state
    this.setState({
      statusPageSelectedMonitors,
    });
  }

  handleChange_formDisplayOpenIncidents(event, type) {
    let openIncidents = this.state.display_open_incidents;
    openIncidents[type] = event.target.checked;

    this.setState({
      display_open_incidents: openIncidents,
    });
  }

  handleChange__displayRange(event, id) {
    let config = this.state.chartTypesConfig;
    const selectedItem = config.findIndex((x) => x.id === id);
    config[selectedItem].is_active = event.target.checked;

    this.setState({
      chartTypesConfig: config,
    });
  }

  handleChange__selectRangeChartType(event, type, id) {
    const value = event.target.value;
    let config = this.state.chartTypesConfig;
    const selectedItem = config.findIndex((x) => x.id === id);
    config[selectedItem][
      type === 'uptime' ? 'uptime_chart_type' : 'responsetime_chart_type'
    ] = value;

    this.setState({
      chartTypesConfig: config,
    });
  }

  componentDidMount() {
    // Get current account Data
    this.getAccountData();
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    // Check if the user has an active account, else redirect the user to /intro page to create a new account
    //console.log('this.state.hasActiveAccount : ', this.state.hasActiveAccount)
    if (this.state.hasActiveAccount === false) {
      // remote current account id from strage
      removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      return <Redirect to="/intro" />;
    }

    if (
      this.state.form_response_status === 'success' &&
      this.state.createdStatusId !== null
    ) {
      return (
        <Redirect to={`/statuspages/${this.state.createdStatusId}/edit`} />
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>New Status Page | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__monitor--form layout__blockInner">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>Create a status page</h2>
                </div>
                <div className="l_header__btnaction">
                  <div
                    className="btn btn-primary"
                    onClick={(event) => {
                      this.handleSubmit(event);
                    }}
                  >
                    Save settings
                    <span className="avatar-wrapper">
                      <span className="avatar-content"></span>
                    </span>
                  </div>
                </div>
                <div className="l_header__description">
                  <div className="field-group submit-fields-group fluid">
                    <div
                      className={`alert alert-${
                        this.state.form_response_status
                      } ${
                        this.state.form_response === '' ? 'hide' : ''
                      } margin-left-none margin-right-none`}
                    >
                      <div className="">{this.state.form_response}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="layout__body">
                <form
                  className="tab-settings__content"
                  method="POST"
                  action=""
                  onSubmit={this.handleSubmit}
                >
                  <div className="white-card margin-bottom-20 ">
                    <h2 className="title">Monitors included</h2>
                    <p className="sub-title">
                      Status pages are great for showing as a public status page
                      for your service ( you can include one or more monitors in
                      a single status page )
                    </p>
                    <div className="monitors-importer">
                      <button
                        type="button"
                        className="btn btn-orange"
                        onClick={this.openModal_selectMonitors}
                      >
                        Choose a monitors
                      </button>
                      <div className="confirmAttachedMonitors">
                        {this.state.statusPageSelectedMonitors.length > 0 ? (
                          this.state.statusPageSelectedMonitors.map(
                            (item, index) => {
                              return (
                                <div className="monitor-item" key={index}>
                                  <StatusIndicator
                                    status={
                                      item.isActive === false
                                        ? ''
                                        : item.isDown === true
                                        ? 'danger'
                                        : 'success'
                                    }
                                    title={
                                      item.isActive === false
                                        ? 'Inactive Monitor'
                                        : item.isDown === true
                                        ? 'Failure Monitor'
                                        : 'Active Monitor'
                                    }
                                  />
                                  <div className="monitor-item__name">
                                    {item.name}
                                  </div>
                                  <div className="monitor-item__url hide">
                                    {item.url}
                                  </div>
                                  <div className="monitor-item__display_options">
                                    <div className="field-group checkbox-group">
                                      <label>
                                        <input
                                          type="checkbox"
                                          className="textField"
                                          id={`monitor--${item.id}--responsetime`}
                                          value={item.id}
                                          checked={
                                            typeof item.displayCharts
                                              .responseTime !== 'undefined' &&
                                            item.displayCharts.responseTime ===
                                              true
                                              ? true
                                              : false
                                          }
                                          onChange={
                                            this
                                              .handleChange__displayResponseTimeMonitor
                                          }
                                        />{' '}
                                        ResponseTime Chart
                                        <Tooltip
                                          placement="top"
                                          trigger={['hover']}
                                          overlay={
                                            <span>
                                              A simple, clear and beautiful
                                              graph that explains the average
                                              response time over the entire
                                              selected period
                                            </span>
                                          }
                                        >
                                          <span className="info-badge"></span>
                                        </Tooltip>
                                      </label>
                                    </div>
                                    <div className="field-group checkbox-group">
                                      <label>
                                        <input
                                          type="checkbox"
                                          className="textField"
                                          id={`monitor--${item.id}--uptime`}
                                          value={item.id}
                                          checked={
                                            typeof item.displayCharts.uptime !==
                                              'undefined' &&
                                            item.displayCharts.uptime === true
                                              ? true
                                              : false
                                          }
                                          onChange={
                                            this
                                              .handleChange__displayUptimeMonitor
                                          }
                                        />{' '}
                                        Uptime Chart
                                        <Tooltip
                                          placement="top"
                                          trigger={['hover']}
                                          overlay={
                                            <span>
                                              Simple, clear and beautiful graph
                                              that explains the availability
                                              rate during the entire selected
                                              period, also available as bars in
                                              some selected periods as a 3 month
                                              interval
                                              <br />
                                              More parameters available on the
                                              edit form
                                            </span>
                                          }
                                        >
                                          <span className="info-badge"></span>
                                        </Tooltip>
                                      </label>
                                    </div>
                                    <div className="field-group checkbox-group">
                                      <label>
                                        <input
                                          type="checkbox"
                                          className="textField"
                                          id={`monitor--${item.id}--indices`}
                                          value={item.id}
                                          checked={
                                            typeof item.displayCharts
                                              .indices !== 'undefined' &&
                                            item.displayCharts.indices === true
                                              ? true
                                              : false
                                          }
                                          onChange={
                                            this
                                              .handleChange__displayUptimeIndicesMonitor
                                          }
                                        />{' '}
                                        Indices
                                        <Tooltip
                                          placement="top"
                                          trigger={['hover']}
                                          overlay={
                                            <span>
                                              Average response time and uptime
                                              presented as a number over each
                                              selected period
                                            </span>
                                          }
                                        >
                                          <span className="info-badge"></span>
                                        </Tooltip>
                                      </label>
                                    </div>
                                  </div>
                                  <div className={`monitor-item__btn_actions`}>
                                    <div
                                      className="btn_action-item remove"
                                      data-id={item.id}
                                      onClick={
                                        this.handleChange__removeAttachedMonitor
                                      }
                                    >
                                      <svg
                                        version="1.1"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        className="octicon octicon-trash"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                                        ></path>
                                        <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                                        <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <div
                            className={`no-items-selected ${
                              this.state.formIsSubmited === true &&
                              this.state.statusPageSelectedMonitors.length <= 0
                                ? 'error'
                                : ''
                            }`}
                          >
                            <span className="no-items-selected_inner">
                              No monitor selected, please choose at least one of
                              your monitors
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="white-card margin-bottom-20 settings-tab active">
                    <h2 className="title fluid">Settings</h2>
                    <p className="sub-title fluid">
                      Status pages are great for showing as a public status page
                      for your service ( you can include one or more monitors in
                      a single status page )
                    </p>
                    <div className="field-group">
                      <label className="fluid">Logo</label>
                      <input
                        type="text"
                        className={`textField fluid`}
                        placeholder={this.state.form_logoUrl_placeholder}
                        value={this.state.form_logoUrl_value}
                        onChange={this.handleChange__editLogoUrl}
                      />
                    </div>
                    <div className="field-group">
                      <label className="fluid">Title *</label>
                      <input
                        type="text"
                        className={`textField fluid ${
                          this.state.formIsSubmited === true &&
                          this.state.form_title_error !== ''
                            ? 'error'
                            : ''
                        }`}
                        placeholder={this.state.form_title_placeholder}
                        value={this.state.form_title_value}
                        onChange={this.handleChange__editTitle}
                      />
                      <span
                        className={`fieldText_alert--error ${
                          this.state.form_title_error !== '' ? '' : 'hide'
                        }`}
                      >
                        {this.state.form_title_error}
                      </span>
                      <div className="fieldText_note">
                        The title that will be shown throughout your status page
                        (your company name)
                      </div>
                    </div>
                    <div className="field-group">
                      <label className="fluid">hosted Subdomain </label>
                      <div
                        className={`inputWrapper fluid ${
                          this.state.formIsSubmited === true &&
                          this.state.form_hostedSubdomain_error !== ''
                            ? 'error'
                            : ''
                        }`}
                      >
                        <div className="input-prefix">https://</div>
                        <input
                          type="text"
                          className={`textField fluid ${
                            this.state.formIsSubmited === true &&
                            this.state.form_hostedSubdomain_value === ''
                              ? 'error'
                              : ''
                          }`}
                          placeholder={
                            this.state.form_hostedSubdomain_placeholder
                          }
                          value={this.state.form_hostedSubdomain_value}
                          onChange={this.handleChange__editHostedSubdomain}
                        />
                        <div className="input-suffix">.odown.io</div>
                      </div>
                      <span
                        className={`fieldText_alert--error ${
                          this.state.form_hostedSubdomain_error !== ''
                            ? ''
                            : 'hide'
                        }`}
                      >
                        {this.state.form_hostedSubdomain_error}
                      </span>
                      <div className="fieldText_note">
                        Access your status page via a custom subdomain on
                        odown.io. The subdomain needs to be unique across all
                        Uptime users.
                      </div>
                    </div>
                    <div className="field-group">
                      <label className="fluid">Custom domain</label>
                      <div
                        className={`inputWrapper fluid ${
                          this.state.formIsSubmited === true &&
                          this.state.form_customDomain_error !== '' &&
                          this.state.form_customDomain_value === ''
                            ? 'error'
                            : ''
                        }`}
                      >
                        <div className="input-prefix">https://</div>
                        <input
                          type="text"
                          className={`textField fluid ${
                            this.state.formIsSubmited === true &&
                            this.state.form_customDomain_error !== '' &&
                            this.state.form_customDomain_value === ''
                              ? 'error'
                              : ''
                          }`}
                          placeholder={this.state.form_customDomain_placeholder}
                          value={this.state.form_customDomain_value}
                          onChange={this.handleChange__editCustomDomain}
                        />
                      </div>
                      <span
                        className={`fieldText_alert--error ${
                          this.state.form_customDomain_error !== '' &&
                          this.state.form_customDomain_value !== ''
                            ? ''
                            : 'hide'
                        }`}
                      >
                        {this.state.form_customDomain_error}
                      </span>
                      <div className="fieldText_note">
                        Create a CNAME record pointing to status.odown.io with
                        the "status" value. Initial request takes a few seconds
                        longer due to the SSL registration processed on the fly!
                      </div>
                    </div>
                    <div className="field-group fluid">
                      <label className="fluid">Homepage link</label>
                      <input
                        type="text"
                        className={`textField fluid`}
                        placeholder={this.state.form_homepageLink_placeholder}
                        value={this.state.form_homepageLink_value}
                        onChange={this.handleChange__editHomepagelink}
                      />
                      <div className="fieldText_note">
                        The link to your homepage clickable from the header
                      </div>
                    </div>
                    <div className="field-group">
                      <label className="fluid">Chart color</label>
                      <div
                        className="textField colorPickerInputWrapper"
                        onClick={this.openColorPicker__chartColor}
                      >
                        <span
                          className="defaultColor"
                          style={{
                            backgroundColor:
                              this.state.form_statusPage_chartColor,
                          }}
                        ></span>
                        <span className="defaultColorString">
                          {this.state.form_statusPage_chartColor}
                        </span>
                      </div>
                      {this.state.colorPicker__chartColor_isOpen === true && (
                        <div className="colorPickerWrapper">
                          <div
                            className="colorPickerBackground"
                            onClick={this.handleClose_colorPicker}
                          />
                          <SketchPicker
                            color={this.state.form_statusPage_chartColor}
                            onChange={this.handleChange__editChartColor}
                          />
                        </div>
                      )}
                      <div className="fieldText_note">
                        Customise your chart line color
                      </div>
                    </div>
                    <div className="field-group">
                      <label className="fluid">Cover background color</label>
                      <div
                        className="textField colorPickerInputWrapper"
                        onClick={this.openColorPicker__backgroundColor}
                      >
                        <span
                          className="defaultColor"
                          style={{
                            backgroundColor:
                              this.state.form_statusPage_backgroundColor,
                          }}
                        ></span>
                        <span className="defaultColorString">
                          {this.state.form_statusPage_backgroundColor}
                        </span>
                      </div>
                      {this.state.colorPicker__backgroundColor_isOpen ===
                        true && (
                        <div className="colorPickerWrapper">
                          <div
                            className="colorPickerBackground"
                            onClick={this.handleClose_colorPicker}
                          />
                          <SketchPicker
                            color={this.state.form_statusPage_backgroundColor}
                            onChange={this.handleChange__editBackgroundColor}
                          />
                        </div>
                      )}
                      <div className="fieldText_note">
                        Customise your cover background color. We recommend
                        setting a dark color and a white version of your logo.
                        If you use a custom color, the buttons and links
                        contained in the header will all be set to white.
                      </div>
                    </div>
                    {/* Display open incidents in the status page's header */}
                    <div className="field-group fluid">
                      <label className="fluid" htmlFor="">
                        Display open incidents in the status page's header{' '}
                      </label>
                      <div className="field-group checkbox-group fluid">
                        <label
                          className="fluid margin-bottom-none"
                          htmlFor="show_open_incidents_realtime_checkbox"
                        >
                          <input
                            id="show_open_incidents_realtime_checkbox"
                            type="checkbox"
                            className={`textField`}
                            value=""
                            checked={this.state.display_open_incidents.realtime}
                            onChange={(event) =>
                              this.handleChange_formDisplayOpenIncidents(
                                event,
                                'realtime'
                              )
                            }
                          />
                          Open default incidents
                        </label>
                        <label
                          className="fluid margin-bottom-none"
                          htmlFor="show_open_incidents_scheduled_checkbox"
                        >
                          <input
                            id="show_open_incidents_scheduled_checkbox"
                            type="checkbox"
                            className={`textField`}
                            value=""
                            checked={
                              this.state.display_open_incidents.scheduled
                            }
                            onChange={(event) =>
                              this.handleChange_formDisplayOpenIncidents(
                                event,
                                'scheduled'
                              )
                            }
                          />
                          Open scheduled maintenances
                        </label>
                      </div>
                      <div className="fieldText_note">
                        Display incidents and maintenances that are not yet
                        closed on the status home page
                      </div>
                    </div>
                    {/* Active ranges and Chart's type */}
                    <div className="field-group charts-range--parameters fluid">
                      <label htmlFor="" className="fluid">
                        Active ranges and Chart's type
                      </label>
                      <div className="charts-range--items fluid">
                        <div className="charts-range--item chart-features">
                          <label></label>
                          <div className="config__chart-type c-feature">
                            Uptime Metric
                          </div>
                        </div>
                        {this.state.chartTypesConfig.map((element, index) => {
                          return (
                            <div
                              key={index}
                              className={`charts-range--item ${
                                element.is_active === true ? 'is-selected' : ''
                              }`}
                            >
                              <label
                                htmlFor={`config__displayedRange__${element.id}`}
                              >
                                <input
                                  type="checkbox"
                                  className={`textField`}
                                  id={`config__displayedRange__${element.id}`}
                                  checked={element.is_active}
                                  onChange={(event) =>
                                    this.handleChange__displayRange(
                                      event,
                                      element.id
                                    )
                                  }
                                />{' '}
                                {element.range_name}
                              </label>
                              <div className="config__chart-type uptime-chart">
                                <div className="radio-group fluid">
                                  {this.state.chartTypeUnchangeble.findIndex(
                                    (x) =>
                                      x.id === element.id &&
                                      (typeof x.uptime === 'undefined' ||
                                        (typeof x.uptime !== 'undefined' &&
                                          x.uptime === 'bar'))
                                  ) >= 0 && (
                                    <label
                                      className="fluid"
                                      htmlFor={`config__chartType__${element.id}__uptime_bar`}
                                    >
                                      <input
                                        type="radio"
                                        className="radio"
                                        id={`config__chartType__${element.id}__uptime_bar`}
                                        name={`config__chartType__${element.id}__uptime`}
                                        value={`bar`}
                                        checked={
                                          element.uptime_chart_type === 'bar'
                                        }
                                        onChange={(event) =>
                                          this.handleChange__selectRangeChartType(
                                            event,
                                            'uptime',
                                            element.id
                                          )
                                        }
                                      />{' '}
                                      <BarsChartTypeIcon />
                                      Bars chart
                                    </label>
                                  )}
                                  <label
                                    className="fluid"
                                    htmlFor={`config__chartType__${element.id}__uptime_area`}
                                  >
                                    <input
                                      type="radio"
                                      className="radio"
                                      id={`config__chartType__${element.id}__uptime_area`}
                                      name={`config__chartType__${element.id}__uptime`}
                                      value={`area`}
                                      checked={
                                        element.uptime_chart_type === 'area'
                                      }
                                      onChange={(event) =>
                                        this.handleChange__selectRangeChartType(
                                          event,
                                          'uptime',
                                          element.id
                                        )
                                      }
                                    />{' '}
                                    <AreaChartTypeIcon />
                                    Area chart
                                  </label>
                                </div>
                              </div>
                              {/* <div className="config__chart-type responsetime-chart">
																<label htmlFor="">Response Time Metric:</label>
																<div className="radio-group fluid">
																	<label className="fluid" htmlFor={`config__chartType__${element.id}__responsetime_bar`} >
																		<input
																			type="radio"
																			className="radio"
																			id={`config__chartType__${element.id}__responsetime_bar`}
																			name={`config__chartType__${element.id}__responsetime`}
																			value={`bar`}
																			checked={element.responsetime_chart_type ==='bar'}
																			onChange={(event) => this.handleChange__selectRangeChartType(event , 'responsetime',element.id)}
																		/> Bars chart</label>
																	<label
																		className="fluid"
																		htmlFor={`config__chartType__${element.id}__responsetime_area`} >
																		<input
																			type="radio"
																			className="radio"
																			id={`config__chartType__${element.id}__responsetime_area`}
																			name={`config__chartType__${element.id}__responsetime`}
																			value={`area`}
																			checked={element.responsetime_chart_type ==='area'}
																			onChange={(event) => this.handleChange__selectRangeChartType(event , 'responsetime',element.id)}
																		/> Area chart</label>
																</div>
															</div> */}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {
          <ModalRight
            title="Available monitors"
            closeModal={this.closeModal_selectMonitors}
            isDisplay={this.state.showModal_selectMonitors}
          >
            <div className="modal_layout-monitors-list">
              <div className="modal_list modal_layout-monitors-list_wrapper">
                <div className="modal-overflow-height_500">
                  {this.state.isLoading ? (
                    <div className="loading-wrapper">
                      <LoadingSpinner />
                    </div>
                  ) : this.state.monitorRowsErrors !== null ? (
                    <div className="alert alert-danger text-center">
                      {this.state.monitorRowsErrors}
                    </div>
                  ) : this.state.monitorRows.length <= 0 ? (
                    <div className="home-empty-state">
                      <img
                        alt="No Monitors"
                        src={icon_no_monitors}
                        width="100px"
                      />
                      <h3>There is No Monitors</h3>
                      <p className="text-muted">
                        Add your first monitor, this will be your dashboard.
                      </p>
                      <Link className="" to={{ pathname: `/monitors/new` }}>
                        <div className="btn btn-primary">
                          <svg
                            version="1.1"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            className="octicon octicon-plus"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"
                            ></path>
                          </svg>
                          <span>New Monitor</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    this.state.monitorRows.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`m-monitor_item ${
                            item.is_active ? '' : 'deactivated'
                          } ${item.is_down ? 'is-down' : ''} ${
                            this.state.statusPageSelectedMonitors.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? 'selected'
                              : ''
                          }`}
                          onClick={this.attachMonitor}
                          data-value={JSON.stringify({
                            id: item.id,
                            isActive: item.is_active,
                            isDown: item.is_down,
                            name: item.name,
                            url: item.url,
                            displayCharts: {
                              responseTime: false,
                              uptime: false,
                              indices: false,
                            },
                            // status : this.state.affected_monitors_statusList[0].id
                          })}
                          data-checked={
                            this.state.statusPageSelectedMonitors.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? true
                              : false
                          }
                        >
                          <input
                            type="checkbox"
                            checked={
                              this.state.statusPageSelectedMonitors.findIndex(
                                (x) => x.id === item.id
                              ) >= 0
                                ? true
                                : false
                            }
                            onChange={this.attachMonitor}
                          />
                          <StatusIndicator
                            status={
                              item.is_active === false
                                ? ''
                                : item.is_down === true
                                ? 'danger'
                                : 'success'
                            }
                            title={
                              item.is_active === false
                                ? 'Inactive Monitor'
                                : item.is_down === true
                                ? 'Failure Monitor'
                                : 'Active Monitor'
                            }
                          />
                          <div className="chk_details-content">
                            <span className="chk_details-name">
                              {item.name.toLowerCase()}
                            </span>
                            <span className="chk_details-url">
                              {item.url.length > 20
                                ? item.url.substr(0, 20) + '..'
                                : item.url}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              {this.state.pageCount > 0 && (
                <ReactPaginate
                  previousLabel={''}
                  nextLabel={''}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleChange_paginationMonitors}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              )}
            </div>
            <div className="d-flex margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_selectMonitors}
              >
                Back
              </button>
              {this.state.monitorRows.length > 0 && (
                <button
                  className="btn btn-primary float-left margin-left-10"
                  disabled={this.state.statusPageSelectedMonitors.length <= 0}
                  onClick={this.confirmAttachedMonitors}
                >
                  Attach the selected monitors (
                  <span>{this.state.statusPageSelectedMonitors.length}</span>){' '}
                </button>
              )}
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </ModalRight>
        }
        {/* {
					this.state.showModal_selectMonitors
						? <Modal title="Available monitors" closeModal={this.closeModal_selectMonitors}>
							<div >
								<div className="layout__monitors layout__blockInner" >
									<div className="modal-overflow-height_500">
										<table className={`check__items  ${isLoading ? 'isLoading' : ''}`} >
											<thead className={`${this.state.monitorRows.length <= 0 ? 'hide' : ''}`}>
												<tr>
													<th></th>
													<th align="center"></th>
													<th align="left">Name</th>
													<th align="left">Url</th>
												</tr>
											</thead>
											<tbody>
												{
													isLoading
													? <tr><td colSpan="4"><div className="loading-wrapper"><LoadingSpinner /></div></td></tr>
													: (
														this.state.monitorRowsErrors !== null 
														? <tr>
															<td colSpan="4">
																<div className="alert alert-danger text-center">{this.state.monitorRowsErrors}</div>
															</td>
														</tr>
														: 
														(this.state.monitorRows.length <= 0 
														?  <tr>
															<td align="center" colSpan="7">
																<div className="home-empty-state" >
																	<img src={icon_no_monitors} width="100px" />
																	<h3>There is No Monitors</h3>
																	<p className="text-muted">Add your first monitor, this will be your dashboard.</p>
																	<Link className="" to={{ pathname: `/monitors/new` }}>
																		<div className="btn btn-primary">
																			<svg version="1.1" width="16" height="16" viewBox="0 0 16 16" className="octicon octicon-plus" aria-hidden="true"><path fillRule="evenodd" d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"></path></svg>
																			<span>New Monitor</span>
																		</div>
																	</Link>	
																</div>
															</td>
														</tr>
														: this.state.monitorRows.map((item, index) => {

															return( <tr className="check__item" key={index}   >
																<td align="center"><input type="checkbox" 
																	value={JSON.stringify({
																		id : item.id , 
																		isActive: item.is_active , 
																		isDown : item.is_down , 
																		name : item.name , 
																		url : item.url, 
																		displayCharts : {responseTime : false , uptime : false , indices : false } })}  
																	checked={this.state.statusPageSelectedMonitors.findIndex(x => x.id === item.id) >= 0 ? true : false }   
																	onChange={this.attachMonitor} /></td>
																<td align="center">
																	<div className={`status-circle ${item.is_down === true ? `fail` : `success` } medium`}>
																		{item.is_down === true ?
																			<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path d="m5.78571429 4.83443522-4.75706811-4.75706811-.95127907.95127907 4.75706811 4.75706811-4.75706811 4.75706811.95127907.9512791 4.75706811-4.75706814 4.75706811 4.75706814.9512791-.9512791-4.75706814-4.75706811 4.75706814-4.75706811-.9512791-.95127907z" fill="#fff" transform="translate(9.428571 9.428571)"></path></svg>
																			:
																			<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path d="m0 5.14285714 1.28571429-1.28571428 3.21428571 3.21428571 7.0714286-7.07142857 1.2857143 1.28571429-8.3571429 8.35714285z" fill="#fff" transform="translate(8.571429 10.285714)"></path></svg>
																		}
																	</div>
																</td>
																<td className="check__info">
																	<div className="chk_details-content">
																		<span className="chk_details-name" >{item.name.toLowerCase()}</span>
																	</div>
																</td>
																<td className="check__info">
																	<div className="chk_details-content">
																		<span className="chk_details-url" >{item.url.length > 20 ? item.url.substr(0 , 20)+'..' : item.url}</span>
																	</div>
																</td>
															</tr>)
														}))
													)
												}
											</tbody>
										</table>
									</div>
									{
										this.state.pageCount > 0 && 
										<ReactPaginate
											previousLabel={""}
											nextLabel={""}
											breakLabel={"..."}
											breakClassName={"break-me"}
											pageCount={this.state.pageCount}
											marginPagesDisplayed={2}
											pageRangeDisplayed={5}
											onPageChange={this.handlePageClick}
											containerClassName={"pagination"}
											subContainerClassName={"pages pagination"}
											activeClassName={"active"}/>
									}
								</div>
							</div>
							<div className="d-flex justify-content-flex-end margin-top-20">
								<button className="btn btn-info float-left margin-left-10" onClick={this.closeModal_selectMonitors}>Back</button>
								{
									this.state.monitorRows.length > 0 && 
									<button className="btn btn-primary float-left margin-left-10" 
										disabled={this.state.statusPageSelectedMonitors.length <= 0} 
										onClick={this.confirmAttachedMonitors}>Attach the selected monitors (<span>{this.state.statusPageSelectedMonitors.length}</span>) </button>
								}
							</div>
							<div className={`modal-response alert alert-${this.state.modalResponse_status} ${this.state.modalResponse === '' ? 'hide' : ''}`}>{this.state.modalResponse}</div>
						</Modal>
						:''
				} */}
      </React.Fragment>
    );
  }
}
