import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import moment from 'moment';
import AppContext from '../../../context/app-context';

// css
import './LeftSidebar.css';

// load icons
import { ReactComponent as CloseMobileMenuIcon } from './../../../assets/icons/return.svg';
import { ReactComponent as Logo } from './../../../assets/logo/logo_negative.svg';

import { ReactComponent as MonitorMenuIcon } from './../../../assets/icons/monitors-menu.svg';
import { ReactComponent as AlertsSettingMenuIcon } from './../../../assets/icons/alert_settings-menu.svg';
import { ReactComponent as StatusPagesMenuIcon } from './../../../assets/icons/status_pages-menu.svg';
import { ReactComponent as TeamMenuIcon } from './../../../assets/icons/team-menu.svg';
import { ReactComponent as AccountsettingMenuIcon } from './../../../assets/icons/account_settings-menu.svg';
import { ReactComponent as BillingMenuIcon } from './../../../assets/icons/billing-menu.svg';
import { ReactComponent as AddIcon } from './../../../assets/icons/add.svg';
import { ReactComponent as IncidentIcon } from './../../../assets/icons/flash.svg';
import { ReactComponent as MaintenanceIcon } from './../../../assets/icons/cone.svg';

export default class LeftSidebar extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      planChecked: false,
      hasActivePlan: true,
    };

    this.handleChange__closeMobileSideBar =
      this.handleChange__closeMobileSideBar.bind(this);
  }

  getPlanAccountState() {
    if (this.state.planChecked === false) {
      if (
        typeof this.context.accountData !== 'undefined' &&
        this.context.accountData !== null
      ) {
        const accountData = this.context.accountData;
        //console.log('accountData : ' , accountData);
        // check if the account has an active plan ( a paid subscription OR active trial period )
        // if (typeof accountData.plan!=='undefined' && accountData.plan!==null &&
        // 	(Object.keys(accountData.plan).length===0) ||
        // 	(typeof accountData.plan.status!=='undefined' && accountData.plan.status !=='active') ||
        // 	(typeof accountData.plan.trial!=='undefined' && accountData.plan.trial===true &&
        // 	 typeof accountData.plan.periodEnd!=='undefined' && accountData.plan.periodEnd!==null &&
        // 	 moment(accountData.plan.periodEnd).diff(moment() , 'minutes') <= 0 )
        // 	){
        if (
          (typeof accountData.plan !== 'undefined' &&
            accountData.plan !== null &&
            Object.keys(accountData.plan).length === 0) ||
          (typeof accountData.plan.is_active !== 'undefined' &&
            accountData.plan.is_active === false) ||
          (typeof accountData.plan.is_trial !== 'undefined' &&
            accountData.plan.is_trial === true &&
            typeof accountData.created_at !== 'undefined' &&
            accountData.created_at !== null &&
            typeof accountData.plan.trial_period !== 'undefined' &&
            accountData.plan.trial_period !== null &&
            moment().diff(
              moment(accountData.created_at).add(
                accountData.plan.trial_period,
                'days'
              ),
              'minutes'
            ) > 0)
        ) {
          //console.log('THE ACCOUNT HAS NOT AN ACTIVE PLAN.......');
          this.setState({
            planChecked: true,
            hasActivePlan: false,
          });
        }
      }
    }
  }

  handleChange__closeMobileSideBar(event) {
    this.context.closeMobileSidebar();
  }

  componentDidUpdate() {
    // this.getPlanAccountState();
  }

  componentWillMount() {
    // //console.log(this.context.hiddenLeftSideBar);
  }
  render() {
    // console.log('process.env.REACT_APP_LIVE_PAYMENT : ' , process.env)

    return (
      <AppContext.Consumer>
        {(context) => (
          <React.Fragment>
            {context.isAuth && context.hiddenLeftSideBar === false && (
              <div
                className={`sidebarContainer ${
                  context.isActiveMobileSideBar === true ? 'active' : ''
                }`}
              >
                <div className="sidebar__wrapper">
                  <div
                    className="sidebar-close"
                    onClick={this.handleChange__closeMobileSideBar}
                  >
                    <CloseMobileMenuIcon />
                  </div>
                  <div className="logo">
                    <Logo />
                  </div>
                  <div className="menu">
                    <ul className="menu__list">
                      <li className="menu__list-item">
                        {this.state.hasActivePlan === true ? (
                          <button className="btn btn-primary">
                            <Link
                              className=""
                              to={{ pathname: `/monitors/new` }}
                              onClick={this.handleChange__closeMobileSideBar}
                            >
                              <AddIcon />
                              <span className="icon add"></span>
                              <span className="text">New Monitor</span>
                            </Link>
                          </button>
                        ) : (
                          <button className="btn btn-primary" disabled={true}>
                            <AddIcon />
                            <span className="icon add"></span>
                            <span className="text">New Monitor</span>
                          </button>
                        )}
                      </li>
                      <li className="menu__list-item">
                        <NavLink
                          exact={true}
                          activeClassName="is-active"
                          className="menu__link"
                          to="/"
                          onClick={this.handleChange__closeMobileSideBar}
                        >
                          <MonitorMenuIcon />
                          <span>Monitors</span>
                        </NavLink>
                      </li>
                      <li className="menu__list-item">
                        <NavLink
                          exact={true}
                          activeClassName="is-active"
                          className="menu__link"
                          to="/settings/alert"
                          onClick={this.handleChange__closeMobileSideBar}
                        >
                          <AlertsSettingMenuIcon />

                          <span>Alert settings</span>
                        </NavLink>
                      </li>
                      <li className="menu__list-item">
                        <NavLink
                          exact={true}
                          activeClassName="is-active"
                          className="menu__link"
                          to="/statuspages"
                          onClick={this.handleChange__closeMobileSideBar}
                        >
                          <StatusPagesMenuIcon />
                          <span>Status pages</span>
                        </NavLink>
                      </li>
                      {
                        // context.connectedUserData!==null && typeof context.connectedUserData.is_beta !=='undefined' &&
                        // // context.accountData!==null && context.accountData?.plan?.statuspage_features?.incident_management === true &&
                        // context.connectedUserData.is_beta!==null &&  context.connectedUserData.is_beta === true &&
                        true && (
                          <>
                            <li className="menu__list-item">
                              <NavLink
                                exact={true}
                                activeClassName="is-active"
                                className="menu__link"
                                to="/incidents"
                                onClick={this.handleChange__closeMobileSideBar}
                              >
                                <IncidentIcon />
                                <span>Incidents</span>
                              </NavLink>
                            </li>
                            <li className="menu__list-item">
                              <NavLink
                                exact={true}
                                activeClassName="is-active"
                                className="menu__link"
                                to="/maintenances"
                                onClick={this.handleChange__closeMobileSideBar}
                              >
                                <MaintenanceIcon />
                                <span>Maintenances</span>
                              </NavLink>
                            </li>
                          </>
                        )
                      }
                      <li className="menu__list-item">
                        <NavLink
                          exact={true}
                          activeClassName="is-active"
                          className="menu__link"
                          to="/settings/team"
                          onClick={this.handleChange__closeMobileSideBar}
                        >
                          <TeamMenuIcon />
                          <span>Team</span>
                        </NavLink>
                      </li>
                      <li className="menu__list-item">
                        <NavLink
                          exact={true}
                          activeClassName="is-active"
                          className="menu__link"
                          to="/settings/general"
                          onClick={this.handleChange__closeMobileSideBar}
                        >
                          <AccountsettingMenuIcon />
                          <span>Account settings</span>
                        </NavLink>
                      </li>
                      <li className="menu__list-item">
                        <NavLink
                          exact={true}
                          activeClassName="is-active"
                          className="menu__link"
                          to="/settings/billing"
                          onClick={this.handleChange__closeMobileSideBar}
                        >
                          <BillingMenuIcon />
                          <span>Billing</span>
                        </NavLink>
                      </li>
                      {process.env.REACT_APP_LIVE_PAYMENT === 'false' && (
                        <div className="test-mode_indice">TEST MODE</div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </AppContext.Consumer>
    );
  }
}
