import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
// context
import AppContext from '../../../../context/app-context';
import LoadingSpinner from '../../../layout/LoadingSpinner/LoadingSpinner';

// import css
import './EmailVerify.css';

// Import icons
import { ReactComponent as GotoApp_icon } from './../../../../assets/icons/right-arrow.svg';

import { ReactComponent as Logo } from './../../../../assets/logo/logo.svg';

export default class EmailVerify extends React.Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      token: props.match.params.token,
      verified: false,
      redirected_to_authPage: false,
      response: '',
      response_status: 'danger',
      isLoading: false,
      submitVerification: false,
    };

    this.validateEmailAddress = this.validateEmailAddress.bind(this);
  }

  /**
   * THE MAIN EMAIL VALIDATION
   */
  validateEmailAddress() {
    this.setState({
      isLoading: true,
      submitVerification: true,
    });
    if (this.state.token !== '') {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}users/confirm-email`,
          {
            token: this.state.token,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            response:
              'Your email was verified. You can continue using the application.',
            response_status: 'success',
            isLoading: false,
          });
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            this.setState({
              verified: true,
              response:
                'An error occurred while verifying the email, please try again.',
              response_status: 'danger',
              isLoading: false,
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
            this.setState({
              verified: true,
              response:
                'An error occurred while verifying the email, please try again.',
              response_status: 'danger',
              isLoading: false,
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
          }
        });
    }
  }

  componentDidMount() {
    // call : get the current invitation info by inviteId
    this.validateEmailAddress();
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Email Verify | Odown</title>
        </Helmet>
        <div className="layout__email-verify bg-gris">
          <div className="layout__email-verify--container">
            <div className="logo">
              <Logo />
            </div>
            <h1>Email Verification</h1>
            {this.state.isLoading === true && (
              <div className="loading-wrapper">
                <LoadingSpinner />
              </div>
            )}
            {this.state.isLoading === false &&
              this.state.submitVerification === true &&
              this.state.response !== '' && (
                <p
                  className={`alert alert-${this.state.response_status} 
										text-${this.state.response_status}`}
                >
                  {this.state.response}
                </p>
              )}
            {this.state.isLoading === false &&
              this.state.response_status === 'success' && (
                <div className="go-to-app">
                  <Link to="/">
                    <div className="btn btn-primary">
                      <span>Go to App</span>
                      <GotoApp_icon />
                    </div>
                  </Link>
                </div>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
