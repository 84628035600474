import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { verifySession } from './../../../authentification/SecurityController';
import { Helmet } from 'react-helmet';
// context
import AppContext from '../../../../context/app-context';

//css
// import { TextField, Button } from '@material-ui/core';
import './../auth-interface.css';

import { ReactComponent as Logo } from './../../../../assets/logo/logo.svg';

export default class ResetPassword extends React.Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      token: props.match.params.token,
      signin_password: '',
      signin_password_placeholder: '',
      signin_password_validate: true,
      signin_password_confirm: '',
      signin_password_confirm_placeholder: '',
      signin_password_confirm_validate: true,
      signin_response: '',
      signin_response_status: 'warning',
      signin_password_start_tapping: false,
      signin_password_confirm_start_tapping: false,
      isLoading: false,
      isConnected: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange_signinPassword =
      this.handleChange_signinPassword.bind(this);
    this.handleChange_signinPasswordConfirm =
      this.handleChange_signinPasswordConfirm.bind(this);
  }

  handleChange_signinPassword(event) {
    let signin_password_validate = false;
    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== ''
    ) {
      signin_password_validate = true;
    }
    this.setState({
      signin_password_start_tapping: true,
      signin_password: event.target.value,
      signin_password_validate: signin_password_validate,
    });
  }

  handleChange_signinPasswordConfirm(event) {
    let signin_password_confirm_validate = false;
    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== ''
    ) {
      signin_password_confirm_validate = true;
    }
    this.setState({
      signin_password_confirm_start_tapping: true,
      signin_password_confirm: event.target.value,
      signin_password_confirm_validate: signin_password_confirm_validate,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const {
      signin_password_confirm,
      signin_password,
      signin_password_validate,
      signin_password_confirm_validate,
    } = this.state;

    this.setState({
      isLoading: true,
    });

    // ! Check the password field
    if (
      typeof signin_password === 'undefined' ||
      signin_password === null ||
      signin_password_validate === false ||
      signin_password.length < 4
    ) {
      this.setState({
        isLoading: false,
        signin_response:
          'Password too short, try a mix of characters and numbers',
        signin_response_status: 'danger',
      });
      return;
    }

    // ! Check the confirm password field
    if (
      typeof signin_password_confirm === 'undefined' ||
      signin_password_confirm === null ||
      signin_password_confirm_validate === false
    ) {
      this.setState({
        isLoading: false,
        signin_response:
          'Confirm password too short, try a mix of characters and numbers',
        signin_response_status: 'danger',
      });
      return;
    }

    // ! check if the password and confirm password are equals
    if (signin_password !== signin_password_confirm) {
      this.setState({
        isLoading: false,
        signin_response: 'Password must be the same!',
        signin_response_status: 'danger',
      });
      return;
    }

    //post request to api
    let paramsPOST = {
      token: this.state.token,
      password: this.state.signin_password,
    };

    //post request to api
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}users/password/reset`,
        paramsPOST,
        {
          cancelToken: this.signal.token,
        }
      )
      .then((res) => res.data)
      .then(async (result) => {
        this.setState(
          {
            isLoading: false,
            signin_response: result.message,
            signin_response_status: 'success',
          },
          async () => {}
        );
      })
      .catch((error) => {
        //console.log('error : ' , error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //console.log(error.response.data);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          this.setState({
            isLoading: false,
            signin_response: error.response.data.message,
            signin_response_status: 'danger',
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              signin_response:
                'An error occurred while changing your password, please try again later',
              signin_response_status: 'danger',
            });
          }
        }
      });
  };

  componentDidMount() {
    verifySession()
      .then((token) => {
        this.setState({
          sessionToken: token,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        this.context.logout(); // update user session global state
      });
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    let submitButtonContent = (
      <span className="btn-inner load-spinner">
        <span>Change your password</span>
      </span>
    );
    if (this.state.isLoading) {
      submitButtonContent = (
        <span className="btn-inner load-spinner">
          <span className="loader_spinner--content"></span>
          <span>Please wait</span>
        </span>
      );
    }

    const {
      signin_password_start_tapping,
      signin_password_validate,
      signin_password_confirm_start_tapping,
      signin_password_confirm_validate,
    } = this.state;

    // if (//(typeof this.context.isAuth!=='undefined' && this.context.isAuth === true )||
    // 	(this.state.sessionToken !== null && typeof this.context.connectedUserData!=='undefined' && this.context.connectedUserData !==null)) {
    // 	// redirect to dashboard home page
    // 	return <Redirect to="/" />
    // 	// return (
    // 	// 	<div className >
    // 	// 		<div >Connected</div>
    // 	// 		<button onClick={this.handleSignout} >Sign out</button>
    // 	// 	</div>
    // 	// );
    // }

    return (
      <>
        <Helmet>
          <title>Change your Password | Odown</title>
        </Helmet>
        <div className="layout__session layout__form--login bg-gris">
          <div className="layout__session--container">
            <div className="logo">
              <Logo />
            </div>
            <h1>Change your Password</h1>
            <form
              className="margin-top-20"
              method="POST"
              action=""
              onSubmit={this.handleSubmit}
            >
              <div className="form-container">
                <div
                  className={`response-group margin-top-10 ${
                    this.state.signin_response_status
                  } message ${this.state.signin_response === '' ? 'hide' : ''}`}
                >
                  <div className="">{this.state.signin_response}</div>
                </div>
                {this.state.signin_response_status !== 'success' && (
                  <>
                    <div className="field-group">
                      <label htmlFor="" className="fluid">
                        New Password
                      </label>
                      <input
                        type="password"
                        className={`textField fluid ${
                          signin_password_start_tapping === true &&
                          signin_password_validate === false
                            ? 'error'
                            : ''
                        }`}
                        placeholder={this.state.signin_password_placeholder}
                        value={this.state.signin_password}
                        onChange={this.handleChange_signinPassword}
                      />
                    </div>
                    <div className="field-group">
                      <label htmlFor="" className="fluid">
                        Confirm New Password
                      </label>
                      <input
                        type="password"
                        className={`textField fluid ${
                          signin_password_confirm_start_tapping === true &&
                          signin_password_confirm_validate === false
                            ? 'error'
                            : ''
                        }`}
                        placeholder={
                          this.state.signin_password_confirm_placeholder
                        }
                        value={this.state.signin_password_confirm}
                        onChange={this.handleChange_signinPasswordConfirm}
                      />
                    </div>
                    <div className="field-group submit-fields-group fluid">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={
                          !(
                            this.state.signin_password_validate &&
                            this.state.signin_password_confirm_validate
                          )
                        }
                      >
                        {submitButtonContent}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </form>
            {this.state.signin_response_status === 'success' ? (
              <div className="other-sign-block">
                <Link to="/signin" className="btn btn-primary">
                  Login to your account
                </Link>
              </div>
            ) : (
              <div className="other-sign-block">
                <label htmlFor="">Don’t have an odown account?</label>
                <Link to="/signup">Sign up for free</Link>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
