import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { isLoggedIn, HasAccountId } from './SecurityController';

/* const PrivateRoute = ({ component: Component, ...rest }) => {
	
	 
	const isLogin = isLoggedIn();
	const { connectedUserData } = useContext(AppContext);

	const __HasAccountId = HasAccountId();
	const __getDefaultValidAccountId = getDefaultValidAccountId(connectedUserData);
	//console.log('__getDefaultValidAccountId' , __getDefaultValidAccountId)

	//console.log('__HasAccountId' , __HasAccountId)
	//console.log('isLogin' , isLogin)
	//console.log('Component' , Component)
	//console.log('rest' , rest)
	 
	return (
		typeof rest.children!=='undefined' && rest.children!==null 
		? 

		// Show the component only when the user is logged in
		// Otherwise, redirect the user to /signin page
		<Route {...rest} render={props => (
			isLogin 
			? (
				!__HasAccountId && rest.path !=='/intro'
				? <Redirect to="/intro" />
				: <Component {...props} />
			)
			: <Redirect to="/signin" />
		)} />
	);

	
}; */

class PrivateRoute extends Route {
  render() {
    if (isLoggedIn()) {
      if (HasAccountId()) {
        // Redirect to main page
        if (this.props.location.pathname === '/intro') {
          return (
            <Redirect
              to={{ pathname: '/', state: { from: this.props.location } }}
            />
          );
          // Display the content
        } else {
          return <Route {...this.props} />;
        }
      } else {
        // Display the content
        if (this.props.location.pathname === '/intro') {
          return <Route {...this.props} />;
          // Redirection to intro page
        } else {
          return (
            <Redirect
              to={{
                pathname: '/intro',
                state: { from: this.props.location },
              }}
            />
          );
        }
      }
    } else {
      return (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: this.props.location },
          }}
        />
      );
    }
  }
}

/* const PrivateRoute = (props) => (
	<Fragment>
		{  isLoggedIn() 
			? (
				!HasAccountId() //&& props.location.pathname !=='/intro'
				? <Redirect to="/intro" />
				: props.children
			)
			 
			: <Redirect to="/signin" />  }
	</Fragment>
)
 */

export default PrivateRoute;

// export default class PrivateRoute extends React.Component {

//     constructor(props) {
//         super(props)
//         this.state = {
//             isAuthenticated : false
//         }
//     }

//     componentDidMount() {
//         const obj = await getItemStorage(`${process.env.REACT_APP_AUTH_NAME_STORAGE}`);
// 		//console.log(obj);

//         if (obj && obj.token) {

//             const { token } = obj;
//             let tokenExpiration = jwtDecode(token).exp;

//             if (tokenExpiration < new Date().getTime() / 1000) {
//                 this.setState({
//                     isAuthenticated: true
//                 });
//             } else {
//                 this.setState({
//                     isAuthenticated: false
//                 });
//             }

//         }
//     }

//     render() {
//         return (
//             <Route {...rest} render={props => (
//                 this.state.isAuthenticated ?
//                     <Component {...props} />
//                 : <Redirect to="/signin" />
//             )} />
//         )
//     }
// }
