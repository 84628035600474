import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class NavbarSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedtab:
        typeof this.props.selectedTab !== 'undefined'
          ? this.props.selectedTab
          : 'general',
    };
  }

  render() {
    return (
      // <div className="tabs-header">
      // 	<ul>
      // 		<li className={this.state.selectedtab === 'general' ? 'active' : ''}><Link to="/settings/general" >Account</Link></li>
      // 		<li className={this.state.selectedtab === 'team' ? 'active' : ''}><Link to="/settings/team" >Team</Link></li>
      // 		<li className={this.state.selectedtab === 'billing' ? 'active' : ''}><Link to="/settings/billing" >Billing</Link></li>
      // 		<li className={this.state.selectedtab === 'sms' ? 'active' : ''}><Link to="/settings/sms" >SMS Settings</Link></li>
      // 	</ul>
      // </div>
      <div className="tab-settings__nav">
        <ul>
          <li className={this.state.selectedtab === 'general' ? 'active' : ''}>
            <span>
              <Link to="/settings/general">Account</Link>
            </span>
            {/* <span className={`errors-numbers ${tabsErrors.monitors === 0 ? 'hide' : '' }`}>{tabsErrors.monitors}</span> */}
          </li>
          <li className={this.state.selectedtab === 'team' ? 'active' : ''}>
            <span>
              <Link to="/settings/team">Team</Link>
            </span>
            {/* <span className={`errors-numbers ${tabsErrors.settings === 0 ? 'hide' : '' }`}>{tabsErrors.settings}</span> */}
          </li>
          <li className={this.state.selectedtab === 'sms' ? 'active' : ''}>
            <span>
              <Link to="/settings/sms">SMS Settings</Link>
            </span>
            {/* <span className={`errors-numbers ${tabsErrors.notifications === 0 ? 'hide' : '' }`}>{tabsErrors.notifications}</span> */}
          </li>
          <li className={this.state.selectedtab === 'billing' ? 'active' : ''}>
            <span>
              <Link to="/settings/billing">Billing</Link>
            </span>
            {/* <span className={`errors-numbers ${tabsErrors.subscribers === 0 ? 'hide' : '' }`}>{tabsErrors.subscribers}</span> */}
          </li>
        </ul>
      </div>
    );
  }
}
