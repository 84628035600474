import React, { Component } from 'react';

export default class HeadwayWidget extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);
    var config = {
      selector: '.headway-badge',
      account: 'xMEm3x',
      // account: "7vG80J"
      // 7vG80J => for hyperping
      // xMEm3x => for odown
    };
    script.onload = function () {
      window.Headway.init(config);
      // console.log('Headway initialing ....');
    };
  }
  render() {
    return (
      <div>
        <span className="headway-badge" id="headway-badge">
          Changelog
        </span>
      </div>
    );
  }
}
