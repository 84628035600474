import React, { Component } from 'react';

export default class GenericNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="">404 ERROR PAGE</div>
      </React.Fragment>
    );
  }
}
