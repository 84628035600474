import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Helmet } from 'react-helmet';
// import jwtDecode from 'jwt-decode';

import { getItemStorage } from '../../../utils/storage';
// load Components
import Navbar from '../../layout/Navbar/Navbar';
import LoadingSpinner from './../../layout/LoadingSpinner/LoadingSpinner';

import AppContext from '../../../context/app-context';

import { getDefaultValidAccountId } from './../../authentification/SecurityController';

import Modal from './../../layout/Modal/Modal';

import './StatusPages.css';
import './../../layout/Pagination/Pagination.css';

// import icons
import icon_no_statuspages from './../../../assets/icons/content.svg';
import { ReactComponent as EditPenIcon } from './../../../assets/icons/3643749_edit_pen_pencil_write_writing_icon.svg';

export default class StatusPages extends React.Component {
  static contextType = AppContext;

  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      data: [],
      perPage: 10,
      currentPage: 0,
      pageCount: 0,
      statusPagesItems: [],
      statusPagesItems_response: '',
      statusPagesItems_response_status: 'warning',
      accountId: null,
      isLoading: false,

      showModal_removeStatusPage: false,
      modalResponse_removeStatusPage_status: 'warning',
      modalResponse_removeStatusPage: '',
      statuspageId_toRemove: null,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.openModal_removeStatusPage =
      this.openModal_removeStatusPage.bind(this);
    this.closeModal_removeStatusPage =
      this.closeModal_removeStatusPage.bind(this);
    this.removeStatusPage = this.removeStatusPage.bind(this);
  }

  getStatusPagesItems = async () => {
    this.setState({
      isLoading: true,
    });
    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    if (obj && obj.token) {
      const token = obj.token;

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${this.state.accountId}/statuspages`,
          {
            params: {
              page: this.state.offset,
              per_page: this.state.perPage,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            pageCount: Math.ceil(data.total / this.state.perPage),
            statusPagesItems: data.data,
            statusPagesItems_response:
              data.total <= 0 ? "You don't have any status pages yet!" : '',
            statusPagesItems_response_status: 'success',
            isLoading: false,
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            //console.log('Error: ', err.message); // => prints: Api is being canceled
          } else {
            if (error.response) {
              this.setState({
                pageCount: 0,
                statusPagesItems: [],
                isLoading: false,
                statusPagesItems_response:
                  error.response.status === 401
                    ? 'Authentication failed, please log in again'
                    : error.response.data.message,
                statusPagesItems_response_status: 'danger',
              });
            } else {
              // Something happened in setting up the request that triggered an Error
              if (
                typeof error.message === 'undefined' ||
                error.message === null ||
                error.message !== 'API_HAS_BEEN_CANCELED'
              ) {
                this.setState({
                  pageCount: 0,
                  statusPagesItems: [],
                  isLoading: false,
                  statusPagesItems_response:
                    'Something went wrong, please try again later.',
                  statusPagesItems_response_status: 'danger',
                });
              }
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getStatusPagesItems(offset);
      }
    );
  };

  sortDataByDate = (items) =>
    [...items].sort(
      (itemA, itemB) =>
        new Date(itemA.selectedDate) - new Date(itemB.selectedDate)
    );

  openModal_removeStatusPage(event) {
    this.setState({
      showModal_removeStatusPage: true,
      statuspageId_toRemove: event.currentTarget.dataset.id,
      modalResponse_removeStatusPage: '',
      modalResponse_removeStatusPage_status: 'warning',
    });
  }

  closeModal_removeStatusPage(event) {
    this.setState({
      showModal_removeStatusPage: !this.state.showModal_removeStatusPage,
      modalResponse_removeStatusPage: '',
    });
  }

  removeStatusPage = async (event) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/statuspages/${this.state.statuspageId_toRemove}`,
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          //console.log(res)

          if (res.status === 200) {
            const selectedDeletedId = this.state.statuspageId_toRemove;

            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse_removeStatusPage:
                'Status page removed successfully',
              modalResponse_removeStatusPage_status: 'success',
              statusPagesItems: this.state.statusPagesItems.filter(
                function (obj) {
                  return obj.id !== selectedDeletedId;
                }
              ),
              showModal_removeStatusPage: false,
            });
          } else {
            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse_removeStatusPage:
                'Something went wrong removing your status page.',
              modalResponse_removeStatusPage_status: 'danger',
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              modalResponse_removeStatusPage:
                'Something went wrong removing your status page.',
              modalResponse_removeStatusPage_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        modalResponse_removeStatusPage:
          'You are not authorized to do this operation',
        modalResponse_removeStatusPage_status: 'danger',
      });
      return;
    }
  };

  async componentDidMount() {
    const targetAccountId = await getDefaultValidAccountId(
      this.context.connectedUserData
    );

    this.setState(
      {
        accountId: targetAccountId,
      },
      async () => {
        this.getStatusPagesItems();
      }
    );
  }

  componentWillUnmount() {
    this.signal.cancel('Api is being canceled');
  }
  render() {
    const breadcrumbs_items = [
      {
        name: 'Home',
        link: '/',
        isLink: true,
      },
      {
        name: 'Status Pages',
        link: '/',
        isLink: false,
      },
    ];

    const { isLoading } = this.state;

    //console.log(this.context);

    return (
      <React.Fragment>
        <Helmet>
          <title>Status pages | Odown</title>
        </Helmet>
        <Navbar breadcrumbs_items={breadcrumbs_items} background="white" />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__statuspages layout__blockInner">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>Status pages </h2>
                </div>
                <div className="l_header__btnaction">
                  <Link to="/statuspages/new" className="btn btn-primary">
                    Create status page
                  </Link>
                </div>
                <div className="l_header__description">
                  {this.state.exceed_account_user_limit === true && (
                    <div className="alert alert-warning">
                      You've hit your plan limit of users. Remove users or
                      outstanding invites, or{' '}
                      <Link to="/settings/billing">upgrade your plan</Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="layout__body white-card">
                {/* {isLoading 
									? <div className="loading-wrapper"><LoadingSpinner /></div>
									: ''
								} */}
                {/* <div className="loading-wrapper"><LoadingSpinner /></div> */}
                <table
                  className={`default-table status-page-items${
                    this.state.statusPagesItems.length <= 0 ? 'empty' : ''
                  }`}
                >
                  <thead
                    className={
                      this.state.statusPagesItems.length <= 0 ? 'hide' : ''
                    }
                  >
                    <tr>
                      <th></th>
                      <th align="left">Title</th>
                      <th align="left">Custom url</th>
                      <th align="left">Custom domain</th>
                      <th align="left">Created</th>
                      <th align="right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="6">
                          <div className="loading-wrapper">
                            <LoadingSpinner />
                          </div>
                        </td>
                      </tr>
                    ) : this.state.statusPagesItems_response_status !==
                      'success' ? (
                      <tr>
                        <td colSpan="5">
                          <div className="alert alert-danger text-center">
                            {this.state.statusPagesItems_response}
                          </div>
                        </td>
                      </tr>
                    ) : this.state.statusPagesItems.length <= 0 ? (
                      <tr>
                        <td colSpan="5">
                          <div className="home-empty-state">
                            <img
                              alt=">No Active Status pages found"
                              src={icon_no_statuspages}
                              width="100px"
                            />
                            <h3>No Active Status pages found</h3>
                            <p className="text-muted">
                              Communicate incidents and maintenances to your
                              users in beautifully simple status pages.
                            </p>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      this.state.statusPagesItems.map((item, index) => {
                        return (
                          <tr className="page__item" key={index}>
                            <td align="center"></td>
                            <td align="left">
                              <div className="field-content">
                                {item.name !== '' ? item.name : '-'}
                              </div>
                            </td>
                            <td align="left">
                              <div className="field-content">
                                {item.hosted_subdomain !== '' ? (
                                  <a
                                    href={`https://${item.hosted_subdomain}.${process.env.REACT_APP_DOMAIN_NAME}`}
                                    target="_blanc"
                                  >
                                    <span className="">
                                      {item.hosted_subdomain}
                                    </span>
                                    <span className="text-muted">
                                      .{process.env.REACT_APP_DOMAIN_NAME}
                                    </span>
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </div>
                            </td>
                            <td align="left">
                              <div className="field-content">
                                {item.custom_domain !== '' ? (
                                  <a
                                    href={`https://${item.custom_domain}`}
                                    target="_blanc"
                                  >
                                    {item.custom_domain}{' '}
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </div>
                            </td>
                            <td align="left">
                              <div className="field-content">
                                {moment(item.created_at).format(
                                  'MMM DD, YYYY hh:mm A'
                                )}
                              </div>
                            </td>
                            <td align="right">
                              <div className="btns_action">
                                <div className="btn_action-item edit">
                                  <Link to={`/statuspages/${item.id}/edit`}>
                                    <EditPenIcon />
                                  </Link>
                                </div>
                                <div className="btn_action-item external-link">
                                  <a
                                    href={
                                      item.custom_domain !== ''
                                        ? `https://${item.custom_domain}`
                                        : `https://${item.hosted_subdomain}.${process.env.REACT_APP_DOMAIN_NAME}`
                                    }
                                    target="_blanc"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-external-link injected-svg svg"
                                    >
                                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                      <polyline points="15 3 21 3 21 9"></polyline>
                                      <line
                                        x1="10"
                                        y1="14"
                                        x2="21"
                                        y2="3"
                                      ></line>
                                    </svg>
                                  </a>
                                </div>
                                <div
                                  className="btn_action-item remove"
                                  data-id={item.id}
                                  onClick={this.openModal_removeStatusPage}
                                >
                                  <svg
                                    version="1.1"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    className="octicon octicon-trash"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                                    ></path>
                                    <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                                    <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                                  </svg>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {this.state.pageCount > 0 && (
                  <ReactPaginate
                    previousLabel={''}
                    nextLabel={''}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.showModal_removeStatusPage ? (
          <Modal
            title="Delete this status page?"
            closeModal={this.closeModal_removeStatusPage}
            fixedWidthWindow="small-window"
          >
            <div>Are you sure you want to remove this status page?</div>
            <div className="d-flex justify-content-flex-end margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_removeStatusPage}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger float-right margin-left-10"
                onClick={this.removeStatusPage}
              >
                Remove
              </button>
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_removeStatusPage_status
              } ${
                this.state.modalResponse_removeStatusPage === '' ? 'hide' : ''
              }`}
            >
              {this.state.modalResponse_removeStatusPage}
            </div>
          </Modal>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}
