import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from 'rc-tooltip';
// css
import './DropDownMenu.css';

export default class DropDownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItems: this.props.items,
      menuWidth:
        typeof this.props.width !== 'undefined' ? this.props.width : 'default',
      menuCustomDesign:
        typeof this.props.customDesign !== 'undefined'
          ? this.props.customDesign
          : '',
      descriptionAsTooltip:
        typeof this.props.descriptionAsTooltip !== 'undefined'
          ? this.props.descriptionAsTooltip
          : false,
    };

    this.getDropdownItemContent = this.getDropdownItemContent.bind(this);
  }

  /**
   *
   * @param {*} item
   * @returns
   */
  getDropdownItemContent(item) {
    return item.link !== false ? (
      <Link to={item.link}>
        <div className="dropdown-item_content">
          {typeof item.icon !== 'undefined' &&
          item.icon !== null &&
          item.icon !== '' ? (
            <span
              className={`dropdown-main-icon ${item.icon} ${
                item.bigIcon === true ? 'big-icon' : ''
              }`}
            ></span>
          ) : (
            ''
          )}
          <div className="di-text_content">
            <span className={`title ${'description' in item ? 'bold' : ''}`}>
              {item.name}
            </span>
            {'description' in item &&
            item.description !== 'undefined' &&
            item.description !== null &&
            item.description !== '' ? (
              <p className="desc">{item.description}</p>
            ) : (
              ''
            )}
          </div>
          {typeof item.isNew !== 'undefined' &&
            item.isNew !== null &&
            item.isNew === true && <span className="is-new">New</span>}
        </div>
      </Link>
    ) : (
      <div
        className={`dropdown-item_content ${
          typeof item.selected !== 'undefined' &&
          item.selected !== null &&
          item.selected === true
            ? 'selected'
            : ''
        }`}
        onClick={item.onClick}
      >
        {typeof item.icon !== 'undefined' &&
        item.icon !== null &&
        item.icon !== '' ? (
          <span
            className={`dropdown-main-icon ${item.icon} ${
              item.bigIcon === true ? 'big-icon' : ''
            }`}
          ></span>
        ) : (
          ''
        )}
        <div className={`di-text_content `}>
          <span className={`title ${'description' in item ? 'bold' : ''}`}>
            {item.name}
          </span>
          {'description' in item &&
          item.description !== 'undefined' &&
          item.description !== null &&
          item.description !== '' ? (
            <p className="desc">{item.description}</p>
          ) : (
            ''
          )}
        </div>
        {typeof item.isNew !== 'undefined' &&
          item.isNew !== null &&
          item.isNew === true && <span className="is-new">New</span>}
      </div>
    );
  }

  render() {
    return (
      <div
        role="menu"
        className={`dropdown-menu dropdown-menu-right ${
          this.state.menuWidth !== 'default' ? this.state.menuWidth : ''
        } ${this.state.menuCustomDesign} `}
      >
        {this.state.menuItems.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {'seperator' in item && item.seperator === true && (
                <div role="separator" className="dropdown-divider"></div>
              )}
              <div className={`dropdown-item `}>
                {this.state.descriptionAsTooltip === true ? (
                  <Tooltip
                    placement="top"
                    trigger={['hover']}
                    overlay={<span>{item.description}</span>}
                  >
                    {this.getDropdownItemContent(item)}
                  </Tooltip>
                ) : (
                  this.getDropdownItemContent(item)
                )}
                {/* {
									item.link !==false
									? <Link to={item.link}>
										<div className="dropdown-item_content">
											{typeof item.icon!=='undefined' && item.icon!==null && item.icon !== ''
												? <span className={`dropdown-main-icon ${item.icon} ${item.bigIcon === true ? 'big-icon' : ''}`} ></span>
												: ''}
											<div className="di-text_content">
												<span className={`title ${'description' in item ? 'bold' : ''}`}>{item.name}</span>
												{("description" in item && item.description !=='undefined' && item.description!==null && item.description !== '')
													? <p className="desc">{item.description}</p>
													: ''}
											</div>
										</div>  
									</Link>
									: <div className="dropdown-item_content" onClick={item.onClick}>
										{typeof item.icon!=='undefined' && item.icon!==null && item.icon !== ''
											? <span className={`dropdown-main-icon ${item.icon} ${item.bigIcon === true ? 'big-icon' : ''}`} ></span>
											: ''
										}
										<div className={`di-text_content ${typeof item.selected!=='undefined' && item.selected!==null && item.selected===true ? 'selected' : ''}`} >
											<span className={`title ${'description' in item ? 'bold' : ''}`}>{item.name}</span>
											{("description" in item && item.description !=='undefined' && item.description!==null && item.description !== '')
												? <p className="desc">{item.description}</p>
												: ''}
										</div>
									</div> 
								} */}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}
