import React, { Component } from 'react';
import moment from 'moment';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';
import Switch from 'react-switch';
import Tooltip from 'rc-tooltip';
import { SketchPicker } from 'react-color';

import { getStatusPageAlertChannels } from '../../../utils/helper';
import { getItemStorage, removeItemStorage } from '../../../utils/storage';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';
import Navbar from '../../layout/Navbar/Navbar';
import Modal from './../../layout/Modal/Modal';
import GA from './../../../components/Tracking/GoogleAnalytics';
import ModalRight from './../../layout/Modal/ModalRight';
import StatusIndicator from '../../layout/StatusIndicator/StatusIndicator';
import AppContext from '../../../context/app-context';
import { getPlans } from '../../../utils/billingsData';
// Style
import './StatusPageForm.css';
// Import icons
import icon_no_monitors from './../../../assets/icons/cactus.svg';
import { ReactComponent as AddIcon } from './../../../assets/icons/add.svg';
import { ReactComponent as AreaChartTypeIcon } from './../../../assets/icons/area-chart-type.svg';
import { ReactComponent as BarsChartTypeIcon } from './../../../assets/icons/bars-chart-type.svg';
import icon_no_alert_channel from './../../../assets/icons/box.svg';

import { UpgradeButton } from '../../layout/Button/Button';

export default class EditStatusPage extends Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      statuspageId: props.match.params.statuspageId,
      hasActiveAccount: true,
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Status Pages',
          link: '/statuspages',
          isLink: true,
        },
        {
          name: 'loading',
          link: '#',
          isLink: false,
        },
        {
          name: 'Edit',
          link: '#',
          isLink: false,
        },
      ],
      form_response_status: 'warning',
      form_response: '',
      formIsSubmited: false,
      showModal_selectMonitors: false,
      modalResponse_status: 'warning',
      modalResponse: '',
      monitorRows: [],
      monitorRowsErrors: null,
      isLoading: false,
      //
      offset: 0,
      data: [],
      perPage: 10,
      currentPage: 0,
      pageCount: 0,
      statusPageSelectedMonitors: [],
      form_statusPage_chartColor: '#0099cc',
      form_statusPage_backgroundColor: '#ffffff',
      colorPicker__chartColor_isOpen: false,
      colorPicker__backgroundColor_isOpen: false,

      form_logoUrl_placeholder: 'Url to company logo',
      form_logoUrl_value: '',
      form_title_placeholder: '',
      form_title_value: '',
      form_title_error: '',
      form_hostedSubdomain_placeholder: 'companyname',
      form_hostedSubdomain_value: '',
      form_hostedSubdomain_error: '', //"Hosted Subdomain is required.",
      form_hostedSubdomain_regex_validator: /^[A-Za-z0-9\-]+$/,
      form_customDomain_placeholder: 'status.companyname.com',
      form_customDomain_value: '',
      form_customDomain_error: '',
      form_homepageLink_placeholder: 'https://companyname.com',
      form_homepageLink_value: '',
      loadingStatusPageData: false,
      tabs_form_selectedTab: 'monitors',
      tabs_form_tabsErrors: {
        monitors: 0,
        settings: {
          title: 0,
          subdomain: 0,
        },
        subscribers: 0,
        notifications: 0,
      },
      activeChannelsForSubscribers: [
        'email',
        'slack',
        // 'sms',
        'webhook',
        // 'telegram',
        // 'discord',
        'rss',
      ],
      // subscribersNotifyType : 'only_incidents',
      subscribersNotificationTypes: {
        incident_updates: true,
        uptime_alerts: false,
      },
      allow_subscriptions: false,
      chartTypesConfig: [
        {
          id: '24hours',
          range_name: '24 hours',
          is_active: true,
          uptime_chart_type: 'area',
          responsetime_chart_type: 'area',
        },
        {
          id: '1week',
          range_name: '1 week',
          is_active: true,
          uptime_chart_type: 'area',
          responsetime_chart_type: 'area',
        },
        {
          id: '1month',
          range_name: '1 month',
          is_active: true,
          uptime_chart_type: 'area',
          responsetime_chart_type: 'area',
        },
        {
          id: '3months',
          range_name: '3 months',
          is_active: true,
          uptime_chart_type: 'bar',
          responsetime_chart_type: 'area',
        },
        {
          id: '12months',
          range_name: '12 months',
          is_active: true,
          uptime_chart_type: 'area',
          responsetime_chart_type: 'area',
        },
      ],
      chartTypeUnchangeble: [
        {
          id: '24hours',
          range_name: '24 hours',
          uptime: 'area',
          responsetime: 'area',
        },
        {
          id: '1week',
          range_name: '1 week',
          responsetime: 'area',
        },
        {
          id: '1month',
          range_name: '1 month',
          responsetime: 'area',
        },
        {
          id: '3months',
          range_name: '3 months',
          responsetime: 'area',
        },
        {
          id: '12months',
          range_name: '12 months',
          uptime: 'area',
          responsetime: 'area',
        },
      ],
      display_open_incidents: {
        realtime: true,
        scheduled: true,
      },

      hasActiveSMSProvider: false,
      statusPageNotFound: false,
      totalAvailableSubscribers: 0,
      // Billing settings :
      currentPricingPlan: null,
      max_usage: {},
    };
    this.getAccountData = this.getAccountData.bind(this);
    this.openModal_selectMonitors = this.openModal_selectMonitors.bind(this);
    this.closeModal_selectMonitors = this.closeModal_selectMonitors.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAvailableMonitors = this.getAvailableMonitors.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.attachMonitor = this.attachMonitor.bind(this);
    this.confirmAttachedMonitors = this.confirmAttachedMonitors.bind(this); // after choose of monitors, we back and add them to main form
    this.handleChange__removeAttachedMonitor =
      this.handleChange__removeAttachedMonitor.bind(this);
    this.handleChange__editChartColor =
      this.handleChange__editChartColor.bind(this);
    this.handleChange__editBackgroundColor =
      this.handleChange__editBackgroundColor.bind(this);
    this.openColorPicker__chartColor =
      this.openColorPicker__chartColor.bind(this);
    this.openColorPicker__backgroundColor =
      this.openColorPicker__backgroundColor.bind(this);
    this.handleClose_colorPicker = this.handleClose_colorPicker.bind(this);
    this.handleChange__editLogoUrl = this.handleChange__editLogoUrl.bind(this);
    this.handleChange__editTitle = this.handleChange__editTitle.bind(this);
    this.handleChange__editHostedSubdomain =
      this.handleChange__editHostedSubdomain.bind(this);
    this.handleChange__editCustomDomain =
      this.handleChange__editCustomDomain.bind(this);
    this.handleChange__editHomepagelink =
      this.handleChange__editHomepagelink.bind(this);
    this.handleChange__displayResponseTimeMonitor =
      this.handleChange__displayResponseTimeMonitor.bind(this);
    this.handleChange__displayUptimeMonitor =
      this.handleChange__displayUptimeMonitor.bind(this);
    this.handleChange__displayUptimeIndicesMonitor =
      this.handleChange__displayUptimeIndicesMonitor.bind(this);
    this.handleChange__form_selectTab =
      this.handleChange__form_selectTab.bind(this);

    this.handleChange_selectChannels =
      this.handleChange_selectChannels.bind(this);
    this.handleChange_formNotificationTypes =
      this.handleChange_formNotificationTypes.bind(this);
    this.handleChange_formAllowSubscribers =
      this.handleChange_formAllowSubscribers.bind(this);
    this.handleChange__displayRange =
      this.handleChange__displayRange.bind(this);
    this.handleChange__selectRangeChartType =
      this.handleChange__selectRangeChartType.bind(this);
    this.handleChange_formDisplayOpenIncidents =
      this.handleChange_formDisplayOpenIncidents.bind(this);
    this.subscriberManagerCallback = this.subscriberManagerCallback.bind(this);
  }

  /**
   *
   *
   * @memberof EditMonitorForm
   */
  getAccountData = async () => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    //console.log('accountID : ' , accountId)
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            hasActiveSMSProvider: data.has_active_sms_provider,
            currentPricingPlan: data.plan,
            max_usage: {
              max_account_users: data.max_account_users,
              max_api_checks: data.max_api_checks,
              max_sms: data.max_sms,
              max_status_pages: data.max_status_pages,
              max_statuspage_subscribers: data.max_statuspage_subscribers,
            },
          });
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              hasActiveAccount: false,
            });
            // remote current account id from storage ( connectedUserData element )
            this.context.cleanUserAccount(accountId);
          }
        });
    } else {
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      statusPageSelectedMonitors,
      form_hostedSubdomain_value,
      form_customDomain_value,
      form_title_value,
    } = this.state;

    // if (formIsSubmited === true ) return ;

    this.setState({
      isLoading: true,
      formIsSubmited: true,
    });

    if (
      typeof statusPageSelectedMonitors === 'undefined' ||
      statusPageSelectedMonitors === null ||
      statusPageSelectedMonitors.length <= 0 ||
      (form_hostedSubdomain_value === '' && form_customDomain_value === '') ||
      form_title_value === ''
    ) {
      let tabs_form_tabsErrors = this.state.tabs_form_tabsErrors;
      if (
        typeof statusPageSelectedMonitors === 'undefined' ||
        statusPageSelectedMonitors === null ||
        statusPageSelectedMonitors.length <= 0
      )
        tabs_form_tabsErrors.monitors = 1;
      if (form_hostedSubdomain_value === '' && form_customDomain_value === '')
        tabs_form_tabsErrors.settings.subdomain = 1;
      if (form_title_value === '') tabs_form_tabsErrors.settings.title = 1;

      this.setState({
        form_response: 'Please fill in all mandatory fields',
        form_response_status: 'danger',
        tabs_form_tabsErrors,
      });
      return;
    }

    // Save a new status page
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/statuspages/${this.state.statuspageId}`,
          {
            is_active: true,
            name: this.state.form_title_value,
            hosted_subdomain: this.state.form_hostedSubdomain_value,
            custom_domain: this.state.form_customDomain_value,
            logo: this.state.form_logoUrl_value,
            color: this.state.form_statusPage_chartColor,
            background_color: this.state.form_statusPage_backgroundColor,
            favicon: null,
            homepage_link: this.state.form_homepageLink_value,
            monitors: this.state.statusPageSelectedMonitors,
            allowed_channel_subs: this.state.activeChannelsForSubscribers,
            notification_type_subs: this.state.subscribersNotificationTypes,
            allow_subscriptions: this.state.allow_subscriptions,
            metric_types: this.state.chartTypesConfig,
            display_open_incidents: this.state.display_open_incidents,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          // stop loading effect

          // ? Google Analytics Event : Edit Status page
          GA.GAevent('Status Pages', 'Edit Status Page');

          this.setState({
            form_response: 'Your new status page has been successfully updated',
            form_response_status: 'success',
            form_customDomain_error: '',
            form_hostedSubdomain_error: '',
            form_title_error: '',
          });
        })
        .catch((error) => {
          let stateItems = {};
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const status = error.response.status;
            const data = error.response.data;

            // default message
            stateItems.form_response = data.message;

            if (status === 422) {
              stateItems.form_response = data.message;
              if (data.validation.length > 0) {
                data.validation.map((validation_item) => {
                  switch (validation_item.key) {
                    case 'hosted_subdomain':
                      stateItems.form_hostedSubdomain_error =
                        validation_item.error;
                      break;
                    case 'name':
                      stateItems.form_title_error = validation_item.error;
                      break;
                    case 'custom_domain':
                      stateItems.form_customDomain_error =
                        validation_item.error;
                      break;
                  }
                });
              }
            } else if (
              status === 403 &&
              typeof data.data !== 'undefined' &&
              data.data !== null
            ) {
              stateItems.form_customDomain_error =
                data.data.isAvailableCustomDomain === false
                  ? 'This custom domain is not available, please try a different one'
                  : '';
              stateItems.form_hostedSubdomain_error =
                data.data.isAvailableHostedsubdomain === false
                  ? 'This subdomain is not available, please try a different one'
                  : '';
            }
            stateItems.form_response_status = 'danger';
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log('Error', error.message);
          }
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState(stateItems);
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  getAvailableMonitors = async () => {
    this.setState({
      isLoading: true,
    });
    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (obj && obj.token) {
      const token = obj.token;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors`, {
          params: {
            page: this.state.offset,
            per_page: this.state.perPage,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => {
          const status = res.status;
          const data = res.data;

          this.setState({
            pageCount:
              status === 200
                ? Math.ceil(res.data.total / this.state.perPage)
                : 0,
            monitorRows: status === 200 ? data.data : [],
            monitorRowsErrors:
              status === 200
                ? data.total <= 0
                  ? "You don't have any status pages yet!"
                  : null
                : 'Something went wrong, please try again later.',
            isLoading: false,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            //console.log('Error: ', err.message); // => prints: Api is being canceled
          } else {
            if (
              typeof err.message === 'undefined' ||
              err.message === null ||
              err.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.setState({
                pageCount: 0,
                monitorRowsErrors:
                  err.response.status === 401
                    ? 'Authentication failed, please log in again'
                    : 'Something went wrong, please try again later.',
                monitorRows: [],
                isLoading: false,
              });
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  getStatusPageData = async () => {
    this.setState({
      loadingStatusPageData: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/statuspages/${this.state.statuspageId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, //'JWT token...'
              'X-Odown-Account': accountId,
            },
            cancelToken: this.signal.token,
          }
        )
        // .then(res => {
        // 	//console.log(res);
        // 	const status = res.status;
        // 	const data = res.data;
        // 	if (status === 200) {

        // 		if (typeof data !== 'undefined' && data !== null) {
        .then((res) => res.data)
        .then((data) => {
          const breadcrumbs_items = this.state.breadcrumbs_items;
          breadcrumbs_items[2].name = data.name;
          breadcrumbs_items[2].link = '/statuspages/' + data.id;
          breadcrumbs_items[2].isLink = true;

          data.monitors.map((item, i) => {
            if (typeof item.displayCharts !== 'undefined') {
              if (typeof item.displayCharts.responseTime === 'undefined')
                data.monitors[i].displayCharts.responseTime = false;
              if (typeof item.displayCharts.uptime === 'undefined')
                data.monitors[i].displayCharts.uptime = false;
              if (typeof item.displayCharts.indices === 'undefined')
                data.monitors[i].displayCharts.indices = false;
            } else {
              data.monitors[i].displayCharts = {
                responseTime: false,
                uptime: false,
                indices: false,
              };
            }
          });

          this.setState({
            form_logoUrl_value: data.logo,
            form_title_value: data.name,
            form_hostedSubdomain_value: data.hosted_subdomain,
            form_customDomain_value: data.custom_domain,
            form_homepageLink_value: data.homepage_link,
            form_statusPage_chartColor: data.color,
            form_statusPage_backgroundColor: data.background_color,
            statusPageSelectedMonitors: data.monitors.map((i) => {
              return {
                id: i.id,
                isActive: i.is_active,
                isDown: i.is_down,
                name: i.name,
                url: i.url,
                displayCharts: {
                  responseTime: i.displayCharts.responseTime,
                  uptime: i.displayCharts.uptime,
                  indices: i.displayCharts.indices,
                },
              };
            }),
            form_hostedSubdomain_error:
              data.hosted_subdomain !== ''
                ? ''
                : this.state.form_hostedSubdomain_error,

            loadingStatusPageData: false,
            breadcrumbs_items: breadcrumbs_items,
            activeChannelsForSubscribers: data.allowed_channel_subs,
            subscribersNotificationTypes: data.notification_type_subs,
            allow_subscriptions: data.allow_subscriptions,
            display_open_incidents: data.display_open_incidents,
            chartTypesConfig: this.state.chartTypesConfig.map((item) => {
              return {
                id: item.id,
                range_name: item.range_name,
                is_active: data.displayed_range[`${item.id}`],
                uptime_chart_type: data[`chart_type__${item.id}`].uptime,
                responsetime_chart_type:
                  data[`chart_type__${item.id}`].responsetime,
              };
            }),
          });
          // }
          // } else {
          // 	this.setState({
          // 		loadingStatusPageData: false,
          // 		statusPageNotFound: true,
          // 		breadcrumbs_items: this.state.breadcrumbs_items[1] = (
          // 			{
          // 				name: "Status Page Not found",
          // 				link: "#",
          // 				isLink: false
          // 			}),
          // 	})
          // }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              loadingStatusPageData: false,
              statusPageNotFound: true,
              breadcrumbs_items: (this.state.breadcrumbs_items[2] = {
                name: 'Not found',
                link: '#',
                isLink: false,
              }),
            });
          }
        });
    } else {
      this.setState({
        loadingStatusPageData: false,
        statusPageNotFound: true,
        breadcrumbs_items: (this.state.breadcrumbs_items[2] = {
          name: 'Not found',
          link: '#',
          isLink: false,
        }),
      });
    }
  };

  /**
   *
   * @param {*} event
   */
  attachMonitor(event) {
    const monitorValue = JSON.parse(event.currentTarget.dataset.value);
    let tabs_form_tabsErrors = this.state.tabs_form_tabsErrors;
    tabs_form_tabsErrors.monitors = 0;

    const statusPageSelectedMonitors = [
      ...this.state.statusPageSelectedMonitors,
    ];
    // ! Affect the selected monitor to the affected monitors
    if (event.currentTarget.dataset.checked === 'false') {
      tabs_form_tabsErrors.monitors = 0;
      this.setState(
        {
          statusPageSelectedMonitors: [
            ...this.state.statusPageSelectedMonitors,
            monitorValue,
          ],
          tabs_form_tabsErrors,
        },
        () => {}
      );
      // ! Remove the selected monitor to the affected monitors
    } else {
      const found = statusPageSelectedMonitors.find(
        (element) => element.id === monitorValue.id
      );
      const index = statusPageSelectedMonitors.indexOf(found);
      statusPageSelectedMonitors.splice(index, 1);
      if (statusPageSelectedMonitors.length <= 0)
        tabs_form_tabsErrors.monitors = 1;
      this.setState(
        {
          statusPageSelectedMonitors,
          tabs_form_tabsErrors,
        },
        () => {}
      );
    }
  }

  confirmAttachedMonitors(event) {
    //console.log(event)

    this.setState({
      showModal_selectMonitors: !this.state.showModal_selectMonitors,
      modalResponse: '',
    });
  }

  handleChange__removeAttachedMonitor(event) {
    const selectedMonitorId = event.currentTarget.dataset.id;
    const statusPageSelectedMonitors = this.state.statusPageSelectedMonitors;
    let tabs_form_tabsErrors = this.state.tabs_form_tabsErrors;

    const found = statusPageSelectedMonitors.find(
      (element) => element.id === selectedMonitorId
    );
    const index = statusPageSelectedMonitors.indexOf(found);
    statusPageSelectedMonitors.splice(index, 1);

    if (statusPageSelectedMonitors.length <= 0)
      tabs_form_tabsErrors.monitors = 1;

    // update state :
    this.setState({
      statusPageSelectedMonitors,
      tabs_form_tabsErrors,
    });
  }

  handleChange__editChartColor(event) {
    this.setState({ form_statusPage_chartColor: event.hex });
  }
  handleChange__editBackgroundColor(event) {
    this.setState({ form_statusPage_backgroundColor: event.hex });
  }

  openColorPicker__chartColor(event) {
    //console.log('call > openColorPicker__chartColor..')
    this.setState({ colorPicker__chartColor_isOpen: true });
  }
  openColorPicker__backgroundColor(event) {
    //console.log('call > openColorPicker__backgroundColor..')
    this.setState({ colorPicker__backgroundColor_isOpen: true });
  }
  handleClose_colorPicker(event) {
    //console.log(event);

    this.setState(
      {
        colorPicker__chartColor_isOpen: false,
        colorPicker__backgroundColor_isOpen: false,
      },
      () => {
        //console.log('colorPicker__chartColor_isOpen : ' , this.state.colorPicker__chartColor_isOpen)
        //console.log('colorPicker__backgroundColor_isOpen : ' , this.state.colorPicker__backgroundColor_isOpen)
      }
    );
  }

  handleChange__editLogoUrl(event) {
    this.setState({
      form_logoUrl_value: event.target.value,
    });
  }
  handleChange__editTitle(event) {
    const value = event.target.value;
    let tabs_form_tabsErrors = this.state.tabs_form_tabsErrors;

    let error = '';
    tabs_form_tabsErrors.settings.title = 0;
    if (typeof value === 'undefined' || value === null || value === '') {
      error = 'Title is required.';
      tabs_form_tabsErrors.settings.title = 1;
    } else if (value.length < 5) {
      error = "'Title' must be a valid format ( at least 5 characters )";
      tabs_form_tabsErrors.settings.title = 1;
    }

    this.setState({
      form_title_value: value,
      form_title_error: error,
      tabs_form_tabsErrors,
    });
  }
  handleChange__editHostedSubdomain(event) {
    const value = event.target.value;
    let tabs_form_tabsErrors = this.state.tabs_form_tabsErrors;

    let error = '';
    tabs_form_tabsErrors.settings.subdomain = 0;
    if (typeof value === 'undefined' || value === null) {
      error = 'Hosted Subdomain is required.';
      tabs_form_tabsErrors.settings.subdomain = 1;
    } else if (
      value.match(this.state.form_hostedSubdomain_regex_validator) === null
    ) {
      error = 'Please check the format of the hosted domain name.';
      tabs_form_tabsErrors.settings.subdomain = 1;
    }
    this.setState({
      form_hostedSubdomain_value: value,
      form_hostedSubdomain_error: error,
      tabs_form_tabsErrors,
    });
  }
  handleChange__editCustomDomain(event) {
    this.setState({
      form_customDomain_value: event.target.value.replace(
        /(http:\/\/|https:\/\/)/i,
        ''
      ),
    });
  }
  handleChange__editHomepagelink(event) {
    this.setState({
      form_homepageLink_value: event.target.value,
    });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getAvailableMonitors(offset);
      }
    );
  };

  sortDataByDate = (items) =>
    [...items].sort(
      (itemA, itemB) =>
        new Date(itemA.selectedDate) - new Date(itemB.selectedDate)
    );

  openModal_selectMonitors(event) {
    this.setState(
      {
        showModal_selectMonitors: true,
      },
      () => {
        // Get monitors list
        this.getAvailableMonitors();
      }
    );
  }

  closeModal_selectMonitors(event) {
    this.setState({
      showModal_selectMonitors: !this.state.showModal_selectMonitors,
      modalResponse: '',
    });
  }

  handleChange__displayResponseTimeMonitor(event) {
    let statusPageSelectedMonitors = [...this.state.statusPageSelectedMonitors];
    let isChecked = event.target.checked;
    const monitorId = event.target.value;

    const monitorBlockIndex = statusPageSelectedMonitors.findIndex(
      (x) => x.id === monitorId
    );
    if (
      typeof monitorBlockIndex !== 'undefined' &&
      monitorBlockIndex !== null &&
      monitorBlockIndex >= 0
    ) {
      // edit the value of display of responsetime chart for the selected monitor id
      if (
        typeof statusPageSelectedMonitors[monitorBlockIndex][
          'displayCharts'
        ] === 'undefined'
      )
        statusPageSelectedMonitors[monitorBlockIndex]['displayCharts'] = {};

      statusPageSelectedMonitors[monitorBlockIndex][
        'displayCharts'
      ].responseTime = isChecked;
    }
    // update state
    this.setState({
      statusPageSelectedMonitors,
    });
  }

  handleChange__displayUptimeMonitor(event) {
    let statusPageSelectedMonitors = [...this.state.statusPageSelectedMonitors];
    let isChecked = event.target.checked;
    const monitorId = event.target.value;

    const monitorBlockIndex = statusPageSelectedMonitors.findIndex(
      (x) => x.id === monitorId
    );
    if (
      typeof monitorBlockIndex !== 'undefined' &&
      monitorBlockIndex !== null &&
      monitorBlockIndex >= 0
    ) {
      // edit the value of display of uptime chart for the selected monitor id
      if (
        typeof statusPageSelectedMonitors[monitorBlockIndex][
          'displayCharts'
        ] === 'undefined'
      )
        statusPageSelectedMonitors[monitorBlockIndex]['displayCharts'] = {};

      statusPageSelectedMonitors[monitorBlockIndex]['displayCharts'].uptime =
        isChecked;
    }
    // update state
    this.setState({
      statusPageSelectedMonitors,
    });
  }
  handleChange__displayUptimeIndicesMonitor(event) {
    let statusPageSelectedMonitors = [...this.state.statusPageSelectedMonitors];
    let isChecked = event.target.checked;
    const monitorId = event.target.value;

    const monitorBlockIndex = statusPageSelectedMonitors.findIndex(
      (x) => x.id === monitorId
    );
    if (
      typeof monitorBlockIndex !== 'undefined' &&
      monitorBlockIndex !== null &&
      monitorBlockIndex >= 0
    ) {
      // edit the value of display of uptime chart for the selected monitor id
      if (
        typeof statusPageSelectedMonitors[monitorBlockIndex][
          'displayCharts'
        ] === 'undefined'
      )
        statusPageSelectedMonitors[monitorBlockIndex]['displayCharts'] = {};

      statusPageSelectedMonitors[monitorBlockIndex]['displayCharts'].indices =
        isChecked;
    }
    // update state
    this.setState({
      statusPageSelectedMonitors,
    });
  }

  /**
   *
   * @param {*} event
   */
  handleChange__form_selectTab(event) {
    const tabName = event.currentTarget.dataset.name;
    this.setState({
      tabs_form_selectedTab: tabName,
    });
  }

  /**
   *
   * @param {*} checked
   * @param {*} event
   * @param {*} id
   */
  handleChange_selectChannels(checked, event, id) {
    let activeChannels = this.state.activeChannelsForSubscribers;

    if (checked === false) {
      // const found = activeChannels.find(x => x.id === id);
      const index = activeChannels.indexOf(id);
      activeChannels.splice(index, 1);
    } else {
      activeChannels.push(id);
    }

    this.setState(
      {
        activeChannels: activeChannels,
      },
      () => {
        //console.log(this.state.channelList)
      }
    );
  }

  /**
   *
   * @param {*} event
   * @param {*} type ( incident_updates / uptime_alerts )
   */
  handleChange_formNotificationTypes(event, type) {
    let notificationTypes = this.state.subscribersNotificationTypes;
    notificationTypes[type] = event.target.checked;
    this.setState({
      subscribersNotificationTypes: notificationTypes,
    });
  }

  handleChange_formAllowSubscribers(event) {
    this.setState({
      allow_subscriptions: event.target.checked,
    });
  }

  handleChange__displayRange(event, id) {
    let config = this.state.chartTypesConfig;
    const selectedItem = config.findIndex((x) => x.id === id);
    config[selectedItem].is_active = event.target.checked;

    this.setState({
      chartTypesConfig: config,
    });
  }

  /**
   * event :
   * type : uptime or responsetime
   * id : 24hours , 1week ,...
   */
  handleChange__selectRangeChartType(event, type, id) {
    const value = event.target.value;
    let config = this.state.chartTypesConfig;
    const selectedItem = config.findIndex((x) => x.id === id);
    config[selectedItem][
      type === 'uptime' ? 'uptime_chart_type' : 'responsetime_chart_type'
    ] = value;

    this.setState({
      chartTypesConfig: config,
    });
  }

  handleChange_formDisplayOpenIncidents(event, type) {
    let openIncidents = this.state.display_open_incidents;
    openIncidents[type] = event.target.checked;

    this.setState({
      display_open_incidents: openIncidents,
    });
  }

  /**
   * Save the subscribers' total
   * @param {*} data
   */
  subscriberManagerCallback(data) {
    if (
      typeof Object.keys(data) !== 'undefined' &&
      Object.keys(data) !== null &&
      Object.keys(data).length > 0
    ) {
      let stateItems = {};
      if (
        typeof data.total_subscribers !== 'undefined' &&
        data.total_subscribers !== null &&
        data.total_subscribers !== ''
      )
        stateItems.totalAvailableSubscribers = data.total_subscribers;
      // ? add the new subscriber added to the total numbers of subscribers
      if (
        typeof data.new_subscriber !== 'undefined' &&
        data.new_subscriber !== null &&
        data.new_subscriber !== ''
      )
        stateItems.totalAvailableSubscribers =
          this.state.totalAvailableSubscribers + 1;

      // ? Decrease the total numbers of subscribers
      if (
        typeof data.remove_subscriber !== 'undefined' &&
        data.remove_subscriber !== null &&
        data.remove_subscriber !== ''
      )
        stateItems.totalAvailableSubscribers =
          this.state.totalAvailableSubscribers - 1;

      if (
        typeof Object.keys(stateItems) !== 'undefined' &&
        Object.keys(stateItems) !== null &&
        Object.keys(stateItems).length > 0
      ) {
        this.setState(stateItems);
      }
    }
  }

  componentDidMount() {
    // Get current account Data
    this.getAccountData();

    // Get status page info
    this.getStatusPageData();
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    // Check if the user has an active account, else redirect the user to /intro page to create a new account
    //console.log('this.state.hasActiveAccount : ', this.state.hasActiveAccount)
    if (this.state.hasActiveAccount === false) {
      // remote current account id from strage
      removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      return <Redirect to="/intro" />;
    }

    let tabs_form_tabsErrors = this.state.tabs_form_tabsErrors;
    let tabsErrors = {
      monitors: tabs_form_tabsErrors.monitors,
      settings: 0,
      subscribers: tabs_form_tabsErrors.subscribers,
      notifications: tabs_form_tabsErrors.notifications,
    };
    Object.keys(tabs_form_tabsErrors.settings).forEach((item) => {
      tabsErrors.settings += tabs_form_tabsErrors.settings[item];
    });

    return (
      <React.Fragment>
        <Helmet>
          <title>Edit Status Page | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__monitor--form layout__blockInner">
              <div className="layout__header ">
                <div className="l_header__title">
                  <h2>Edit status page</h2>
                </div>
                {this.state.statusPageNotFound === false && (
                  <>
                    <div className="l_header__btnaction">
                      <div
                        className="btn btn-primary"
                        onClick={(event) => {
                          this.handleSubmit(event);
                        }}
                      >
                        Save settings
                        <span className="avatar-wrapper">
                          <span className="avatar-content"></span>
                        </span>
                      </div>
                    </div>
                    <div className="l_header__description">
                      <div className="field-group submit-fields-group fluid">
                        <div
                          className={`alert alert-${
                            this.state.form_response_status
                          } ${
                            this.state.form_response === '' ? 'hide' : ''
                          } margin-left-none margin-right-none`}
                        >
                          <div className="">{this.state.form_response}</div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="layout__body">
                {this.state.loadingStatusPageData === true ? (
                  <div className="loading-wrapper">
                    <LoadingSpinner />
                  </div>
                ) : this.state.statusPageNotFound === true ? (
                  <div className="layout__notFound white-card">
                    <h2>Not Found</h2>
                    <p>
                      Unfortunately, this status page is not available on the
                      current account.
                    </p>
                  </div>
                ) : (
                  <form
                    method="POST"
                    action=""
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="tab-settings__nav">
                      <ul>
                        <li
                          className={`no-link ${
                            this.state.tabs_form_selectedTab === 'monitors'
                              ? 'active'
                              : ''
                          }`}
                          data-name="monitors"
                          onClick={this.handleChange__form_selectTab}
                        >
                          <span>Monitors included</span>
                          <span
                            className={`errors-numbers ${
                              tabsErrors.monitors === 0 ? 'hide' : ''
                            }`}
                          >
                            {tabsErrors.monitors}
                          </span>
                        </li>
                        <li
                          className={`no-link ${
                            this.state.tabs_form_selectedTab === 'settings'
                              ? 'active'
                              : ''
                          }`}
                          data-name="settings"
                          onClick={this.handleChange__form_selectTab}
                        >
                          <span>Settings</span>
                          <span
                            className={`errors-numbers ${
                              tabsErrors.settings === 0 ? 'hide' : ''
                            }`}
                          >
                            {tabsErrors.settings}
                          </span>
                        </li>
                        <li
                          className={`no-link ${
                            this.state.tabs_form_selectedTab === 'subscribers'
                              ? 'active'
                              : ''
                          }`}
                          data-name="subscribers"
                          onClick={this.handleChange__form_selectTab}
                        >
                          <span>Subscribers</span>
                          <span className={`errors-numbers success-color`}>
                            {this.state.totalAvailableSubscribers} /{' '}
                            {this.state.max_usage !== null ? (
                              // ? ( this.state.max_usage.max_statuspage_subscribers )
                              this.state.max_usage.max_statuspage_subscribers >=
                              0 ? (
                                this.state.max_usage.max_statuspage_subscribers
                              ) : (
                                <span
                                  className="infinity-symbol"
                                  title="Unlimited"
                                >
                                  ∞
                                </span>
                              )
                            ) : (
                              0
                            )}
                          </span>
                        </li>
                        <li
                          className={`no-link ${
                            this.state.tabs_form_selectedTab === 'notifications'
                              ? 'active'
                              : ''
                          }`}
                          data-name="notifications"
                          onClick={this.handleChange__form_selectTab}
                        >
                          <span>Notifications</span>
                          <span
                            className={`errors-numbers ${
                              tabsErrors.notifications === 0 ? 'hide' : ''
                            }`}
                          >
                            {tabsErrors.notifications}
                          </span>
                          <span
                            className={`small-note error ${
                              this.state.allow_subscriptions === true
                                ? 'hide'
                                : ''
                            }`}
                          >
                            {this.state.allow_subscriptions === false && (
                              <span className="small-note-inner">Locked</span>
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-settings__content">
                      <div
                        className={`ts_content-item white-card margin-bottom-20 ${
                          this.state.tabs_form_selectedTab === 'monitors'
                            ? 'active'
                            : ''
                        }`}
                      >
                        <h2 className="title">Monitors included</h2>
                        <p className="sub-title">
                          Status pages are great for showing as a public status
                          page for your service ( you can include one or more
                          monitors in a single status page )
                        </p>
                        <div className="monitors-importer">
                          <button
                            type="button"
                            className="btn btn-orange"
                            onClick={this.openModal_selectMonitors}
                          >
                            Choose a monitors
                          </button>
                          <div className="confirmAttachedMonitors">
                            {this.state.loadingStatusPageData === true ? (
                              <div className="loading-wrapper">
                                <LoadingSpinner />
                              </div>
                            ) : this.state.statusPageSelectedMonitors.length >
                              0 ? (
                              this.state.statusPageSelectedMonitors.map(
                                (item, index) => {
                                  return (
                                    <div className="monitor-item" key={index}>
                                      <StatusIndicator
                                        status={
                                          item.isActive === false
                                            ? ''
                                            : item.isDown === true
                                            ? 'danger'
                                            : 'success'
                                        }
                                        title={
                                          item.isActive === false
                                            ? 'Inactive Monitor'
                                            : item.isDown === true
                                            ? 'Failure Monitor'
                                            : 'Active Monitor'
                                        }
                                      />
                                      <div className="monitor-item__name">
                                        {item.name}
                                      </div>
                                      <div className="monitor-item__url hide">
                                        {item.url}
                                      </div>
                                      <div className="monitor-item__display_options">
                                        <div className="field-group checkbox-group">
                                          <label>
                                            <input
                                              type="checkbox"
                                              className="textField"
                                              id={`monitor--${item.id}--responsetime`}
                                              value={item.id}
                                              checked={
                                                typeof item.displayCharts
                                                  .responseTime !==
                                                  'undefined' &&
                                                item.displayCharts
                                                  .responseTime === true
                                                  ? true
                                                  : false
                                              }
                                              onChange={
                                                this
                                                  .handleChange__displayResponseTimeMonitor
                                              }
                                            />{' '}
                                            ResponseTime Chart
                                            <Tooltip
                                              placement="top"
                                              trigger={['hover']}
                                              overlay={
                                                <span>
                                                  A simple, clear and beautiful
                                                  graph that explains the
                                                  average response time over the
                                                  entire selected period
                                                </span>
                                              }
                                            >
                                              <span className="info-badge"></span>
                                            </Tooltip>
                                          </label>
                                        </div>
                                        <div className="field-group checkbox-group">
                                          <label>
                                            <input
                                              type="checkbox"
                                              className="textField"
                                              id={`monitor--${item.id}--uptime`}
                                              value={item.id}
                                              checked={
                                                typeof item.displayCharts
                                                  .uptime !== 'undefined' &&
                                                item.displayCharts.uptime ===
                                                  true
                                                  ? true
                                                  : false
                                              }
                                              onChange={
                                                this
                                                  .handleChange__displayUptimeMonitor
                                              }
                                            />{' '}
                                            Uptime Chart
                                            <Tooltip
                                              placement="top"
                                              trigger={['hover']}
                                              overlay={
                                                <span>
                                                  Simple, clear and beautiful
                                                  graph that explains the
                                                  availability rate during the
                                                  entire selected period, also
                                                  available as bars in some
                                                  selected periods as a 3 month
                                                  interval
                                                </span>
                                              }
                                            >
                                              <span className="info-badge"></span>
                                            </Tooltip>
                                          </label>
                                        </div>
                                        <div className="field-group checkbox-group">
                                          <label>
                                            <input
                                              type="checkbox"
                                              className="textField"
                                              id={`monitor--${item.id}--indices`}
                                              value={item.id}
                                              checked={
                                                typeof item.displayCharts
                                                  .indices !== 'undefined' &&
                                                item.displayCharts.indices ===
                                                  true
                                                  ? true
                                                  : false
                                              }
                                              onChange={
                                                this
                                                  .handleChange__displayUptimeIndicesMonitor
                                              }
                                            />{' '}
                                            Indices
                                            <Tooltip
                                              placement="top"
                                              trigger={['hover']}
                                              overlay={
                                                <span>
                                                  Average response time and
                                                  uptime presented as a number
                                                  over each selected period
                                                </span>
                                              }
                                            >
                                              <span className="info-badge"></span>
                                            </Tooltip>
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        className={`monitor-item__btn_actions`}
                                      >
                                        <div
                                          className="btn_action-item remove"
                                          data-id={item.id}
                                          onClick={
                                            this
                                              .handleChange__removeAttachedMonitor
                                          }
                                        >
                                          <svg
                                            version="1.1"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            className="octicon octicon-trash"
                                            aria-hidden="true"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                                            ></path>
                                            <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                                            <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div
                                className={`no-items-selected ${
                                  this.state.formIsSubmited === true &&
                                  this.state.statusPageSelectedMonitors
                                    .length <= 0
                                    ? 'error'
                                    : ''
                                }`}
                              >
                                <span className="no-items-selected_inner">
                                  No monitor selected, please choose at least
                                  one of your monitors
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`ts_content-item settings-tab white-card margin-bottom-20 ${
                          this.state.tabs_form_selectedTab === 'settings'
                            ? 'active'
                            : ''
                        }`}
                      >
                        <h2 className="title fluid">General Settings</h2>
                        <p className="sub-title fluid">
                          Status pages are great for showing as a public status
                          page for your service ( you can include one or more
                          monitors in a single status page )
                        </p>
                        <div className="field-group">
                          <label className="fluid">Logo</label>
                          <input
                            type="text"
                            className={`textField fluid`}
                            placeholder={this.state.form_logoUrl_placeholder}
                            value={this.state.form_logoUrl_value}
                            onChange={this.handleChange__editLogoUrl}
                          />
                        </div>
                        <div className="field-group">
                          <label className="fluid">Title *</label>
                          <input
                            type="text"
                            className={`textField fluid`}
                            placeholder={this.state.form_title_placeholder}
                            value={this.state.form_title_value}
                            onChange={this.handleChange__editTitle}
                          />
                          <span
                            className={`fieldText_alert--error ${
                              this.state.form_title_error !== '' ? '' : 'hide'
                            }`}
                          >
                            {this.state.form_title_error}
                          </span>
                          <div className="fieldText_note">
                            The title that will be shown throughout your status
                            page (your company name)
                          </div>
                        </div>
                        <div className="field-group">
                          <label className="fluid">Hosted Subdomain *</label>
                          <div
                            className={`inputWrapper fluid ${
                              this.state.form_hostedSubdomain_error !== ''
                                ? 'error'
                                : ''
                            }`}
                          >
                            <div className="input-prefix">https://</div>
                            <input
                              type="text"
                              className={`textField fluid ${
                                this.state.form_hostedSubdomain_error !== ''
                                  ? 'error'
                                  : ''
                              }`}
                              placeholder={
                                this.state.form_hostedSubdomain_placeholder
                              }
                              value={this.state.form_hostedSubdomain_value}
                              onChange={this.handleChange__editHostedSubdomain}
                            />
                            <div className="input-suffix">.odown.io</div>
                          </div>
                          <span
                            className={`fieldText_alert--error ${
                              this.state.form_hostedSubdomain_error !== ''
                                ? ''
                                : 'hide'
                            }`}
                          >
                            {this.state.form_hostedSubdomain_error}
                          </span>
                          <div className="fieldText_note">
                            Access your status page via a custom subdomain on
                            odown.io. The subdomain needs to be unique across
                            all Uptime users.
                          </div>
                        </div>
                        <div className="field-group">
                          <label className="fluid">Custom domain</label>
                          <div
                            className={`inputWrapper fluid ${
                              this.state.form_customDomain_error !== ''
                                ? 'error'
                                : ''
                            }`}
                          >
                            <div className="input-prefix">https://</div>
                            <input
                              type="text"
                              className={`textField fluid ${
                                this.state.form_customDomain_error !== ''
                                  ? 'error'
                                  : ''
                              }`}
                              placeholder={
                                this.state.form_customDomain_placeholder
                              }
                              value={this.state.form_customDomain_value}
                              onChange={this.handleChange__editCustomDomain}
                            />
                          </div>
                          <span
                            className={`fieldText_alert--error ${
                              this.state.form_customDomain_error !== ''
                                ? ''
                                : 'hide'
                            }`}
                          >
                            {this.state.form_customDomain_error}
                          </span>
                          <div className="fieldText_note">
                            Create a CNAME record pointing to status.odown.io
                            with the "status" value. Initial request takes a few
                            seconds longer due to the SSL registration processed
                            on the fly!
                          </div>
                        </div>
                        <div className="field-group">
                          <label className="fluid">Homepage link</label>
                          <input
                            type="text"
                            className={`textField fluid`}
                            placeholder={
                              this.state.form_homepageLink_placeholder
                            }
                            value={this.state.form_homepageLink_value}
                            onChange={this.handleChange__editHomepagelink}
                          />
                          <div className="fieldText_note">
                            The link to your homepage clickable from the header
                          </div>
                        </div>
                        <div className="field-group fluid">
                          <label className="fluid" htmlFor="">
                            Display open incidents in the status page's header{' '}
                          </label>
                          <div className="field-group checkbox-group fluid">
                            <label
                              className="fluid margin-bottom-none"
                              htmlFor="show_open_incidents_realtime_checkbox"
                            >
                              <input
                                id="show_open_incidents_realtime_checkbox"
                                type="checkbox"
                                className={`textField`}
                                value=""
                                checked={
                                  this.state.display_open_incidents.realtime
                                }
                                onChange={(event) =>
                                  this.handleChange_formDisplayOpenIncidents(
                                    event,
                                    'realtime'
                                  )
                                }
                              />
                              Open default incidents
                            </label>
                            <label
                              className="fluid margin-bottom-none"
                              htmlFor="show_open_incidents_scheduled_checkbox"
                            >
                              <input
                                id="show_open_incidents_scheduled_checkbox"
                                type="checkbox"
                                className={`textField`}
                                value=""
                                checked={
                                  this.state.display_open_incidents.scheduled
                                }
                                onChange={(event) =>
                                  this.handleChange_formDisplayOpenIncidents(
                                    event,
                                    'scheduled'
                                  )
                                }
                              />
                              Open scheduled maintenances
                            </label>
                          </div>
                          <div className="fieldText_note">
                            Display incidents and maintenances that are not yet
                            closed on the status home page
                          </div>
                        </div>
                        {/* DESIGN  */}
                        <h2 className="title fluid margin-top-20">Design</h2>
                        <div className="field-group">
                          <label className="fluid">Chart color</label>
                          <div
                            className="textField colorPickerInputWrapper"
                            onClick={this.openColorPicker__chartColor}
                          >
                            <span
                              className="defaultColor"
                              style={{
                                backgroundColor:
                                  this.state.form_statusPage_chartColor,
                              }}
                            ></span>
                            <span className="defaultColorString">
                              {this.state.form_statusPage_chartColor}
                            </span>
                          </div>
                          {this.state.colorPicker__chartColor_isOpen ===
                            true && (
                            <div className="colorPickerWrapper">
                              <div
                                className="colorPickerBackground"
                                onClick={this.handleClose_colorPicker}
                              />
                              <SketchPicker
                                color={this.state.form_statusPage_chartColor}
                                onChange={this.handleChange__editChartColor}
                              />
                            </div>
                          )}
                          <div className="fieldText_note">
                            Customise your chart line color
                          </div>
                        </div>
                        <div className="field-group">
                          <label className="fluid">
                            Cover background color
                          </label>
                          <div
                            className="textField colorPickerInputWrapper"
                            onClick={this.openColorPicker__backgroundColor}
                          >
                            <span
                              className="defaultColor"
                              style={{
                                backgroundColor:
                                  this.state.form_statusPage_backgroundColor,
                              }}
                            ></span>
                            <span className="defaultColorString">
                              {this.state.form_statusPage_backgroundColor}
                            </span>
                          </div>
                          {this.state.colorPicker__backgroundColor_isOpen ===
                            true && (
                            <div className="colorPickerWrapper">
                              <div
                                className="colorPickerBackground"
                                onClick={this.handleClose_colorPicker}
                              />
                              <SketchPicker
                                color={
                                  this.state.form_statusPage_backgroundColor
                                }
                                onChange={
                                  this.handleChange__editBackgroundColor
                                }
                              />
                            </div>
                          )}
                          <div className="fieldText_note">
                            Customise your cover background color. We recommend
                            setting a dark color and a white version of your
                            logo. If you use a custom color, the buttons and
                            links contained in the header will all be set to
                            white.
                          </div>
                        </div>
                        <div className="field-group charts-range--parameters fluid">
                          <label htmlFor="" className="fluid">
                            Active ranges and Chart's type
                          </label>
                          <div className="charts-range--items fluid">
                            <div className="charts-range--item chart-features">
                              <label></label>
                              <div className="config__chart-type c-feature">
                                Uptime Metric
                              </div>
                            </div>
                            {this.state.chartTypesConfig.map(
                              (element, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={`charts-range--item ${
                                      element.is_active === true
                                        ? 'is-selected'
                                        : ''
                                    }`}
                                  >
                                    <label
                                      htmlFor={`config__displayedRange__${element.id}`}
                                    >
                                      <input
                                        type="checkbox"
                                        className={`textField`}
                                        id={`config__displayedRange__${element.id}`}
                                        checked={element.is_active}
                                        onChange={(event) =>
                                          this.handleChange__displayRange(
                                            event,
                                            element.id
                                          )
                                        }
                                      />{' '}
                                      {element.range_name}
                                    </label>
                                    <div className="config__chart-type uptime-chart">
                                      <div className="radio-group fluid">
                                        {this.state.chartTypeUnchangeble.findIndex(
                                          (x) =>
                                            x.id === element.id &&
                                            (typeof x.uptime === 'undefined' ||
                                              (typeof x.uptime !==
                                                'undefined' &&
                                                x.uptime == 'bar'))
                                        ) >= 0 && (
                                          <label
                                            className="fluid"
                                            htmlFor={`config__chartType__${element.id}__uptime_bar`}
                                          >
                                            <input
                                              type="radio"
                                              className="radio"
                                              id={`config__chartType__${element.id}__uptime_bar`}
                                              name={`config__chartType__${element.id}__uptime`}
                                              value={`bar`}
                                              checked={
                                                element.uptime_chart_type ===
                                                'bar'
                                              }
                                              onChange={(event) =>
                                                this.handleChange__selectRangeChartType(
                                                  event,
                                                  'uptime',
                                                  element.id
                                                )
                                              }
                                            />{' '}
                                            <BarsChartTypeIcon />
                                            Bars chart
                                          </label>
                                        )}
                                        <label
                                          className="fluid"
                                          htmlFor={`config__chartType__${element.id}__uptime_area`}
                                        >
                                          <input
                                            type="radio"
                                            className="radio"
                                            id={`config__chartType__${element.id}__uptime_area`}
                                            name={`config__chartType__${element.id}__uptime`}
                                            value={`area`}
                                            checked={
                                              element.uptime_chart_type ===
                                              'area'
                                            }
                                            onChange={(event) =>
                                              this.handleChange__selectRangeChartType(
                                                event,
                                                'uptime',
                                                element.id
                                              )
                                            }
                                          />{' '}
                                          <AreaChartTypeIcon />
                                          Area chart
                                        </label>
                                      </div>
                                    </div>
                                    {/* <div className="config__chart-type responsetime-chart">
																			<label htmlFor="">Response Time Metric:</label>
																			<div className="radio-group fluid">
																				<label className="fluid" htmlFor={`config__chartType__${element.id}__responsetime_bar`} >
																					<input
																						type="radio"
																						className="radio"
																						id={`config__chartType__${element.id}__responsetime_bar`}
																						name={`config__chartType__${element.id}__responsetime`}
																						value={`bar`}
																						checked={element.responsetime_chart_type ==='bar'}
																						onChange={(event) => this.handleChange__selectRangeChartType(event , 'responsetime',element.id)}
																					/> Bars chart</label>
																				<label
																					className="fluid"
																					htmlFor={`config__chartType__${element.id}__responsetime_area`} >
																					<input
																						type="radio"
																						className="radio"
																						id={`config__chartType__${element.id}__responsetime_area`}
																						name={`config__chartType__${element.id}__responsetime`}
																						value={`area`}
																						checked={element.responsetime_chart_type ==='area'}
																						onChange={(event) => this.handleChange__selectRangeChartType(event , 'responsetime',element.id)}
																					/> Area chart</label>
																			</div>
																		</div> */}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`ts_content-item white-card margin-bottom-20 ${
                          this.state.tabs_form_selectedTab === 'subscribers'
                            ? 'active'
                            : ''
                        }`}
                      >
                        <StatuspageSubscribersManager
                          statuspageId={this.state.statuspageId}
                          parentCallback={this.subscriberManagerCallback}
                          global_state={this.state}
                        />
                      </div>
                      <div
                        className={`ts_content-item white-card margin-bottom-20 ${
                          this.state.tabs_form_selectedTab === 'notifications'
                            ? 'active'
                            : ''
                        }`}
                      >
                        <StatuspageNotificationsManager
                          statuspageId={this.state.statuspageId}
                          subscribersNotificationTypes={
                            this.state.subscribersNotificationTypes
                          }
                          activeChannelsForSubscribers={
                            this.state.activeChannelsForSubscribers
                          }
                          allow_subscriptions={this.state.allow_subscriptions}
                          hasActiveSMSProvider={this.state.hasActiveSMSProvider}
                          handleChange_formNotificationTypes={
                            this.handleChange_formNotificationTypes
                          }
                          handleChange_selectChannels={
                            this.handleChange_selectChannels
                          }
                          handleChange_formAllowSubscribers={
                            this.handleChange_formAllowSubscribers
                          }
                          global_state={this.state}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
        {
          <ModalRight
            title="Available monitors"
            closeModal={this.closeModal_selectMonitors}
            isDisplay={this.state.showModal_selectMonitors}
          >
            <div className="modal_layout-monitors-list">
              <div className="modal_list modal_layout-monitors-list_wrapper">
                <div className="modal-overflow-height_500">
                  {this.state.isLoading ? (
                    <div className="loading-wrapper">
                      <LoadingSpinner />
                    </div>
                  ) : this.state.monitorRowsErrors !== null ? (
                    <div className="alert alert-danger text-center">
                      {this.state.monitorRowsErrors}
                    </div>
                  ) : this.state.monitorRows.length <= 0 ? (
                    <div className="home-empty-state">
                      <img
                        alt="No Monitors"
                        src={icon_no_monitors}
                        width="100px"
                      />
                      <h3>There is No Monitors</h3>
                      <p className="text-muted">
                        Add your first monitor, this will be your dashboard.
                      </p>
                      <Link className="" to={{ pathname: `/monitors/new` }}>
                        <div className="btn btn-primary">
                          <svg
                            version="1.1"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            className="octicon octicon-plus"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"
                            ></path>
                          </svg>
                          <span>New Monitor</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    this.state.monitorRows.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`m-monitor_item ${
                            item.is_active ? '' : 'deactivated'
                          } ${item.is_down ? 'is-down' : ''} ${
                            this.state.statusPageSelectedMonitors.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? 'selected'
                              : ''
                          }`}
                          onClick={this.attachMonitor}
                          data-value={JSON.stringify({
                            id: item.id,
                            isActive: item.is_active,
                            isDown: item.is_down,
                            name: item.name,
                            url: item.url,
                            displayCharts: {
                              responseTime: false,
                              uptime: false,
                              indices: false,
                            },
                            // status : this.state.affected_monitors_statusList[0].id
                          })}
                          data-checked={
                            this.state.statusPageSelectedMonitors.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? true
                              : false
                          }
                        >
                          <input
                            type="checkbox"
                            checked={
                              this.state.statusPageSelectedMonitors.findIndex(
                                (x) => x.id === item.id
                              ) >= 0
                                ? true
                                : false
                            }
                            onChange={this.attachMonitor}
                          />
                          <StatusIndicator
                            status={
                              item.is_active === false
                                ? ''
                                : item.is_down === true
                                ? 'danger'
                                : 'success'
                            }
                            title={
                              item.is_active === false
                                ? 'Inactive Monitor'
                                : item.is_down === true
                                ? 'Failure Monitor'
                                : 'Active Monitor'
                            }
                          />
                          <div className="chk_details-content">
                            <span className="chk_details-name">
                              {item.name.toLowerCase()}
                            </span>
                            <span className="chk_details-url">
                              {item.url.length > 20
                                ? item.url.substr(0, 20) + '..'
                                : item.url}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              {this.state.pageCount > 0 && (
                <ReactPaginate
                  previousLabel={''}
                  nextLabel={''}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleChange_paginationMonitors}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              )}
            </div>
            <div className="d-flex margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_selectMonitors}
              >
                Back
              </button>
              {this.state.monitorRows.length > 0 && (
                <button
                  className="btn btn-primary float-left margin-left-10"
                  disabled={this.state.statusPageSelectedMonitors.length <= 0}
                  onClick={this.confirmAttachedMonitors}
                >
                  Attach the selected monitors (
                  <span>{this.state.statusPageSelectedMonitors.length}</span>){' '}
                </button>
              )}
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </ModalRight>
        }
      </React.Fragment>
    );
  }
}

/**
 * Status page subsctibers manager
 * - Subscribers list
 * - List actions :
 * 		- Add subscriber : ( Email , SMS )  << ModalRight
 * 		- Delete subscriber  << Modal
 * - list filter
 * 		- Subscriber type ( Email , SMS , Webhook , Discord , Telegram , Slack )
 */
export class StatuspageSubscribersManager extends Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      statuspageId: this.props.statuspageId,
      offset: 0,
      data: [],
      perPage: 10,
      currentPage: 0,
      pageCount: 0,
      subscribersList: [],
      subscribersListErrors: false,
      isLoadingSubscribersList: false,
      isCompletedLoading: false,
      showModal_addSubscriber: false,
      showModal_removeSubscriber: false,
      addSubscriberForm_selectedType: 'email',

      email_regex_validator:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      phonenumber_regex_validator: /^\+[0-9]+$/,

      form_addSubscriber_email_value: '',
      form_addSubscriber_email_validate: false,
      form_addSubscriber_email_error_response: null,
      form_addSubscriber_email_placeholder: 'contact@example.com',
      form_addSubscriber_email_startChanges: false,

      form_addSubscriber_phoneNumber_value: '',
      form_addSubscriber_phoneNumber_validate: false,
      form_addSubscriber_phoneNumber_error_response: null,
      form_addSubscriber_phoneNumber_placeholder: '+44654123987',
      form_addSubscriber_phoneNumber_startChanges: false,

      modalResponse: '',
      modalResponse_status: 'warning',
      selectedSubscriberId: null,
      selectedFilterSubscriberType: null,
    };

    this.getSubscribers = this.getSubscribers.bind(this);
    this.openModal_addSubscriber = this.openModal_addSubscriber.bind(this);
    this.openModal_removeSubscriber =
      this.openModal_removeSubscriber.bind(this);
    this.closeModal_addSubscriber = this.closeModal_addSubscriber.bind(this);
    this.closeModal_removeSubscriber =
      this.closeModal_removeSubscriber.bind(this);
    this.handleChange_addSubscirber__selectTypeTab =
      this.handleChange_addSubscirber__selectTypeTab.bind(this);
    this.handleChange_addSubForm_field_email =
      this.handleChange_addSubForm_field_email.bind(this);
    this.handleChange_addSubForm_field_phoneNumber =
      this.handleChange_addSubForm_field_phoneNumber.bind(this);
    this.addSubscriber = this.addSubscriber.bind(this);
    this.removeSubscriber = this.removeSubscriber.bind(this);
    this.handleChange__filterSubscriberType =
      this.handleChange__filterSubscriberType.bind(this);
  }

  /**
   *
   */
  getSubscribers = async () => {
    this.setState({
      isLoadingSubscribersList: true,
    });
    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (obj && obj.token) {
      const token = obj.token;

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/statuspages/${this.state.statuspageId}/subscribers`,
          {
            params: {
              page: this.state.offset,
              per_page: this.state.perPage,
              type:
                this.state.selectedFilterSubscriberType !== null
                  ? this.state.selectedFilterSubscriberType.toUpperCase()
                  : '',
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          // return the subscribers' total value to the parent component
          this.props.parentCallback({ total_subscribers: data.total });

          this.setState({
            pageCount: Math.ceil(data.total / this.state.perPage),
            subscribersList: data.data,
            subscribersListErrors:
              data.total <= 0 ? "You don't have any subscribers yet!" : null,
            isLoadingSubscribersList: false,
            isCompletedLoading: true,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            //console.log('Error: ', err.message); // => prints: Api is being canceled
          } else {
            if (
              typeof err.message === 'undefined' ||
              err.message === null ||
              err.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.setState({
                pageCount: 0,
                subscribersList: [],
                subscribersListErrors:
                  err.response.status === 401
                    ? 'Authentication failed, please log in again'
                    : 'Something went wrong, please try again later.',
                isLoadingSubscribersList: false,
                isCompletedLoading: true,
              });
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
        isCompletedLoading: true,
      });
    }
  };

  render__itemsChannelLogo(__TYPE) {
    return (
      <div className="channel-logo-icon">
        <span className={`ch-lg ${__TYPE.toLowerCase()}`}></span>
      </div>
    );
  }

  /**
   *
   * @param {*} item
   * @returns
   */
  render__itemsDetailsDescription(item) {
    switch (item.type) {
      case 'EMAIL':
        return (
          <>
            {/* <span className="text-muted">Email to </span> */}
            <span className="text-gray-dark">{item.config.address}</span>
          </>
        );
      case 'SLACK':
        return (
          <>
            {/* <span className="text-muted">Slack hook to </span> */}
            {item.config.channel !== null ? (
              <span className="text-gray-dark">{`${item.config.channel} `}</span>
            ) : (
              ''
            )}
            <span className="text-muted small">
              <code>{item.config.webhook}</code>
            </span>
          </>
        );
      case 'SMS':
        return (
          <>
            {/* <span className="text-muted">SMS to </span> */}
            <span className="text-gray-dark">{item.config.phoneNumber}</span>
          </>
        );
      case 'WEBHOOK':
        return (
          <>
            {/* <span className="text-muted">Webhook </span> */}
            {item.config.name !== null ? (
              <span className="text-gray-dark">{item.config.name}</span>
            ) : (
              ''
            )}
            {/* <span className="text-muted"> URL: </span> */}
            <span className="text-muted small">
              <code>{item.config.url}</code>
            </span>
          </>
        );
      case 'TELEGRAM':
        return (
          <>
            {/* <span className="text-muted">Telegram to chat ID : </span> */}
            <span className="text-gray-dark">{item.config.chatid}</span>
          </>
        );

      case 'DISCORD':
        return (
          <>
            {/* <span className="text-muted">Discord </span> */}
            {/* {item.config.name !== null ? <span className="text-gray-dark">{item.config.name}</span> : ''} */}
            {/* <span className="text-muted"> URL: </span> */}
            <span className="text-muted small">
              <code>{`${item.config.url.substring(0, 54)}...`}</code>
            </span>
          </>
        );
    }
  }

  openModal_addSubscriber(event) {
    const selectedEventId = event.currentTarget.dataset.id;

    this.setState(
      {
        showModal_addSubscriber: true,
        selectedEventId: selectedEventId,
        modalResponse: '',
        modalResponse_status: 'warning',
        form_addSubscriber_email_value: '',
        form_addSubscriber_email_validate: false,
        form_addSubscriber_email_error_response: null,
        form_addSubscriber_email_startChanges: false,

        form_addSubscriber_phoneNumber_value: '',
        form_addSubscriber_phoneNumber_validate: false,
        form_addSubscriber_phoneNumber_error_response: null,
        form_addSubscriber_phoneNumber_startChanges: false,
      },
      () => {}
    );
  }
  openModal_removeSubscriber(event) {
    const selectedSubscriberId = event.currentTarget.dataset.id;
    this.setState(
      {
        showModal_removeSubscriber: true,
        selectedSubscriberId: selectedSubscriberId,
        modalResponse: '',
        modalResponse_status: 'warning',
      },
      () => {
        // Get monitors list
        // this.getAvailableMonitors();
      }
    );
  }
  closeModal_addSubscriber(event) {
    this.setState({
      showModal_addSubscriber: !this.state.showModal_addSubscriber,
      modalResponse: '',
    });
  }
  closeModal_removeSubscriber(event) {
    this.setState({
      showModal_removeSubscriber: !this.state.showModal_removeSubscriber,
      modalResponse: '',
    });
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  handleChange_addSubForm_field_email(event) {
    let isValid = false;
    let isEmpty = true;

    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== ''
    ) {
      isEmpty = false;
      if (event.target.value.match(this.state.email_regex_validator) !== null)
        isValid = true;
    }

    this.setState({
      form_addSubscriber_email_startChanges: true,
      form_addSubscriber_email_value: event.target.value,
      form_addSubscriber_email_validate: isValid,
      form_addSubscriber_email_error_response:
        isEmpty === true
          ? 'The email field is required.'
          : isValid === false
          ? 'The email field does not have the right format.'
          : '',
    });
  }

  /**
   *
   * @param {*} event
   */
  handleChange_addSubForm_field_phoneNumber(event) {
    let isValid = false;
    let isEmpty = true;

    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== ''
    ) {
      isEmpty = false;
      if (
        event.target.value.match(this.state.phonenumber_regex_validator) !==
        null
      )
        isValid = true;
    }

    this.setState({
      form_addSubscriber_phoneNumber_startChanges: true,
      form_addSubscriber_phoneNumber_value: event.target.value,
      form_addSubscriber_phoneNumber_validate: isValid,
      form_addSubscriber_phoneNumber_error_response:
        isEmpty === true
          ? 'The phone number field is required.'
          : isValid === false
          ? 'The phone number field does not have the right format.'
          : '',
    });
  }
  /**
   *
   * @param {*} event
   * @returns
   */
  addSubscriber = async (event) => {
    const type = this.state.addSubscriberForm_selectedType;

    if (
      (type === 'email' &&
        (typeof this.state.form_addSubscriber_email_value === 'undefined' ||
          this.state.form_addSubscriber_email_value === null ||
          this.state.form_addSubscriber_email_value === '' ||
          this.state.form_addSubscriber_email_validate === false)) ||
      (type === 'sms' &&
        (typeof this.state.form_addSubscriber_phoneNumber_value ===
          'undefined' ||
          this.state.form_addSubscriber_phoneNumber_value === null ||
          this.state.form_addSubscriber_phoneNumber_value === '' ||
          this.state.form_addSubscriber_phoneNumber_validate === false))
    ) {
      switch (type) {
        case 'email':
          this.setState({
            isLoading: false,
            modalResponse: 'The email field is required.',
            modalResponse_status: 'danger',
          });
          break;
        case 'sms':
          this.setState({
            isLoading: false,
            modalResponse: 'The phone number field is required.',
            modalResponse_status: 'danger',
          });
          break;
      }

      return;
    }

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      let customConfig = {
        type: type.toUpperCase(),
      };

      /* Example : 
			{
				config : {
					address : this.state.form_field_email_value,
				},
			} */

      switch (type) {
        case 'email':
          customConfig.config = {
            address: this.state.form_addSubscriber_email_value,
          };
          break;
        case 'sms':
          customConfig.config = {
            name: '',
            phoneNumber: this.state.form_addSubscriber_phoneNumber_value,
          };
          break;
      }

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/statuspages/${this.state.statuspageId}/subscribers`,
          customConfig,
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          // return the subscribers' total value to the parent component
          this.props.parentCallback({ new_subscriber: true });

          // const selectedEventId = this.state.selectedEventId;

          // let historyEvents = this.state.historyEvents
          // console.log('historyEvents : ' , historyEvents);
          // const indexHistoryEvents = historyEvents.findIndex(obj => obj.id === selectedEventId )
          // console.log('indexHistoryEvents : ' , indexHistoryEvents);
          // historyEvents[indexHistoryEvents].message = this.state.tmp_editModal__message
          // historyEvents[indexHistoryEvents].type = this.state.tmp_editModal__status
          // console.log('>>> historyEvents : ' , historyEvents);

          // stop loading effect
          this.setState({
            isLoading: false,
            modalResponse: 'Subscriber created successfully',
            modalResponse_status: 'success',
            showModal_addSubscriber: false,
            subscribersList: [data, ...this.state.subscribersList],
          });
        })
        .catch((err) => {
          let msg = 'Something went wrong removing your event';
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            if (err.response && err.response.data.message !== '') {
              msg = err.response.data.message;
            }
          }

          this.setState({
            isLoading: false,
            modalResponse: msg,
            modalResponse_status: 'danger',
          });
        });
    } else {
      this.setState({
        isLoading: false,
        modalResponse: 'You are not authorized to do this operation',
        modalResponse_status: 'danger',
      });
      return;
    }
  };

  /**
   *
   * @param {*} event
   * @returns
   */
  removeSubscriber = async (event) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/statuspages/${this.state.statuspageId}/subscribers/${this.state.selectedSubscriberId}`,
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const selectedSubscriberId = this.state.selectedSubscriberId;

            // return the subscribers' total value to the parent component
            this.props.parentCallback({ remove_subscriber: true });

            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse: 'Subscriber removed successfully',
              modalResponse_status: 'success',
              subscribersList: this.state.subscribersList.filter(
                function (obj) {
                  return obj.id !== selectedSubscriberId;
                }
              ),
              showModal_removeSubscriber: false,
            });
          } else {
            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse: 'Something went wrong removing your subscriber',
              modalResponse_status: 'danger',
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              modalResponse: 'Something went wrong removing your subscriber',
              modalResponse_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        modalResponse: 'You are not authorized to do this operation',
        modalResponse_status: 'danger',
      });
      return;
    }
  };

  /**
   *
   * @param {*} event
   */
  handleChange_addSubscirber__selectTypeTab(event) {
    const value = event.target.value;
    this.setState({
      addSubscriberForm_selectedType: value,
    });
  }

  /**
   *
   * @param {*} event
   */
  handleChange__filterSubscriberType(event) {
    const value = event.target.value;
    this.setState(
      {
        selectedFilterSubscriberType: value,
      },
      () => {
        this.getSubscribers();
      }
    );
  }

  /**
   *
   * @param {*} e
   */
  handlePagePaginationClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getSubscribers(offset);
      }
    );
  };

  componentDidMount() {
    this.getSubscribers();
  }

  render() {
    return (
      <React.Fragment>
        <div className="statuspage-subscribers__list">
          <div className="s-subscribers__header">
            <div className="s-subscribers__filter">
              <div className="field-group">
                <label>Subscriber Type</label>
                <select
                  className="textField"
                  value={
                    this.state.selectedFilterSubscriberType !== null
                      ? this.state.selectedFilterSubscriberType
                      : ''
                  }
                  onChange={this.handleChange__filterSubscriberType}
                >
                  <option value="">All</option>
                  {getStatusPageAlertChannels().map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {
              // this.props.global_state.currentPricingPlan!==null &&
              // (

              this.props.global_state.max_usage.max_statuspage_subscribers ===
                -1 ||
              (this.props.global_state.max_usage.max_statuspage_subscribers >
                0 &&
                this.props.global_state.totalAvailableSubscribers <
                  this.props.global_state.max_usage
                    .max_statuspage_subscribers) ? (
                <div
                  className="btn btn-orange"
                  onClick={this.openModal_addSubscriber}
                >
                  <AddIcon /> Add Subscriber
                </div>
              ) : (
                <Tooltip
                  placement="top"
                  trigger={['hover']}
                  overlay={
                    <span>
                      {this.props.global_state.max_usage
                        .max_statuspage_subscribers > 0 &&
                      this.props.global_state.totalAvailableSubscribers >=
                        this.props.global_state.max_usage
                          .max_statuspage_subscribers
                        ? `You have reached the limit of subscribers on your account, if you want to accept more subscribers you must upgrade your plan`
                        : `This feature is not available on your ${
                            this.props.global_state.currentPricingPlan !== null
                              ? `'${this.props.global_state.currentPricingPlan.public_name}'`
                              : ''
                          } plan, please upgrade to unlock this premium feature`}
                      <br />
                      <Link to={`/settings/billing`}>
                        Upgrade your plan now
                      </Link>
                    </span>
                  }
                >
                  <div className="btn btn-orange disabled" disabled>
                    <AddIcon /> Add Subscriber
                  </div>
                </Tooltip>
              )

              // )
            }
          </div>
          <div className="s-subscribers__content">
            <div className="s-subscribers__list">
              {this.state.isLoadingSubscribersList ? (
                <div className="loading-wrapper">
                  <LoadingSpinner />
                </div>
              ) : this.state.isCompletedLoading &&
                this.state.subscribersList.length <= 0 ? (
                <div className="home-empty-state">
                  <img
                    alt="No subscribers"
                    src={icon_no_alert_channel}
                    width="100px"
                  />
                  <h3>No active subscribers</h3>
                  <p className="text-muted">
                    Subscribers who have confirmed their subscription will
                    appear here.
                  </p>
                </div>
              ) : (
                typeof this.state.subscribersList !== 'undefined' &&
                this.state.subscribersList.length !== null &&
                this.state.subscribersList.map((item, index) => {
                  return (
                    <div key={index} className="s-subscribers__item">
                      <div className="ss-item_iconType">
                        {this.render__itemsChannelLogo(item.type)}
                      </div>
                      <div className="field-content">
                        {this.render__itemsDetailsDescription(item)}
                      </div>
                      <div className="ss-item_type">
                        {item.is_unsubscribed === true ? (
                          <span
                            className="subscriber-state unsubscribed"
                            title={
                              item.unsubscribed_at !== null
                                ? `Unsubscribed at ${moment(
                                    item.unsubscribed_at
                                  ).format('MMM DD, hh:mm A')}`
                                : ''
                            }
                          >{`Unsubscribed`}</span>
                        ) : item.type === 'EMAIL' &&
                          item.is_confirmed_email === false ? (
                          <span className="subscriber-state unconfirmed">{`Unconfirmed`}</span>
                        ) : (
                          <span className="subscriber-state activated">{`Activated`}</span>
                        )}
                      </div>
                      <div className="ss-item_date">
                        {moment(item.created_at).format('MMM DD, hh:mm A')}
                      </div>
                      <div className="ss-item_actions">
                        <div
                          className="btn_action-item remove"
                          data-id={item.id}
                          onClick={this.openModal_removeSubscriber}
                        >
                          <svg
                            version="1.1"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            className="octicon octicon-trash"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                            ></path>
                            <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                            <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
        {this.state.pageCount > 0 && (
          <ReactPaginate
            previousLabel={''}
            nextLabel={''}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePagePaginationClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
        {
          //this.state.historyEvents.length > 0 &&
          <ModalRight
            title="Add subscriber"
            closeModal={this.closeModal_addSubscriber}
            isDisplay={this.state.showModal_addSubscriber}
          >
            <div className="modal_layout-wrapper">
              <div className="modal_form">
                <div className="modal-overflow-height_500">
                  <div>
                    <div className="field-group">
                      <label className="fluid">Subscriber Type</label>
                      <select
                        className={`textField`}
                        onChange={
                          this.handleChange_addSubscirber__selectTypeTab
                        }
                      >
                        {getStatusPageAlertChannels()
                          .filter((x) => ['email', 'sms'].includes(x.id))
                          .map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-tabs">
                      {this.state.addSubscriberForm_selectedType ===
                        'email' && (
                        <div className="form-tab__type_email">
                          <div className="field-group fluid">
                            <label className="fluid" htmlFor="">
                              Email
                            </label>
                            <input
                              type="text"
                              className={`textField fluid ${
                                this.state
                                  .form_addSubscriber_email_startChanges ===
                                  true &&
                                this.state
                                  .form_addSubscriber_email_error_response !==
                                  ''
                                  ? 'error'
                                  : ''
                              }`}
                              value={this.state.form_addSubscriber_email_value}
                              placeholder={
                                this.state.form_addSubscriber_email_placeholder
                              }
                              onChange={
                                this.handleChange_addSubForm_field_email
                              }
                            />
                            <span
                              className={`fieldText_alert--error ${
                                this.state
                                  .form_addSubscriber_email_startChanges ===
                                  true &&
                                this.state
                                  .form_addSubscriber_email_error_response !==
                                  ''
                                  ? ''
                                  : 'hide'
                              }`}
                            >
                              {
                                this.state
                                  .form_addSubscriber_email_error_response
                              }
                            </span>
                          </div>
                        </div>
                      )}
                      {/* {
											this.state.addSubscriberForm_selectedType === 'slack' && 
											<div className="form-tab__type_slack" >
												tab__type_slack
											</div>
										} */}
                      {this.state.addSubscriberForm_selectedType === 'sms' && (
                        <div className="form-tab__type_sms">
                          <div className="field-group fluid">
                            <label className="fluid" htmlFor="">
                              Phone Number
                            </label>
                            <input
                              type="text"
                              className={`textField fluid ${
                                this.state
                                  .form_addSubscriber_phoneNumber_startChanges ===
                                  true &&
                                this.state
                                  .form_addSubscriber_phoneNumber_error_response !==
                                  ''
                                  ? 'error'
                                  : ''
                              }`}
                              value={
                                this.state.form_addSubscriber_phoneNumber_value
                              }
                              placeholder={
                                this.state
                                  .form_addSubscriber_phoneNumber_placeholder
                              }
                              onChange={
                                this.handleChange_addSubForm_field_phoneNumber
                              }
                            />
                            <span
                              className={`fieldText_alert--error ${
                                this.state
                                  .form_addSubscriber_phoneNumber_startChanges ===
                                  true &&
                                this.state
                                  .form_addSubscriber_phoneNumber_error_response !==
                                  ''
                                  ? ''
                                  : 'hide'
                              }`}
                            >
                              {
                                this.state
                                  .form_addSubscriber_phoneNumber_error_response
                              }
                            </span>
                          </div>
                        </div>
                      )}
                      {/* {
											this.state.addSubscriberForm_selectedType === 'webhooks' && 
											<div className="form-tab__type_webhooks" >
												tab__type_webhooks
											</div>
										} */}
                      {/* {
											this.state.addSubscriberForm_selectedType === 'telegram' && 
											<div className="form-tab__type_telegram" >
												tab__type_telegram
											</div>
										}
										{
											this.state.addSubscriberForm_selectedType === 'discord' && 
											<div className="form-tab__type_discord" >
												tab__type_discord
											</div>
										} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_addSubscriber}
              >
                Back
              </button>
              {
                <button
                  className="btn btn-primary float-left margin-left-10"
                  onClick={this.addSubscriber}
                >
                  Add Subscriber!
                </button>
              }
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </ModalRight>
        }
        {this.state.showModal_removeSubscriber ? (
          <Modal
            title="Confirm remove subscriber"
            closeModal={this.closeModal_removeSubscriber}
            fixedWidthWindow="small-window"
          >
            <div>Are you sure you want to remove this subscriber?</div>
            <div className="d-flex justify-content-flex-end margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_removeSubscriber}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger float-right margin-left-10"
                onClick={this.removeSubscriber}
              >
                Confirm removal
              </button>
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </Modal>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

/**
 * Notifications Settings:
 *  - Settings :
 * 		- select the displayed channels on status page frontend
 * 		- Which notifications to send?
 */
export class StatuspageNotificationsManager extends Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  // compomentDidMount()

  render() {
    // const plans = getPlans().then(res => { return res ;
    // 	// console.log(res);
    // 	// res[process.env.REACT_APP_LIVE_PAYMENT === 'true' ? 'live' : 'test']
    // 	// .find( x=> x.statuspage_features.all_alerts_notifications === true)
    // 	// .public_name
    // })

    return (
      <React.Fragment>
        <div className="statuspage-notifications_settings">
          <div className="ss-notifications_settings--wrapper">
            <div className="ss-nsettings__displayedChannels">
              <div className="ssns-dc__options">
                <div className="field-group checkbox-group margin-bottom-20 ">
                  <label
                    className="fluid margin-bottom-none"
                    htmlFor="subscribe_to_update"
                  >
                    {this.props.global_state.currentPricingPlan !== null &&
                    this.props.global_state.currentPricingPlan
                      .statuspage_features.subscribers_updates === true ? (
                      <input
                        id="subscribe_to_update"
                        type="checkbox"
                        className={`textField`}
                        value=""
                        checked={this.props.allow_subscriptions}
                        onChange={this.props.handleChange_formAllowSubscribers}
                      />
                    ) : (
                      <input
                        id="subscribe_to_update"
                        type="checkbox"
                        className={`textField`}
                        value=""
                        checked={false}
                        disabled={true}
                      />
                    )}
                    Subscribe to updates
                    {((this.props.global_state.currentPricingPlan !== null &&
                      this.props.global_state.currentPricingPlan
                        .statuspage_features.subscribers_updates === false) ||
                      this.props.global_state.currentPricingPlan === null) && (
                      <UpgradeButton
                        tooltip={`This feature is not available on your ${
                          this.props.global_state.currentPricingPlan !== null
                            ? `'${this.props.global_state.currentPricingPlan.public_name}'`
                            : ''
                        } plan, please upgrade to unlock this premium feature`}
                      >
                        Upgrade
                      </UpgradeButton>
                    )}
                  </label>
                  <div className="fieldText_note">
                    Allow visitors to your status page to subscribe to receive
                    updates on incidents as they occur through multiple alert
                    channels.
                  </div>
                </div>
                <div className="field-group">
                  <label htmlFor="">
                    Displayed Alert channels on Status page
                  </label>
                  {getStatusPageAlertChannels().map((item, index) => {
                    // return(<input type="radio" key={index} value={item.id} />)
                    return (
                      <div key={index} className="channel-item">
                        <Switch
                          onChange={
                            item.id === 'sms' &&
                            this.props.hasActiveSMSProvider === false
                              ? () => false
                              : this.props.handleChange_selectChannels
                          }
                          checked={
                            (item.id === 'sms' &&
                              this.props.hasActiveSMSProvider === true) ||
                            item.id !== 'sms'
                              ? this.props.activeChannelsForSubscribers.indexOf(
                                  item.id
                                ) >= 0
                                ? true
                                : false
                              : false
                          }
                          onColor="#1f80f9"
                          // onHandleColor="#2693e6"
                          handleDiameter={18}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          width={48}
                          height={22}
                          className="react-switch"
                          id={item.id}
                          disabled={
                            this.props.global_state.currentPricingPlan ===
                              null ||
                            this.props.global_state.currentPricingPlan
                              .statuspage_features.subscribers_updates === false
                              ? true
                              : item.id === 'sms' &&
                                this.props.hasActiveSMSProvider === false
                              ? true
                              : false
                          }
                        />
                        <div className="channel-item__name">
                          {item.name}
                          {item.id === 'sms' &&
                            this.props.hasActiveSMSProvider === false && (
                              <Tooltip
                                placement="top"
                                trigger={['hover']}
                                overlay={
                                  <span>
                                    {`By using this feature, you can send SMS messages to subscribers subscribed to a phone number when posting manual incidents / scheduled maintenance or even when an automated failure/recovery alert occurs.\n
									Your own Twilio account will be required to send SMS to subscribers with independent billing.
									`}
                                    <br />
                                    <Link to={`/settings/sms`}>
                                      Connect your SMS Twilio or Nexmo account
                                    </Link>
                                  </span>
                                }
                              >
                                <span className="question-badge margin-left-10"></span>
                              </Tooltip>
                            )}
                        </div>
                        {item.id === 'sms' &&
                          this.props.hasActiveSMSProvider === false && (
                            <span className="margin-left-10 ">
                              <Link to={`/settings/sms`}>
                                Connect your SMS account
                              </Link>
                            </span>
                          )}
                      </div>
                    );
                  })}
                </div>
                <div className="field-group">
                  <label className="fluid margin-bottom-none" htmlFor="">
                    What types of notifications to send to your subscribers?
                  </label>
                  <div className="field-group checkbox-group">
                    <label
                      className="fluid margin-bottom-none"
                      htmlFor={`form_statuspageNotificationType--incident_updates`}
                    >
                      <input
                        type="checkbox"
                        className="textField"
                        value={`incident_updates`}
                        id={`form_statuspageNotificationType--incident_updates`}
                        checked={
                          this.props.subscribersNotificationTypes
                            .incident_updates
                        }
                        onChange={(event) =>
                          this.props.handleChange_formNotificationTypes(
                            event,
                            'incident_updates'
                          )
                        }
                        disabled={
                          this.props.global_state.currentPricingPlan !== null &&
                          this.props.global_state.currentPricingPlan
                            .statuspage_features.subscribe_manual_incidents ===
                            true
                            ? false
                            : true
                        }
                      />
                      Manual incident updates
                      {this.props.global_state.currentPricingPlan !== null &&
                        this.props.global_state.currentPricingPlan
                          .statuspage_features.subscribe_manual_incidents ===
                          false && (
                          <UpgradeButton
                            tooltip={`This feature is not available on your '${this.props.global_state.currentPricingPlan.public_name}' plan, please upgrade to unlock this premium feature`}
                          >
                            Upgrade
                          </UpgradeButton>
                        )}
                    </label>
                    <label
                      className="fluid margin-bottom-none"
                      htmlFor={`form_statuspageNotificationType--uptime_alerts`}
                    >
                      <input
                        type="checkbox"
                        className="textField"
                        value={`uptime_alerts`}
                        id={`form_statuspageNotificationType--uptime_alerts`}
                        checked={
                          this.props.subscribersNotificationTypes.uptime_alerts
                        }
                        onChange={(event) =>
                          this.props.handleChange_formNotificationTypes(
                            event,
                            'uptime_alerts'
                          )
                        }
                        disabled={
                          this.props.global_state.currentPricingPlan !== null &&
                          this.props.global_state.currentPricingPlan
                            .statuspage_features.subscribe_all_alerts === true
                            ? false
                            : true
                        }
                      />
                      All automated failures and recovery alerts
                      {this.props.global_state.currentPricingPlan !== null &&
                        this.props.global_state.currentPricingPlan
                          .statuspage_features.subscribe_all_alerts ===
                          false && (
                          <UpgradeButton
                            tooltip={`This feature is not available on your '${this.props.global_state.currentPricingPlan.public_name}' plan, please upgrade to unlock this premium feature`}
                          >
                            Upgrade
                          </UpgradeButton>
                        )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="ssns-dc__preview">
                <div
                  className={`ssns-dc__preview-wrapper ${
                    this.props.allow_subscriptions === false ? 'locked' : ''
                  }`}
                >
                  <div className="preview-title">Status page preview</div>
                  <div className="btn btn-gray dropdown">
                    Subscribe to updates
                  </div>
                  <div className="ssns-dc__channels-items dropdown-menu dropdown-menu-right medium-width dropdown-menu-grid-channel ">
                    {getStatusPageAlertChannels()
                      .filter((x) =>
                        this.props.activeChannelsForSubscribers.includes(x.id)
                      )
                      .map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="ssns-dc__channels-item dropdown-item "
                          >
                            <div className="ssns-dc__channels-item_content dropdown-item_content">
                              {typeof item.icon !== 'undefined' &&
                              item.icon !== null &&
                              item.icon !== '' ? (
                                <span
                                  className={`dropdown-main-icon ${item.icon} ${
                                    item.bigIcon === true ? 'big-icon' : ''
                                  }`}
                                ></span>
                              ) : (
                                ''
                              )}
                              <div className="di-text_content">
                                <span
                                  className={`title ${
                                    'description' in item ? 'bold' : ''
                                  }`}
                                >
                                  {item.name}
                                </span>
                                {'description' in item &&
                                item.description !== 'undefined' &&
                                item.description !== null &&
                                item.description !== '' ? (
                                  <p className="desc">{item.description}</p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
