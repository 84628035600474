import React, { PureComponent } from 'react';
import moment from 'moment';
import ReactCountryFlag from 'react-country-flag';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Surface,
  Symbols,
  Legend,
} from 'recharts';

import _ from 'lodash';

// css
import './Recharts.css';

/**
 *
 * @param {*} param0
 */
const CustomTooltip__responsetime = ({ active, payload, label }) => {
  if (active) {
    var label_content = '';
    if (
      typeof label === 'undefined' ||
      label === null ||
      label === 0 ||
      isNaN(label) === false
    ) {
      label_content =
        typeof payload !== 'undefined' && payload !== null && payload.length > 0
          ? moment(payload[0].date).format('DD MMM YYYY HH:mm:ss')
          : '00:00:00';
    } else {
      if (
        moment(label).utc(0).hours() === 0 &&
        moment(label).utc(0).minutes() === 0
      ) {
        // show only the days
        label_content = moment(label).format('DD MMM YYYY');
      } else {
        label_content = moment(label).format('DD MMM YYYY HH:mm:ss');
      }
    }

    const responseTimeData =
      typeof payload !== 'undefined' && payload !== null
        ? payload.find((x) => x.dataKey === 'total')
        : false;

    // regional responsetime
    let regionalResponseTime = null;
    if (
      typeof payload !== 'undefined' &&
      payload !== null &&
      payload.length > 0
    ) {
      regionalResponseTime = payload
        .filter(
          (item) =>
            item.dataKey !== 'total' &&
            item.value !== 'undefined' &&
            item.value > 0
        )
        .map((item, index) => {
          return (
            item.dataKey !== 'total' &&
            item.value !== 'undefined' &&
            item.value > 0 && (
              <li key={index}>
                <span
                  className="lineColor"
                  style={{ backgroundColor: `${item.color}` }}
                ></span>
                <span className="label">{item.name}</span>
                <span className="value">{`${
                  typeof item.value !== 'undefined' && item.value !== null
                    ? item.value
                    : 0
                } ms`}</span>
              </li>
            )
          );
        });
    }

    return (
      <div className="custom-tooltip responseTime__chart-tooltip">
        <div className="main-item">
          <span
            className="lineColor"
            style={{ backgroundColor: `#607d8b` }}
          ></span>
          <span className="label">{label_content}</span>
          <span className="value">
            {responseTimeData && `${responseTimeData.value} ms`}
          </span>
        </div>
        {regionalResponseTime !== null && regionalResponseTime.length > 0 && (
          <div className="secondary-item">
            <ul>{regionalResponseTime}</ul>
          </div>
        )}
      </div>
    );
  }

  return null;
};

/**
 *
 * @param {*} param0
 * @returns
 */
const CustomTooltip__httpbreakdown = ({ active, payload, label }) => {
  if (active) {
    var label_content = '';
    if (
      typeof label === 'undefined' ||
      label === null ||
      label === 0 ||
      isNaN(label) === false
    ) {
      label_content =
        typeof payload !== 'undefined' && payload !== null && payload.length > 0
          ? moment(payload[0].date).format('DD MMM YYYY HH:mm:ss')
          : '00:00:00';
    } else {
      if (
        moment(label).utc(0).hours() === 0 &&
        moment(label).utc(0).minutes() === 0
      ) {
        // show only the days
        label_content = moment(label).format('DD MMM YYYY');
      } else {
        label_content = moment(label).format('DD MMM YYYY HH:mm:ss');
      }
    }

    const responseTimeData =
      typeof payload !== 'undefined' && payload !== null
        ? payload.find((x) => x.dataKey === 'total')
        : false;

    return (
      <div className="custom-tooltip responseTime__chart-tooltip">
        <div className="main-item">
          <span
            className="lineColor"
            style={{ backgroundColor: `#607d8b` }}
          ></span>
          <span className="label">{label_content}</span>
          <span className="value">
            {responseTimeData && `${responseTimeData.value} ms`}
          </span>
        </div>
        {typeof payload !== 'undefined' &&
        payload !== null &&
        payload.length > 0 ? (
          <div className="secondary-item">
            <ul>
              {payload.map((item, index) => {
                return (
                  item.dataKey !== 'total' && (
                    <li key={index}>
                      <span
                        className="lineColor"
                        style={{ backgroundColor: `${item.color}` }}
                      ></span>
                      <span className="label">{item.name}</span>
                      <span className="value">{`${
                        typeof item.value !== 'undefined' && item.value !== null
                          ? item.value
                          : 0
                      } ms`}</span>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  return null;
};

/**
 *
 * @param {*} param0
 */
const CustomTooltip__uptime = ({ active, payload, label }) => {
  if (active) {
    var label_content = '';
    if (
      typeof label === 'undefined' ||
      label === null ||
      label === 0 ||
      isNaN(label) === false
    ) {
      label_content =
        typeof payload !== 'undefined' && payload !== null && payload.length > 0
          ? moment(payload[0].date).format('DD MMM YYYY HH:mm:ss')
          : '00:00:00';
    } else {
      if (
        moment(label).utc(0).hours() === 0 &&
        moment(label).utc(0).minutes() === 0
      ) {
        // show only the days
        label_content = moment(label).format('DD MMM YYYY');
      } else {
        label_content = moment(label).format('DD MMM YYYY HH:mm:ss');
      }
    }
    return (
      <div className="custom-tooltip ">
        <span className="label">{label_content}</span>
        <span className="value">{`${
          typeof payload !== 'undefined' &&
          payload !== null &&
          payload.length > 0
            ? payload[0].value
            : 0
        } %`}</span>
      </div>
    );
  }

  return null;
};

/**
 *
 * @param {*} param0
 */
const CustomTooltip__apdex = ({ active, payload, label }) => {
  if (active) {
    var label_content = '';
    let sub_label_content = '';
    if (
      typeof label === 'undefined' ||
      label === null ||
      label === 0 ||
      isNaN(label) === false
    ) {
      label_content =
        typeof payload !== 'undefined' && payload !== null && payload.length > 0
          ? moment(payload[0].date).format('DD MMM YYYY HH:mm:ss')
          : '00:00:00';
    } else {
      if (
        moment(label).utc(0).hours() === 0 &&
        moment(label).utc(0).minutes() === 0
      ) {
        // show only the days
        label_content = moment(label).format('DD MMM YYYY');
      } else {
        label_content = moment(label).format('HH:mm A');
        label_content += ' → ';
        label_content += moment(label)
          .add(5 - 1, 'minutes')
          .format('HH:mm A');
        sub_label_content = moment(label).format('DD MMM YYYY');
      }
    }
    return (
      <div className="custom-tooltip ">
        <span className="label">
          <span>{label_content}</span>
          {sub_label_content !== '' && (
            <span className="sub-label">{sub_label_content}</span>
          )}
        </span>
        <span className="value">{`${
          typeof payload !== 'undefined' &&
          payload !== null &&
          payload.length > 0
            ? payload[0].value
            : 0
        }`}</span>
      </div>
    );
  }

  return null;
};

/**
 *
 * @param {*} param0
 */
const CustomTooltip__downtime = ({ active, payload, label }) => {
  if (active) {
    var label_content = '';
    let sub_label_content = '';
    if (
      typeof label === 'undefined' ||
      label === null ||
      label === 0 ||
      isNaN(label) === false
    ) {
      label_content =
        typeof payload !== 'undefined' && payload !== null && payload.length > 0
          ? moment(payload[0].date).format('DD MMM YYYY HH:mm:ss')
          : '00:00:00';
    } else {
      if (
        moment(label).utc(0).hours() === 0 &&
        moment(label).utc(0).minutes() === 0
      ) {
        // show only the days
        label_content = moment(label).format('DD MMM YYYY');
      } else {
        label_content = moment(label).format('HH:mm A');
        label_content += ' → ';
        label_content += moment(label).add(59, 'minutes').format('HH:mm A');
        sub_label_content = moment(label).format('DD MMM YYYY');
      }
    }
    return (
      <div className="custom-tooltip ">
        <span className="label">
          <span>{label_content}</span>
          {sub_label_content !== '' && (
            <span className="sub-label">{sub_label_content}</span>
          )}
        </span>
        <span className="value">{`${
          typeof payload !== 'undefined' &&
          payload !== null &&
          payload.length > 0
            ? payload[0].value
            : 0
        } times`}</span>
      </div>
    );
  }

  return null;
};

/**
 * RECHART LINE CHART
 */
export default class RechartLineChart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chart: {
        width: typeof props.width !== 'undefined' ? props.width : '100%',
        height: typeof props.height !== 'undefined' ? props.height : '80%',
      },
      data: props.data,
      chartType:
        typeof props.chartType !== 'undefined' ? props.chartType : 'line',
      XAxisInterval:
        typeof props.XAxisInterval !== 'undefined'
          ? props.XAxisInterval
          : 'hours',
      charts__responsetime: {},
      disabledCharts__responsetime: [],
    };

    // //console.log("Constructor");
  }
  /* UNSAFE_componentWillMount() {
		// //console.log("Component will mount");
	}
	
	UNSAFE_componentDidMount() {
		// //console.log("Component did mount");
		 
	}
	  
	UNSAFE_componentWillReceiveProps(nextProps) {
		// //console.log("Component will receive props", nextProps);
		//this.forceUpdate();
	}

	UNSAFE_shouldComponentUpdate(nextProps, nextState) {
		// //console.log("Should Component update", nextProps, nextState)
		return true;
	}
	
	UNSAFE_componentWillUpdate(nextProps, nextState) {
		// //console.log("component will update", nextProps, nextState);
		
	}

	UNSAFE_componentDidUpdate(prevProps, prevState) {
		// //console.log("component did update" , prevProps , prevState);
	}

	UNSAFE_componentWillUnmount() {
		// //console.log("Component will unmount");
	} */

  handleClick__responsetime = (dataKey) => {
    if (_.includes(this.state.disabledCharts__responsetime, dataKey)) {
      this.setState({
        disabledCharts__responsetime:
          this.state.disabledCharts__responsetime.filter(
            (obj) => obj !== dataKey
          ),
      });
    } else {
      this.setState({
        disabledCharts__responsetime:
          this.state.disabledCharts__responsetime.concat(dataKey),
      });
    }
  };

  renderCusomizedLegend__responsetime = ({ payload }) => {
    return (
      <div className="customized-legend">
        {payload.map((entry) => {
          const { dataKey, color, name } = entry;
          const active = _.includes(
            this.state.disabledCharts__responsetime,
            dataKey
          );
          /* const style = {
				marginRight: 10,
				color: active ? "#AAA" : "#000"
			}; */

          return (
            <span
              className={`legend-item ${active ? 'inactive' : ''}`}
              onClick={() => this.handleClick__responsetime(dataKey)}
              key={dataKey}
            >
              <Surface width={10} height={10} viewBox={`0 0 10 10`}>
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                {active && (
                  <Symbols
                    cx={5}
                    cy={5}
                    type="circle"
                    size={25}
                    fill={'#FFF'}
                  />
                )}
              </Surface>
              <span>{name}</span>
            </span>
          );
        })}
      </div>
    );
  };

  componentDidMount() {
    // Generate te Chart reponsetime
    if (this.state.chartType === 'responsetime') {
      let charts__responsetime = {};
      this.props.locationConfig.map((item) => {
        charts__responsetime[item.name] = {
          color: item.chartColor,
          name: item.location,
        };
      });

      this.setState({
        charts__responsetime: charts__responsetime,
      });
    }
  }

  render() {
    // //console.log(this.state);
    if (this.state.chartType === 'responsetime') {
      return (
        <ResponsiveContainer width={'100%'} height={250}>
          <LineChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#eee"
              vertical={false}
            />
            <XAxis
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              strokeWidth={0}
              domain={[0, 'auto']}
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) => tickItem + ' ms'}
            />
            <Tooltip content={<CustomTooltip__responsetime />} />
            {/* Generate Custom Legend */}
            <Legend
              verticalAlign="bottom"
              height={36}
              align="right"
              payload={_.toPairs(this.state.charts__responsetime).map(
                (pair) => ({
                  dataKey: pair[0],
                  color: pair[1]['color'],
                  name: pair[1]['name'],
                })
              )}
              content={this.renderCusomizedLegend__responsetime}
            />
            {/* Generate lines */}
            {_.toPairs(this.state.charts__responsetime)
              .filter(
                (pair) =>
                  !_.includes(this.state.disabledCharts__responsetime, pair[0])
              )
              .map((pair) => (
                <Line
                  type="monotone"
                  key={pair[0]}
                  dataKey={pair[0]}
                  name={pair[1]['name']}
                  stroke={pair[1]['color']}
                  strokeWidth={2}
                  dot={false}
                  legendType={'none'}
                  isAnimationActive={false}
                />
              ))}
          </LineChart>
        </ResponsiveContainer>
      );
    } else if (this.state.chartType === 'uptime') {
      return (
        <ResponsiveContainer width={'100%'} height={160}>
          <LineChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            {/* <defs>
						<linearGradient id="colorUv_uptime" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="50%" stopColor="#0ace66" stopOpacity={1}/>
							<stop offset="100%" stopColor="#fe4a49" stopOpacity={0.3}/>
						</linearGradient>
					</defs> */}
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#eee"
              vertical={false}
            />
            <XAxis
              name=""
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              strokeWidth={0}
              domain={[0, 'auto']}
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) => tickItem + '%'}
            />
            <Tooltip content={<CustomTooltip__uptime />} />
            {/* <Legend /> */}
            {/* <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2}  dot={false}
					legendType={"none"}  /> */}
            <Line
              type="monotone"
              dataKey="value"
              stroke="#0ace66"
              strokeWidth={2}
              dot={false}
              activeDot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      );
    } else if (this.state.chartType === 'downtime') {
      return (
        <ResponsiveContainer width={'100%'} height={160}>
          <LineChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            {/* <defs>
						<linearGradient id="colorUv_downtime" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="80%" stopColor="#fe4a49" stopOpacity={1}/>
							<stop offset="100%" stopColor="#0ace66" stopOpacity={0.3}/>
						</linearGradient>
					</defs> */}
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#eee"
              vertical={false}
            />
            <XAxis
              name=""
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              strokeWidth={0}
              domain={[0, 'auto']}
              tick={{ fill: '#afafaf', fontSize: 10 }}
            />
            <Tooltip content={<CustomTooltip__downtime />} />
            {/* <Legend /> */}
            <Line
              type="monotone"
              dataKey="value"
              stroke="#fe4a49"
              strokeWidth={2}
              dot={false}
              legendType={'none'}
            />
          </LineChart>
        </ResponsiveContainer>
      );
    } else if (this.state.chartType === 'apdex') {
      return (
        <ResponsiveContainer width={'100%'} height={160}>
          <LineChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient
                id="colorUv_apdex"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="80%" stopColor="#0ace66" stopOpacity={1} />
                <stop offset="100%" stopColor="#fe4a49" stopOpacity={0.3} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#eee"
              vertical={false}
            />
            <XAxis
              name=""
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              strokeWidth={0}
              domain={[0, 'auto']}
              tick={{ fill: '#afafaf', fontSize: 10 }}
            />
            <Tooltip content={<CustomTooltip__apdex />} />
            {/* <Legend /> */}
            {/* <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2}  dot={false} legendType={"none"}  /> */}
            <Line
              type="monotone"
              dataKey="value"
              stroke="url(#colorUv_apdex)"
              strokeWidth={2}
              dot={false}
              activeDot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      );
    }
  }
}

/**
 * RECHART AREA CHART
 */
export class RechartAreaChart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chart: {
        width: typeof props.width !== 'undefined' ? props.width : '100%',
        height: typeof props.height !== 'undefined' ? props.height : '80%',
      },
      data: props.data,
      chartType:
        typeof props.chartType !== 'undefined' ? props.chartType : 'line',
      XAxisInterval:
        typeof props.XAxisInterval !== 'undefined'
          ? props.XAxisInterval
          : 'hours',
      charts__responsetime: {},
      charts__httpbreakdown: {
        total: {
          color: '#607d8b',
          name: 'Total',
        },
        contentTransfer: {
          color: '#28d102',
          name: 'Content Transfer',
        },
        dnsLookup: {
          color: '#f42a04',
          name: 'DNS Lookup',
        },
        firstByte: {
          color: '#673ab7',
          name: 'First Byte',
        },
        tcpConnection: {
          color: '#2196f3',
          name: 'TCP Connection ',
        },
        wait: {
          color: '#ffc107',
          name: 'Wait',
        },
        request: {
          color: '#E91E63',
          name: 'Request',
        },
        tlsHandshake: {
          color: '#9C27B0',
          name: 'TLS Handshake',
        },
      },
      disabledCharts__responsetime: [],
      disabledCharts__httpbreakdown: [],
    };
  }

  handleClick__responsetime = (dataKey) => {
    if (_.includes(this.state.disabledCharts__responsetime, dataKey)) {
      this.setState({
        disabledCharts__responsetime:
          this.state.disabledCharts__responsetime.filter(
            (obj) => obj !== dataKey
          ),
      });
    } else {
      this.setState({
        disabledCharts__responsetime:
          this.state.disabledCharts__responsetime.concat(dataKey),
      });
    }
  };

  renderCusomizedLegend__responsetime = ({ payload }) => {
    return (
      <div className="customized-legend">
        {payload.map((entry) => {
          const { dataKey, color, name, countryCode, countryName } = entry;
          const active = _.includes(
            this.state.disabledCharts__responsetime,
            dataKey
          );
          /* const style = {
				marginRight: 10,
				color: active ? "#AAA" : "#000"
			}; */

          return (
            <span
              className={`legend-item ${active ? 'inactive' : ''}`}
              onClick={() => this.handleClick__responsetime(dataKey)}
              key={dataKey}
              style={{ color: color }}
            >
              <ReactCountryFlag
                countryCode={countryCode}
                svg
                title={countryName}
                aria-label={countryName}
              />
              {/* <Surface width={10} height={10} viewBox="0 0 10 10">
						<Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
						{active && (
						<Symbols
							cx={5}
							cy={5}
							type="circle"
							size={25}
							fill={"#FFF"}
						/>
						)}
					</Surface> */}
              <span>{name}</span>
            </span>
          );
        })}
      </div>
    );
  };

  handleClick__httpbreakdown = (dataKey) => {
    if (_.includes(this.state.disabledCharts__httpbreakdown, dataKey)) {
      this.setState({
        disabledCharts__httpbreakdown:
          this.state.disabledCharts__httpbreakdown.filter(
            (obj) => obj !== dataKey
          ),
      });
    } else {
      this.setState({
        disabledCharts__httpbreakdown:
          this.state.disabledCharts__httpbreakdown.concat(dataKey),
      });
    }
  };

  renderCusomizedLegend__httpbreakdown = ({ payload }) => {
    return (
      <div className="customized-legend">
        {payload.map((entry) => {
          const { dataKey, color, name } = entry;
          const active = _.includes(
            this.state.disabledCharts__httpbreakdown,
            dataKey
          );
          /* const style = {
				marginRight: 10,
				color: active ? "#AAA" : "#000"
			}; */

          return (
            <span
              className={`legend-item ${active ? 'inactive' : ''}`}
              onClick={() => this.handleClick__httpbreakdown(dataKey)}
              key={dataKey}
            >
              <Surface width={10} height={10}>
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                {active && (
                  <Symbols
                    cx={5}
                    cy={5}
                    type="circle"
                    size={25}
                    fill={'#FFF'}
                  />
                )}
              </Surface>
              <span>{name}</span>
            </span>
          );
        })}
      </div>
    );
  };

  /* UNSAFE_componentWillMount() {
		// //console.log("Component will mount");
	}
	
	UNSAFE_componentDidMount() {
		// //console.log("Component did mount");
		 
	}
	  
	UNSAFE_componentWillReceiveProps(nextProps) {
		// //console.log("Component will receive props", nextProps);
		this.setState({ data : nextProps})
		// this.forceUpdate();
	}

	UNSAFE_shouldComponentUpdate(nextProps, nextState) {
		// //console.log("Should Component update", nextProps, nextState)
		return true;
	}
	
	UNSAFE_componentWillUpdate(nextProps, nextState) {
		// //console.log("component will update", nextProps, nextState);
		
	}

	UNSAFE_componentDidUpdate(prevProps, prevState) {
		// //console.log("component did update" , prevProps , prevState);
	}

	UNSAFE_componentWillUnmount() {
		// //console.log("Component will unmount");
	} */

  componentDidMount() {
    // Generate te Chart reponsetime
    if (this.state.chartType === 'responsetime') {
      let charts__responsetime = {};
      this.props.locationConfig.map((item) => {
        charts__responsetime[item.name] = {
          color: item.chartColor,
          name: item.location,
          countryCode: item.countryCode,
          countryName: item.countryName,
        };
      });

      this.setState({
        charts__responsetime: charts__responsetime,
      });
    }
  }

  render() {
    if (this.state.chartType === 'responsetime') {
      return (
        <ResponsiveContainer width={'100%'} height={250}>
          <AreaChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <defs>
              {_.toPairs(this.state.charts__responsetime)
                .filter(
                  (pair) =>
                    !_.includes(
                      this.state.disabledCharts__responsetime,
                      pair[0]
                    )
                )
                .map((pair) => (
                  <linearGradient
                    key={pair[0]}
                    id={`colorAreaChart__responsetime_${pair[0]}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="10%"
                      stopColor={pair[1]['color']}
                      stopOpacity={0.3}
                    />
                    <stop
                      offset="100%"
                      stopColor={pair[1]['color']}
                      stopOpacity={0}
                    />
                  </linearGradient>
                ))}
            </defs>
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#eee"
              vertical={false}
            />
            <XAxis
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              strokeWidth={0}
              domain={[0, 'auto']}
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) => tickItem + ' ms'}
            />
            <Tooltip content={<CustomTooltip__responsetime />} />
            {/* Generate Custom Legend */}
            <Legend
              verticalAlign="bottom"
              height={36}
              align="right"
              payload={_.toPairs(this.state.charts__responsetime).map(
                (pair) => ({
                  dataKey: pair[0],
                  color: pair[1]['color'],
                  name: pair[1]['name'],
                  countryCode: pair[1]['countryCode'],
                  countryName: pair[1]['countryName'],
                })
              )}
              content={this.renderCusomizedLegend__responsetime}
            />
            {/* Generate Bar */}
            {_.toPairs(this.state.charts__responsetime)
              .filter(
                (pair) =>
                  !_.includes(this.state.disabledCharts__responsetime, pair[0])
              )
              .map((pair) => (
                <Area
                  type="monotone"
                  key={pair[0]}
                  //stackId={'a'}
                  dataKey={pair[0]}
                  name={pair[1]['name']}
                  fill={`url(#colorAreaChart__responsetime_${pair[0]})`}
                  fillOpacity={1}
                  stroke={pair[1]['color']}
                  strokeWidth={2}
                  dot={false}
                  legendType={'none'}
                  isAnimationActive={false}
                />
              ))}
            <Area
              type="monotone"
              key={`total`}
              //stackId={'a'}
              dataKey={`total`}
              name={`total`}
              // fill={`url(#colorAreaChart__responsetime_total)`}
              fillOpacity={0}
              stroke={`#0099cc`}
              strokeWidth={2}
              dot={false}
              legendType={'none'}
              isAnimationActive={false}
            />
          </AreaChart>
        </ResponsiveContainer>
      );
    }
    if (this.state.chartType === 'httpbreakdown') {
      return (
        <ResponsiveContainer width={'100%'} height={200}>
          <AreaChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <defs>
              {_.toPairs(this.state.charts__httpbreakdown)
                .filter(
                  (pair) =>
                    !_.includes(
                      this.state.disabledCharts__httpbreakdown,
                      pair[0]
                    )
                )
                .map((pair) => (
                  <linearGradient
                    key={pair[0]}
                    id={`colorAreaChart__httpbreakdown_${pair[0]}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="10%"
                      stopColor={pair[1]['color']}
                      stopOpacity={0.3}
                    />
                    <stop
                      offset="100%"
                      stopColor={pair[1]['color']}
                      stopOpacity={0}
                    />
                  </linearGradient>
                ))}
            </defs>
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#eee"
              vertical={false}
            />
            <XAxis
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              strokeWidth={0}
              domain={[0, 'auto']}
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) => tickItem + ' ms'}
            />
            <Tooltip content={<CustomTooltip__httpbreakdown />} />
            {/* Generate Custom Legend */}
            <Legend
              verticalAlign="bottom"
              height={36}
              align="right"
              payload={_.toPairs(this.state.charts__httpbreakdown).map(
                (pair) => ({
                  dataKey: pair[0],
                  color: pair[1]['color'],
                  name: pair[1]['name'],
                })
              )}
              content={this.renderCusomizedLegend__httpbreakdown}
            />
            {/* Generate Bar */}
            {_.toPairs(this.state.charts__httpbreakdown)
              .filter(
                (pair) =>
                  !_.includes(this.state.disabledCharts__httpbreakdown, pair[0])
              )
              .map((pair) => (
                <Area
                  type="monotone"
                  key={pair[0]}
                  // stackId={'a'}
                  dataKey={pair[0]}
                  name={pair[1]['name']}
                  fill={`url(#colorAreaChart__httpbreakdown_${pair[0]})`}
                  fillOpacity={1}
                  stroke={pair[1]['color']}
                  strokeWidth={2}
                  dot={false}
                  legendType={'none'}
                  isAnimationActive={false}
                />
              ))}
          </AreaChart>
        </ResponsiveContainer>
      );
    }
    if (this.state.chartType === 'uptime') {
      return (
        <ResponsiveContainer width={'100%'} height={160}>
          <AreaChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient
                id="colorAreaChart__uptime"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="10%" stopColor="#1a53db21" stopOpacity={0.3} />
                <stop offset="100%" stopColor="#1a53db21" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              name=""
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              strokeWidth={0}
              domain={[0, 'auto']}
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) => tickItem + '%'}
            />
            <Tooltip content={<CustomTooltip__uptime />} />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#1853db"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorAreaChart__uptime)"
              dot={false}
              legendType={'none'}
            />
          </AreaChart>
        </ResponsiveContainer>
      );
    }
    if (this.state.chartType === 'downtime') {
      return (
        <ResponsiveContainer width={'100%'} height={160}>
          <AreaChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient
                id="colorAreaChart__downtime"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="10%" stopColor="#ff00004d" stopOpacity={0.3} />
                <stop offset="100%" stopColor="#ff00004d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#eee"
              vertical={false}
            />
            <XAxis
              name=""
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              strokeWidth={0}
              domain={[0, 'auto']}
              tick={{ fill: '#afafaf', fontSize: 10 }}
            />
            <Tooltip content={<CustomTooltip__downtime />} />

            <Area
              type="monotone"
              dataKey="value"
              stroke="#fe4a49"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorAreaChart__downtime)"
              dot={false}
              legendType={'none'}
            />
          </AreaChart>
        </ResponsiveContainer>
      );
    }
    if (this.state.chartType === 'apdex') {
      return (
        <ResponsiveContainer width={'100%'} height={160}>
          <AreaChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient
                id="colorAreaChart__apdex"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="10%" stopColor="#1a53db21" stopOpacity={0.3} />
                <stop offset="100%" stopColor="#1a53db21" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              name=""
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              strokeWidth={0}
              domain={[0, 1]}
              tick={{ fill: '#afafaf', fontSize: 10 }}
            />
            <Tooltip content={<CustomTooltip__apdex />} />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#1853db"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorAreaChart__apdex)"
              dot={false}
              legendType={'none'}
            />
          </AreaChart>
        </ResponsiveContainer>
      );
    }
  }
}

/**
 * RECHART BAR CHART
 */
export class RechartBarChart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chart: {
        width: typeof props.width !== 'undefined' ? props.width : '100%',
        height: typeof props.height !== 'undefined' ? props.height : '80%',
      },
      data: props.data,
      chartType:
        typeof props.chartType !== 'undefined' ? props.chartType : 'apdex',
      barColor:
        typeof props.barColor !== 'undefined' ? props.barColor : '#F44336',
      XAxisInterval:
        typeof props.XAxisInterval !== 'undefined'
          ? props.XAxisInterval
          : 'hours',
      charts__responsetime: {
        total: {
          color: '#607d8b',
          name: 'Total',
        },
        contentTransfer: {
          color: '#28d102',
          name: 'Content Transfer',
        },
        dnsLookup: {
          color: '#f42a04',
          name: 'DNS Lookup',
        },
        firstByte: {
          color: '#673ab7',
          name: 'First Byte',
        },
        tcpConnection: {
          color: '#2196f3',
          name: 'TCP Connection ',
        },
        wait: {
          color: '#ffc107',
          name: 'Wait',
        },
        request: {
          color: '#E91E63',
          name: 'Request',
        },
        tlsHandshake: {
          color: '#9C27B0',
          name: 'TLS Handshake',
        },
      },
      disabledCharts__responsetime: [],
    };
  }

  handleClick__responsetime = (dataKey) => {
    if (_.includes(this.state.disabledCharts__responsetime, dataKey)) {
      this.setState({
        disabledCharts__responsetime:
          this.state.disabledCharts__responsetime.filter(
            (obj) => obj !== dataKey
          ),
      });
    } else {
      this.setState({
        disabledCharts__responsetime:
          this.state.disabledCharts__responsetime.concat(dataKey),
      });
    }
  };

  renderCusomizedLegend__responsetime = ({ payload }) => {
    return (
      <div className="customized-legend">
        {payload.map((entry) => {
          const { dataKey, color, name } = entry;
          const active = _.includes(
            this.state.disabledCharts__responsetime,
            dataKey
          );
          /* const style = {
				marginRight: 10,
				color: active ? "#AAA" : "#000"
			}; */

          return (
            <span
              className={`legend-item ${active ? 'inactive' : ''}`}
              onClick={() => this.handleClick__responsetime(dataKey)}
            >
              <Surface width={10} height={10} viewBox="0 0 10 10">
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                {active && (
                  <Symbols
                    cx={5}
                    cy={5}
                    type="circle"
                    size={25}
                    fill={'#FFF'}
                  />
                )}
              </Surface>
              <span>{name}</span>
            </span>
          );
        })}
      </div>
    );
  };

  render() {
    if (this.state.chartType === 'responsetime') {
      return (
        <ResponsiveContainer width={'100%'} height={200}>
          <BarChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#eee"
              vertical={false}
            />
            <XAxis
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              strokeWidth={0}
              domain={[0, 'auto']}
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) => tickItem + ' ms'}
            />
            <Tooltip content={<CustomTooltip__responsetime />} />
            {/* Generate Custom Legend */}
            <Legend
              verticalAlign="bottom"
              height={36}
              align="right"
              payload={_.toPairs(this.state.charts__responsetime).map(
                (pair) => ({
                  dataKey: pair[0],
                  color: pair[1]['color'],
                  name: pair[1]['name'],
                })
              )}
              content={this.renderCusomizedLegend__responsetime}
            />
            {/* Generate Bar */}
            {_.toPairs(this.state.charts__responsetime)
              .filter(
                (pair) =>
                  !_.includes(this.state.disabledCharts__responsetime, pair[0])
              )
              .map((pair) => (
                <Bar
                  type="monotone"
                  key={pair[0]}
                  stackId={pair[0] !== 'total' ? 'a' : 'b'}
                  dataKey={pair[0]}
                  name={pair[1]['name']}
                  fill={pair[1]['color']}
                  strokeWidth={0}
                  dot={false}
                  legendType={'none'}
                />
              ))}
          </BarChart>
        </ResponsiveContainer>
      );
    } else if (this.state.chartType === 'apdex') {
      return (
        <ResponsiveContainer width={'100%'} height={160}>
          <BarChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient
                id="colorUv_apdex"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#0ace66" stopOpacity={1} />
                <stop offset="100%" stopColor="#fe4a49" stopOpacity={0.3} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#eee"
              vertical={false}
            />
            <XAxis
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              strokeWidth={0}
              tick={{ fill: '#afafaf', fontSize: 10 }}
              domain={[0, 1]}
            />
            <Tooltip content={<CustomTooltip__apdex />} />
            {/* <Legend /> */}
            {/* <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2}  dot={false}
					legendType={"none"}  /> */}
            <Bar
              dataKey="value"
              fill="url(#colorUv_apdex)"
              background={{ fill: '#f5f5f5' }}
            />

            {/* <YAxis type="number" stroke="#afafaf"  strokeWidth={0}  domain={[ 0 , 'auto']} tick={{fill: "#afafaf", fontSize: 10}} /> */}
            {/* <Tooltip content={<CustomTooltip__apdex   />}  /> */}
            {/* <Legend /> */}
            {/* <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2}  dot={false} legendType={"none"}  /> */}
            {/* <Line type='monotone' dataKey='value' stroke='url(#colorUv_apdex)' strokeWidth={2} dot={false} activeDot={false} /> */}
          </BarChart>
        </ResponsiveContainer>
      );
    } else if (this.state.chartType === 'downtime') {
      return (
        <ResponsiveContainer width={'100%'} height={160}>
          <BarChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#eee"
              vertical={false}
            />
            <XAxis
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              type="number"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              domain={[0, 1]}
            />
            <Tooltip content={<CustomTooltip__downtime />} />
            {/* <Legend /> */}
            {/* <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2}  dot={false}
					legendType={"none"}  /> */}
            <Bar dataKey="value" fill="#F44336" background={{ fill: '#eee' }} />
          </BarChart>
        </ResponsiveContainer>
      );
    } else if (this.state.chartType === 'simple') {
      return (
        <ResponsiveContainer width={210} height={this.state.chart.height}>
          <BarChart
            data={this.state.data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#eee"
              vertical={false}
            />
            <XAxis
              hide={true}
              dataKey="date"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              tickFormatter={(tickItem) =>
                this.state.XAxisInterval === 'days'
                  ? moment(tickItem).format('MMM DD')
                  : moment(tickItem).format('HH:mm')
              }
            />
            <YAxis
              hide={true}
              type="number"
              stroke="#afafaf"
              tick={{ fill: '#afafaf', fontSize: 10 }}
              domain={[0, 1]}
            />
            {/* <Tooltip content={<CustomTooltip__downtime   />}  /> */}
            {/* <Legend /> */}
            {/* <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2}  dot={false}
					legendType={"none"}  /> */}
            <Bar
              dataKey="value"
              fill={this.state.barColor}
              background={{ fill: '#eee' }}
              barSize={10}
            />
          </BarChart>
        </ResponsiveContainer>
      );
    }
  }
}
