import React, { Component } from 'react';
import './ModalRight.css';

export default class ModalRight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_title:
        typeof this.props.title !== 'undefined' ? this.props.title : '',
      modal_close_btn_showed:
        typeof this.props.hideCloseBtn !== 'undefined' &&
        this.props.hideCloseBtn === true
          ? false
          : true,
      modal_close_btn_value: 'Close',
      hasCloseButton:
        typeof this.props.hasCloseButton !== 'undefined'
          ? this.props.hasCloseButton
          : true,
      fixedWidthWindow:
        typeof this.props.fixedWidthWindow !== 'undefined'
          ? this.props.fixedWidthWindow
          : '',
      isDisplay:
        typeof this.props.isDisplay !== 'undefined' &&
        this.props.isDisplay === true
          ? true
          : false,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(event) {
    return this.props.closeModal(event);
  }

  render() {
    return (
      <div
        className={`modal-right fade ${
          this.props.isDisplay === true ? 'active' : ''
        } ${this.state.fixedWidthWindow}`}
        id="model-right"
      >
        <div className="modal-dialog">
          {this.state.hasCloseButton && (
            <button
              title={this.state.modal_close_btn_value}
              type="button"
              aria-label="Close"
              className="close"
              onClick={this.closeModal}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M6 6L18 18"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
          )}
          <div className="modal-content">
            <div className="modal-header">
              <h2>{this.state.modal_title}</h2>
            </div>
            <div className="modal-body">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
