import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import Switch from 'react-switch';
import { Helmet } from 'react-helmet';
// Tooltip component
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { RechartAreaChart } from './../../layout/Rechart/Rechart';
import queryString from 'query-string';

import { getLocationData, getLocationList } from '../../../utils/helper';
import ReactCountryFlag from 'react-country-flag';
import { getItemStorage } from './../../../utils/storage';
// load Components
import LoadingSpinner from './../../layout/LoadingSpinner/LoadingSpinner';
import Navbar from './../../layout/Navbar/Navbar';
import Modal from './../../layout/Modal/Modal';
import DropDownMenu from './../../layout/DropDownMenu/DropDownMenu';
import GA from './../../../components/Tracking/GoogleAnalytics';
import StatusIndicator from '../../layout/StatusIndicator/StatusIndicator';
import AppContext from './../../../context/app-context';

// include css file
import './MonitorDetails.css';

export default class MonitorDetails extends Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      monitorId: props.match.params.monitorId, // capture from url (/monitors/:monitorId)
      monitorName: '',
      monitorMethod: null,
      monitorUrl: null,
      monitorLocations: [],
      responseTimeChart: {
        content: null,
        hasContent: false,
      },
      httpBreakDownChart: {
        content: null,
        hasContent: false,
      },
      uptimeChart: {
        content: null,
        hasContent: false,
      },
      apdexChart: {
        content: null,
        hasContent: false,
      },
      downtimeChart: {
        content: null,
        hasContent: false,
      },
      averageKeys: {
        responsetime: 0,
        uptime: 0,
        apdex: 0,
        errors: 0,
      },
      errorLogs: {
        content_length: 0,
        content: null,
        offset: 0,
        perPage: 10,
        maxPages: 0,
      },
      outageLogs: {
        content_length: 0,
        content: null,
        offset: 0,
        perPage: 10,
        maxPages: 0,
      },

      loadingChart_responseTime: true,
      loadingChart_httpBreakdown: true,
      loadingChart_uptime: true,
      loadingChart_downtime: true,
      loadingChart_apdex: true,

      loadingErrorsLog: false,
      loadingOutagesLog: false,
      loadingMonitorData: false,
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
      ],
      showModal_deleteMonitor: false,
      modalResponse_status: 'warning',
      modalResponse: '',
      monitorNotFound: false,
      isOpenMenu_filter_pingTimeInterval: false,
      filter_pingTimeInterval_options: [
        {
          name: 'Last 10 minutes',
          handleName: 'last_10_minutes',
          link: false,
          selected: false,
          onClick: () => {
            this.getResults(
              ['responsetime', 'httpbreakdown', 'uptime', 'downtime', 'apdex'],
              1,
              10
            );
            this.selectMenu__filter_pingTimeInterval('last_10_minutes');
          },
        },
        {
          name: 'Last 60 minutes',
          handleName: 'last_60_minutes',
          link: false,
          selected: false,
          onClick: () => {
            this.getResults(
              ['responsetime', 'httpbreakdown', 'uptime', 'downtime', 'apdex'],
              5,
              60
            );
            this.selectMenu__filter_pingTimeInterval('last_60_minutes');
          },
        },
        {
          name: 'Last 3 hours',
          handleName: 'last_3_hours',
          link: false,
          selected: true,
          onClick: () => {
            this.getResults(
              ['responsetime', 'httpbreakdown', 'uptime', 'downtime', 'apdex'],
              5,
              3 * 60
            );
            this.selectMenu__filter_pingTimeInterval('last_3_hours');
          },
        },
        {
          name: 'Last 6 hours',
          handleName: 'last_6_hours',
          link: false,
          selected: false,
          onClick: () => {
            this.getResults(
              ['responsetime', 'httpbreakdown', 'uptime', 'downtime', 'apdex'],
              5,
              6 * 60
            );
            this.selectMenu__filter_pingTimeInterval('last_6_hours');
          },
        },
        {
          name: 'Last 12 hours',
          handleName: 'last_12_hours',
          link: false,
          selected: false,
          onClick: () => {
            this.getResults(
              ['responsetime', 'httpbreakdown', 'uptime', 'downtime', 'apdex'],
              5,
              12 * 60
            );
            this.selectMenu__filter_pingTimeInterval('last_12_hours');
          },
        },
        {
          name: 'Last 24 hours',
          handleName: 'last_24_hours',
          link: false,
          selected: false,
          onClick: () => {
            this.getResults(
              ['responsetime', 'httpbreakdown', 'uptime', 'downtime', 'apdex'],
              5,
              24 * 60
            );
            this.selectMenu__filter_pingTimeInterval('last_24_hours');
          },
        },
        {
          name: 'Last 7 days',
          handleName: 'last_7_days',
          link: false,
          selected: false,
          onClick: () => {
            this.getResults(
              ['responsetime', 'httpbreakdown', 'uptime', 'downtime', 'apdex'],
              60 * 24,
              7 * 24 * 60
            );
            this.selectMenu__filter_pingTimeInterval('last_7_days');
          },
        },
        {
          name: 'Last 30 days',
          handleName: 'last_30_days',
          link: false,
          selected: false,
          onClick: () => {
            this.getResults(
              ['responsetime', 'httpbreakdown', 'uptime', 'downtime', 'apdex'],
              60 * 24,
              30 * 24 * 60
            );
            this.selectMenu__filter_pingTimeInterval('last_30_days');
          },
        },
        {
          name: 'Last 90 days',
          handleName: 'last_90_days',
          link: false,
          selected: false,
          onClick: () => {
            this.getResults(
              ['responsetime', 'httpbreakdown', 'uptime', 'downtime', 'apdex'],
              60 * 24,
              90 * 24 * 60
            );
            this.selectMenu__filter_pingTimeInterval('last_90_days');
          },
        },
      ],
      filter_pingTimeInterval_selectedOption: 'last_3_hours',
      isActiveMonitor: false,
      isDownMonitor: false,
      main_header_menu: [
        {
          name: 'Overview',
          link: `/monitors/${props.match.params.monitorId}/`,
          is_active: true,
        },
        {
          name: 'Outages',
          link: `/monitors/${props.match.params.monitorId}/outages`,
          is_active: false,
        },
        {
          name: 'Results Log',
          link: `/monitors/${props.match.params.monitorId}/results-log`,
          is_active: false,
        },
      ],
      sslReminderDays: 0,
      sslCertificateData: {},
      monitorInfoLoaded: false,
      urlQueryParameters: {},
      showAlert__beforestartmonitoring: false,
      showAlert__beforestartmonitoring_lefttime: 60,
    };
    this.openModal_deleteMonitor = this.openModal_deleteMonitor.bind(this);
    this.closeModal_deleteMonitor = this.closeModal_deleteMonitor.bind(this);
    this.deleteMonitor = this.deleteMonitor.bind(this);

    this.openMenu__filter_pingTimeInterval =
      this.openMenu__filter_pingTimeInterval.bind(this);
    this.closeMenu__filter_pingTimeInterval =
      this.closeMenu__filter_pingTimeInterval.bind(this);
    this.selectMenu__filter_pingTimeInterval =
      this.selectMenu__filter_pingTimeInterval.bind(this);

    this.handleChange_formSwitchActivatedMonitor =
      this.handleChange_formSwitchActivatedMonitor.bind(this);
  }

  /**
   *
   * @param {*} chartTypes
   * @param {*} __delay
   * @param {*} __from
   */
  getResults = async (chartTypes, __delay = 5, __from = null) => {
    if (this._isMounted) {
      if (chartTypes.includes('responsetime'))
        this.setState({ loadingChart_responseTime: true });
      if (chartTypes.includes('httpbreakdown'))
        this.setState({ loadingChart_httpBreakdown: true });
      if (chartTypes.includes('uptime'))
        this.setState({ loadingChart_uptime: true });
      if (chartTypes.includes('downtime'))
        this.setState({ loadingChart_downtime: true });
      if (chartTypes.includes('apdex'))
        this.setState({ loadingChart_apdex: true });
    }

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;
      let from = moment().subtract(24, 'hours').unix(); // default : last 24 hours
      if (__from !== null) {
        if (__from <= 60) from = moment().subtract(__from, 'minutes').unix();
        else if (__from > 60 && __from <= 24 * 60)
          from = moment()
            .subtract(__from / 60, 'hours')
            .unix();
        else
          from = moment()
            .subtract(__from / (24 * 60), 'days')
            .unix();
      }
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}/results`,
          {
            params: {
              from: from,
              delay: __delay,
            },
            headers: {
              Authorization: `Bearer ${token}`, //'JWT token...'
              'X-Odown-Account': accountId,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          const data = res.data;

          if (typeof data !== 'undefined' && data.length > 0) {
            // Responsetime chart
            if (chartTypes.includes('responsetime')) {
              const availableLocations = getLocationList('active');

              // console.log('availableLocations : ' , availableLocations);
              // console.log('availableLocations : data : ' , data);

              const ChartContent__responseTime = (
                <RechartAreaChart
                  chartType="responsetime"
                  data={data.map((item) => {
                    let globalItem = {};
                    globalItem.date = item.date;
                    availableLocations.map((location) => {
                      const found = item.loc_latency.find(
                        (x) => x.region === location.name
                      );

                      globalItem[location.name] =
                        typeof found !== 'undefined' && found !== null
                          ? parseFloat(
                              found.responsetime
                                .toFixed(2)
                                .replace(/[.,]00$/, '')
                            )
                          : 0;
                    });
                    globalItem.total =
                      typeof item.responsetime !== 'undefined' &&
                      item.responsetime !== null
                        ? item.responsetime.toFixed(2).replace(/[.,]00$/, '')
                        : 0;

                    return globalItem;
                  })}
                  XAxisInterval={__delay === 5 ? 'hours' : 'days'}
                  locationConfig={availableLocations}
                />
              );

              this.setState({
                responseTimeChart: {
                  content: ChartContent__responseTime,
                  hasContent: true,
                },
                loadingChart_responseTime: false,
              });
            }
            // Http BreakDown chart
            if (chartTypes.includes('httpbreakdown')) {
              const ChartContent__httpBreakDown = (
                <RechartAreaChart
                  chartType="httpbreakdown"
                  data={data.map((item) => {
                    return {
                      date: item.date,
                      total: parseFloat(item.responsetime)
                        ? parseFloat(
                            item.responsetime.toFixed(2).replace(/[.,]00$/, '')
                          )
                        : 0,
                      contentTransfer:
                        Object.keys(item.latency).length > 0 &&
                        item.latency.contenttransfer !== '' &&
                        item.latency.contenttransfer !== null
                          ? parseFloat(
                              item.latency.contenttransfer
                                .toFixed(2)
                                .replace(/[.,]00$/, '')
                            )
                          : 0,
                      dnsLookup:
                        Object.keys(item.latency).length > 0 &&
                        item.latency.dnslookup !== '' &&
                        item.latency.dnslookup !== null
                          ? parseFloat(
                              item.latency.dnslookup
                                .toFixed(2)
                                .replace(/[.,]00$/, '')
                            )
                          : 0,
                      firstByte:
                        Object.keys(item.latency).length > 0 &&
                        item.latency.firstbyte !== '' &&
                        item.latency.firstbyte !== null
                          ? parseFloat(
                              item.latency.firstbyte
                                .toFixed(2)
                                .replace(/[.,]00$/, '')
                            )
                          : 0,
                      tcpConnection:
                        Object.keys(item.latency).length > 0 &&
                        item.latency.tcpconnection !== '' &&
                        item.latency.tcpconnection !== null
                          ? parseFloat(
                              item.latency.tcpconnection
                                .toFixed(2)
                                .replace(/[.,]00$/, '')
                            )
                          : 0,
                      wait:
                        Object.keys(item.latency).length > 0 &&
                        item.latency.wait !== '' &&
                        item.latency.wait !== null
                          ? parseFloat(
                              item.latency.wait
                                .toFixed(2)
                                .replace(/[.,]00$/, '')
                            )
                          : 0,
                      request:
                        Object.keys(item.latency).length > 0 &&
                        item.latency.request !== '' &&
                        item.latency.request !== null
                          ? parseFloat(
                              item.latency.request
                                .toFixed(2)
                                .replace(/[.,]00$/, '')
                            )
                          : 0,
                      tlsHandshake:
                        Object.keys(item.latency).length > 0 &&
                        item.latency.tlshandshake !== '' &&
                        item.latency.tlshandshake !== null
                          ? parseFloat(
                              item.latency.tlshandshake
                                .toFixed(2)
                                .replace(/[.,]00$/, '')
                            )
                          : 0,
                    };
                  })}
                  XAxisInterval={__delay === 5 ? 'hours' : 'days'}
                />
              );

              this.setState({
                httpBreakDownChart: {
                  content: ChartContent__httpBreakDown,
                  hasContent: true,
                },
                loadingChart_httpBreakdown: false,
              });
            }

            // uptime chart
            if (chartTypes.includes('uptime')) {
              const ChartContent__uptime = (
                <RechartAreaChart
                  chartType="uptime"
                  data={data.map((item) => {
                    return {
                      date: item.date,
                      value: parseFloat(
                        item.uptime.toFixed(2).replace(/[.,]00$/, '')
                      ),
                    };
                  })}
                  XAxisInterval={__delay === 5 ? 'hours' : 'days'}
                />
              );

              this.setState({
                uptimeChart: {
                  content: ChartContent__uptime,
                  hasContent: true,
                },
                loadingChart_uptime: false,
              });
            }

            // downtime chart
            if (chartTypes.includes('downtime')) {
              //<RechartBarChart
              const ChartContent__downtime = (
                <RechartAreaChart
                  chartType="downtime"
                  data={data.map((item) => {
                    return {
                      date: item.date,
                      value: parseInt(item.error),
                    };
                  })}
                  XAxisInterval={__delay === 5 ? 'hours' : 'days'}
                />
              );

              this.setState({
                downtimeChart: {
                  content: ChartContent__downtime,
                  hasContent: true,
                },
                loadingChart_downtime: false,
              });
            }

            // apdex chart
            if (chartTypes.includes('apdex')) {
              //RechartBarChart
              const ChartContent__apdex = (
                <RechartAreaChart
                  chartType="apdex"
                  data={data.map((item) => {
                    return {
                      date: item.date,
                      value: parseFloat(
                        item.apdex.toFixed(2).replace(/[.,]00$/, '')
                      ),
                    };
                  })}
                  XAxisInterval={__delay === 5 ? 'hours' : 'days'}
                />
              );

              this.setState({
                apdexChart: {
                  content: ChartContent__apdex,
                  hasContent: true,
                },
                loadingChart_apdex: false,
              });
            }
          } else {
            if (chartTypes.includes('responsetime'))
              this.setState({
                loadingChart_responseTime: false,
                responsetimeChart: {
                  content: null,
                  hasContent: false,
                },
              });
            if (chartTypes.includes('httpbreakdown'))
              this.setState({
                loadingChart_httpBreakdown: false,
                httpBreakDownChart: {
                  content: null,
                  hasContent: false,
                },
              });
            if (chartTypes.includes('uptime'))
              this.setState({
                loadingChart_uptime: false,
                uptimeChart: {
                  content: null,
                  hasContent: false,
                },
              });
            if (chartTypes.includes('downtime'))
              this.setState({
                loadingChart_downtime: false,
                downtimeChart: {
                  content: null,
                  hasContent: false,
                },
              });
            if (chartTypes.includes('apdex'))
              this.setState({
                loadingChart_apdex: false,
                apdexChart: {
                  content: null,
                  hasContent: false,
                },
              });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            if (chartTypes.includes('responsetime'))
              this.setState({ loadingChart_responseTime: false });
            if (chartTypes.includes('httpbreakdown'))
              this.setState({ loadingChart_httpBreakdown: false });
            if (chartTypes.includes('uptime'))
              this.setState({ loadingChart_uptime: false });
            if (chartTypes.includes('downtime'))
              this.setState({ loadingChart_downtime: false });
            if (chartTypes.includes('apdex'))
              this.setState({ loadingChart_apdex: false });
          }
        });
    } else {
      if (chartTypes.includes('responsetime'))
        this.setState({ loadingChart_responseTime: false });
      if (chartTypes.includes('httpbreakdown'))
        this.setState({ loadingChart_httpBreakdown: false });
      if (chartTypes.includes('uptime'))
        this.setState({ loadingChart_uptime: false });
      if (chartTypes.includes('downtime'))
        this.setState({ loadingChart_downtime: false });
      if (chartTypes.includes('apdex'))
        this.setState({ loadingChart_apdex: false });
    }
  };

  /**
   * GET MONITOR DATA
   */
  getMonitorData = async () => {
    this.setState({
      loadingMonitorData: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`, //'JWT token...'
              'X-Odown-Account': accountId,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          this.state.breadcrumbs_items.push({
            name: data.name.toLowerCase(),
            link: data.id,
            isLink: false,
          });

          this.setState({
            monitorInfoLoaded: true,
            averageKeys: {
              responsetime:
                typeof data.elapsed_time !== 'undefined'
                  ? parseFloat(
                      data.elapsed_time.toFixed(2).replace(/[.,]00$/, '')
                    )
                  : 0,
              uptime:
                typeof data.uptime !== 'undefined'
                  ? parseFloat(data.uptime.toFixed(2).replace(/[.,]00$/, ''))
                  : 0,
              apdex:
                typeof data.apdex !== 'undefined'
                  ? parseFloat(data.apdex.toFixed(2).replace(/[.,]00$/, ''))
                  : 0,
              errors:
                typeof data.downtime !== 'undefined'
                  ? parseFloat(data.downtime.toFixed(2).replace(/[.,]00$/, ''))
                  : 0,
            },
            monitorName: data.name,
            monitorMethod: data.method,
            monitorUrl: data.url,
            monitorLocations: data.locations,
            // breadcrumbs_items: this.state.breadcrumbs_items.push(
            // 	{
            // 		name: data.name.toLowerCase(),
            // 		link: data.id,
            // 		isLink: false
            // 	}),
            loadingMonitorData: false,
            isActiveMonitor: data.is_active,
            isDownMonitor: data.is_down,
            sslReminderDays:
              typeof data.ssl_certificate.validTo !== 'undefined' &&
              data.ssl_certificate.validTo !== null
                ? moment(data.ssl_certificate.validTo).diff(moment(), 'days')
                : 0,
            sslCertificateData: data.ssl_certificate,
          });
        })
        .catch((err) => {
          // console.log('err : ' , err);
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              loadingMonitorData: false,
              monitorNotFound: true,
              breadcrumbs_items: this.state.breadcrumbs_items.push({
                name: 'Monitor Not found',
                link: '#',
                isLink: false,
              }),
            });
          }
        });
    } else {
      this.setState({
        loadingMonitorData: false,
        monitorNotFound: true,
        breadcrumbs_items: this.state.breadcrumbs_items.push({
          name: 'Monitor Not found',
          link: '#',
          isLink: false,
        }),
      });
    }
  };

  /**
   *
   * @param {*} event
   */
  openModal_deleteMonitor(event) {
    this.setState({
      showModal_deleteMonitor: true,
    });
  }

  /**
   *
   * @param {*} event
   */
  closeModal_deleteMonitor(event) {
    this.setState({
      showModal_deleteMonitor: !this.state.showModal_deleteMonitor,
      modalResponse: '',
    });
  }

  /**
   *
   *
   * @param {*} event
   * @memberof EditMonitorForm
   */
  deleteMonitor = async (event) => {
    this.setState({ isLoading: true });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}`,
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            // const selectedAlertChannelId = this.state.alertChannelId;
            // ? Google Analytics Event : Delete Monitor Item
            GA.GAevent('Monitors', 'Delete Monitor');

            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse: 'Monitor removed successfully',
              modalResponse_status: 'success',
              showModal_deleteMonitor: false,
            });
          } else {
            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse: 'Something went wrong removing your monitor',
              modalResponse_status: 'danger',
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              modalResponse: 'Something went wrong removing your monitor',
              modalResponse_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        modalResponse: 'You are not authorized to do this operation',
        modalResponse_status: 'danger',
      });
      return;
    }
  };

  /**
   *
   * @param {*} event
   */
  openMenu__filter_pingTimeInterval(event) {
    this.setState(
      {
        isOpenMenu_filter_pingTimeInterval: true,
      },
      () => {
        document.addEventListener(
          'click',
          this.closeMenu__filter_pingTimeInterval
        );
      }
    );
  }

  /**
   *
   * @param {*} event
   */
  closeMenu__filter_pingTimeInterval(event) {
    this.setState(
      {
        isOpenMenu_filter_pingTimeInterval: false,
      },
      () => {
        document.removeEventListener(
          'click',
          this.closeMenu__filter_pingTimeInterval
        );
      }
    );
  }

  /**
   *
   * @param {*} __handleName
   */
  selectMenu__filter_pingTimeInterval(__handleName) {
    // const filter_pingTimeInterval_options = this.state.filter_pingTimeInterval_options;
    // const index = filter_pingTimeInterval_options.findIndex(x => x.handleName === __handleName);
    // filter_pingTimeInterval_options[index].selected = true;

    this.setState({
      filter_pingTimeInterval_selectedOption: __handleName,
    });
  }

  /**
   *
   * @param {*} checked
   * @param {*} event
   * @param {*} id
   */
  handleChange_formSwitchActivatedMonitor = async (checked, event, id) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}`,
          {
            is_active: checked,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          // stop loading effect
          this.setState({
            isLoading: false,
            form_response: 'Your monitor has been successfully updated',
            form_response_status: 'success',
            isActiveMonitor: checked,
          });
          //console.log(res);
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              form_response:
                'an error occurred while creating a new monitor, please try againon',
              form_response_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  timingBeforeStartMonitoring = async () => {
    // return new Promise( async(resolve , reject) => {

    // Get URL query parameters :
    // Check if the parameter "f", and if it's true so we publish a message to inform the user that the ping will start in the next 60 seconds
    let params = queryString.parse(this.props.location.search);

    let timeBeforeMonitoring = setInterval(async () => {
      // console.log('check >>> ' , this.state.showAlert__beforestartmonitoring_lefttime);
      this.setState({
        showAlert__beforestartmonitoring_lefttime:
          this.state.showAlert__beforestartmonitoring_lefttime - 1,
      });
      if (this.state.showAlert__beforestartmonitoring_lefttime <= 0) {
        clearInterval(timeBeforeMonitoring);
        this.setState({
          showAlert__beforestartmonitoring: false,
        });
        // ? restart the data loading ...
        // Retrive general monitor data
        await this.getMonitorData();
        // Retreive responsetime and uptime by 5 mimute interval
        await this.getResults(
          ['responsetime', 'httpbreakdown', 'uptime', 'downtime', 'apdex'],
          5,
          3 * 60
        );
      }
    }, 1000);

    this.setState({
      showAlert__beforestartmonitoring:
        typeof params !== 'undefined' &&
        typeof params.f !== 'undefined' &&
        params.f === 'true'
          ? true
          : false,
    });
    // })
  };

  /**
   *
   */
  async componentDidMount() {
    this._isMounted = true;

    await this.timingBeforeStartMonitoring();

    // Retrive general monitor data
    await this.getMonitorData();
    // Retreive responsetime and uptime by 5 mimute interval
    await this.getResults(
      ['responsetime', 'httpbreakdown', 'uptime', 'downtime', 'apdex'],
      5,
      3 * 60
    );

    // console.log('componentDidMount > breadcrumbs_items : ' , this.state.breadcrumbs_items)
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    const {
      loadingMonitorData,
      loadingChart_responseTime,
      loadingChart_httpBreakdown,
      loadingChart_uptime,
      loadingChart_downtime,
      loadingChart_apdex,
      sslReminderDays,
    } = this.state;

    if (this.state.modalResponse_status === 'success') {
      return <Redirect to="/" />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Monitor details | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__monitor--details layout__blockInner">
              {this.state.monitorNotFound === true ? (
                <div className="layout__notFound white-card">
                  <h2>Not Found</h2>
                  <p>
                    Unfortunately, this monitor does not exist in the current
                    account.
                  </p>
                </div>
              ) : (
                <React.Fragment>
                  {this.state.showAlert__beforestartmonitoring === true && (
                    <div className="alert white-card">
                      <span>
                        The monitoring will start in the next{' '}
                        {this.state.showAlert__beforestartmonitoring_lefttime}{' '}
                        seconds, please wait...
                      </span>
                    </div>
                  )}
                  <div className="layout__header white-card padding-bottom-none">
                    {loadingMonitorData ? (
                      <div className="loading-wrapper">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <>
                        <div className="monitor__info limited-text">
                          <div className="monitor__name">
                            <StatusIndicator
                              status={
                                this.state.isActiveMonitor === false
                                  ? ''
                                  : this.state.isDownMonitor === true
                                  ? 'danger'
                                  : 'success'
                              }
                              title={
                                this.state.isActiveMonitor === false
                                  ? 'Inactive Monitor'
                                  : this.state.isDownMonitor === true
                                  ? 'Failure Monitor'
                                  : 'Active Monitor'
                              }
                              size="xsmall"
                            />
                            <h2 className="limited-text">
                              {this.state.monitorName.toLowerCase()}
                            </h2>
                            <div className="monitor__runlocations">
                              {this.state.monitorLocations
                                ? this.state.monitorLocations.map(
                                    (item, index) => {
                                      const locationInfo =
                                        getLocationData(item);
                                      if (typeof locationInfo !== 'undefined')
                                        return (
                                          <Tooltip
                                            key={index}
                                            placement="bottom"
                                            trigger={['hover']}
                                            overlay={
                                              <span>{`${locationInfo.location} ( ${locationInfo.id} ) - ${locationInfo.countryName}`}</span>
                                            }
                                          >
                                            <ReactCountryFlag
                                              key={index}
                                              countryCode={
                                                locationInfo.countryCode
                                              }
                                              svg
                                              title={locationInfo.countryName}
                                              aria-label={
                                                locationInfo.countryName
                                              }
                                            />
                                          </Tooltip>
                                        );
                                    }
                                  )
                                : ''}
                            </div>
                          </div>
                          <div className="monitor__request">
                            <div className="request_method">
                              {this.state.monitorMethod}
                            </div>
                            <div className="request_url">
                              {this.state.monitorUrl}
                            </div>
                          </div>
                        </div>
                        <div className="monitor__btnAction">
                          <div className="btn-switch">
                            <Switch
                              onChange={
                                this.handleChange_formSwitchActivatedMonitor
                              }
                              checked={this.state.isActiveMonitor}
                              onColor="#1f80f9"
                              // onHandleColor="#2693e6"
                              handleDiameter={18}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              width={48}
                              height={22}
                              className="react-switch"
                              id={'switch-activated-monitor'}
                            />
                            <label>Activated</label>
                          </div>
                          <div
                            className="btn btn-danger"
                            onClick={this.openModal_deleteMonitor}
                          >
                            Delete
                          </div>
                          <div className="btn btn-primary has-link">
                            <Link to={`/monitors/${this.state.monitorId}/edit`}>
                              Edit
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                    {
                      // Show here the main menu of Monitor details ( Overview , ...)
                      typeof this.state.main_header_menu !== 'undefined' &&
                        this.state.main_header_menu !== null &&
                        this.state.main_header_menu.length > 0 && (
                          <div className="layout-header__bottom_menu">
                            <ul>
                              {this.state.main_header_menu.map((x, index) => (
                                <li
                                  key={index}
                                  className={
                                    x.is_active === true ? 'active' : ''
                                  }
                                >
                                  <Link to={x.link}>{x.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )
                    }
                  </div>
                  <div className="layout__keys--data">
                    <ul>
                      <li>
                        <div className="li__wrapper">
                          <div className="label">Response Time</div>
                          <div className="span">
                            {this.state.averageKeys.responsetime
                              .toFixed(2)
                              .replace(/[.,]00$/, '')
                              .replace(/[.,]00$/, '')}{' '}
                            ms
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="li__wrapper">
                          <div className="label">Uptime</div>
                          <div className="span">
                            {this.state.averageKeys.uptime} %
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="li__wrapper">
                          <div className="label">Apdex</div>
                          <div className="span">
                            {this.state.averageKeys.apdex}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="li__wrapper">
                          <div className="label">Downtimes</div>
                          <div className="span">
                            {this.state.averageKeys.errors}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div
                          className={`li__wrapper check-ssl-stats ${
                            this.state.monitorInfoLoaded === false
                              ? 'check-stats-ssl-box-loading'
                              : sslReminderDays <= 0
                              ? 'check-stats-ssl-box-danger'
                              : sslReminderDays > 0 && sslReminderDays <= 20
                              ? 'check-stats-ssl-box-warning'
                              : 'check-stats-ssl-box-success'
                          }`}
                        >
                          {this.state.monitorInfoLoaded === false ? (
                            <div className="loading-wrapper">
                              <LoadingSpinner size="small" />
                            </div>
                          ) : (
                            <>
                              <div className="label">SSL days remaining</div>
                              <div className="icon float-right">
                                <svg
                                  version="1.1"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 16 16"
                                  className="octicon octicon-lock"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4 4v2h-.25A1.75 1.75 0 002 7.75v5.5c0 .966.784 1.75 1.75 1.75h8.5A1.75 1.75 0 0014 13.25v-5.5A1.75 1.75 0 0012.25 6H12V4a4 4 0 10-8 0zm6.5 2V4a2.5 2.5 0 00-5 0v2h5zM12 7.5h.25a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25H12z"
                                  ></path>
                                </svg>
                              </div>
                              {typeof sslReminderDays !== 'undefined' &&
                                sslReminderDays !== null &&
                                sslReminderDays > 0 &&
                                typeof Object.keys(
                                  this.state.sslCertificateData
                                ) !== 'undefined' &&
                                Object.keys(this.state.sslCertificateData) !==
                                  null &&
                                Object.keys(this.state.sslCertificateData)
                                  .length > 0 && (
                                  <Tooltip
                                    placement="left"
                                    trigger={['hover']}
                                    overlay={
                                      <div className="ssl-certificate-info-wrapper">
                                        <ul>
                                          <li>
                                            <label>Subject</label>
                                            <span>
                                              {
                                                this.state.sslCertificateData
                                                  .subject
                                              }
                                            </span>
                                          </li>
                                          <li>
                                            <label>Issuer</label>
                                            <span>{`${
                                              this.state.sslCertificateData
                                                .issuer.CN !== ''
                                                ? this.state.sslCertificateData
                                                    .issuer.CN + ' '
                                                : ''
                                            }${
                                              this.state.sslCertificateData
                                                .issuer.O !== ''
                                                ? `(${this.state.sslCertificateData.issuer.O})`
                                                : ''
                                            }`}</span>
                                          </li>
                                          <li>
                                            <label>Valid from</label>
                                            <span>
                                              {this.state.sslCertificateData
                                                .validFrom !== ''
                                                ? moment(
                                                    this.state
                                                      .sslCertificateData
                                                      .validFrom
                                                  ).format(
                                                    'MMM DD, YYYY hh:mm A'
                                                  )
                                                : ''}
                                            </span>
                                          </li>
                                          <li>
                                            <label>Valid to</label>
                                            <span>
                                              {this.state.sslCertificateData
                                                .validTo !== ''
                                                ? moment(
                                                    this.state
                                                      .sslCertificateData
                                                      .validTo
                                                  ).format(
                                                    'MMM DD, YYYY hh:mm A'
                                                  )
                                                : ''}
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    }
                                  >
                                    <span className="readmore">
                                      Read more!{' '}
                                    </span>
                                  </Tooltip>
                                )}
                              <div className="span">{sslReminderDays}</div>
                            </>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="details__card--wrapper">
                    <div className="details__card filters">
                      <div className="filter-item">
                        <div
                          className="btn btn-white dropdown"
                          onClick={this.openMenu__filter_pingTimeInterval}
                        >
                          {
                            this.state.filter_pingTimeInterval_options.find(
                              (x) =>
                                x.handleName ===
                                this.state
                                  .filter_pingTimeInterval_selectedOption
                            ).name
                          }
                          {this.state.isOpenMenu_filter_pingTimeInterval && (
                            <DropDownMenu
                              width="small-width"
                              items={this.state.filter_pingTimeInterval_options}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="details__card insightMonitor__responseTime">
                      <div className="details__card-wrapper">
                        <h2>
                          <span>Response Time</span>
                          <i> over all regions</i>
                        </h2>
                        {loadingChart_responseTime ? (
                          <div className="loading-wrapper">
                            <LoadingSpinner />
                          </div>
                        ) : this.state.responseTimeChart.hasContent ? (
                          this.state.responseTimeChart.content
                        ) : (
                          <div className="recharts-responsive-container no-data">
                            <div className="no-data-alert">No data found</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="details__card insightMonitor__responseTime">
                      <div className="details__card-wrapper">
                        <h2>
                          <span>HTTP breakdown</span>
                          <i> over all regions</i>
                        </h2>
                        {loadingChart_httpBreakdown ? (
                          <div className="loading-wrapper">
                            <LoadingSpinner />
                          </div>
                        ) : this.state.httpBreakDownChart.hasContent ? (
                          this.state.httpBreakDownChart.content
                        ) : (
                          <div className="recharts-responsive-container no-data">
                            <div className="no-data-alert">No data found</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="details__card insightMonitor__uptime">
                      <div className="details__card-wrapper">
                        <h2>
                          <span>Uptime</span>
                        </h2>
                        {loadingChart_uptime ? (
                          <div className="loading-wrapper">
                            <LoadingSpinner />
                          </div>
                        ) : this.state.uptimeChart.hasContent ? (
                          this.state.uptimeChart.content
                        ) : (
                          <div className="recharts-responsive-container no-data">
                            <div className="no-data-alert">No data found</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="details__card insightMonitor__downtime">
                      <div className="details__card-wrapper">
                        <h2>
                          <span>Downtime</span>
                        </h2>
                        {loadingChart_downtime ? (
                          <div className="loading-wrapper">
                            <LoadingSpinner />
                          </div>
                        ) : this.state.downtimeChart.hasContent ? (
                          this.state.downtimeChart.content
                        ) : (
                          <div className="recharts-responsive-container no-data">
                            <div className="no-data-alert">No data found</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="details__card insightMonitor__apdex">
                      <div className="details__card-wrapper">
                        <h2>
                          <span>Apdex</span>
                        </h2>
                        {loadingChart_apdex ? (
                          <div className="loading-wrapper">
                            <LoadingSpinner />
                          </div>
                        ) : this.state.apdexChart.hasContent ? (
                          this.state.apdexChart.content
                        ) : (
                          <div className="recharts-responsive-container no-data">
                            <div className="no-data-alert">No data found</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        {this.state.showModal_deleteMonitor ? (
          <Modal
            title="Delete this monitor?"
            closeModal={this.closeModal_deleteMonitor}
            fixedWidthWindow="small-window"
          >
            <div>
              Deleting this check will erase all historical data like check
              results and alerts.
            </div>
            <div className="d-flex justify-content-flex-end margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_deleteMonitor}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger float-right margin-left-10"
                onClick={this.deleteMonitor}
              >
                {this.state.isLoading === true ? 'Deleting...' : 'Delete'}
              </button>
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </Modal>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}
