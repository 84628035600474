import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Helmet } from 'react-helmet';
// Tooltip component
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import { getItemStorage } from '../../../utils/storage';
// load Components
import Navbar from '../../layout/Navbar/Navbar';
import LoadingSpinner from './../../layout/LoadingSpinner/LoadingSpinner';
import StatusIndicator from '../../layout/StatusIndicator/StatusIndicator';
import AppContext from '../../../context/app-context';

import { getDefaultValidAccountId } from './../../authentification/SecurityController';

import './Monitor.css';
import './../../layout/Pagination/Pagination.css';

// Import icons
import icon_no_monitors from './../../../assets/icons/cactus.svg';
import { ReactComponent as IconSecureSSL } from './../../../assets/icons/padlock.svg';
import { ReactComponent as IconUnsecureSSL } from './../../../assets/icons/padlock-unlock.svg';

/**
 *
 */
export class CustomBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      defaultValues: props.defaultValues,
      isActive: props.isActive,
      isDown: props.isDown,
      maxDefaultHeight: 2400,
      maxHeight: 0,
      monitorsStats: [],
    };

    this.calculMaxValues = this.calculMaxValues.bind(this);
  }

  /**
   *
   */
  calculMaxValues = () => {
    if (typeof this.state.data !== 'undefined' && this.state.data !== null) {
      let maxValues = 0;
      this.state.data.map((item) => {
        maxValues =
          Math.floor(item.responseTime) > maxValues
            ? Math.floor(item.responseTime)
            : maxValues;
      });

      // Update the max value in state
      this.setState({
        maxHeight: maxValues,
      });
    }
  };

  componentDidMount() {
    this.calculMaxValues();
  }

  render() {
    return (
      <div className={`status-bar-graph`}>
        {typeof this.state.data !== 'undefined' && this.state.data !== null ? (
          <ul>
            {this.state.data.map((pi, index) => (
              <li
                key={index}
                className={`status-bar-graph-item 
									${this.state.isActive === true ? '' : 'deactivated'} 
									${
                    pi.ping === false
                      ? 'no-ping'
                      : pi.uptime <= 0
                      ? 'fail'
                      : pi.uptime > 0 && pi.uptime <= 90
                      ? 'warning'
                      : 'success'
                  }
									`}
                style={{
                  height:
                    Math.floor(
                      parseFloat(pi.responseTime / this.state.maxHeight) * 100
                    ) + '%',
                }}
              ></li>
            ))}
          </ul>
        ) : (
          <ul>
            {this.state.defaultValues.map((pi, index) => (
              <li
                key={index}
                className={`status-bar-graph-item ${
                  this.state.isActive === true ? '' : 'deactivated'
                } ${this.state.isDown === true ? 'fail' : 'success'}`}
                style={{
                  height:
                    Math.floor(
                      parseFloat(pi.value / this.state.maxDefaultHeight) * 100
                    ) + '%',
                }}
              ></li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default class Monitors extends React.Component {
  static contextType = AppContext;

  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      // data: [],
      perPage: 10,
      currentPage: 0,
      pageCount: 0,
      // monitors: [],
      accountId: null,
      isLoading: false,
      monitorsList: [],
      monitorsList_error: null,
      default24hoursPings: [],
      isLoading_monitorsStats: false,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  receivedData = async () => {
    this.setState({
      isLoading: true,
    });
    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    if (obj && obj.token) {
      const token = obj.token;

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${this.state.accountId}/monitors`,
          {
            params: {
              page: this.state.offset,
              per_page: this.state.perPage,
              // w_data : true
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          const monitorIds = [...new Set(data.data.map((item) => item.id))];

          //console.log('monitorIds ; ' , monitorIds)

          this.getMonitorsResultsInfo(monitorIds);

          var defaultData = [];
          for (let index = 23; index >= 0; index--) {
            defaultData.push({
              date: moment()
                .subtract(index, 'hours')
                .format('YYYY-MM-DDTHH:00:00.000Z'),
              value: 0,
            });
          }

          this.setState({
            monitorsList: data.data,
            default24hoursPings: defaultData,
            pageCount: Math.ceil(data.total / this.state.perPage),
            isLoading: false,
          });
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              isLoading: false,
              monitorsList_error:
                typeof error.response.data.error.message !== 'undefined'
                  ? error.response.data.error.message
                  : error.response.data.message,
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            if (
              typeof error.message === 'undefined' ||
              error.message === null ||
              error.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.setState({
                isLoading: false,
                monitorsList_error:
                  'Something went wrong, please try again later.',
              });
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  getMonitorsResultsInfo = async (monitorsList) => {
    this.setState({
      isLoading_monitorsStats: true,
    });

    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    if (
      obj &&
      obj.token &&
      typeof monitorsList !== 'undefined' &&
      monitorsList !== null &&
      monitorsList.length > 0
    ) {
      const accountId = await getItemStorage(
        `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
        'string'
      );

      const token = obj.token;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts/${this.state.accountId}/results-info`,
          {
            monitors: monitorsList,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            monitorsStats: data,
            isLoading_monitorsStats: false,
          });
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              isLoading_monitorsStats: false,
              // monitorsList_error: (typeof error.response.data.error.message !=='undefined') ? error.response.data.error.message : error.response.data.message,
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            if (
              typeof error.message === 'undefined' ||
              error.message === null ||
              error.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.setState({
                isLoading_monitorsStats: false,
                // monitorsList_error: 'Something went wrong, please try again later.',
              });
            }
          }
        });
    }
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData(offset);
      }
    );
  };

  sortDataByDate = (items) =>
    [...items].sort(
      (itemA, itemB) =>
        new Date(itemA.selectedDate) - new Date(itemB.selectedDate)
    );

  async componentDidMount() {
    const targetAccountId = await getDefaultValidAccountId(
      this.context.connectedUserData
    );
    if (typeof targetAccountId !== 'undefined') {
      this.setState(
        {
          accountId: targetAccountId,
        },
        async () => {
          this.receivedData();
        }
      );
    }
    //console.log('Monitors > componentDidMount > targetAccountId : ' , targetAccountId)
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
    //console.log('............. Unmount monitors component');
  }
  render() {
    const breadcrumbs_items = [
      {
        name: 'Home',
        link: '/',
        isLink: true,
      },
      {
        name: 'Monitors',
        link: '/',
        isLink: false,
      },
    ];

    const { isLoading, isLoading_monitorsStats } = this.state;

    //console.log(this.context);

    return (
      <React.Fragment>
        <Helmet>
          <title>Monitors | Odown</title>
          <meta
            name="description"
            content="Be the first to know about your website issues before your customers do with real-time website monitoring combined with instant alerts via email, Slack, Webhooks or SMS."
          />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://app.odown.io" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Monitors | Odown" />
          <meta
            property="og:description"
            content="Be the first to know about your website issues before your customers do with real-time website monitoring combined with instant alerts via email, Slack, Webhooks or SMS."
          />
          <meta
            property="og:image"
            content="https://app.odown.io/general-image-og--home-page.jpg"
          />

          {/* <!-- Twitter Meta Tags -- /> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="app.odown.io" />
          <meta property="twitter:url" content="https://app.odown.io" />
          <meta name="twitter:title" content="Monitors | Odown" />
          <meta
            name="twitter:description"
            content="Be the first to know about your website issues before your customers do with real-time website monitoring combined with instant alerts via email, Slack, Webhooks or SMS."
          />
          <meta
            name="twitter:image"
            content="https://app.odown.io/general-image-og--home-page.jpg"
          />
        </Helmet>
        <Navbar breadcrumbs_items={breadcrumbs_items} />
        <div className="layout__content--wrapper">
          <div className="container">
            <div className="layout__monitors layout__blockInner">
              <table
                className={`check__items ${
                  this.state.monitorsList.length <= 0 ? 'empty' : ''
                }`}
              >
                <thead
                  className={
                    typeof this.state.monitorsList !== 'undefined' &&
                    this.state.monitorsList !== null &&
                    this.state.monitorsList.length <= 0
                      ? 'hide'
                      : ''
                  }
                >
                  <tr>
                    <th align="center"></th>
                    <th align="left">Name</th>
                    <th align="center">
                      <Tooltip
                        placement="top"
                        trigger={['hover']}
                        overlay={
                          <span>
                            The average uptime over the last 24 hours,
                            calculated over all active regions.
                          </span>
                        }
                      >
                        <span className="dash-table-tooltip">Uptime</span>
                      </Tooltip>
                    </th>
                    <th align="center">Apdex</th>
                    <th align="center">
                      <Tooltip
                        placement="top"
                        trigger={['hover']}
                        overlay={
                          <span>
                            The average response time over the last 24 hours,
                            calculated over all active regions.
                          </span>
                        }
                      >
                        <span className="dash-table-tooltip">
                          Response Time
                        </span>
                      </Tooltip>
                    </th>
                    <th align="center">
                      <Tooltip
                        placement="top"
                        trigger={['hover']}
                        overlay={
                          <span>
                            The downtime alerts over the last 24 hours and over
                            all active regions.
                          </span>
                        }
                      >
                        <span className="dash-table-tooltip">Downtimes</span>
                      </Tooltip>
                    </th>
                    <th align="center">
                      <Tooltip
                        placement="top"
                        trigger={['hover']}
                        overlay={<span>Showing the last 24 hours results</span>}
                      >
                        <span className="dash-table-tooltip">Last Results</span>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="7">
                        <div className="loading-wrapper">
                          <LoadingSpinner />
                        </div>
                      </td>
                    </tr>
                  ) : this.state.monitorsList_error !== null ? (
                    <tr>
                      <td align="center" colSpan="7">
                        <div className="alert alert-danger text-center">
                          {this.state.monitorsList_error}
                        </div>
                      </td>
                    </tr>
                  ) : typeof this.state.monitorsList !== 'undefined' &&
                    this.state.monitorsList !== null &&
                    this.state.monitorsList.length <= 0 ? (
                    <tr>
                      <td align="center" colSpan="7">
                        <div className="home-empty-state">
                          <img
                            alt="No monitors"
                            src={icon_no_monitors}
                            width="100px"
                          />
                          <h3>There is No Monitors</h3>
                          <p className="text-muted">
                            Add your first monitor, this will be your dashboard.
                          </p>
                          <Link className="" to={{ pathname: `/monitors/new` }}>
                            <div className="btn btn-primary">
                              <svg
                                version="1.1"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                className="octicon octicon-plus"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"
                                ></path>
                              </svg>
                              <span>New Monitor</span>
                            </div>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    this.state.monitorsList.map((item, index) => {
                      // Get Measures + 24h pings list
                      const monitorsStats =
                        typeof this.state.monitorsStats !== 'undefined' &&
                        this.state.monitorsStats !== null
                          ? this.state.monitorsStats.find(
                              (x) => x.id === item.id
                            )
                          : undefined;
                      //console.log('selected monitorsStats : ' , item.id , monitorsStats)

                      let tmp__defaultValues = [];
                      for (let index = 23; index >= 0; index--) {
                        tmp__defaultValues.push({
                          date: moment()
                            .subtract(index, 'hours')
                            .format('YYYY-MM-DDTHH:00:00.000Z'),
                          value: Math.floor(Math.random() * 2400) + 150,
                        });
                      }

                      // item.data represent the results of the last 24h
                      item.data = []; //(item.data.length > 0) ? this.sortDataByDate(item.data) : item.data;
                      const cleanURL = item.url.replace(
                        /^(?:https?:\/\/)?(?:www\.)?/i,
                        ''
                      );
                      const sslReminderDays =
                        typeof item.ssl_certificate.validTo !== 'undefined' &&
                        item.ssl_certificate.validTo !== null
                          ? moment(item.ssl_certificate.validTo).diff(
                              moment(),
                              'days'
                            )
                          : 0;
                      return (
                        <tr
                          className={`check__item ${
                            item.is_active === false ? 'deactivated' : ''
                          }`}
                          key={index}
                        >
                          <td align="right">
                            {/* <div className={`status-circle ${item.is_active === false ? 'deactivated' : (item.is_down === true ? `fail` : `success`) } medium`}>
																{item.is_down === true ?
																	<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path d="m5.78571429 4.83443522-4.75706811-4.75706811-.95127907.95127907 4.75706811 4.75706811-4.75706811 4.75706811.95127907.9512791 4.75706811-4.75706814 4.75706811 4.75706814.9512791-.9512791-4.75706814-4.75706811 4.75706814-4.75706811-.9512791-.95127907z" fill="#fff" transform="translate(9.428571 9.428571)"></path></svg>
																	:
																	<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path d="m0 5.14285714 1.28571429-1.28571428 3.21428571 3.21428571 7.0714286-7.07142857 1.2857143 1.28571429-8.3571429 8.35714285z" fill="#fff" transform="translate(8.571429 10.285714)"></path></svg>
																}
															</div> */}
                            <StatusIndicator
                              status={
                                item.is_active === false
                                  ? ''
                                  : item.is_down === true
                                  ? 'danger'
                                  : 'success'
                              }
                              title={
                                item.is_active === false
                                  ? 'Inactive Monitor'
                                  : item.is_down === true
                                  ? 'Failure Monitor'
                                  : 'Active Monitor'
                              }
                            />
                          </td>
                          <td align="left" className="check__info limited-text">
                            <div className="chk_details-content">
                              <Link
                                className="chk_details-name"
                                to={{ pathname: `/monitors/${item.id}` }}
                              >
                                {item.name !== null
                                  ? item.name.toLowerCase()
                                  : 'Untitled Monitor'}
                              </Link>
                              <Link
                                className="chk_details-date"
                                title={moment(item.created_at).format(
                                  'dddd, MMMM Do YYYY, h:mm A'
                                )}
                                to={{ pathname: `/monitors/${item.id}` }}
                              >
                                {moment(item.created_at).fromNow()}
                              </Link>
                              <Link
                                className="chk_details-url"
                                to={{ pathname: `/monitors/${item.id}` }}
                              >
                                <span
                                  className={`chk-d__ssl-status ${
                                    sslReminderDays > 0
                                      ? 'secure-ssl'
                                      : 'unsecure-ssl'
                                  }`}
                                >
                                  {sslReminderDays > 0 ? (
                                    <IconSecureSSL />
                                  ) : (
                                    <IconUnsecureSSL />
                                  )}
                                </span>
                                <div className="chk-d__full-url">
                                  {cleanURL.length > 20
                                    ? cleanURL.substr(0, 20) + '..'
                                    : cleanURL}
                                </div>
                              </Link>
                            </div>
                          </td>
                          <td align="center" className="check__globalInsight">
                            <Link
                              className="ghost-link"
                              to={{ pathname: `/monitors/${item.id}` }}
                            >
                              <span className="globalInsight--label">
                                Uptime
                              </span>
                              <span className="globalInsight--num">
                                {typeof monitorsStats !== 'undefined' &&
                                typeof monitorsStats.measures.uptime !==
                                  'undefined' &&
                                monitorsStats.measures.uptime > 0
                                  ? monitorsStats.measures.uptime
                                      .toFixed(2)
                                      .replace(/[.,]00$/, '')
                                  : 0}{' '}
                                %
                              </span>
                            </Link>
                          </td>
                          <td align="center" className="check__globalInsight">
                            <Link
                              className="ghost-link"
                              to={{ pathname: `/monitors/${item.id}` }}
                            >
                              <span className="globalInsight--label">
                                Apdex
                              </span>
                              <span className="globalInsight--num">
                                {typeof monitorsStats !== 'undefined' &&
                                typeof monitorsStats.measures.apdex !==
                                  'undefined' &&
                                monitorsStats.measures.apdex > 0
                                  ? monitorsStats.measures.apdex
                                      .toFixed(2)
                                      .replace(/[.,]00$/, '')
                                  : 0}
                              </span>
                            </Link>
                          </td>
                          <td align="center" className="check__globalInsight">
                            <Link
                              className="ghost-link"
                              to={{ pathname: `/monitors/${item.id}` }}
                            >
                              <span className="globalInsight--label">
                                ResponseTime
                              </span>
                              <span className="globalInsight--num">
                                {typeof monitorsStats !== 'undefined' &&
                                typeof monitorsStats.measures.elapsed_time !==
                                  'undefined' &&
                                monitorsStats.measures.elapsed_time > 0
                                  ? monitorsStats.measures.elapsed_time
                                      .toFixed(2)
                                      .replace(/[.,]00$/, '')
                                  : 0}{' '}
                                ms
                              </span>
                            </Link>
                          </td>
                          <td align="center" className="check__globalInsight">
                            <Link
                              className="ghost-link"
                              to={{ pathname: `/monitors/${item.id}` }}
                            >
                              <span className="globalInsight--label">
                                Downtimes
                              </span>
                              <span
                                className={`globalInsight--num text-bold ${
                                  typeof monitorsStats !== 'undefined' &&
                                  monitorsStats.measures.downtime > 0
                                    ? 'text-red'
                                    : 'text-green'
                                }`}
                              >
                                {typeof monitorsStats !== 'undefined' &&
                                typeof monitorsStats.measures.downtime !==
                                  'undefined'
                                  ? monitorsStats.measures.downtime
                                  : 0}
                              </span>
                            </Link>
                          </td>
                          <td align="center" className="check__graph">
                            {isLoading_monitorsStats ? (
                              <div className="loading-wrapper">
                                <LoadingSpinner size="small" />
                              </div>
                            ) : (
                              <>
                                <Link
                                  className="ghost-link"
                                  to={{ pathname: `/monitors/${item.id}` }}
                                >
                                  <CustomBarChart
                                    defaultValues={
                                      this.state.default24hoursPings
                                    }
                                    data={
                                      typeof monitorsStats !== 'undefined' &&
                                      monitorsStats.pings.length > 0
                                        ? monitorsStats.pings
                                        : null
                                    }
                                    // defaultValues={tmp__defaultValues}
                                    // data={null}
                                    isActive={item.is_active}
                                    isDown={item.is_down}
                                  />
                                </Link>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              {this.state.pageCount > 0 && (
                <ReactPaginate
                  previousLabel={''}
                  nextLabel={''}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
