import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';
import { getItemStorage, removeItemStorage } from '../../../utils/storage';
import Navbar from '../../layout/Navbar/Navbar';
import NavbarSettings from './NavbarSettings';
import { Helmet } from 'react-helmet';
import Modal from './../../layout/Modal/Modal';
// Tooltip component
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
// load stripe components
import { CardElement } from '@stripe/react-stripe-js';

// Invoke the global state
import AppContext from './../../../context/app-context';

import GA from './../../../components/Tracking/GoogleAnalytics';
// import css
import './Billing.css';

// import icons
import icon_unauthorized_downgrade from './../../../assets/icons/unauthorized-downgrade.svg';
import { ReactComponent as IconInfo } from '../../../assets/icons/info.svg';
import ModalRight from '../../layout/Modal/ModalRight';
import { countries, taxabilityReasons } from '../../../utils/helper';
import { ReactComponent as IconInfoGray } from './../../../assets/icons/info-gray.svg';
import { ReactComponent as IconLuSetting } from './../../../assets/icons/lu_settings.svg';

export default class BillingSettings extends React.Component {
  // permission to use the global state
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoadingAccountData: false,
      formIsSubmited: false,
      form_response_status: 'warning',
      form_response: '',
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Billing',
          link: '#',
          isLink: false,
        },
      ],
      plans: null,
      checkout_steps: [
        {
          id: 1,
          name: 'Choose a plan',
          description: '',
          isActive: true,
        },
        {
          id: 2,
          name: 'Payment details',
          description: '',
          isActive: false,
        },
      ],
      prevPlan: null,
      prevPlanPeriod: 'monthly',
      selectedPlan: 'basic',
      selectedPlanPeriod: 'monthly',
      stripe: this.props.stripe,

      // stripe data
      stripeCustomerId: null,
      stripeSubscriptionId: null,
      billingName: '',
      billingName__response: '',
      billingEmail: '',
      billingEmail__response: '',
      billingCoupon__response: '',
      billingCoupon: '',
      cardErrorsResponse: '',
      isProcessingPayment: false,
      isSuccessPayment: false,

      preview_upcoming_invoice: null,
      isLoadingPreviewInvoice: false,
      preInvoice_subscription_proration_date: null,

      stripe_commission_percent: 2.7,
      planChecked: false,
      hasActivePlan: true,
      hasActiveAccount: true,
      email_regex_validator:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      error_response_step1: '',
      unauthrorizedDowngradeReport: [],
      changePlanState: 'upgrade',
      showModal_cancelSubscription: false,
      isLoadingCancelSubscription: false,
      modalResponse_cancelSubscription: '',
      modalResponse_cancelSubscription_status: 'warning',
      usage: {},

      /** Billing settings */
      showModal_updateBillingAddress: false,
      isLoading_getBillingAddress: false,
      isLoading_setBillingAddress: false,
      modalResponse_status: 'warning',
      modalResponse: '',

      billingAddress: {
        name: '',
        email: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        zipcode: '',
        state: '',
        country: '',
      },
      billingAddressFormValidation: {
        name: '',
        email: '',
        address_line_1: '',
        city: '',
        zipcode: '',
        state: '',
        country: '',
      },
      billingAddressForm__response: '',
      billingAddressPaymentForm_error: '',
      isLoadingPlans: false,
      regex: {
        email:
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
    };

    this.handleSubmit__upgradeAccount =
      this.handleSubmit__upgradeAccount.bind(this);
    // this.paymentProcess__selectPlan = this.paymentProcess__selectPlan.bind(this);
    this.paymentProcess__goto_step1 =
      this.paymentProcess__goto_step1.bind(this);
    this.paymentProcess__goto_step2 =
      this.paymentProcess__goto_step2.bind(this);
    this.handleChange__editBillingName =
      this.handleChange__editBillingName.bind(this);
    this.handleChange__editBillingEmail =
      this.handleChange__editBillingEmail.bind(this);
    this.handleChange__editBillingCoupon =
      this.handleChange__editBillingCoupon.bind(this);
    this.paymentProcess__selectPlanPeriod =
      this.paymentProcess__selectPlanPeriod.bind(this);
    this.handlePaymentThatRequiresCustomerAction =
      this.handlePaymentThatRequiresCustomerAction.bind(this);
    this.handleRequiresPaymentMethod =
      this.handleRequiresPaymentMethod.bind(this);
    this.onSubscriptionComplete = this.onSubscriptionComplete.bind(this);
    this.showCardError = this.showCardError.bind(this);
    this.generatePreviewUpcomingInvoice =
      this.generatePreviewUpcomingInvoice.bind(this);
    this.getCurrentStripeCustomerId =
      this.getCurrentStripeCustomerId.bind(this);

    this.openModal_cancelSubscription =
      this.openModal_cancelSubscription.bind(this);
    this.closeModal_cancelSubscription =
      this.closeModal_cancelSubscription.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.undoCancelSubscription = this.undoCancelSubscription.bind(this);
    this.closeModal_updateBillingAddress =
      this.closeModal_updateBillingAddress.bind(this);
    this.openModal_updateBillingAddress =
      this.openModal_updateBillingAddress.bind(this);
    this.handleChangeBillingAddress =
      this.handleChangeBillingAddress.bind(this);
    this.getPlans = this.getPlans.bind(this);
    // this.handleSubmit__updateBillingAddress = this.handleSubmit__updateBillingAddress.bind(this);
  }

  getAccountData = async () => {
    this.setState({
      isLoadingAccountData: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => res.data)
        .then((data) => {
          if (typeof data !== 'undefined' && data !== null) {
            let hasActivePlan = true;
            let stateItems = {};

            if (
              typeof data.plan === 'undefined' ||
              data.plan === null ||
              (typeof data.plan.is_active !== 'undefined' &&
                data.plan.is_active === false) ||
              (typeof data.plan.is_trial !== 'undefined' &&
                data.plan.is_trial === true &&
                typeof data.created_at !== 'undefined' &&
                data.created_at !== null &&
                typeof data.plan.trial_period !== 'undefined' &&
                data.plan.trial_period !== null &&
                moment().diff(
                  moment(data.created_at).add(data.plan.trial_period, 'days'),
                  'minutes'
                ) > 0)
            ) {
              stateItems.hasActivePlan = false;
              hasActivePlan = false;
            }

            stateItems.isLoadingAccountData = false;
            stateItems.accountId = data.id;
            stateItems.prevPlan =
              hasActivePlan === true ? data.plan.name : null;
            stateItems.prevPlanPeriod =
              hasActivePlan === true ? data.plan.interval : 'monthly';
            stateItems.selectedPlan =
              hasActivePlan === true ? data.plan.name : 'basic';
            // Initialise the selected plan period
            stateItems.selectedPlanPeriod = 'monthly';
            if (hasActivePlan === true) {
              if (stateItems.prevPlan === stateItems.selectedPlan)
                stateItems.selectedPlanPeriod =
                  stateItems.prevPlanPeriod === 'monthly'
                    ? 'yearly'
                    : 'monthly';
              else stateItems.selectedPlanPeriod = data.plan.interval;
            }

            // save the account usage
            stateItems.usage = data.usage;

            this.setState(stateItems);
            //console.log(data.alertChannelSubscriptions);

            // //console.log(data.alertChannelSubscriptions[0].subscriptions.find(e => e.activated === true));
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoadingAccountData: false,
              hasActiveAccount: false,
            });
            // remote current account id from storage ( connectedUserData element )
            this.context.cleanUserAccount(accountId);
          }
        });
    }
  };

  handleSubmit__upgradeAccount = async (event) => {
    event.preventDefault();

    if (this.state.isProcessingPayment === true) return;

    // in processing ...
    this.setState({
      isProcessingPayment: true,
      formIsSubmited: true,
    });

    const { connectedUserData } = this.context;
    const { billing_address } = connectedUserData;

    // ! Check the billing address of the user
    if (
      this.context?.connectedUserData?.billing_address?.country === '' ||
      this.context?.connectedUserData?.billing_address?.country === null
    ) {
      this.setState({
        billingAddressPaymentForm_error: 'Your billing address is required',
        isProcessingPayment: false,
      });

      return;
    }

    // Check the user name and email value
    // if(this.state.billingName==="" || this.state.billingEmail===""){
    // 	let stateItems = {};
    // 	if (this.state.billingName==="") stateItems.billingName__response = "The Name is required";
    // 	if (this.state.billingEmail==="") stateItems.billingEmail__response = "The Email address is required"
    // 	stateItems.isProcessingPayment = false;

    // 	this.setState(stateItems);
    // 	return;
    // }

    const { stripe, elements } = this.props;
    //console.log(stripe);
    //console.log(elements);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // save stripe props as state
    this.setState({
      stripe: stripe,
    });

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    const billingName = this.state.billingName;
    const billingEmail = this.state.billingEmail;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: billing_address?.name ?? connectedUserData?.name,
        email: billing_address?.email ?? connectedUserData?.email,
        address: {
          city: billing_address?.city ?? '',
          country: billing_address?.country ?? '',
          line1: billing_address?.line1 ?? '',
          line2: billing_address?.line2 ?? '',
          line2: billing_address?.line2 ?? '',
          postal_code: billing_address?.postal_code ?? '',
          state: billing_address?.state ?? '',
        },
      },
    });

    //console.log('[error]', error);
    if (error) {
      //console.log('[error]', error);
      this.setState({
        cardErrorsResponse:
          typeof error.message !== 'undefined'
            ? error.message
            : 'Something went wrong, please try again',
        isProcessingPayment: false,
      });

      return;
    }

    // clear the card error response
    this.setState({
      cardErrorsResponse: '',
    });

    //console.log('[PaymentMethod]', paymentMethod);
    const paymentMethodId = paymentMethod.id;

    // load customer id
    const customerId = this.state.stripeCustomerId;

    // Create the subscription
    const priceId = this.getSelectedPricePlan().id;

    // // If a previous payment was attempted, get the latest invoice
    // const latestInvoicePaymentIntentStatus = localStorage.getItem(
    // 	'latestInvoicePaymentIntentStatus'
    //   );

    // if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
    // 	// Update the payment method and retry invoice payment
    // 	const invoiceId = localStorage.getItem('latestInvoiceId');
    // 	this.retryInvoiceWithNewPaymentMethod({
    // 		customerId,
    // 		paymentMethodId,
    // 		invoiceId,
    // 		priceId,
    // 	});
    // } else {

    this.createSubscription({
      customerId,
      paymentMethodId,
      priceId,
      billingName: billing_address?.name ?? connectedUserData?.name,
      billingEmail: billing_address?.email ?? connectedUserData?.email,
    });
    // }
  };
  /**
   *
   *
   * @param {*} event
   * @memberof BillingSettings
   */
  /* paymentProcess__selectPlan(event) {
		this.setState({
			selectedPlan : event.target.value
		})
	} */

  /**
   *
   *
   * @memberof BillingSettings
   */
  paymentProcess__goto_step1() {
    let checkout_steps = this.state.checkout_steps;
    checkout_steps.map((x, i) => {
      if (x.id === 1) {
        checkout_steps[i].isActive = true;
      } else {
        checkout_steps[i].isActive = false;
      }

      return;
    });
    this.setState({
      checkout_steps,
      selectedPlanPeriod: 'monthly',
    });
  }
  /**
   *
   *
   * @memberof BillingSettings
   */
  paymentProcess__goto_step2 = async (event) => {
    const selectedPlan = event.currentTarget.dataset.plan;

    let checkout_steps = this.state.checkout_steps;
    const selectedPlanData =
      this.state.plans !== null
        ? this.state.plans?.find((x) => x.name === selectedPlan)
        : null;
    // the user has chosen one of the available plans
    if (typeof selectedPlanData !== 'undefined' && selectedPlanData !== null) {
      let tmpStateItems = {};
      checkout_steps.map((x, i) => {
        if (x.id === 2) {
          checkout_steps[i].isActive = true;
        } else {
          checkout_steps[i].isActive = false;
        }
      });
      tmpStateItems.checkout_steps = checkout_steps;
      tmpStateItems.error_response_step1 = '';

      // ! Check if the user went to upgrade or downgrade
      // ! If downgrade then we check the features in the selected plan and compare them with current content
      // ! of the account ( api checks , status page , team users )
      // ! 	if the content not equal then we display an warning message to explain to the user to clean and delete additional content
      // !	before downgrade plan
      let changePaymentPlanAction = 'upgrade';
      let authorizedPayment = true;
      tmpStateItems.unauthrorizedDowngradeReport = [];
      if (
        (this.state.prevPlan === 'advanced' &&
          ['pro', 'basic'].includes(selectedPlan)) ||
        (this.state.prevPlan === 'pro' && selectedPlan === 'basic')
      )
        changePaymentPlanAction = 'downgrade';

      //console.log('changePaymentPlanAction : ' , changePaymentPlanAction);
      //console.log('selectedPlanData : ' , selectedPlanData);
      // ? Check if the downgrade is authorized
      if (changePaymentPlanAction === 'downgrade') {
        // - Verify the account usage
        // - And compare them with the features of the selected plan ( of the downgrade )
        //console.log('this.context.accountData : ' , this.context.accountData)
        // const usage = (typeof this.context.accountData!=='undefined' && this.context.accountData!==null)
        const usage =
          typeof this.state.usage !== 'undefined' && this.state.usage !== null
            ? this.state.usage
            : { account_users: 0, api_checks: 0, sms: 0, status_pages: 0 };

        // Load the account information ( USAGE )

        if (
          (usage.api_checks > selectedPlanData.monitors &&
            selectedPlanData.monitors >= 0) ||
          (usage.account_users > selectedPlanData.team &&
            selectedPlanData.team >= 0) ||
          (usage.status_pages > selectedPlanData.status_pages &&
            selectedPlanData.status_pages >= 0)
        ) {
          authorizedPayment = false;

          tmpStateItems.unauthrorizedDowngradeReport = [
            {
              type: 'Monitors',
              usage: usage.api_checks,
              limit: selectedPlanData.monitors,
            },
            {
              type: 'Team members',
              usage: usage.account_users,
              limit: selectedPlanData.team,
            },
            {
              type: 'Status pages',
              usage: usage.status_pages,
              limit: selectedPlanData.status_pages,
            },
          ];
        }
      }

      // ? Payment processing ....
      if (authorizedPayment) {
        // Create / load stripe customer id
        // ! Get stripe customer id from state
        let customerId =
          typeof this.context !== 'undefined' &&
          this.context.connectedUserData !== null
            ? this.context.connectedUserData.scustomerid
            : null;
        const billingEmail =
          typeof this.context !== 'undefined' &&
          this.context.connectedUserData !== null
            ? this.context.connectedUserData.email
            : '';
        const billingName =
          typeof this.context !== 'undefined' &&
          this.context.connectedUserData !== null
            ? this.context.connectedUserData.name
            : '';
        tmpStateItems.billingEmail = billingEmail;
        tmpStateItems.billingName = billingName;
        // if not exist => create a new one
        if (typeof customerId === 'undefined' || customerId == null) {
          // Create a new customer Id from stripe Account
          this.createStripeCustomer(this.context.connectedUserData.email).then(
            (r) => {
              // customerId = this.state.stripeCustomerId;
              // generate the preview invoice ( upgrade / downgrade )
              this.generatePreviewUpcomingInvoice();
            }
          );
        } else {
          //console.log('customer id from global state : ' , customerId);
          // update customerId in state
          //console.log('_isMounted' , this._isMounted);
          if (this._isMounted)
            this.setState({ stripeCustomerId: customerId }, () => {
              //console.log('saved stripeCustomerId in state : ' , this.state.stripeCustomerId)
              // generate the preview invoice ( upgrade / downgrade )
              this.generatePreviewUpcomingInvoice();
            });
        }
      }
      tmpStateItems.selectedPlan = selectedPlan;
      tmpStateItems.changePlanState = changePaymentPlanAction;

      //update the state
      this.setState(tmpStateItems);
    } else {
      this.setState({
        error_response_step1:
          'You must choose a plan to complete the subscription process',
        selectedPlan: selectedPlan,
      });
    }
  };

  /**
   *
   *
   * @param {*} event
   * @memberof BillingSettings
   */
  paymentProcess__selectPlanPeriod(event) {
    this.setState(
      {
        selectedPlanPeriod: event.target.value,
      },
      () => {
        // generate the preview invoice ( upgrade / downgrade )
        this.generatePreviewUpcomingInvoice();
      }
    );
  }
  /**
   *
   *
   * @param {*} event
   * @memberof BillingSettings
   */
  handleChange__editBillingName(event) {
    this.setState({
      billingName: event.target.value,
      billingName__response:
        event.target.value === '' ? 'The Name is required' : '',
    });
  }
  /**
   *
   *
   * @param {*} event
   * @memberof BillingSettings
   */
  handleChange__editBillingEmail(event) {
    let validatedEmail = false;
    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== '' &&
      event.target.value.match(this.state.email_regex_validator) != null
    ) {
      validatedEmail = true;
    }

    this.setState({
      billingEmail: event.target.value,
      billingEmail__response:
        event.target.value === ''
          ? 'The Email address is required'
          : validatedEmail === false
          ? 'Email address is not valid'
          : '',
    });
  }

  /**
   *
   *
   * @param {*} event
   * @memberof BillingSettings
   */
  handleChange__editBillingCoupon(event) {
    this.setState(
      {
        billingCoupon: event.target.value,
      },
      () => {
        // generate the preview invoice ( upgrade / downgrade )
        this.generatePreviewUpcomingInvoice();
      }
    );
  }

  /**
   *
   *
   * @returns
   * @memberof BillingSettings
   */
  getSelectedPricePlan() {
    const plans = this.state.plans !== null ? this.state.plans : null;
    const plan =
      plans !== null
        ? plans.find((x) => x.name === this.state.selectedPlan)
        : null;
    if (plan) {
      if (this.state.selectedPlanPeriod === 'monthly') {
        return plan.monthly_price;
      } else {
        // yearly pricing plan
        return plan.yearly_price;
      }
    }
  }

  /**
   *
   *
   * @param {*} UserEmail
   * @memberof BillingSettings
   */
  createStripeCustomer = async (UserEmail) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}payment/create-customer`,
          {
            email: UserEmail,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          const data = res.data;
          this.setState({
            stripeCustomerId: data.scustomer_id,
          });
        })
        .catch((error) => {
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
          }
        });
    } else {
    }
  };

  /**
   *
   *
   * @memberof BillingSettings
   */
  createSubscription = async ({
    customerId,
    paymentMethodId,
    priceId,
    billingName,
    billingEmail,
  }) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };
      return await axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/payment/create-subscription`,
          {
            customerId: customerId,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            upt_plan: this.state.selectedPlan,
            upt_planPeriod: this.state.selectedPlanPeriod,
            subscription_proration_date:
              this.state.preInvoice_subscription_proration_date,
            coupon: this.state.billingCoupon,
            payerName: billingName,
            payerEmail: billingEmail,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((response) => {
          // console.log('response 1 : ' , response )
          return response.data;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          // console.log('response 2 : ' , result )
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          // console.log('response 3 : ' , result )
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(this.handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          // console.log('General error : ' , error)
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            if (
              typeof error.message === 'undefined' ||
              error.message === null ||
              error.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.showCardError(error.response.data);
              return;
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
            this.showCardError({
              error: {
                message: 'Something went wrong, please try again',
              },
            });
            return;
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error?.error?.message);

            this.showCardError({
              error: {
                message: error?.error?.message,
              },
            });
            return;
          }
        });
    } else {
      this.showCardError({
        error: {
          message: 'You are not authorized to do this operation',
        },
      });
      return;
    }
  };
  /**
   *
   *
   * @param {*} {
   * 		customerId,
   * 		paymentMethodId,
   * 		invoiceId,
   * 		priceId
   * 	  }
   * @returns
   * @memberof BillingSettings
   */
  retryInvoiceWithNewPaymentMethod = async ({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId,
  }) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      return (
        axios
          .post(
            `${process.env.REACT_APP_API_URL}accounts/${accountId}/payment/retry-invoice`,
            {
              customerId: customerId,
              paymentMethodId: paymentMethodId,
              invoiceId: invoiceId,
            },
            {
              headers: headers,
              cancelToken: this.signal.token,
            }
          )
          .then((response) => {
            return response.json();
          })
          // If the card is declined, display an error to the user.
          .then((result) => {
            if (result.error) {
              // The card had an error when trying to attach it to a customer.
              throw result;
            }
            return result;
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          .then((result) => {
            return {
              // Use the Stripe 'object' property on the
              // returned result to understand what object is returned.
              invoice: result,
              paymentMethodId: paymentMethodId,
              priceId: priceId,
              isRetry: true,
            };
          })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          .then(this.handlePaymentThatRequiresCustomerAction)
          // No more actions required. Provision your service for the user.
          .then(this.onSubscriptionComplete)
          .catch((error) => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            if (
              typeof error.message === 'undefined' ||
              error.message === null ||
              error.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.showCardError(error);
            }
          })
      );
    }

    return false;
  };

  /**
   *
   *
   * @param {*} {
   * 		subscription,
   * 		invoice,
   * 		priceId,
   * 		paymentMethodId,
   * 		isRetry,
   * 	  }
   * @returns
   * @memberof BillingSettings
   */
  handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    // //console.log(subscription);
    // //console.log(invoice);
    // //console.log(priceId);
    // //console.log(paymentMethodId);
    // //console.log(isRetry);
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent =
      invoice && typeof invoice !== 'undefined'
        ? invoice.payment_intent
        : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return this.state.stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          //console.log(result);
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw { error: { message: result.error.message } };
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.

              // change the subscription status to active ( while the customer has been successfully confirmed or authentificated  )
              subscription.status = 'active';
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          // displayError(error);
          //console.log(error);
          throw { error: { message: error.error.message } };
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  /**
   *
   *
   * @param {*} {
   * 		subscription,
   * 		paymentMethodId,
   * 		priceId,
   * 	  }
   * @returns
   * @memberof BillingSettings
   */
  handleRequiresPaymentMethod({ subscription, paymentMethodId, priceId }) {
    //console.log(subscription);
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );

      throw {
        error: {
          message:
            typeof subscription.latest_invoice.payment_intent.last_payment_error
              .message !== 'undefined'
              ? subscription.latest_invoice.payment_intent.last_payment_error
                  .message
              : 'Your card was declined.',
        },
      };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  /**
   *
   *
   * @param {*} result
   * @memberof BillingSettings
   */
  onSubscriptionComplete(result) {
    // Payment was successful.
    if (['active', 'paid'].includes(result.subscription.status)) {
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
      //console.log('Your account has been upgraded successfully');

      this.setState(
        {
          isSuccessPayment: true,
        },
        () => {
          // ? Google Analytics Event : Order Plan
          const amount_due =
            this.state.preview_upcoming_invoice !== null &&
            this.state.preview_upcoming_invoice.amount_due !== null &&
            this.state.preview_upcoming_invoice.amount_due >= 0
              ? this.state.preview_upcoming_invoice.amount_due
              : result.subscription.latest_invoice !== null &&
                result.subscription.latest_invoice.amount_due !== null &&
                result.subscription.latest_invoice.amount_due >= 0
              ? result.subscription.latest_invoice.amount_due
              : null;

          if (amount_due !== null && amount_due >= 0) {
            GA.GAevent(
              'Billing',
              'Upgrade Plan',
              parseFloat(this.state.preview_upcoming_invoice.amount_due) / 100
            );
          }
        }
      );

      // ! update the current account data as Global State
      this.context.getCurrentAccountData().then((response) => {
        // Update the current plan and period
        if (
          this.context.accountData !== null &&
          this.context.accountData.plan !== null
        ) {
          this.setState({
            prevPlan: this.context.accountData.plan.name,
            prevPlanPeriod: this.context.accountData.plan.interval,
            hasActivePlan: true,
          });
        }
      });

      // Only for the active case
      if (result.subscription.status === 'active') {
        this.confirmSubscriptionDB({
          customerId: result.subscription.customer,
          client_secret:
            result.subscription.latest_invoice.payment_intent.client_secret,
          pi_id: result.subscription.latest_invoice.payment_intent.id,
          sub_id: result.subscription.id,
          upt_plan: this.state.selectedPlan,
          upt_planPeriod: this.state.selectedPlanPeriod,
        }).then((confirmResponse) => {
          this.context.getCurrentAccountData().then((response) => {
            // Update the current plan and period
            if (
              this.context.accountData !== null &&
              this.context.accountData.plan !== null
            ) {
              this.setState({
                prevPlan: this.context.accountData.plan.name,
                prevPlanPeriod: this.context.accountData.plan.interval,
                hasActivePlan: true,
              });
            }
          });
        });
      }
    }

    // stop processing payment
    this.setState({
      isProcessingPayment: false,
    });
  }

  /**
   *
   *
   * @param {*} error
   * @memberof BillingSettings
   */
  showCardError(error) {
    // console.log('showCardError ::: below :');
    // console.log(error);

    this.setState({
      cardErrorsResponse:
        typeof error.error !== 'undefined' &&
        typeof error.error.message !== 'undefined'
          ? error.error.message
          : typeof error.error !== 'undefined' &&
            typeof error.message !== 'undefined'
          ? error.message
          : 'Something went wrong, please try again',
      isProcessingPayment: false,
    });
  }

  confirmSubscriptionDB = async (args) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };
      return axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/payment/confirm-subscription`,
          {
            customerId: args.customerId,
            client_secret: args.client_secret,
            pi_id: args.pi_id,
            sub_id: args.sub_id,
            upt_plan: args.upt_plan,
            upt_planPeriod: args.upt_planPeriod,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((response) => {
          return response.data;
        })
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    }

    return false;
  };

  /**
   *
   *
   * @memberof BillingSettings
   */
  generatePreviewUpcomingInvoice = async () => {
    //console.log('generatePreviewUpcomingInvoice .... ');
    this.setState({
      isLoadingPreviewInvoice: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/payment/preview-upcoming-invoice`,
          {
            customerId: this.state.stripeCustomerId,
            upt_plan: this.state.selectedPlan,
            upt_planPeriod: this.state.selectedPlanPeriod,
            coupon: this.state.billingCoupon,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((response) => {
          return response.data;
        })
        .then((result) => {
          //console.log(result)
          if (this._isMounted) {
            this.setState({
              isLoadingPreviewInvoice: false,
              preInvoice_subscription_proration_date:
                result.subscription_proration_date,
              preview_upcoming_invoice: result,
              billingCoupon__response:
                this.state.billingCoupon !== '' &&
                (typeof result.coupon === 'undefined' || result.coupon === null)
                  ? 'Sorry, this coupon code is no longer available and it will not be counted towards your payment'
                  : '',
            });
          }
          //console.log(this.state.preview_upcoming_invoice)
        })
        .catch((error) => {
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoadingPreviewInvoice: false,
            });
          }
        });
    }
  };

  getCurrentStripeCustomerId() {
    // Create / load stripe customer id
    let customerId = this.context.connectedUserData.stripeCustomerId; // get stripe customer id from state
    // if not exsi`te => create a new one
    if (typeof customerId === 'undefined' || customerId == null) {
      // Create a new customer Id from stripe Account
      this.createStripeCustomer(this.context.connectedUserData.email);
      customerId = this.state.stripeCustomerId;
    } else {
      //console.log('customer id from global state : ' , customerId);
      // update customerId in state
      //console.log('_isMounted' , this._isMounted);
      if (this._isMounted) this.setState({ stripeCustomerId: customerId });
    }
  }

  getPlanAccountState() {
    if (this.state.planChecked === false) {
      if (
        typeof this.context.accountData !== 'undefined' &&
        this.context.accountData !== null
      ) {
        const accountData = this.context.accountData;
        // check if the account has an active plan ( a paid subscription OR active trial period )
        if (
          accountData.plan === null ||
          Object.keys(accountData.plan).length === 0 ||
          (typeof accountData.plan.status !== 'undefined' &&
            accountData.plan.status !== 'active') ||
          (typeof accountData.plan.trial !== 'undefined' &&
            accountData.plan.trial === true &&
            typeof accountData.plan.periodEnd !== 'undefined' &&
            accountData.plan.periodEnd !== null &&
            moment(accountData.plan.periodEnd).diff(moment(), 'minutes') <= 0)
        ) {
          //console.log('THE ACCOUNT HAS NOT AN ACTIVE PLAN.......');
          this.setState({
            planChecked: true,
            hasActivePlan: false,
            prevPlan: null,
          });
        }
      }
    }
  }

  openModal_cancelSubscription(event) {
    // if(typeof event.currentTarget.dataset.type!=='undefined' && event.currentTarget.dataset.type !== null
    // 	&& ['MEMBER' , 'INVITE'].includes(event.currentTarget.dataset.type)){

    this.setState({
      showModal_cancelSubscription: true,
      // revoke_id : event.currentTarget.dataset.id,
      // revoke_invitedUser : (typeof event.currentTarget.dataset.invited!=='undefined' && event.currentTarget.dataset.invited!==null && event.currentTarget.dataset.invited===true) ? true : false,
      // revoke_teamUserType : event.currentTarget.dataset.type,
      modalResponse_cancelSubscription: '',
      modalResponse_cancelSubscription_status: 'warning',
    });
    // }
  }

  closeModal_cancelSubscription(event) {
    this.setState({
      showModal_cancelSubscription: !this.state.showModal_cancelSubscription,
      modalResponse_cancelSubscription: '',
    });
  }

  async cancelSubscription() {
    // loading ... effect
    this.setState({
      isLoadingCancelSubscription: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/payment/cancel-subscription`,
          {},
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((result) => {
          // console.log('results : ' , result )

          // update success data state
          this.setState({
            isLoadingCancelSubscription: false,
            modalResponse_cancelSubscription: result.message,
            modalResponse_cancelSubscription_status: 'success',
          });

          // Update the account data from the global context
          const tmpAccountData = this.context.accountData;
          if (tmpAccountData.plan !== null) {
            tmpAccountData.plan.cancel_at = result.data.cancel_at;
            tmpAccountData.plan.cancel_at_period_end =
              result.data.cancel_at_period_end;
            tmpAccountData.plan.canceled_at = result.data.canceled_at;
          }

          this.context.setCurrentAccountData(tmpAccountData);
          // close the model after 1 second
          setTimeout(() => {
            this.setState({ showModal_cancelSubscription: false });
          }, 1000);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);

            this.setState({
              isLoadingCancelSubscription: false,
              modalResponse_cancelSubscription:
                error.response.data.message !== 'undefined'
                  ? error.response.data.message
                  : 'Something went wrong, please try later',
              modalResponse_cancelSubscription_status: 'danger',
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            if (
              typeof error.message === 'undefined' ||
              error.message === null ||
              error.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.setState({
                isLoadingCancelSubscription: false,
                modalResponse_cancelSubscription:
                  'Something went wrong, please try later',
                modalResponse_cancelSubscription_status: 'danger',
              });
            }
          }
          //console.log(error.config);

          return;
        });
    } else {
      this.setState({
        isLoadingInviteTeamUser: false,
        modalResponse_inviteTeamUser:
          'You are not authorized to do this operation',
        modalResponse_inviteTeamUser_status: 'danger',
      });
      return;
    }
  }

  async undoCancelSubscription() {
    this.setState({
      isLoadingCancelSubscription: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/payment/undo-cancel-subscription`,
          {},
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((result) => {
          // console.log('results : ' , result )

          // update success data state
          this.setState({
            isLoadingCancelSubscription: false,
          });

          // Update the account data from the global context
          const tmpAccountData = this.context.accountData;
          if (tmpAccountData.plan !== null) {
            tmpAccountData.plan.cancel_at = result.data.cancel_at;
            tmpAccountData.plan.cancel_at_period_end =
              result.data.cancel_at_period_end;
            tmpAccountData.plan.canceled_at = result.data.canceled_at;
          }

          this.context.setCurrentAccountData(tmpAccountData);
          // close the model after 1 second
          setTimeout(() => {
            this.setState({ showModal_cancelSubscription: false });
          }, 1000);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);

            this.setState({
              isLoadingCancelSubscription: false,
              modalResponse_cancelSubscription:
                error.response.data.message !== 'undefined'
                  ? error.response.data.message
                  : 'Something went wrong, please try later',
              modalResponse_cancelSubscription_status: 'danger',
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            if (
              typeof error.message === 'undefined' ||
              error.message === null ||
              error.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.setState({
                isLoadingCancelSubscription: false,
                modalResponse_cancelSubscription:
                  'Something went wrong, please try later',
                modalResponse_cancelSubscription_status: 'danger',
              });
            }
          }
          //console.log(error.config);

          return;
        });
    } else {
      this.setState({
        isLoadingInviteTeamUser: false,
        modalResponse_inviteTeamUser:
          'You are not authorized to do this operation',
        modalResponse_inviteTeamUser_status: 'danger',
      });
      return;
    }
  }

  componentDidUpdate() {
    this.getPlanAccountState();
  }

  componentDidMount() {
    this._isMounted = true;
    // Load account data
    this.getAccountData();

    // Load the billing data ( plans )
    this.getPlans().then((response) => this.setState({ plans: response }));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   *
   * @returns
   */
  getPlans() {
    return new Promise(async (resolve, reject) => {
      this.setState({ isLoadingPlans: true });

      const localStorageData = await getItemStorage(
        `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
      );
      const accountId = await getItemStorage(
        `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
        'string'
      );
      if (localStorageData && localStorageData.token) {
        const { token } = localStorageData;

        await axios
          .get(`${process.env.REACT_APP_API_URL}plans`, {
            params: {
              is_live:
                process.env.REACT_APP_LIVE_PAYMENT === 'true' ? true : false,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`, //'JWT token...'
              'X-Odown-Account': accountId,
            },
            cancelToken: this.signal.token,
          })
          .then((res) => res.data)
          .then((data) => {
            if (typeof data !== 'undefined' && data !== null) {
              // plans = data;
              resolve(data);
            }
            reject();

            this.setState({ isLoadingPlans: false });
          })
          .catch((err) => {
            if (
              typeof err.message === 'undefined' ||
              err.message === null ||
              err.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.setState({
                // isLoading_getBillingAddress: false,
                hasActiveAccount: false,
              });
              // remote current account id from storage ( connectedUserData element )
              this.context.cleanUserAccount(accountId);
            }
            reject(err);

            this.setState({ isLoadingPlans: false });
          });
      }
    });
  }

  /**
   * Billing Settings Modal
   * @param {*} event
   */
  closeModal_updateBillingAddress(event) {
    this.setState(
      {
        showModal_updateBillingAddress:
          !this.state.showModal_updateBillingAddress,
        modalResponse: '',
      },
      () => {
        // generate the preview invoice ( upgrade / downgrade )
        this.generatePreviewUpcomingInvoice();
      }
    );
  }

  /**
   *
   * @param {*} event
   */
  openModal_updateBillingAddress(event) {
    this.setState(
      {
        showModal_updateBillingAddress: true,
        billingAddressFormValidation: {
          name: '',
          email: '',
          address_line_1: '',
          city: '',
          zipcode: '',
          state: '',
          country: '',
        },
      },
      () => {
        this.getBillingAddress();
      }
    );
  }

  getBillingAddress = async () => {
    this.setState({
      isLoading_getBillingAddress: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      await axios
        .get(`${process.env.REACT_APP_API_URL}payment/billing/address`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => res.data)
        .then((data) => {
          if (typeof data !== 'undefined' && data !== null) {
            this.setState({
              billingAddress: {
                name: data?.userData?.name,
                email:
                  data?.userData?.email ??
                  this.context.connectedUserData?.email,
                address_line_1: data?.userData?.address?.line1,
                address_line_2: data?.userData?.address?.line2,
                city: data?.userData?.address?.city,
                zipcode: data?.userData?.address?.zipcode,
                state: data?.userData?.address?.state,
                country: data?.userData?.address?.country,
              },
              isLoading_getBillingAddress: false,
            });

            // Update the context state
            this.context.setConnectedUserData({
              ...this.context.connectedUserData,
              billing_address: {
                name: data?.userData?.name,
                email:
                  data?.userData?.email ??
                  this.context.connectedUserData?.email,
                address_line_1: data?.userData?.address?.line1,
                address_line_2: data?.userData?.address?.line2,
                city: data?.userData?.address?.city,
                zipcode: data?.userData?.address?.zipcode,
                state: data?.userData?.address?.state,
                country: data?.userData?.address?.country,
              },
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading_getBillingAddress: false,
              hasActiveAccount: false,
            });
            // remote current account id from storage ( connectedUserData element )
            this.context.cleanUserAccount(accountId);
          }
        });
    }
  };

  /**
   *
   * @param {*} param0
   */
  handleChangeBillingAddress({ target: { name, value } }) {
    // Create a shallow copy of the billingAddressValidation object
    const updatedBillingAddressValidation = {
      ...this?.state?.billingAddressFormValidation,
    };

    switch (name) {
      case 'name':
      case 'address_line_1':
      case 'city':
      case 'zipcode':
      case 'state':
      case 'country':
        // Update the name property with the new value
        updatedBillingAddressValidation[name] =
          value === '' ? 'Required Field' : '';

        // Update the state with the modified billingAddress object
        this.setState({
          billingAddressFormValidation: updatedBillingAddressValidation,
        });

        break;
      case 'email':
        let validationEmail = value === '' ? 'Required Field' : ''; // (formData.subdomain.match(regex_validator.subdomain)===null) ? 'Invalid subdomain, must only include letters, numbers and the special symbols "- _"' : ''
        // ! Check the syntaxe
        if (value.match(this.state.regex.email) === null) {
          validationEmail = 'Invalid email';
        }
        // Update the name property with the new value
        updatedBillingAddressValidation[name] = validationEmail;

        // Update the state with the modified billingAddress object
        this.setState({
          billingAddressFormValidation: updatedBillingAddressValidation,
        });

        break;
    }

    // Create a shallow copy of the billingAddress object
    const updatedBillingAddress = { ...this.state.billingAddress };
    updatedBillingAddress[name] = value;

    this.setState({ billingAddress: updatedBillingAddress });
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  handleSubmit__updateBillingAddress = async (event) => {
    event.preventDefault();

    const {
      name,
      email,
      address_line_1,
      address_line_2,
      city,
      zipcode,
      state,
      country,
    } = this.state?.billingAddress;

    const { billingAddress } = this.state;
    const { billingAddressFormValidation } = this.state;

    this.setState({
      isLoading_setBillingAddress: true,
    });
    // ! Check the form's fields
    if (
      Object.keys(billingAddressFormValidation)?.filter(
        (x) =>
          this.state?.billingAddress[x] === '' ||
          this.state?.billingAddress[x] === null ||
          billingAddressFormValidation[x] !== ''
      )?.length > 0
    ) {
      let tmp_stateItems = {};
      tmp_stateItems.modalResponse = 'Please fill in all mandatory fields';
      tmp_stateItems.modalResponse_status = 'danger';
      tmp_stateItems.isLoading_setBillingAddress = false;
      this.setState(tmp_stateItems);

      // Validation of all field
      const updatedBillingAddressValidation = {
        ...this?.state?.billingAddressFormValidation,
      };

      Object.keys(billingAddress)?.map((name) => {
        switch (name) {
          case 'name':
          case 'address_line_1':
          case 'city':
          case 'zipcode':
          case 'state':
          case 'country':
            // Update the name property with the new value
            updatedBillingAddressValidation[name] =
              billingAddress[name] === '' || billingAddress[name] === null
                ? 'Required Field'
                : '';

            // Update the state with the modified billingAddress object
            this.setState({
              billingAddressFormValidation: updatedBillingAddressValidation,
            });

            break;
          case 'email':
            let validationEmail =
              billingAddress[name] === '' || billingAddress[name] === null
                ? 'Required Field'
                : ''; // (formData.subdomain.match(regex_validator.subdomain)===null) ? 'Invalid subdomain, must only include letters, numbers and the special symbols "- _"' : ''
            // ! Check the syntaxe
            if (billingAddress[name]?.match(this.state.regex.email) === null) {
              validationEmail = 'Invalid email';
            }
            // Update the name property with the new value
            updatedBillingAddressValidation[name] = validationEmail;

            // Update the state with the modified billingAddress object
            this.setState({
              billingAddressFormValidation: updatedBillingAddressValidation,
            });

            break;
        }
      });

      return;
    }

    // Save a new status page
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      //console.log(this.state);

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}payment/billing/address`,
          {
            name,
            email,
            address_line_1,
            address_line_2,
            city,
            zipcode,
            state,
            country,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            modalResponse:
              'Your billing settings have been successfully updated',
            modalResponse_status: 'success',
            isLoading_setBillingAddress: false,
          });

          // Update the context state
          this.context.setConnectedUserData({
            ...this.context.connectedUserData,
            billing_address: {
              name: name,
              email: email,
              address_line_1: address_line_1,
              address_line_2: address_line_2,
              city: city,
              zipcode: zipcode,
              state: state,
              country: country,
            },
            scustomer_id: data?.scustomer_id,
          });
        })
        .catch((error) => {
          let stateItems = {
            isLoading_setBillingAddress: false,
          };
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const status = error.response.status;
            const data = error.response.data;

            // default message
            stateItems.modalResponse = data.message;

            if (status === 422) {
              stateItems.modalResponse = data.message;
              // if(data.validation.length > 0 ){
              // 	data.validation.map(validation_item => {
              // 		switch (validation_item.key) {
              // 			case "hosted_subdomain":
              // 				stateItems.form_hostedSubdomain_error = validation_item.error;
              // 				break;
              // 			case "name":
              // 				stateItems.form_title_error = validation_item.error;
              // 				break;
              // 			case "custom_domain":
              // 				stateItems.form_customDomain_error = validation_item.error;
              // 				break;

              // 		}
              // 	})
              // }

              // }else if(status === 403 && typeof data.data!=='undefined' && data.data !==null){
              // 	stateItems.form_customDomain_error = data.data.isAvailableCustomDomain === false ? 'This custom domain is not available, please try a different one' : ""
              // 	stateItems.form_hostedSubdomain_error = data.data.isAvailableHostedsubdomain === false ? 'This subdomain is not available, please try a different one' : ""
            }
            stateItems.modalResponse_status = 'danger';
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            stateItems.modalResponse =
              'Something went wrong, please try again later!';
            stateItems.modalResponse_status = 'danger';
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            stateItems.modalResponse =
              'Something went wrong, please try again later!';
            stateItems.modalResponse_status = 'danger';
            //console.log('Error', error.message);
          }
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            // Save data in state
            this.setState(stateItems);
          }
        });
    } else {
      this.setState({
        isLoading: false,
        modalResponse: 'You are not authorized to do this operation',
        modalResponse_status: 'danger',
      });
      return;
    }
  };

  /**
   *
   * @returns
   */
  render() {
    const {
      formIsSubmited,
      isProcessingPayment,
      preview_upcoming_invoice,
      billingName__response,
      billingEmail__response,
      billingCoupon__response,
      billingAddressFormValidation,
      billingAddress,
    } = this.state;

    const selectedPlanData =
      this.state.plans !== null
        ? this.state.plans?.find((x) => x.name === this.state.selectedPlan)
        : null;

    // //console.log('selectedPlanData : ' , selectedPlanData);
    // //console.log('this.state.selectedPlanPeriod : ' , this.state.selectedPlanPeriod);
    // //console.log('this.state.selectedPlanPeriod : ' , this.state.selectedPlanPeriod);
    // //console.log('Compare monthly checkbox : ' , this.state.selectedPlanPeriod === 'monthly' && this.state.prevPlan !== selectedPlanData.name ? true : false);
    let amount_due = 0;
    if (typeof selectedPlanData !== 'undefined' && selectedPlanData !== null) {
      amount_due =
        this.state.selectedPlanPeriod === 'monthly'
          ? selectedPlanData.monthly_price.amount
          : selectedPlanData.yearly_price.amount;
    }

    // apply amount due if upcoming invoice is available
    if (
      preview_upcoming_invoice !== null &&
      preview_upcoming_invoice.amount_due >= 0
    ) {
      amount_due = parseFloat(preview_upcoming_invoice.amount_due) / 100;
    }

    const { stripe } = this.props;

    let changePlan_actionName = 'upgrade';
    if (
      (this.state.prevPlan === 'basic' &&
        ['pro', 'advanced'].includes(this.state.selectedPlan)) ||
      (this.state.prevPlan === 'pro' && this.state.selectedPlan === 'advanced')
    )
      changePlan_actionName = 'upgrade';
    else if (
      (this.state.prevPlan === 'advanced' &&
        ['basic', 'pro'].includes(this.state.selectedPlan)) ||
      (this.state.prevPlan === 'pro' && this.state.selectedPlan === 'basic')
    )
      changePlan_actionName = 'downgrade';

    // Check if the user has an active account, else redirect the user to /intro page to create a new account
    if (this.state.hasActiveAccount === false) {
      // remote current account id from strage
      removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      return <Redirect to="/intro" />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Billing | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__monitor--form layout__blockInner">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>Account settings</h2>
                </div>
                <div className="l_header__btnaction">
                  {/* <div className="btn btn-primary" onClick={event => { this.handleSubmit(event)}} >Save Settings</div> */}
                </div>
                <div className="l_header__description">
                  <div className="field-group submit-fields-group fluid">
                    <div
                      className={`alert alert-${
                        this.state.form_response_status
                      } ${this.state.form_response === '' ? 'hide' : ''}`}
                    >
                      <div className="">{this.state.form_response}</div>
                    </div>
                  </div>
                  <div
                    className={`billing-current_plan alert ${
                      this.state.hasActivePlan === true ? '' : 'alert-danger'
                    }`}
                  >
                    <label htmlFor="">Plan</label>
                    {this.state.hasActivePlan === true ? (
                      <div>
                        <div className="alert-text">
                          <div className="main-text">
                            <span>You are currently on the </span>
                            <span className="selected-plan">
                              {this.state.prevPlan !== null
                                ? this.state.prevPlan + ' Plan'
                                : ''}
                            </span>
                            <span className="selected-period">
                              {this.state.prevPlanPeriod !== null
                                ? '- ' + this.state.prevPlanPeriod
                                : ''}
                            </span>
                          </div>
                          {this.context.accountData !== null &&
                            this.context.accountData.plan !== null &&
                            (this.context.accountData.plan.cancel_at !== null &&
                            this.context.accountData.plan.cancel_at > 0 ? (
                              <div className="second-text danger">
                                <label>Cancellation scheduled : </label>
                                <Tooltip
                                  placement="top"
                                  trigger={['hover']}
                                  overlay={
                                    <span>
                                      Your subscription will be canceled
                                      automatically at the end of the period ({' '}
                                      <b>
                                        {moment(
                                          this.context.accountData.plan
                                            .cancel_at * 1000
                                        ).format('dddd, MMMM Do YYYY')}
                                      </b>{' '}
                                      ), after that we will never withdraw the
                                      money from your credit card.
                                    </span>
                                  }
                                >
                                  <span>
                                    {this.context.accountData !== null
                                      ? moment(
                                          this.context.accountData.plan
                                            .cancel_at * 1000
                                        ).format('dddd, MMMM Do YYYY')
                                      : '...'}{' '}
                                    <IconInfo />
                                  </span>
                                </Tooltip>
                              </div>
                            ) : this.context.accountData.plan.is_trial !==
                                null &&
                              this.context.accountData.plan.is_trial ===
                                true ? (
                              <div className="second-text">
                                <label>Trial ending :</label>
                                <span>
                                  {this.context.accountData !== null
                                    ? moment(
                                        this.context.accountData.created_at
                                      )
                                        .add(
                                          this.context.accountData.plan
                                            .trial_period,
                                          'days'
                                        )
                                        .format('dddd, MMMM Do YYYY')
                                    : '...'}
                                </span>
                              </div>
                            ) : (
                              <div className="second-text">
                                <label>Next billing :</label>
                                <span>
                                  {this.context.accountData !== null
                                    ? moment(
                                        this.context.accountData.plan
                                          .period_end * 1000
                                      ).format('dddd, MMMM Do YYYY')
                                    : '...'}
                                </span>
                              </div>
                            ))}
                        </div>
                        {this.context.accountData !== null &&
                        this.context.accountData.plan !== null &&
                        this.context.accountData.plan.is_trial !== null &&
                        this.context.accountData.plan.is_trial === false ? (
                          this.context.accountData.plan.cancel_at !== null &&
                          this.context.accountData.plan.cancel_at > 0 ? (
                            <button
                              className={`btn btn-primary`}
                              disabled={this.state.isLoadingCancelSubscription}
                              onClick={this.undoCancelSubscription}
                            >
                              {this.state.isLoadingCancelSubscription
                                ? `Loading...`
                                : `Don't cancel subscription`}
                            </button>
                          ) : (
                            <button
                              className={`btn btn-danger`}
                              onClick={this.openModal_cancelSubscription}
                            >
                              Cancel Subscription
                            </button>
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <span>{`You are not subscribed to any plan, please choose from the plans below`}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="layout__body">
                <NavbarSettings selectedTab="billing" />
                <div className="tab-settings__content">
                  <div className="ts_content-item white-card margin-bottom-20 active">
                    {/* {
											isLoadingAccountData
											? <div className="loading-wrapper"><LoadingSpinner /></div>
											:
											<> */}
                    <h2 className="title">
                      Billing
                      <Tooltip
                        placement="top"
                        trigger={['hover']}
                        // defaultVisible={true}
                        overlay={
                          <span>
                            Providing your billing address helps us calculate
                            the correct tax amount based on your location. This
                            ensures accurate and transparent billing. Don't
                            worry, we respect your privacy and safeguard your
                            information securely.
                          </span>
                        }
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.openModal_updateBillingAddress}
                          style={{ float: 'right' }}
                        >
                          <IconLuSetting />{' '}
                          {
                            // (this.state.billingAddress?.zipcode!=='' && this.state.billingAddress?.zipcode!==null) ||
                            this.context?.connectedUserData?.billing_address
                              ?.country !== '' &&
                            this.context?.connectedUserData?.billing_address
                              ?.country !== null
                              ? `Edit`
                              : `Add`
                          }{' '}
                          Billing Settings
                        </button>
                      </Tooltip>
                    </h2>

                    {this.state.isSuccessPayment === true ? (
                      <React.Fragment>
                        <div className="alert alert-success margin-top-20">
                          Your account has been updated successfully, please
                          refresh the page to view the changes
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="billing-upgrade-processing">
                        <div className="steps-header">
                          <ul>
                            {this.state.checkout_steps.map((step, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    step.isActive === true ? 'active' : ''
                                  }
                                >
                                  <span className="id">{step.id}</span>
                                  <span className="name">{step.name}</span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="steps-body">
                          {this.state.checkout_steps[0].isActive === true && (
                            <div className={`billing-plans`}>
                              {this.state.isLoadingPlans === true ? (
                                <LoadingSpinner />
                              ) : this.state.plans === null ||
                                this.state.plans?.length <= 0 ? (
                                <div className="no-plans-message">
                                  <span>
                                    No active plans found, please contact us
                                  </span>
                                </div>
                              ) : (
                                <ul>
                                  {this.state.plans !== null &&
                                    this.state.plans
                                      ?.filter((x) => x.is_active === true)
                                      .map((item, index) => {
                                        return (
                                          <li
                                            key={index}
                                            className={
                                              this.state.hasActivePlan ===
                                                true &&
                                              this.state.prevPlan === item.name
                                                ? 'active'
                                                : ''
                                            }
                                          >
                                            {this.state.hasActivePlan ===
                                              true &&
                                              this.state.prevPlan ===
                                                item.name && (
                                                <div className="currentPlan-marker">
                                                  Your plan
                                                </div>
                                              )}
                                            <label className="plan-wrapper">
                                              <div className="p-name">
                                                {item.public_name}
                                              </div>
                                              <div className="p-options">
                                                <ul>
                                                  <li className="p-opt-item">{`${item.monitors} Monitors`}</li>
                                                  <li className="p-opt-item">{`${item.sms} SMS credits`}</li>
                                                  <li className="p-opt-item">{`${
                                                    item.team < 0
                                                      ? 'Unlimited'
                                                      : item.team
                                                  } Team members`}</li>
                                                  <li
                                                    className={`p-opt-item ${
                                                      item.status_pages === 0
                                                        ? 'hide'
                                                        : ''
                                                    }`}
                                                  >{`${
                                                    item.status_pages < 0
                                                      ? 'Unlimited'
                                                      : item.status_pages
                                                  } Status pages`}</li>
                                                  {/* {
                                                                                                            item.statuspage_features.subscribers_updates === true && 
                                                                                                            <>
                                                                                                                <li className={`p-opt-item`}>{`${item.statuspage_features.subscribers} Status page subscribers `}</li>
                                                                                                                <li className={`p-opt-item ${item.statuspage_features.all_alerts_notifications === false ? 'hide' : ''}`}>{`Realtime outage notifications`}</li>
                                                                                                            </>
                                                                                                        } */}
                                                  <li
                                                    className={`p-opt-item ${
                                                      item.statuspage_features
                                                        .subscribers_updates ===
                                                      false
                                                        ? 'hide'
                                                        : ''
                                                    }`}
                                                  >{`${item.statuspage_features.subscribers} Status page subscribers `}</li>
                                                  <li
                                                    className={`p-opt-item ${
                                                      item.statuspage_features
                                                        .incident_management ===
                                                      false
                                                        ? 'hide'
                                                        : ''
                                                    }`}
                                                  >{`Incident management`}</li>
                                                  <li
                                                    className={`p-opt-item ${
                                                      item.statuspage_features
                                                        .manual_incident_notifications ===
                                                      false
                                                        ? 'hide'
                                                        : ''
                                                    }`}
                                                  >{`Manual incident notifications`}</li>
                                                  <li
                                                    className={`p-opt-item ${
                                                      item.statuspage_features
                                                        .all_alerts_notifications ===
                                                      false
                                                        ? 'hide'
                                                        : ''
                                                    }`}
                                                  >{`Realtime outage notifications`}</li>
                                                  <li
                                                    className={`p-opt-item ${
                                                      item.statuspage_features
                                                        .private === false
                                                        ? 'hide'
                                                        : ''
                                                    }`}
                                                  >{`Private status page`}</li>
                                                </ul>
                                              </div>
                                              <div className="p-price-wrapper">
                                                <span className="p-price monthly">
                                                  {`${item.monthly_price.amount} $`}
                                                  <span>/month</span>
                                                </span>
                                                <span className="p-price yearly">
                                                  {`${item.yearly_price.amount} $`}
                                                  <span>/year</span>
                                                </span>
                                              </div>
                                              <button
                                                className="btn btn-primary"
                                                data-plan={item.name}
                                                onClick={
                                                  this
                                                    .paymentProcess__goto_step2
                                                }
                                              >
                                                {this.state.hasActivePlan ===
                                                  true &&
                                                this.state.prevPlan ===
                                                  item.name
                                                  ? 'Upgrade plan period'
                                                  : (this.state.prevPlan ===
                                                      'advanced' &&
                                                      ['pro', 'basic'].includes(
                                                        item.name
                                                      )) ||
                                                    (this.state.prevPlan ===
                                                      'pro' &&
                                                      item.name === 'basic')
                                                  ? 'Downgrade'
                                                  : 'Upgrade'}
                                              </button>
                                            </label>
                                          </li>
                                        );
                                      })}
                                </ul>
                              )}
                            </div>
                          )}
                          {this.state.checkout_steps[1].isActive === true && (
                            <div className={`billing-payment_process  `}>
                              <form
                                method="POST"
                                onSubmit={this.handleSubmit__upgradeAccount}
                              >
                                <div className="period-plans">
                                  <ul>
                                    <li
                                      className={`${
                                        this.state.selectedPlanPeriod ===
                                        'yearly'
                                          ? 'active'
                                          : ''
                                      } ${
                                        this.state.prevPlanPeriod ===
                                          'yearly' &&
                                        this.state.prevPlan ===
                                          selectedPlanData.name
                                          ? 'disabled'
                                          : ''
                                      }`}
                                    >
                                      <label className="period-plan--wrapper">
                                        <input
                                          type="radio"
                                          name={selectedPlanData.name}
                                          value="yearly"
                                          defaultChecked={
                                            this.state.selectedPlanPeriod ===
                                            'yearly'
                                              ? true
                                              : false
                                          }
                                          onChange={
                                            this
                                              .paymentProcess__selectPlanPeriod
                                          }
                                          disabled={
                                            this.state.prevPlanPeriod ===
                                              'yearly' &&
                                            this.state.prevPlan ===
                                              selectedPlanData.name
                                              ? true
                                              : false
                                          }
                                        />
                                        <div className="p-name">
                                          {selectedPlanData.public_name}{' '}
                                          <span className="p-period-type">
                                            Yearly
                                          </span>
                                          {this.state.hasActivePlan === true &&
                                            this.state.prevPlanPeriod ===
                                              'yearly' &&
                                            this.state.prevPlan ===
                                              selectedPlanData.name && (
                                              <div className="currentPlan-marker">
                                                Your plan
                                              </div>
                                            )}
                                        </div>
                                        <div className="p-price ">
                                          {`$ ${parseFloat(
                                            selectedPlanData.yearly_price.amount
                                          ).toFixed(2)}`}
                                          <span>per year</span>
                                        </div>
                                      </label>
                                    </li>
                                    <li
                                      className={`${
                                        this.state.selectedPlanPeriod ===
                                        'monthly'
                                          ? 'active'
                                          : ''
                                      } ${
                                        this.state.prevPlanPeriod ===
                                          'monthly' &&
                                        this.state.prevPlan ===
                                          selectedPlanData.name
                                          ? 'disabled'
                                          : ''
                                      }`}
                                    >
                                      <label className="period-plan--wrapper">
                                        <input
                                          type="radio"
                                          name={selectedPlanData.name}
                                          value="monthly"
                                          defaultChecked={
                                            this.state.selectedPlanPeriod ===
                                            'monthly'
                                              ? true
                                              : false
                                          }
                                          onChange={
                                            this
                                              .paymentProcess__selectPlanPeriod
                                          }
                                          disabled={
                                            this.state.prevPlanPeriod ===
                                              'monthly' &&
                                            this.state.prevPlan ===
                                              selectedPlanData.name
                                              ? true
                                              : false
                                          }
                                        />
                                        <div className="p-name">
                                          {selectedPlanData.public_name}{' '}
                                          <span className="p-period-type">
                                            Monthly
                                          </span>
                                          {this.state.hasActivePlan === true &&
                                            this.state.prevPlanPeriod ===
                                              'monthly' &&
                                            this.state.prevPlan ===
                                              selectedPlanData.name && (
                                              <div className="currentPlan-marker">
                                                Your plan
                                              </div>
                                            )}
                                        </div>
                                        <div className="p-price ">
                                          {`$ ${parseFloat(
                                            selectedPlanData.monthly_price
                                              .amount
                                          ).toFixed(2)}`}
                                          <span>per month</span>
                                        </div>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                                {typeof this.state
                                  .unauthrorizedDowngradeReport !==
                                  'undefined' &&
                                this.state.unauthrorizedDowngradeReport !==
                                  null &&
                                this.state.unauthrorizedDowngradeReport.length >
                                  0 ? (
                                  <div className="unauthorized-downgrade-report">
                                    <img
                                      alt="Unauthorized downgrade"
                                      src={icon_unauthorized_downgrade}
                                      width={100}
                                    />
                                    <h3>Unauthorized downgrade</h3>
                                    <p className="muted-text">
                                      Before downgrading, you must first reduce
                                      the number of monitors, the status pages
                                      and the number of users according to the
                                      limits of the selected plan{' '}
                                    </p>
                                    <p className="muted-text">
                                      You will find below the details on the
                                      limitation which could affect the contents
                                      of your account
                                    </p>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Features</th>
                                          <th align="center">Current usage </th>
                                          <th align="center">Basic limit</th>
                                          <th align="center">Must delete</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.unauthrorizedDowngradeReport.map(
                                          (report_item, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <span>
                                                    {report_item.type}
                                                  </span>
                                                </td>
                                                <td align="center">
                                                  <span>
                                                    {report_item.usage}
                                                  </span>
                                                </td>
                                                <td align="center">
                                                  <span>
                                                    {report_item.limit}
                                                  </span>
                                                </td>
                                                <td align="center">
                                                  <span>
                                                    {parseInt(
                                                      report_item.usage
                                                    ) >
                                                    parseInt(report_item.limit)
                                                      ? parseInt(
                                                          report_item.usage
                                                        ) -
                                                        parseInt(
                                                          report_item.limit
                                                        )
                                                      : 'Do nothing'}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div
                                      className={`preview-upcoming-invoice  `}
                                    >
                                      <div className="invoice-wrapper">
                                        {this.state.isLoadingPreviewInvoice ? (
                                          <div className="loading-wrapper">
                                            <LoadingSpinner />
                                          </div>
                                        ) : (
                                          <>
                                            {preview_upcoming_invoice !==
                                              null && (
                                              <>
                                                <div className="p-invoice-title">
                                                  <span>
                                                    Preview upcoming invoice
                                                  </span>
                                                </div>
                                                {preview_upcoming_invoice.lines.data.map(
                                                  (line, index) => {
                                                    let descriptionLine =
                                                      line.description;
                                                    let hasDiscount =
                                                      /^([a-zA-Z0-9\s]+)(\(.*\))([a-zA-Z0-9\s]+)$/gm.exec(
                                                        line.description
                                                      );

                                                    return (
                                                      <div
                                                        className="inv-line"
                                                        key={index}
                                                      >
                                                        <div
                                                          className={`inv-line_desc ${
                                                            hasDiscount !==
                                                            null > 0
                                                              ? 'discount-include'
                                                              : ''
                                                          }`}
                                                        >
                                                          {hasDiscount !==
                                                          null ? (
                                                            <>
                                                              {hasDiscount[1]}
                                                              <span>
                                                                {hasDiscount[2]}
                                                              </span>
                                                              {hasDiscount[3]}
                                                            </>
                                                          ) : (
                                                            descriptionLine
                                                          )}
                                                        </div>
                                                        <div className="inv-line_amount">{`${
                                                          line.currency ===
                                                          'usd'
                                                            ? '$'
                                                            : '$'
                                                        } ${(
                                                          parseFloat(
                                                            line.amount
                                                          ) / 100
                                                        ).toFixed(2)}`}</div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </>
                                            )}
                                            {preview_upcoming_invoice !==
                                              null && (
                                              <>
                                                <div className="inv-line line-footer subtotal">
                                                  <div className="inv-line_desc small-field text-bold">
                                                    Subtotal
                                                  </div>
                                                  <div className="inv-line_amount text-bold">{`${
                                                    preview_upcoming_invoice.currency ===
                                                    'usd'
                                                      ? '$'
                                                      : '$'
                                                  } ${(
                                                    parseFloat(
                                                      preview_upcoming_invoice.subtotal
                                                    ) / 100
                                                  ).toFixed(2)}`}</div>
                                                </div>
                                                {preview_upcoming_invoice
                                                  ?.total_tax_amounts?.length >
                                                  0 && (
                                                  <div className="inv-line line-footer subtotal">
                                                    <div className="inv-line_desc small-field text-bold">
                                                      Total excluding tax
                                                    </div>
                                                    <div className="inv-line_amount text-bold">{`${
                                                      preview_upcoming_invoice.currency ===
                                                      'usd'
                                                        ? '$'
                                                        : '$'
                                                    } ${(
                                                      parseFloat(
                                                        preview_upcoming_invoice?.subtotal_excluding_tax
                                                      ) / 100
                                                    ).toFixed(2)}`}</div>
                                                  </div>
                                                )}
                                                {preview_upcoming_invoice
                                                  .total_discount_amounts
                                                  .length > 0 &&
                                                  preview_upcoming_invoice.total_discount_amounts
                                                    .filter((x) => x.amount > 0)
                                                    .map((i, index) => {
                                                      return (
                                                        <div
                                                          key={index}
                                                          className="inv-line line-footer discount"
                                                        >
                                                          <div className="inv-line_desc small-field">
                                                            Discount{' '}
                                                          </div>
                                                          <div className="inv-line_amount text-bold">{`${
                                                            preview_upcoming_invoice.currency ===
                                                            'usd'
                                                              ? '$'
                                                              : '$'
                                                          } ${(
                                                            (parseFloat(
                                                              i.amount
                                                            ) /
                                                              100) *
                                                            -1
                                                          ).toFixed(2)}`}</div>
                                                        </div>
                                                      );
                                                    })}
                                                {preview_upcoming_invoice
                                                  .total_tax_amounts.length >
                                                  0 && (
                                                  <div className="inv-line line-footer total">
                                                    <div className="inv-line_desc small-field text-muted">
                                                      <span>
                                                        VAT –{' '}
                                                        {preview_upcoming_invoice
                                                          ?.tax_rate
                                                          ?.jurisdiction ??
                                                          ''}{' '}
                                                        (
                                                        {preview_upcoming_invoice
                                                          ?.tax_rate
                                                          ?.effective_percentage ??
                                                          '0'}
                                                        % on $
                                                        {(
                                                          parseFloat(
                                                            preview_upcoming_invoice
                                                              ?.total_tax_amounts?.[0]
                                                              ?.taxable_amount
                                                          ) / 100
                                                        ).toFixed(2)}
                                                        )
                                                      </span>
                                                      {preview_upcoming_invoice
                                                        ?.total_tax_amounts?.[0]
                                                        ?.taxability_reason !==
                                                        null &&
                                                        taxabilityReasons?.findIndex(
                                                          (x) =>
                                                            x.id ===
                                                            preview_upcoming_invoice
                                                              ?.total_tax_amounts?.[0]
                                                              ?.taxability_reason
                                                        ) >= 0 && (
                                                          <span className="explanation-reason">
                                                            <Tooltip
                                                              placement="top"
                                                              trigger={[
                                                                'hover',
                                                              ]}
                                                              overlay={
                                                                <span>
                                                                  {
                                                                    taxabilityReasons?.find(
                                                                      (x) =>
                                                                        x.id ===
                                                                        preview_upcoming_invoice
                                                                          ?.total_tax_amounts?.[0]
                                                                          ?.taxability_reason
                                                                    )
                                                                      ?.explanation
                                                                  }
                                                                </span>
                                                              }
                                                            >
                                                              <IconInfoGray />
                                                            </Tooltip>
                                                          </span>
                                                        )}
                                                    </div>
                                                    <div className="inv-line_amount text-bold">{`${
                                                      preview_upcoming_invoice.currency ===
                                                      'usd'
                                                        ? '$'
                                                        : '$'
                                                    } ${(
                                                      parseFloat(
                                                        preview_upcoming_invoice?.tax
                                                      ) / 100
                                                    ).toFixed(2)}`}</div>
                                                  </div>
                                                )}
                                                <div className="inv-line line-footer total">
                                                  <div className="inv-line_desc small-field text-bold">
                                                    Total
                                                  </div>
                                                  <div className="inv-line_amount text-bold">{`${
                                                    preview_upcoming_invoice.currency ===
                                                    'usd'
                                                      ? '$'
                                                      : '$'
                                                  } ${(
                                                    parseFloat(
                                                      preview_upcoming_invoice.total
                                                    ) / 100
                                                  ).toFixed(2)}`}</div>
                                                </div>
                                                <div className="inv-line line-footer subtotal">
                                                  <div className="inv-line_desc small-field text-muted">
                                                    Applied balance
                                                  </div>
                                                  <div className="inv-line_amount text-bold">{`${
                                                    preview_upcoming_invoice.currency ===
                                                    'usd'
                                                      ? '$'
                                                      : '$'
                                                  } ${(
                                                    parseFloat(
                                                      preview_upcoming_invoice.starting_balance
                                                    ) / 100
                                                  ).toFixed(2)}`}</div>
                                                </div>
                                                <div className="inv-line line-footer amount_due">
                                                  <div className="inv-line_desc small-field text-bold">
                                                    Amount due
                                                  </div>
                                                  <div className="inv-line_amount text-bold">{`${
                                                    preview_upcoming_invoice.currency ===
                                                    'usd'
                                                      ? '$'
                                                      : '$'
                                                  } ${(
                                                    parseFloat(
                                                      preview_upcoming_invoice.amount_due
                                                    ) / 100
                                                  ).toFixed(2)}`}</div>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="payment-form ">
                                      {/* <div className="field-group">
																				<label htmlFor="">Name *</label>
																				<input type="text" name="name" className={`textField ${ formIsSubmited===true && this.state.billingName__response!=="" ? 'error' : ''}`} value={this.state.billingName || ""}   onChange={this.handleChange__editBillingName} />
																				<span className={`fieldText_alert--error ${ formIsSubmited===true && this.state.billingName__response!=="" ? '' : 'hide'}`}>{billingName__response}</span>
																			</div>
																			<div className="field-group">
																				<label htmlFor="">Email *</label>
																				<input type="text" name="email" className={`textField ${ formIsSubmited===true && this.state.billingEmail__response!=="" ? 'error' : ''}`} value={this.state.billingEmail} onChange={this.handleChange__editBillingEmail} />
																				<span className={`fieldText_alert--error ${ formIsSubmited===true && this.state.billingEmail__response!=="" ? '' : 'hide'}`}>{billingEmail__response}</span>
																			</div> */}
                                      <div
                                        className={`billingAddressPreviewCard ${
                                          this.state
                                            .billingAddressPaymentForm_error !==
                                          ''
                                            ? 'error'
                                            : ''
                                        }`}
                                        onClick={
                                          this.openModal_updateBillingAddress
                                        }
                                      >
                                        <h2>
                                          {this.context.connectedUserData
                                            ?.billing_address?.country === null
                                            ? 'Add '
                                            : ''}
                                          Billing address
                                        </h2>
                                        <div className="billingAddress_wrapper">
                                          {this.context.connectedUserData
                                            ?.billing_address?.country ===
                                          null ? (
                                            <div className="no-info-found">
                                              No information found
                                            </div>
                                          ) : (
                                            <>
                                              <div className="bA-email">
                                                {this.context.connectedUserData
                                                  ?.billing_address?.email ??
                                                  ''}
                                              </div>
                                              <div className="bA-name">
                                                {this.context.connectedUserData
                                                  ?.billing_address?.name ?? ''}
                                              </div>
                                              <div className="bA-country">
                                                {countries?.[
                                                  this.context.connectedUserData
                                                    ?.billing_address?.country
                                                ] ?? ''}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                        {this.context.connectedUserData
                                          ?.billing_address?.country !== null &&
                                          this.context.connectedUserData
                                            ?.billing_address?.country !==
                                            '' && (
                                            <button
                                              type="button"
                                              className="btn btn-orange"
                                            >
                                              Edit
                                            </button>
                                          )}
                                      </div>
                                      {this.state
                                        .billingAddressPaymentForm_error !==
                                        '' && (
                                        <div className="error-message card-error ">
                                          {
                                            this.state
                                              .billingAddressPaymentForm_error
                                          }
                                        </div>
                                      )}
                                      <div className="field-group">
                                        <label htmlFor="">Coupon</label>
                                        <input
                                          type="text"
                                          name="coupon"
                                          className="textField"
                                          value={this.state.billingCoupon}
                                          onChange={
                                            this.handleChange__editBillingCoupon
                                          }
                                        />
                                        <span
                                          className={`fieldText_alert--error ${
                                            billingCoupon__response !== ''
                                              ? ''
                                              : 'hide'
                                          }`}
                                        >
                                          {billingCoupon__response}
                                        </span>
                                      </div>
                                      <div className="field-group">
                                        <label htmlFor="">
                                          Your credit card details
                                        </label>
                                        <CardElement
                                          options={{
                                            hidePostalCode: true, // Hide the ZIP code field
                                            // You can also customize other options here if needed
                                          }}
                                        />
                                        <div
                                          className={`card-error ${
                                            this.state.cardErrorsResponse === ''
                                              ? 'hide'
                                              : ''
                                          }`}
                                        >
                                          {this.state.cardErrorsResponse}
                                        </div>
                                      </div>
                                      <div className="field-group submit-fields-group fluid">
                                        <div
                                          className={`alert alert-${
                                            this.state.form_response_status
                                          } ${
                                            this.state.form_response === ''
                                              ? 'hide'
                                              : ''
                                          }`}
                                        >
                                          <div className="">
                                            {this.state.form_response}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )}
                              </form>
                            </div>
                          )}
                        </div>
                        <div className="steps-footer">
                          <button
                            className={`btn btn-info ${
                              this.state.checkout_steps.find(
                                (x) => x.isActive === true
                              ).id === 1
                                ? 'hide'
                                : ''
                            }`}
                            onClick={this.paymentProcess__goto_step1}
                          >
                            Back{' '}
                          </button>
                          {this.state.checkout_steps.find(
                            (x) => x.isActive === true
                          ).id === 2 &&
                            (typeof this.state.unauthrorizedDowngradeReport ===
                              'undefined' ||
                              this.state.unauthrorizedDowngradeReport ===
                                null ||
                              this.state.unauthrorizedDowngradeReport.length <=
                                0) && (
                              <button
                                className="btn btn-primary"
                                onClick={(e) =>
                                  this.handleSubmit__upgradeAccount(e)
                                }
                                disabled={
                                  !stripe ||
                                  isProcessingPayment ||
                                  (this.state.prevPlan ===
                                    this.state.selectedPlan &&
                                    this.state.prevPlanPeriod ===
                                      this.state.selectedPlanPeriod)
                                }
                              >
                                {!stripe ||
                                isProcessingPayment /* || (this.state.prevPlan === this.state.selectedPlan && this.state.prevPlanPeriod === this.state.selectedPlanPeriod) */
                                  ? 'Payment processing...'
                                  : `Pay $ ${amount_due.toFixed(
                                      2
                                    )} and ${changePlan_actionName} my account`}
                              </button>
                            )}
                          {this.state.error_response_step1 !== '' && (
                            <div className="alert alert-danger fluid">
                              {this.state.error_response_step1}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* </>
										} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showModal_cancelSubscription ? (
          <Modal
            title="Subscrpition cancellation"
            closeModal={this.closeModal_cancelSubscription}
            fixedWidthWindow="small-window"
          >
            <div>Are you sure you want to cancel the current subscription?</div>
            <div className="note margin-top-10">
              Please note that your subscription will be canceled automatically
              at the end of the period ({' '}
              <b>
                {this.context.accountData !== null
                  ? moment(
                      this.context.accountData.plan.period_end * 1000
                    ).format('dddd, MMMM Do YYYY')
                  : ''}{' '}
              </b>{' '}
              ), after that we will never withdraw the money from your credit
              card.
            </div>
            <div className="d-flex justify-content-flex-end margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_cancelSubscription}
              >
                Back
              </button>
              <button
                disabled={this.state.isLoadingCancelSubscription}
                className="btn btn-danger float-right margin-left-10"
                onClick={this.cancelSubscription}
              >
                {this.state.isLoadingCancelSubscription === true
                  ? 'Cancelling...'
                  : 'Cancel the subscription'}
              </button>
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_cancelSubscription_status
              } ${
                this.state.modalResponse_cancelSubscription === '' ? 'hide' : ''
              }`}
            >
              {this.state.modalResponse_cancelSubscription}
            </div>
          </Modal>
        ) : (
          ''
        )}
        {
          <ModalRight
            title="Billing Settings"
            closeModal={this.closeModal_updateBillingAddress}
            isDisplay={this.state.showModal_updateBillingAddress}
          >
            <div className="modal_layout">
              <div className="modal_list ">
                <div className="modal-overflow">
                  {this.state.isLoading_getBillingAddress ? (
                    <div className="loading-wrapper">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <form>
                      <div className="payment-form billing-address-form">
                        <div className="field-group">
                          <label htmlFor="">Organization name*</label>
                          <input
                            type="text"
                            name="name"
                            className={`textField ${
                              billingAddressFormValidation?.name !== ''
                                ? 'error'
                                : ''
                            }`}
                            value={billingAddress?.name || ''}
                            onChange={this.handleChangeBillingAddress}
                          />
                          <span
                            className={`fieldText_alert--error ${
                              billingAddressFormValidation?.name !== ''
                                ? ''
                                : 'hide'
                            }`}
                          >
                            {billingAddressFormValidation?.name}
                          </span>
                        </div>
                        <div className="field-group">
                          <label htmlFor="">
                            Billing contact email address*
                          </label>
                          <input
                            type="text"
                            name="email"
                            className={`textField ${
                              billingAddressFormValidation?.email !== ''
                                ? 'error'
                                : ''
                            }`}
                            value={
                              billingAddress?.email ||
                              this.context?.connectedUserData?.email ||
                              ''
                            }
                            onChange={this.handleChangeBillingAddress}
                          />
                          <span
                            className={`fieldText_alert--error ${
                              billingAddressFormValidation?.email !== ''
                                ? ''
                                : 'hide'
                            }`}
                          >
                            {billingAddressFormValidation?.name}
                          </span>
                        </div>
                        <h3 className="margin-top-20">Billing Address</h3>
                        <div className="field-group">
                          <label htmlFor="">Address Line 1*</label>
                          <input
                            type="text"
                            name="address_line_1"
                            className={`textField ${
                              billingAddressFormValidation?.address_line_1 !==
                              ''
                                ? 'error'
                                : ''
                            }`}
                            value={billingAddress?.address_line_1 || ''}
                            onChange={this.handleChangeBillingAddress}
                          />
                          <span
                            className={`fieldText_alert--error ${
                              billingAddressFormValidation?.address_line_1 !==
                              ''
                                ? ''
                                : 'hide'
                            }`}
                          >
                            {billingAddressFormValidation?.address_line_1}
                          </span>
                        </div>
                        <div className="field-group">
                          <label htmlFor="">Address Line 2</label>
                          <input
                            type="text"
                            name="address_line_2"
                            className={`textField  `}
                            value={billingAddress?.address_line_2 || ''}
                            onChange={this.handleChangeBillingAddress}
                          />
                        </div>
                        <div className="field-group">
                          <label htmlFor="">City*</label>
                          <input
                            type="text"
                            name="city"
                            className={`textField ${
                              billingAddressFormValidation?.city !== ''
                                ? 'error'
                                : ''
                            }`}
                            value={billingAddress?.city || ''}
                            onChange={this.handleChangeBillingAddress}
                          />
                          <span
                            className={`fieldText_alert--error ${
                              billingAddressFormValidation?.city !== ''
                                ? ''
                                : 'hide'
                            }`}
                          >
                            {billingAddressFormValidation?.city}
                          </span>
                        </div>
                        <div className="field-group half">
                          <label htmlFor="">ZIP / Postal code*</label>
                          <input
                            type="text"
                            name="zipcode"
                            className={`textField ${
                              billingAddressFormValidation?.zipcode !== ''
                                ? 'error'
                                : ''
                            }`}
                            value={billingAddress?.zipcode || ''}
                            onChange={this.handleChangeBillingAddress}
                          />
                          <span
                            className={`fieldText_alert--error ${
                              billingAddressFormValidation?.zipcode !== ''
                                ? ''
                                : 'hide'
                            }`}
                          >
                            {billingAddressFormValidation?.zipcode}
                          </span>
                        </div>
                        <div className="field-group half">
                          <label htmlFor="">State*</label>
                          <input
                            type="text"
                            name="state"
                            className={`textField ${
                              billingAddressFormValidation?.state !== ''
                                ? 'error'
                                : ''
                            }`}
                            value={billingAddress?.state || ''}
                            onChange={this.handleChangeBillingAddress}
                          />
                          <span
                            className={`fieldText_alert--error ${
                              billingAddressFormValidation?.state !== ''
                                ? ''
                                : 'hide'
                            }`}
                          >
                            {billingAddressFormValidation?.state}
                          </span>
                        </div>
                        <div className="field-group">
                          <label htmlFor="">Country*</label>
                          <select
                            className={`textField ${
                              billingAddressFormValidation?.country !== ''
                                ? 'error'
                                : ''
                            }`}
                            name="country"
                            onChange={this.handleChangeBillingAddress}
                            defaultValue={billingAddress?.country}
                          >
                            {Object.keys(countries).map(function (key, index) {
                              return (
                                <option key={index} value={key}>
                                  {countries[key]}
                                </option>
                              );
                            })}
                          </select>
                          <span
                            className={`fieldText_alert--error ${
                              billingAddressFormValidation?.country !== ''
                                ? ''
                                : 'hide'
                            }`}
                          >
                            {billingAddressFormValidation?.country}
                          </span>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
            <div className="modal_layout-footer d-flex margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_updateBillingAddress}
              >
                Back
              </button>
              {
                // this.state.monitorRows.length > 0 &&
                <button
                  className="btn btn-primary float-left margin-left-10"
                  // disabled={this.state.statusPageSelectedMonitors.length <= 0}
                  onClick={this.handleSubmit__updateBillingAddress}
                >
                  Save
                </button>
              }
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </ModalRight>
        }
      </React.Fragment>
    );
  }
}

// export class InvoiceDescriptionLine extends React.Component {
// 	render(){
// 		let descriptionLine = this.props.description
// 		let hasDiscount = /^[a-zA-Z0-9\s]+(\(.*\))[a-zA-Z0-9\s]+$/gm.exec(this.props.description)
// 		if(hasDiscount!==null){
// 			// Replace the discount pourcent with Span html tag
// 			descriptionLine = hasDiscount[0].replace(hasDiscount[1] , <span>{hasDiscount[1]}</span>)

// 		}
// 		return (
// 			descriptionLine
// 		)
// 	}
// }
