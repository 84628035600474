import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import ReactGA from 'react-ga'
import ReactGA from 'react-ga4';
import { Route } from 'react-router-dom';

class GoogleAnalytics extends Component {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: { pathname, search },
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = '') {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options,
    });
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  options: PropTypes.object,
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = (options = {}, GA_TRACKING_ID, DEBUG = false) => {
  //   const env = window._env_ || {}
  const isGAEnabled = !!GA_TRACKING_ID;

  if (isGAEnabled) {
    ReactGA.initialize(
      GA_TRACKING_ID,
      // {
      // 	debug: true,//DEBUG ?? false,
      // 	...options
      // },
      {
        gtagOptions: {
          debug_mode: false,
        },
      }
    );
  }

  return isGAEnabled;
};

/**
 *
 * @param {*} categoryName
 * @param {*} eventName
 */
// const GAevent = (categoryName, eventName , eventValue = 1 ) => {

//     ReactGA.event({
//         category: categoryName,  // Required
//         action: eventName,       // Required
//         label: 'labelName',
//         value: eventValue ,
//         nonInteraction: false
//     });
// }
const GAevent = (eventName, params = {}) => {
  if (params && typeof params !== 'undefined' && Object.keys(params).length > 0)
    ReactGA.event(eventName, params);
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
  GAevent,
};
