import React, { Component } from 'react';
import axios from 'axios';
import { getItemStorage, setItemStorage } from '../../../utils/storage';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from '../../layout/Navbar/Navbar';
// import LoadingSpinner from './../../layout/LoadingSpinner/LoadingSpinner'
// import Navbar from './../../layout/Navbar/Navbar'
import { HasAccountId } from './../../authentification/SecurityController';
import { ReactComponent as Logo } from './../../../assets/logo/logo.svg';

import AppContext from './../../../context/app-context';

// css
import './Intro.css';

export default class Intro extends Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      form_email: '',
      form_email_placeholder: 'alex@example.com',
      form_phoneNumber: '',
      form_phoneNumber_placeholder: '+44778555666',
      form_accountName: '',
      form_accountName_placeholder: 'e.g. CompanyName Inc.',
      form_response: '',
      form_response_status: 'warning',
      formIsSubmited: false,
      isLoading: false,
      redirectToHomePage: false,
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Intro',
          link: '#',
          isLink: false,
        },
      ],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange_formEmailAddress =
      this.handleChange_formEmailAddress.bind(this);
    this.handleChange_formPhoneNumber =
      this.handleChange_formPhoneNumber.bind(this);
    this.handleChange_formAccountName =
      this.handleChange_formAccountName.bind(this);
  }

  handleChange_formEmailAddress(event) {
    this.setState({
      form_email: event.target.value,
    });
  }

  handleChange_formPhoneNumber(event) {
    this.setState({
      form_phoneNumber: event.target.value,
    });
  }

  handleChange_formAccountName(event) {
    this.setState({
      form_accountName: event.target.value,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { form_email, form_phoneNumber, form_accountName } = this.state;
    // loading ... effect
    this.setState({
      isLoading: true,
      formIsSubmited: true,
    });
    // we check the email alert channel
    if (
      typeof form_email === 'undefined' ||
      form_email === null ||
      form_email === ''
    ) {
      this.setState({
        isLoading: false,
        form_response: 'Please complete all required fields',
        form_response_status: 'warning',
      });
      return;
    }

    // we check the account name
    if (
      typeof form_accountName === 'undefined' ||
      form_accountName === null ||
      form_accountName === ''
    ) {
      this.setState({
        isLoading: false,
        form_response: 'Please complete all required fields',
        form_response_status: 'danger',
      });
      return;
    }

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
      };

      // prepare the alert channels subscriptions list :
      let form_alertChannelSubscriptions = [];
      // Add email channel
      form_alertChannelSubscriptions.push({
        config: {
          address: form_email,
        },
        type: 'EMAIL',
        sendRecovery: true,
        sendDegraded: true,
        sendFailure: true,
        sslExpiry: false,
        sslExpiryThreshold: 30,
        subscriptions: [],
      });

      // we check the sms (phone number ) alert channel
      if (
        typeof form_phoneNumber !== 'undefined' &&
        form_phoneNumber !== null &&
        form_phoneNumber !== ''
      ) {
        form_alertChannelSubscriptions.push({
          config: {
            name: null,
            phoneNumber: form_phoneNumber,
          },
          type: 'SMS',
          sendRecovery: true,
          sendDegraded: true,
          sendFailure: true,
          sslExpiry: false,
          sslExpiryThreshold: 30,
          subscriptions: [],
        });
      }
      // call the api : Create a new account
      axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts`,
          {
            name: form_accountName,
            alertChannelSubscriptions: form_alertChannelSubscriptions,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          return res.data;
        })
        .then((res) => {
          // //console.log(res);
          if (typeof res !== 'undefined' && res !== null) {
            // save the acocunt Id in storage
            setItemStorage(
              `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
              res.id,
              'string'
            );

            // stop loading effect
            this.setState({
              isLoading: false,
              form_response: 'Your account has been successfully updated',
              form_response_status: 'success',
            });

            // update global state > current user Data
            this.context.getConnectedUser();
            // update global state > current account Data
            this.context.getCurrentAccountData();

            this.context.showLeftSideBar();
            // redirect the user to home page ( monitors list )
            // return <Redirect to="/" />
            this.setState({
              redirectToHomePage: true,
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              form_response:
                'an error occurred while updating your account, please try again',
              form_response_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  async componentDidMount() {
    // this.context.hideLeftSideBar();

    // check if the current use has an account id in storage
    const __HasAccountId = await HasAccountId();
    if (__HasAccountId === true) {
      // //console.log('accountId from componentDidMount : ' , __HasAccountId);
      this.setState({
        redirectToHomePage: true,
      });
    } else {
      const userEmail =
        typeof this.context.connectedUserData !== 'undefined' &&
        this.context.connectedUserData !== null
          ? this.context.connectedUserData.email
          : null;
      if (
        typeof userEmail !== 'undefined' &&
        userEmail !== null &&
        this.state.form_email === ''
      ) {
        this.setState({
          form_email: userEmail,
        });
      }
      // //console.log('intro > componentDidMount ' , this.context.connectedUserData)
      if (
        this.context.connectedUserData !== null &&
        typeof this.context.connectedUserData.accounts !== 'undefined' &&
        this.context.connectedUserData !== null &&
        this.context.connectedUserData.accounts.length > 0
      ) {
        // update account Id in storage :::
        setItemStorage(
          `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
          this.context.connectedUserData.accounts[0].id,
          'string'
        );
        this.setState({
          redirectToHomePage: true,
        });
      }
    }
  }

  async componentDidUpdate() {
    const userEmail =
      typeof this.context.connectedUserData !== 'undefined' &&
      this.context.connectedUserData !== null
        ? this.context.connectedUserData.email
        : null;
    if (
      typeof userEmail !== 'undefined' &&
      userEmail !== null &&
      this.state.form_email === ''
    ) {
      this.setState({
        form_email: userEmail,
      });
    }
    // check if the current use has an account id in storage
    const __HasAccountId = await HasAccountId();
    if (__HasAccountId === true) {
      // //console.log('accountId from componentDidUpdate : ' , __HasAccountId);
      // this.setState({
      // 	redirectToHomePage : true
      // });
      this.context.showLeftSideBar();
    }
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    let submitButtonContent = (
      <span className="btn-inner load-spinner">
        <span>Set up my account!</span>
      </span>
    );
    if (this.state.isLoading) {
      submitButtonContent = (
        <span className="btn-inner load-spinner">
          <span className="loader_spinner--content"></span>
          <span>Please wait</span>
        </span>
      );
    }

    if (this.state.redirectToHomePage === true) {
      return <Redirect to="/" />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Setup a new account | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
          hideAccountList={true}
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form  layout__blockInner">
              <div className="layout__intro bg-gris">
                <div className="layout__intro--container">
                  <div className="logo">
                    <Logo />
                  </div>
                  <h1>Let's set up your account...</h1>
                  <p>
                    Where should alerts go? You can add Slack or Webhooks later.
                  </p>
                  <form method="POST" action="" onSubmit={this.handleSubmit}>
                    <div className="field-group">
                      <label htmlFor="">
                        Email address for alerts{' '}
                        <i className="required-tag">*</i>
                      </label>
                      <input
                        type="text"
                        className={`textField fluid ${
                          this.state.formIsSubmited === true &&
                          this.state.form_email === ''
                            ? 'error'
                            : ''
                        }`}
                        value={this.state.form_email}
                        placeholder={this.state.form_email_placeholder}
                        onChange={this.handleChange_formEmailAddress}
                      />
                    </div>
                    <div className="field-group">
                      <label htmlFor="">Mobile Number for sms</label>
                      <input
                        type="text"
                        className={`textField fluid`}
                        value={this.state.form_phoneNumber}
                        placeholder={this.state.form_phoneNumber_placeholder}
                        onChange={this.handleChange_formPhoneNumber}
                      />
                    </div>
                    <p className="description">
                      Choose an account name so team mates can easily recognize
                      your account when they are invited to join.
                    </p>
                    <div className="field-group">
                      <label htmlFor="">
                        Choose an account name <i className="required-tag">*</i>
                      </label>
                      <input
                        type="text"
                        className={`textField fluid ${
                          this.state.formIsSubmited === true &&
                          this.state.form_accountName === ''
                            ? 'error'
                            : ''
                        }`}
                        value={this.state.form_accountName}
                        placeholder={this.state.form_accountName_placeholder}
                        onChange={this.handleChange_formAccountName}
                      />
                    </div>
                    <div className="field-group submit-fields-group fluid">
                      <button type="submit" className="btn btn-primary">
                        {submitButtonContent}
                      </button>
                      <div
                        className={`alert alert-${
                          this.state.form_response_status
                        } ${
                          this.state.form_response === '' ? 'hide' : ''
                        } fluid margin-left-none margin-right-none`}
                      >
                        <div className="">{this.state.form_response}</div>
                      </div>
                      <p className="note">
                        Don't worry, you can change all of this later
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
