import React, { Component } from 'react';

import './LoadingSpinner.css';
export default class LoadingSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: props.size !== 'undefined' ? props.size : '',
    };
  }

  render() {
    return <div className={`spinner ${this.state.size}`}></div>;
  }
}
