import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';
import { getItemStorage, removeItemStorage } from '../../../utils/storage';
import Navbar from '../../layout/Navbar/Navbar';
import NavbarSettings from './NavbarSettings';
import { Helmet } from 'react-helmet';

import AppContext from '../../../context/app-context';

// css
import './General.css';

export default class GeneralSettings extends React.Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      isLoadingAccountData: false,
      formIsSubmited: false,
      form_accountName: '',
      form_accountName_placeholder: '',
      form_accountId: '',
      form_response_status: 'warning',
      form_response: '',
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Account',
          link: '#',
          isLink: false,
        },
      ],
      account_plan: null,
      usage: null,
      hasActiveAccount: true,
      max_usage: {},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange_formAccountName =
      this.handleChange_formAccountName.bind(this);
  }

  /**
   *
   *
   * @memberof EditMonitorForm
   */
  getAccountData = async () => {
    this.setState({
      isLoadingAccountData: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => {
          //console.log(res);
          res = res.data; // axios main response content
          if (
            typeof res !== 'undefined' &&
            res !== null &&
            typeof res.error === 'undefined'
          ) {
            const data = res;

            this.setState({
              isLoadingAccountData: false,
              form_accountId: data.id,
              form_accountName: data.name,
              account_plan: data.plan,
              usage: data.usage, // ! not yet generated from RESTAPI
              max_usage: {
                max_account_users: data.max_account_users,
                max_api_checks: data.max_api_checks,
                max_sms: data.max_sms,
                max_status_pages: data.max_status_pages,
                max_statuspage_subscribers: data.max_statuspage_subscribers,
              },
            });
            // //console.log(data.alertChannelSubscriptions);

            // //console.log(data.alertChannelSubscriptions[0].subscriptions.find(e => e.activated === true));
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoadingAccountData: false,
              hasActiveAccount: false,
            });
            // remote current account id from storage ( connectedUserData element )
            this.context.cleanUserAccount(accountId);
          }
        });
    } else {
    }
  };

  handleChange_formAccountName(event) {
    this.setState({
      form_accountName: event.target.value,
    });
  }

  /**
   *
   * @param {*} event
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    const { form_accountName } = this.state;
    // loading ... effect
    this.setState({
      isLoading: true,
      formIsSubmited: true,
    });
    // we check the name ( name is required field )
    if (
      typeof form_accountName === 'undefined' ||
      form_accountName === null ||
      form_accountName === ''
    ) {
      this.setState({
        isLoading: false,
        form_response: 'Please complete all required fields',
        form_response_status: 'warning',
      });
      return;
    }

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      axios
        .put(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}`,
          {
            name: form_accountName,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          // stop loading effect
          this.setState({
            isLoading: false,
            form_response: 'Your account has been successfully updated',
            form_response_status: 'success',
          });
          //console.log(res);
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              form_response:
                'an error occurred while updating your account, please try again',
              form_response_status: 'success',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  componentDidMount() {
    // Load account data
    this.getAccountData();
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    const {
      isLoadingAccountData,
      formIsSubmited,
      form_accountName,
      form_accountName_placeholder,
      form_accountId,
      form_response_status,
      form_response,
    } = this.state;

    let submitButtonContent = (
      <span className="btn-inner load-spinner">
        <span>Save Account Settings</span>
      </span>
    );
    if (this.state.isLoading) {
      submitButtonContent = (
        <span className="btn-inner load-spinner">
          <span className="loader_spinner--content"></span>
          <span>Saving...</span>
        </span>
      );
    }

    const usageApiChecksStyle = {
      width: `${
        ((typeof this.state.usage !== 'undefined' && this.state.usage !== null
          ? this.state.usage.api_checks
          : 0) /
          (typeof this.state.max_usage !== 'undefined' &&
          this.state.max_usage !== null
            ? this.state.max_usage.max_api_checks
            : 0)) *
        100
      }%`,
    };
    const usageAccountUsersStyle = {
      width: `${
        ((typeof this.state.usage !== 'undefined' && this.state.usage !== null
          ? this.state.usage.account_users
          : 0) /
          (typeof this.state.max_usage !== 'undefined' &&
          this.state.max_usage !== null
            ? this.state.max_usage.max_account_users
            : 0)) *
        100
      }%`,
    };
    const usageSMSStyle = {
      width: `${
        ((typeof this.state.usage !== 'undefined' && this.state.usage !== null
          ? this.state.usage.sms
          : 0) /
          (typeof this.state.max_usage !== 'undefined' &&
          this.state.max_usage !== null
            ? this.state.max_usage.max_sms
            : 0)) *
        100
      }%`,
    };
    const usageStatusPagesStyle = {
      width: `${
        ((typeof this.state.usage !== 'undefined' && this.state.usage !== null
          ? this.state.usage.status_pages
          : 0) /
          (typeof this.state.max_usage !== 'undefined' &&
          this.state.max_usage !== null
            ? this.state.max_usage.max_status_pages
            : 0)) *
        100
      }%`,
    };
    const usageStatusPageSubscribersStyle = {
      width: `${
        ((typeof this.state.usage !== 'undefined' && this.state.usage !== null
          ? this.state.usage.statuspage_subscribers
          : 0) /
          (typeof this.state.max_usage !== 'undefined' &&
          this.state.max_usage !== null
            ? this.state.max_usage.max_statuspage_subscribers
            : 0)) *
        100
      }%`,
    };

    // Check if the user has an active account, else redirect the user to /intro page to create a new account
    if (this.state.hasActiveAccount === false) {
      // remote current account id from strage
      removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      return <Redirect to="/intro" />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Account settings | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__monitor--form layout__blockInner">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>Account settings</h2>
                </div>
                <div className="l_header__btnaction">
                  <div
                    className="btn btn-primary"
                    onClick={(event) => {
                      this.handleSubmit(event);
                    }}
                  >
                    {submitButtonContent}
                  </div>
                </div>
                <div className="l_header__description">
                  <div className="field-group submit-fields-group fluid">
                    <div
                      className={`alert alert-${form_response_status} ${
                        form_response === '' ? 'hide' : ''
                      }`}
                    >
                      <div className="">{form_response}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="layout__body">
                <form method="POST" action="" onSubmit={this.handleSubmit}>
                  <NavbarSettings selectedTab="general" />
                  <div className="tab-settings__content">
                    <div className="ts_content-item white-card margin-bottom-20 active">
                      {isLoadingAccountData ? (
                        <div className="loading-wrapper">
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <>
                          <h2 className="title">General settings</h2>
                          <p className="sub-title">
                            Give your account a recognisable name, so your team
                            mates can easily switch between accounts.
                          </p>
                          <div className="field-group">
                            <label htmlFor="" className="fluid">
                              Account Name *
                            </label>
                            <input
                              type="text"
                              className={`textField fluid ${
                                formIsSubmited === true &&
                                form_accountName === ''
                                  ? 'error'
                                  : ''
                              }`}
                              value={form_accountName}
                              placeholder={form_accountName_placeholder}
                              onChange={this.handleChange_formAccountName}
                            />
                            <span
                              className={`fieldText_alert--error ${
                                formIsSubmited === true &&
                                form_accountName === ''
                                  ? ''
                                  : 'hide'
                              }`}
                            >
                              Name is required
                            </span>
                            <div className="fieldText_note xs-note">
                              Account ID: {form_accountId}{' '}
                            </div>
                          </div>
                          <h3 className="title">Usages </h3>
                          <p className="sub-title">
                            A summary of the usage on your account
                          </p>
                          <div className="usage-items">
                            <ul>
                              <li>
                                <label htmlFor="">Monitors</label>
                                <span className="usage-item_no">
                                  {typeof this.state.usage !== 'undefined' &&
                                  this.state.usage !== null
                                    ? this.state.usage.api_checks
                                    : 0}{' '}
                                  /{' '}
                                  {typeof this.state.max_usage !==
                                    'undefined' && this.state.max_usage !== null
                                    ? this.state.max_usage.max_api_checks
                                    : 0}
                                </span>
                                <span className="usage-item_progressbar-wrapper">
                                  <span
                                    className="usage-item_progressbar"
                                    style={usageApiChecksStyle}
                                  ></span>
                                </span>
                              </li>
                              <li>
                                <label htmlFor="">Team users</label>
                                <span className="usage-item_no">
                                  {typeof this.state.usage !== 'undefined' &&
                                  this.state.usage !== null
                                    ? this.state.usage.account_users
                                    : 0}{' '}
                                  /{' '}
                                  {typeof this.state.max_usage !==
                                    'undefined' &&
                                  this.state.max_usage !== null ? (
                                    this.state.max_usage.max_account_users >=
                                    0 ? (
                                      this.state.max_usage.max_account_users
                                    ) : (
                                      <span
                                        className="infinity-symbol"
                                        title="Unlimited"
                                      >
                                        ∞
                                      </span>
                                    )
                                  ) : (
                                    0
                                  )}
                                </span>
                                <span className="usage-item_progressbar-wrapper">
                                  <span
                                    className="usage-item_progressbar"
                                    style={usageAccountUsersStyle}
                                  ></span>
                                </span>
                                <div className="usage-item_note">
                                  Including the account owner
                                </div>
                              </li>
                              <li>
                                <label htmlFor="">SMS credits</label>
                                <span className="usage-item_no">
                                  {typeof this.state.usage !== 'undefined' &&
                                  this.state.usage !== null
                                    ? this.state.usage.sms
                                    : 0}{' '}
                                  /{' '}
                                  {typeof this.state.max_usage !==
                                    'undefined' && this.state.max_usage !== null
                                    ? this.state.max_usage.max_sms
                                    : 0}
                                </span>
                                <span className="usage-item_progressbar-wrapper">
                                  <span
                                    className="usage-item_progressbar"
                                    style={usageSMSStyle}
                                  ></span>
                                </span>
                              </li>
                              <li>
                                <label htmlFor="">Status pages</label>
                                <span className="usage-item_no">
                                  {typeof this.state.usage !== 'undefined' &&
                                  this.state.usage !== null
                                    ? this.state.usage.status_pages
                                    : 0}{' '}
                                  /{' '}
                                  {typeof this.state.max_usage !==
                                    'undefined' &&
                                  this.state.max_usage !== null ? (
                                    this.state.max_usage.max_status_pages >=
                                    0 ? (
                                      this.state.max_usage.max_status_pages
                                    ) : (
                                      <span
                                        className="infinity-symbol"
                                        title="Unlimited"
                                      >
                                        ∞
                                      </span>
                                    )
                                  ) : (
                                    0
                                  )}
                                </span>
                                <span className="usage-item_progressbar-wrapper">
                                  <span
                                    className="usage-item_progressbar"
                                    style={usageStatusPagesStyle}
                                  ></span>
                                </span>
                              </li>
                              <li>
                                <label htmlFor="">
                                  Status page subscribers
                                </label>
                                <span className="usage-item_no">
                                  {typeof this.state.usage !== 'undefined' &&
                                  this.state.usage !== null
                                    ? this.state.usage.statuspage_subscribers
                                    : 0}{' '}
                                  /{' '}
                                  {typeof this.state.max_usage !==
                                    'undefined' &&
                                  this.state.max_usage !== null ? (
                                    this.state.max_usage
                                      .max_statuspage_subscribers >= 0 ? (
                                      this.state.max_usage
                                        .max_statuspage_subscribers
                                    ) : (
                                      <span
                                        className="infinity-symbol"
                                        title="Unlimited"
                                      >
                                        ∞
                                      </span>
                                    )
                                  ) : (
                                    0
                                  )}
                                </span>
                                <span className="usage-item_progressbar-wrapper">
                                  <span
                                    className="usage-item_progressbar"
                                    style={usageStatusPageSubscribersStyle}
                                  ></span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
