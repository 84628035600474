import React, { Component } from 'react';
import './Modal.css';

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_title:
        typeof this.props.title !== 'undefined' ? this.props.title : '',
      modal_close_btn_showed:
        typeof this.props.hideCloseBtn !== 'undefined' &&
        this.props.hideCloseBtn === true
          ? false
          : true,
      modal_close_btn_value: 'Close',
      hasCloseButton:
        typeof this.props.hasCloseButton !== 'undefined'
          ? this.props.hasCloseButton
          : true,
      fixedWidthWindow:
        typeof this.props.fixedWidthWindow !== 'undefined'
          ? this.props.fixedWidthWindow
          : '',
    };
  }

  render() {
    return (
      <div
        className={`modal fade show ${this.state.fixedWidthWindow}`}
        id="modal"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2>{this.state.modal_title}</h2>
              {this.state.hasCloseButton && (
                <button
                  title={this.state.modal_close_btn_value}
                  type="button"
                  aria-label="Close"
                  className="close"
                  onClick={this.props.closeModal}
                >
                  <span aria-hidden="true">×</span>
                </button>
              )}
            </div>
            <div className="modal-body">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
