import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import Switch from 'react-switch';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';
// Tooltip component
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import { getLocationData } from '../../../utils/helper';
import ReactCountryFlag from 'react-country-flag';
import { getItemStorage } from './../../../utils/storage';
// load Components
import LoadingSpinner from './../../layout/LoadingSpinner/LoadingSpinner';
import Navbar from './../../layout/Navbar/Navbar';
import Modal from './../../layout/Modal/Modal';
import StatusIndicator from '../../layout/StatusIndicator/StatusIndicator';
import GA from './../../../components/Tracking/GoogleAnalytics';

import AppContext from './../../../context/app-context';

// include css file
import './MonitorDetails.css';

export default class MonitorResultsLog extends Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      monitorId: props.match.params.monitorId, // capture from url (/monitors/:monitorId)
      monitorName: '',
      monitorMethod: null,
      monitorUrl: null,
      monitorLocations: [],
      resultsLogData: [],

      loadingResultsLog: false,
      loadingMonitorData: false,
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
      ],
      showModal_deleteMonitor: false,
      modalResponse_status: 'warning',
      modalResponse: '',
      monitorNotFound: false,
      isActiveMonitor: false,
      isDownMonitor: false,
      // Pagination config
      offset: 0,
      perPage: 10,
      currentPage: 0,
      pageCount: 0,
      main_header_menu: [
        {
          name: 'Overview',
          link: `/monitors/${props.match.params.monitorId}/`,
          is_active: false,
        },
        {
          name: 'Outages',
          link: `/monitors/${props.match.params.monitorId}/outages`,
          is_active: false,
        },
        {
          name: 'Results Log',
          link: `/monitors/${props.match.params.monitorId}/results-log`,
          is_active: true,
        },
      ],
      resultsLogData_response: '',
      resultsLogData_response_status: 'warning',
    };
    this.openModal_deleteMonitor = this.openModal_deleteMonitor.bind(this);
    this.closeModal_deleteMonitor = this.closeModal_deleteMonitor.bind(this);
    this.deleteMonitor = this.deleteMonitor.bind(this);

    this.handleChange_formSwitchActivatedMonitor =
      this.handleChange_formSwitchActivatedMonitor.bind(this);
  }

  getMonitorData = async () => {
    this.setState({
      loadingMonitorData: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`, //'JWT token...'
              'X-Odown-Account': accountId,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          const data = res.data;

          this.setState({
            averageKeys: {
              responsetime:
                typeof data.elapsed_time !== 'undefined'
                  ? parseFloat(
                      data.elapsed_time.toFixed(2).replace(/[.,]00$/, '')
                    )
                  : 0,
              uptime:
                typeof data.uptime !== 'undefined'
                  ? parseFloat(data.uptime.toFixed(2).replace(/[.,]00$/, ''))
                  : 0,
              apdex:
                typeof data.apdex !== 'undefined'
                  ? parseFloat(data.apdex.toFixed(2).replace(/[.,]00$/, ''))
                  : 0,
              errors:
                typeof data.downtime !== 'undefined'
                  ? parseFloat(data.downtime.toFixed(2).replace(/[.,]00$/, ''))
                  : 0,
            },
            monitorName: data.name,
            monitorMethod: data.method,
            monitorUrl: data.url,
            monitorLocations: data.locations,
            breadcrumbs_items: this.state.breadcrumbs_items.push({
              name: data.name.toLowerCase(),
              link: data.id,
              isLink: false,
            }),
            loadingMonitorData: false,
            isActiveMonitor: data.is_active,
            isDownMonitor: data.is_down,
          });
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              loadingMonitorData: false,
              monitorNotFound: true,
              breadcrumbs_items: this.state.breadcrumbs_items.push({
                name: 'Monitor Not found',
                link: '#',
                isLink: false,
              }),
            });
          }
        });
    } else {
      this.setState({
        loadingMonitorData: false,
        monitorNotFound: true,
        breadcrumbs_items: this.state.breadcrumbs_items.push({
          name: 'Monitor Not found',
          link: '#',
          isLink: false,
        }),
      });
    }
  };

  getResultsLog = async () => {
    this.setState({
      loadingResultsLog: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}/results-log`,
          {
            params: {
              page: this.state.offset,
              per_page: this.state.perPage,
            },
            headers: {
              Authorization: `Bearer ${token}`, //'JWT token...'
              'X-Odown-Account': accountId,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          const data = res.data;
          if (typeof data !== 'undefined' && data !== null) {
            if (data.total > 0) {
              this.setState({
                loadingResultsLog: false,
                pageCount: Math.ceil(data.total / this.state.perPage),
                resultsLogData: data.data,
                resultsLogData_response: '',
              });
            } else {
              this.setState({
                loadingResultsLog: false,
                pageCount: 0,
                resultsLogData: [],
                resultsLogData_response:
                  "You don't have any check results log yet!",
                resultsLogData_response_status: 'warning',
              });
            }
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              loadingResultsLog: false,
              resultsLogData_response:
                'Something went wrong, please try again later.',
              resultsLogData_response_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        loadingResultsLog: false,
      });
    }
  };

  /**
   *
   * @param {*} event
   */
  openModal_deleteMonitor(event) {
    this.setState({
      showModal_deleteMonitor: true,
    });
  }

  /**
   *
   * @param {*} event
   */
  closeModal_deleteMonitor(event) {
    this.setState({
      showModal_deleteMonitor: !this.state.showModal_deleteMonitor,
      modalResponse: '',
    });
  }

  /**
   *
   *
   * @param {*} event
   * @memberof EditMonitorForm
   */
  deleteMonitor = async (event) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}`,
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            // const selectedAlertChannelId = this.state.alertChannelId;
            // ? Google Analytics Event : Delete Monitor Item
            GA.GAevent('Monitors', 'Delete Monitor');

            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse: 'Monitor removed successfully',
              modalResponse_status: 'success',
              showModal_deleteMonitor: false,
            });
          } else {
            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse: 'Something went wrong removing your monitor',
              modalResponse_status: 'danger',
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              modalResponse: 'Something went wrong removing your monitor',
              modalResponse_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        modalResponse: 'You are not authorized to do this operation',
        modalResponse_status: 'danger',
      });
      return;
    }
  };

  /**
   *
   * @param {*} checked
   * @param {*} event
   * @param {*} id
   */
  handleChange_formSwitchActivatedMonitor = async (checked, event, id) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors/${this.state.monitorId}`,
          {
            is_active: checked,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          // stop loading effect
          this.setState({
            isLoading: false,
            form_response: 'Your monitor has been successfully updated',
            form_response_status: 'success',
            isActiveMonitor: checked,
          });
          //console.log(res);
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              form_response:
                'an error occurred while updating a monitor, please try again',
              form_response_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getResultsLog(offset);
      }
    );
  };

  sortDataByDate = (items) =>
    [...items].sort(
      (itemA, itemB) =>
        new Date(itemA.selectedDate) - new Date(itemB.selectedDate)
    );

  /**
   *
   */
  async componentDidMount() {
    // Retrive general monitor data
    await this.getMonitorData();
    // Retreive the last outages
    this.getResultsLog();
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    const { loadingMonitorData, loadingResultsLog } = this.state;

    if (this.state.modalResponse_status === 'success') {
      return <Redirect to="/" />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Monitor results log | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__monitor--details layout__blockInner">
              {this.state.monitorNotFound === true ? (
                <div className="layout__notFound white-card">
                  <h2>Not Found</h2>
                  <p>
                    Unfortunately, this monitor does not exist in the current
                    account.
                  </p>
                </div>
              ) : (
                <React.Fragment>
                  <div className="layout__header white-card padding-bottom-none">
                    {loadingMonitorData ? (
                      <div className="loading-wrapper">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <>
                        <div className="monitor__info limited-text">
                          <div className="monitor__name">
                            <StatusIndicator
                              status={
                                this.state.isActiveMonitor === false
                                  ? ''
                                  : this.state.isDownMonitor === true
                                  ? 'danger'
                                  : 'success'
                              }
                              title={
                                this.state.isActiveMonitor === false
                                  ? 'Inactive Monitor'
                                  : this.state.isDownMonitor === true
                                  ? 'Failure Monitor'
                                  : 'Active Monitor'
                              }
                              size="xsmall"
                            />
                            <h2 className="limited-text">
                              {this.state.monitorName.toLowerCase()}
                            </h2>
                            <div className="monitor__runlocations">
                              {this.state.monitorLocations
                                ? this.state.monitorLocations.map(
                                    (item, index) => {
                                      const locationInfo =
                                        getLocationData(item);
                                      if (typeof locationInfo !== 'undefined')
                                        return (
                                          <Tooltip
                                            key={index}
                                            placement="bottom"
                                            trigger={['hover']}
                                            overlay={
                                              <span>{`${locationInfo.location} ( ${locationInfo.id} ) - ${locationInfo.countryName}`}</span>
                                            }
                                          >
                                            <ReactCountryFlag
                                              key={index}
                                              countryCode={
                                                locationInfo.countryCode
                                              }
                                              svg
                                              title={locationInfo.countryName}
                                              aria-label={
                                                locationInfo.countryName
                                              }
                                            />
                                          </Tooltip>
                                        );
                                    }
                                  )
                                : ''}
                            </div>
                          </div>
                          <div className="monitor__request">
                            <div className="request_method">
                              {this.state.monitorMethod}
                            </div>
                            <div className="request_url">
                              {this.state.monitorUrl}
                            </div>
                          </div>
                        </div>
                        <div className="monitor__btnAction">
                          <div className="btn-switch">
                            <Switch
                              onChange={
                                this.handleChange_formSwitchActivatedMonitor
                              }
                              checked={this.state.isActiveMonitor}
                              onColor="#1f80f9"
                              // onHandleColor="#2693e6"
                              handleDiameter={18}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              width={48}
                              height={22}
                              className="react-switch"
                              id={'switch-activated-monitor'}
                            />
                            <label>Activated</label>
                          </div>
                          <div
                            className="btn btn-danger"
                            onClick={this.openModal_deleteMonitor}
                          >
                            Delete
                          </div>
                          <div className="btn btn-primary has-link">
                            <Link to={`/monitors/${this.state.monitorId}/edit`}>
                              Edit
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                    {
                      // Show here the main menu of Monitor details ( Overview , ...)
                      typeof this.state.main_header_menu !== 'undefined' &&
                        this.state.main_header_menu !== null &&
                        this.state.main_header_menu.length > 0 && (
                          <div className="layout-header__bottom_menu">
                            <ul>
                              {this.state.main_header_menu.map((x, index) => (
                                <li
                                  key={index}
                                  className={
                                    x.is_active === true ? 'active' : ''
                                  }
                                >
                                  <Link to={x.link}>{x.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )
                    }
                  </div>
                  <div className="details__card--wrapper ">
                    <div className="details__card col-full">
                      <div className="details__card-wrapper log__container">
                        <div className="log__column results-log-column">
                          <h2>
                            <span>Check Results Log</span>
                            <i>Showing last 24 hours</i>
                          </h2>
                          <div className="log__content">
                            <table
                              className={`default-table results-log-table ${
                                loadingResultsLog ? 'isLoading' : ''
                              }`}
                            >
                              <thead>
                                <tr>
                                  <th></th>
                                  <th align="center">Code</th>
                                  <th align="left">Response Time</th>
                                  <th align="center">Timing</th>
                                  <th align="left">Run Location</th>
                                  <th align="right">Timestamp</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.resultsLogData.length > 0 &&
                                this.state.resultsLogData_response === '' ? (
                                  this.state.resultsLogData.map(
                                    (item, index) => {
                                      const locationInfo = getLocationData(
                                        item.run_location
                                      );
                                      if (typeof locationInfo !== 'undefined')
                                        return (
                                          <tr
                                            className="page__item"
                                            key={index}
                                          >
                                            <td align="center">
                                              <div className={`field-content`}>
                                                <span
                                                  className={`r-log_status ${
                                                    item.error === 1 ||
                                                    item.uptime === 0
                                                      ? 'down'
                                                      : 'up'
                                                  }`}
                                                ></span>
                                              </div>
                                            </td>
                                            <td align="center">
                                              <div className="field-content">
                                                <span
                                                  className={`r-log_statuscode ${
                                                    item.error === 1 ||
                                                    item.uptime === 0
                                                      ? 'down'
                                                      : 'up'
                                                  }`}
                                                >
                                                  {item.statuscode !== ''
                                                    ? item.statuscode
                                                    : '-'}
                                                </span>
                                              </div>
                                            </td>
                                            <td align="left">
                                              <div className="field-content responsetime">
                                                <div className="icon text-muted">
                                                  <svg
                                                    version="1.1"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    className="octicon octicon-clock"
                                                    aria-hidden="true"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.5 4.75a.75.75 0 00-1.5 0v3.5a.75.75 0 00.471.696l2.5 1a.75.75 0 00.557-1.392L8.5 7.742V4.75z"
                                                    ></path>
                                                  </svg>
                                                </div>
                                                <span>{`${item.responsetime} ms`}</span>
                                              </div>
                                            </td>
                                            <td align="center">
                                              <div className="field-content">
                                                <Tooltip
                                                  key={index}
                                                  placement="top"
                                                  trigger={['hover']}
                                                  overlay={
                                                    <div className="global-latency-bar__tooltip">
                                                      <span className={`wait`}>
                                                        <span className="color"></span>
                                                        <span className="name">
                                                          Wait
                                                        </span>
                                                        <span className="vl">
                                                          {item.latency.wait +
                                                            ' ms'}
                                                        </span>
                                                      </span>
                                                      <span
                                                        className={`dnslookup`}
                                                      >
                                                        <span className="color"></span>
                                                        <span className="name">
                                                          DNS Lookup
                                                        </span>
                                                        <span className="vl">
                                                          {item.latency
                                                            .dnslookup + ' ms'}
                                                        </span>
                                                      </span>
                                                      <span
                                                        className={`tcpconnection`}
                                                      >
                                                        <span className="color"></span>
                                                        <span className="name">
                                                          TCP Connection
                                                        </span>
                                                        <span className="vl">
                                                          {item.latency
                                                            .tcpconnection +
                                                            ' ms'}
                                                        </span>
                                                      </span>
                                                      <span
                                                        className={`tlshandshake`}
                                                      >
                                                        <span className="color"></span>
                                                        <span className="name">
                                                          TLS Handshake
                                                        </span>
                                                        <span className="vl">
                                                          {item.latency
                                                            .tlshandshake +
                                                            ' ms'}
                                                        </span>
                                                      </span>
                                                      <span
                                                        className={`firstbyte`}
                                                      >
                                                        <span className="color"></span>
                                                        <span className="name">
                                                          First Byte
                                                        </span>
                                                        <span className="vl">
                                                          {item.latency
                                                            .firstbyte + ' ms'}
                                                        </span>
                                                      </span>
                                                      <span
                                                        className={`request`}
                                                      >
                                                        <span className="color"></span>
                                                        <span className="name">
                                                          Request
                                                        </span>
                                                        <span className="vl">
                                                          {item.latency
                                                            .request + ' ms'}
                                                        </span>
                                                      </span>
                                                      <span
                                                        className={`contenttransfer`}
                                                      >
                                                        <span className="color"></span>
                                                        <span className="name">
                                                          Content Transfer
                                                        </span>
                                                        <span className="vl">
                                                          {item.latency
                                                            .contenttransfer +
                                                            ' ms'}
                                                        </span>
                                                      </span>
                                                    </div>
                                                  }
                                                >
                                                  <div className="r-log_timing global-latency-bar">
                                                    <span
                                                      style={{
                                                        width: `${
                                                          (item.latency.wait /
                                                            item.latency
                                                              .total) *
                                                          100
                                                        }%`,
                                                      }}
                                                      className={`ltcy-wait`}
                                                    ></span>
                                                    <span
                                                      style={{
                                                        width: `${
                                                          (item.latency
                                                            .dnslookup /
                                                            item.latency
                                                              .total) *
                                                          100
                                                        }%`,
                                                      }}
                                                      className={`ltcy-dnslookup`}
                                                    ></span>
                                                    <span
                                                      style={{
                                                        width: `${
                                                          (item.latency
                                                            .tcpconnection /
                                                            item.latency
                                                              .total) *
                                                          100
                                                        }%`,
                                                      }}
                                                      className={`ltcy-tcpconnection`}
                                                    ></span>
                                                    <span
                                                      style={{
                                                        width: `${
                                                          (item.latency
                                                            .tlshandshake /
                                                            item.latency
                                                              .total) *
                                                          100
                                                        }%`,
                                                      }}
                                                      className={`ltcy-tlshandshake`}
                                                    ></span>
                                                    <span
                                                      style={{
                                                        width: `${
                                                          (item.latency
                                                            .firstbyte /
                                                            item.latency
                                                              .total) *
                                                          100
                                                        }%`,
                                                      }}
                                                      className={`ltcy-firstbyte`}
                                                    ></span>
                                                    <span
                                                      style={{
                                                        width: `${
                                                          (item.latency
                                                            .request /
                                                            item.latency
                                                              .total) *
                                                          100
                                                        }%`,
                                                      }}
                                                      className={`ltcy-request`}
                                                    ></span>
                                                    <span
                                                      style={{
                                                        width: `${
                                                          (item.latency
                                                            .contenttransfer /
                                                            item.latency
                                                              .total) *
                                                          100
                                                        }%`,
                                                      }}
                                                      className={`ltcy-contenttransfer`}
                                                    ></span>
                                                  </div>
                                                </Tooltip>
                                              </div>
                                            </td>
                                            <td align="left">
                                              <div className="field-content">
                                                <div className="chk-location">
                                                  <Tooltip
                                                    placement="top"
                                                    trigger={['hover']}
                                                    overlay={
                                                      <span>{`${locationInfo.location} ( ${locationInfo.id} ) - ${locationInfo.countryName}`}</span>
                                                    }
                                                  >
                                                    <ReactCountryFlag
                                                      countryCode={
                                                        locationInfo.countryCode
                                                      }
                                                      svg
                                                      title={
                                                        locationInfo.countryName
                                                      }
                                                      aria-label={
                                                        locationInfo.countryName
                                                      }
                                                    />
                                                  </Tooltip>
                                                  <span className="flag-icon flag-icon-us"></span>
                                                  <span>{`${locationInfo.location}`}</span>
                                                </div>
                                              </div>
                                            </td>
                                            <td align="right">
                                              <div className="field-content">
                                                {moment(item.date).format(
                                                  'MMM DD, YYYY hh:mm A'
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan="6">
                                      <div
                                        className={`alert alert-${
                                          this.state
                                            .resultsLogData_response_status
                                        } text-center ${
                                          this.state.resultsLogData_response ===
                                          ''
                                            ? 'hide'
                                            : ''
                                        }`}
                                      >
                                        {this.state.resultsLogData_response}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            {this.state.pageCount > 0 && (
                              <ReactPaginate
                                previousLabel={''}
                                nextLabel={''}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        {this.state.showModal_deleteMonitor ? (
          <Modal
            title="Delete this monitor?"
            closeModal={this.closeModal_deleteMonitor}
            fixedWidthWindow="small-window"
          >
            <div>
              Deleting this check will erase all historical data like check
              results and alerts.
            </div>
            <div className="d-flex justify-content-flex-end margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_deleteMonitor}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger float-right margin-left-10"
                onClick={this.deleteMonitor}
              >
                Delete
              </button>
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </Modal>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}
