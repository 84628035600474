import React from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
// import { Button,TextField,Container, Grid, Paper ,makeStyles } from '@material-ui/core';
import PrivateRoute from './components/authentification/PrivateRoute';

// import pages
import Monitors from './components/Root/Monitors';
import MonitorDetails from './components/Root/MonitorDetails';
import MonitorOutages from './components/Root/MonitorDetails/MonitorOutages';
import MonitorResultsLog from './components/Root/MonitorDetails/MonitorResultsLog';

import NewMonitorForm from './components/Root/MonitorForm/NewMonitorForm';
import EditMonitorForm from './components/Root/MonitorForm/EditMonitorForm';

import GeneralSettings from './components/Root/Settings/General';
import TeamSettings from './components/Root/Settings/Team';
import BillingSettings from './components/Root/Settings/Billing';
import SmsSettings from './components/Root/Settings/SmsSettings';

import AlertSettings from './components/Root/AlertSettings';
import AlertSettingsEmailEdit from './components/Root/AlertSettings/Email/AlertSettingsEmailEdit';
import AlertSettingsEmailNew from './components/Root/AlertSettings/Email/AlertSettingsEmailNew';
import AlertSettingsSlackEdit from './components/Root/AlertSettings/Slack/AlertSettingsSlackEdit';
import AlertSettingsSlackNew from './components/Root/AlertSettings/Slack/AlertSettingsSlackNew';
import AlertSettingsSmsEdit from './components/Root/AlertSettings/Sms/AlertSettingsSmsEdit';
import AlertSettingsSmsNew from './components/Root/AlertSettings/Sms/AlertSettingsSmsNew';
import AlertSettingsWebhookNew from './components/Root/AlertSettings/Webhook/AlertSettingsWebhookNew';
import AlertSettingsWebhookEdit from './components/Root/AlertSettings/Webhook/AlertSettingsWebhookEdit';
import AlertSettingsTelegramNew from './components/Root/AlertSettings/Telegram/AlertSettingsTelegramNew';
import AlertSettingsTelegramEdit from './components/Root/AlertSettings/Telegram/AlertSettingsTelegramEdit';
import AlertSettingsPagerdutyNew from './components/Root/AlertSettings/PagerDuty/AlertSettingsPagerdutyNew';
import AlertSettingsPagerdutyEdit from './components/Root/AlertSettings/PagerDuty/AlertSettingsPagerdutyEdit';
import AlertSettingsOpsgenieNew from './components/Root/AlertSettings/Opsgenie/AlertSettingsOpsgenieNew';
import AlertSettingsOpsgenieEdit from './components/Root/AlertSettings/Opsgenie/AlertSettingsOpsgenieEdit';
import AlertSettingsDiscordNew from './components/Root/AlertSettings/Discord/AlertSettingsDiscordNew';
import AlertSettingsDiscordEdit from './components/Root/AlertSettings/Discord/AlertSettingsDiscordEdit';

// Incidents
import Incidents from './components/Root/Incidents/Incidents';
import NewIncidentForm from './components/Root/Incidents/NewIncidentForm';
import EditIncidentForm from './components/Root/Incidents/EditIncidentForm';

// Maintenance
import Maintenances from './components/Root/Maintenances/Maintenances';
import NewMaintenanceForm from './components/Root/Maintenances/NewMaintenanceForm';
import EditMaintenanceForm from './components/Root/Maintenances/EditMaintenanceForm';

import Signin from './components/Root/Auth/Signin';
import Signup from './components/Root/Auth/Signup';
import Signout from './components/Root/Auth/Signout';
import ForgotPassword from './components/Root/Auth/ForgotPassword';
import ResetPassword from './components/Root/Auth/ResetPassword';

import JoinTeam from './components/Root/Auth/JoinTeam';
import EmailVerify from './components/Root/Auth/EmailVerify';
import Intro from './components/Root/Intro/Intro';

import NewStatusPage from './components/Root/StatusPageForm/NewStatusPage';
import EditStatusPage from './components/Root/StatusPageForm/EditStatusPage';
import StatusPages from './components/Root/StatusPages';

import GlobalState from './context/GlobalState';

// 404 page
import GenericNotFound from './components/Root/Generic/GenericNotFound';

// Load Stripe
import MainDashboard from './components/layout/MainDashboard/MainDashboard.js';

import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import GA from './components/Tracking/GoogleAnalytics';

// import app css
import './App.css';

const stripePromise =
  process.env.REACT_APP_LIVE_PAYMENT === 'true'
    ? loadStripe(process.env.REACT_APP_STRIPE_LIVE_API_KEY)
    : loadStripe(process.env.REACT_APP_STRIPE_TEST_API_KEY);

// const Root = () => {
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <GlobalState>
        <BrowserRouter>
          {/* <div id="app" className={`appContainer`}   >
						<div className="main-wrapper">
							<LeftSidebar /> */}
          {/* <main className="mainContainer"> */}
          <MainDashboard>
            {GA.init({}, 'G-71MPRLME5E', false) && <GA.RouteTracker />}
            <Switch>
              <Route component={Signin} exact path="/signin" />
              <Route component={Signup} exact path="/signup" />
              <Route component={Signout} exact path="/signout" />
              <Route component={JoinTeam} exact path="/join-team/:inviteId" />
              <Route
                component={EmailVerify}
                exact
                path="/email-verify/:token"
              />
              <Route component={ForgotPassword} exact path="/forgot-password" />
              <Route
                component={ResetPassword}
                exact
                path="/reset-password/:token"
              />
              <PrivateRoute>
                <Route component={Monitors} exact path="/" />
                <Route component={NewMonitorForm} exact path="/monitors/new" />
                <Route
                  component={EditMonitorForm}
                  exact
                  path="/monitors/:monitorId/edit"
                />
                <Route
                  component={MonitorDetails}
                  exact
                  path="/monitors/:monitorId([a-z0-9-]{10,})"
                />
                <Route
                  component={MonitorOutages}
                  exact
                  path="/monitors/:monitorId([a-z0-9-]{10,})/outages"
                />
                <Route
                  component={MonitorResultsLog}
                  exact
                  path="/monitors/:monitorId([a-z0-9-]{10,})/results-log"
                />
                <Route component={AlertSettings} exact path="/settings/alert" />
                <Route
                  component={AlertSettingsEmailEdit}
                  exact
                  path="/settings/alert/channels/edit/email/:alertChannelId"
                />
                <Route
                  component={AlertSettingsEmailNew}
                  exact
                  path="/settings/alert/channels/new/email"
                />
                <Route
                  component={AlertSettingsSlackEdit}
                  exact
                  path="/settings/alert/channels/edit/slack/:alertChannelId"
                />
                <Route
                  component={AlertSettingsSlackNew}
                  exact
                  path="/settings/alert/channels/new/slack"
                />
                <Route
                  component={AlertSettingsSmsEdit}
                  exact
                  path="/settings/alert/channels/edit/sms/:alertChannelId"
                />
                <Route
                  component={AlertSettingsSmsNew}
                  exact
                  path="/settings/alert/channels/new/sms"
                />
                <Route
                  component={AlertSettingsWebhookNew}
                  exact
                  path="/settings/alert/channels/new/webhook"
                />
                <Route
                  component={AlertSettingsWebhookEdit}
                  exact
                  path="/settings/alert/channels/edit/webhook/:alertChannelId"
                />
                <Route
                  component={AlertSettingsTelegramNew}
                  exact
                  path="/settings/alert/channels/new/telegram"
                />
                <Route
                  component={AlertSettingsTelegramEdit}
                  exact
                  path="/settings/alert/channels/edit/telegram/:alertChannelId"
                />
                <Route
                  component={AlertSettingsPagerdutyNew}
                  exact
                  path="/settings/alert/channels/new/pagerduty"
                />
                <Route
                  component={AlertSettingsPagerdutyEdit}
                  exact
                  path="/settings/alert/channels/edit/pagerduty/:alertChannelId"
                />
                <Route
                  component={AlertSettingsOpsgenieNew}
                  exact
                  path="/settings/alert/channels/new/opsgenie"
                />
                <Route
                  component={AlertSettingsOpsgenieEdit}
                  exact
                  path="/settings/alert/channels/edit/opsgenie/:alertChannelId"
                />
                <Route
                  component={AlertSettingsDiscordNew}
                  exact
                  path="/settings/alert/channels/new/discord"
                />
                <Route
                  component={AlertSettingsDiscordEdit}
                  exact
                  path="/settings/alert/channels/edit/discord/:alertChannelId"
                />
                {/* Statup pages routes  */}
                <Route
                  component={NewStatusPage}
                  exact
                  path="/statuspages/new"
                />
                <Route
                  component={EditStatusPage}
                  exact
                  path="/statuspages/:statuspageId/edit"
                />
                <Route
                  exact
                  path="/statuspages/:statuspageId"
                  render={(props) => {
                    if (props.match.params.statuspageId !== 'new')
                      return (
                        <Redirect
                          to={`/statuspages/${props.match.params.statuspageId}/edit`}
                        />
                      );
                  }}
                />
                {/* <Route component={StatusPageDetails} exact path="/statuspages/:statuspageId([a-z0-9-]{10,})" /> */}
                <Route component={StatusPages} exact path="/statuspages" />
                <Route component={Incidents} exact path="/incidents" />
                <Route
                  component={NewIncidentForm}
                  exact
                  path="/incidents/new"
                />
                <Route
                  component={EditIncidentForm}
                  exact
                  path="/incidents/:incidentId/edit"
                />
                <Route
                  exact
                  path="/incidents/:incidentId"
                  render={(props) => {
                    if (props.match.params.incidentId !== 'new')
                      return (
                        <Redirect
                          to={`/incidents/${props.match.params.incidentId}/edit`}
                        />
                      );
                  }}
                />

                <Route component={Maintenances} exact path="/maintenances" />
                <Route
                  component={NewMaintenanceForm}
                  exact
                  path="/maintenances/new"
                />
                <Route
                  component={EditMaintenanceForm}
                  exact
                  path="/maintenances/:incidentId/edit"
                />
                <Route
                  exact
                  path="/maintenances/:incidentId"
                  render={(props) => {
                    if (props.match.params.incidentId !== 'new')
                      return (
                        <Redirect
                          to={`/maintenances/${props.match.params.incidentId}/edit`}
                        />
                      );
                  }}
                />
                <Route
                  component={GeneralSettings}
                  exact
                  path="/settings/general"
                />
                <Route component={TeamSettings} exact path="/settings/team" />
                <Route exact path="/settings/billing">
                  <Elements stripe={stripePromise}>
                    <InjectedCheckoutBillingForm />
                  </Elements>
                </Route>
                <Route component={SmsSettings} exact path="/settings/sms" />
                <Route component={Intro} exact path="/intro" />
              </PrivateRoute>
              {/* Default pages */}
              <Route component={GenericNotFound} />
            </Switch>
            {/* </main> */}
          </MainDashboard>
          {/* </div>
					</div> */}
        </BrowserRouter>
      </GlobalState>
    );
  }
}

const InjectedCheckoutBillingForm = () => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <BillingSettings elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  );
};

// export default Root ;
