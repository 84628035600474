import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import ReactPaginate from 'react-paginate';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';

import { getMaintenanceEvents } from '../../../utils/helper';
import { getItemStorage, removeItemStorage } from '../../../utils/storage';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';
import Navbar from '../../layout/Navbar/Navbar';
import ModalRight from './../../layout/Modal/ModalRight';
import AppContext from '../../../context/app-context';
import StatusIndicator from '../../layout/StatusIndicator/StatusIndicator';
// Import icons
import icon_no_monitors from './../../../assets/icons/cactus.svg';
import icon_no_statuspages from './../../../assets/icons/content.svg';
import { ReactComponent as AddIcon } from './../../../assets/icons/add.svg';
//css
import './Maintenances.css';
import 'antd/dist/antd.css';

export default class NewMaintenanceForm extends Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      maintenance_name: '',
      maintenance_name_placeholder: 'Maintenance Name',
      maintenance_selected_event: 'SCHEDULED',
      maintenance_event_description: '',
      maintenance_event_description_placeholder:
        'We will be undergoing scheduled maintenance during this time.',
      maintenance_event_send_notification: false,
      affected_monitors: [],
      selected_statuspages: [],
      isLoading: false,
      form_response: '',
      form_response_status: 'warning',
      formIsSubmited: false,
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Schedule maintenance',
          link: '#',
          isLink: false,
        },
      ],
      hasActiveAccount: true,
      progressStage: '',
      descriptionPlaceholder: {
        SCHEDULED:
          'We will be undergoing scheduled maintenance during this time.',
        INPROGRESS:
          'Scheduled maintenance is currently in progress. We will provide updates as necessary.',
        VERIFING:
          'Verification is currently underway for the maintenance items.',
        COMPLETED: 'The scheduled maintenance has been completed.',
      },
      showModal_selectMonitors: false,
      showModal_selectStatuspages: false,
      affected_monitors_statusList: [
        { id: 'OPERATIONAL', name: 'Operational' },
        { id: 'DEGRADED-PERFORMANCE', name: 'Degraded performance' },
        { id: 'PARTIAL-OUTAGE', name: 'Partial outage' },
        { id: 'MAJOR-OUTAGE', name: 'Major outage' },
        { id: 'UNDER-MAINTENANCE', name: 'Under maintenance' },
      ],
      monitorRows: [],
      monitorRowsErrors: null,
      monitorRowsErrors_status: 'warning',
      statuspagesRows: [],
      statuspagesRowsErrors: null,
      statuspagesRowsErrors_status: 'warning',
      modalResponse_status: 'warning',
      modalResponse: '',
      offset: 0,
      // data: [],
      perPage: 10,
      currentPage: 0,
      pageCount: 0,
      createdIncidentId: null,
      scheduled_for: moment(),
      scheduled_for_date: moment(),
      scheduled_for_time: moment(),
      scheduled_until: moment().add(1, 'hours'),
      scheduled_delay_hours: 1,
      scheduled_delay_minutes: 0,

      scheduled_remind_prior: true,
      scheduled_remind_prior_value: 60,
      scheduled_auto_in_progress: true,
      auto_transition_to_maintenance_state: true,
      auto_transition_deliver_notifications_at_start: true,
      scheduled_auto_completed: true,
      auto_transition_to_operational_state: true,
      auto_transition_deliver_notifications_at_end: true,
    };
    this.handleChange_formMaintenanceName =
      this.handleChange_formMaintenanceName.bind(this);
    this.handleChange_formMaintenanceDescription =
      this.handleChange_formMaintenanceDescription.bind(this);
    this.handleChange_formMaintenanceSendNotification =
      this.handleChange_formMaintenanceSendNotification.bind(this);
    this.handleChange_selectEvent = this.handleChange_selectEvent.bind(this);
    this.getAvailableMonitors = this.getAvailableMonitors.bind(this);
    this.attachMonitor = this.attachMonitor.bind(this);
    this.openModal_selectMonitors = this.openModal_selectMonitors.bind(this);
    this.closeModal_selectMonitors = this.closeModal_selectMonitors.bind(this);
    this.confirmAttachedMonitors = this.confirmAttachedMonitors.bind(this);
    this.handleChange__removeAttachedMonitor =
      this.handleChange__removeAttachedMonitor.bind(this);
    this.handleChange_paginationMonitors =
      this.handleChange_paginationMonitors.bind(this);

    this.getAvailableStatuspages = this.getAvailableStatuspages.bind(this);
    this.attachStatuspage = this.attachStatuspage.bind(this);
    this.handleChange_paginationStatuspages =
      this.handleChange_paginationStatuspages.bind(this);
    this.openModal_selectStatuspages =
      this.openModal_selectStatuspages.bind(this);
    this.closeModal_selectStatuspages =
      this.closeModal_selectStatuspages.bind(this);
    this.confirmAttachedStatuspages =
      this.confirmAttachedStatuspages.bind(this);
    this.handleChange__removeAttachedStatuspage =
      this.handleChange__removeAttachedStatuspage.bind(this);
    this.handleChange_selectAffectedMonitorStatus =
      this.handleChange_selectAffectedMonitorStatus.bind(this);
    this.handleChange_formMaintenanceScheduledTime_date =
      this.handleChange_formMaintenanceScheduledTime_date.bind(this);
    this.handleChange_formMaintenanceScheduledTime_time =
      this.handleChange_formMaintenanceScheduledTime_time.bind(this);
    this.handleChange_formScheduledDelayHours =
      this.handleChange_formScheduledDelayHours.bind(this);
    this.handleChange_formScheduledDelayMinutes =
      this.handleChange_formScheduledDelayMinutes.bind(this);
    this.handleChange_cb_scheduled_remind_prior =
      this.handleChange_cb_scheduled_remind_prior.bind(this);
    this.handleChange_cb_scheduled_remind_prior_value =
      this.handleChange_cb_scheduled_remind_prior_value.bind(this);
    this.handleChange_cb_scheduled_auto_in_progress =
      this.handleChange_cb_scheduled_auto_in_progress.bind(this);
    this.handleChange_cb_auto_transition_to_maintenance_state =
      this.handleChange_cb_auto_transition_to_maintenance_state.bind(this);
    this.handleChange_cb_auto_transition_deliver_notifications_at_start =
      this.handleChange_cb_auto_transition_deliver_notifications_at_start.bind(
        this
      );
    this.handleChange_cb_scheduled_auto_completed =
      this.handleChange_cb_scheduled_auto_completed.bind(this);
    this.handleChange_cb_auto_transition_to_operational_state =
      this.handleChange_cb_auto_transition_to_operational_state.bind(this);
    this.handleChange_cb_auto_transition_deliver_notifications_at_end =
      this.handleChange_cb_auto_transition_deliver_notifications_at_end.bind(
        this
      );
  }

  /**
   *
   * @param {*} event
   */
  handleChange_formMaintenanceName(event) {
    this.setState({
      maintenance_name: event.target.value,
    });
  }

  /**
   *
   * @param {*} date
   * @param {*} dateString
   */
  handleChange_formMaintenanceScheduledTime_date(date, dateString) {
    const full_date = moment(
      `${dateString} ${this.state.scheduled_for_time.hours()}:${this.state.scheduled_for_time.minutes()}`
    );
    const scheduled_until = moment(full_date.format())
      .add(this.state.scheduled_delay_hours, 'hours')
      .add(this.state.scheduled_delay_minutes, 'minutes');
    this.setState({
      scheduled_for: full_date,
      scheduled_for_date: date,
      scheduled_until: scheduled_until,
    });
  }

  /**
   *
   * @param {*} time
   * @param {*} timeString
   */
  handleChange_formMaintenanceScheduledTime_time(time, timeString) {
    console.log(time, timeString);
    const full_date = moment(
      `${this.state.scheduled_for_date.format('YYYY-MM-DD')} ${timeString}`
    );
    const scheduled_until = moment(full_date.format())
      .add(this.state.scheduled_delay_hours, 'hours')
      .add(this.state.scheduled_delay_minutes, 'minutes');

    this.setState({
      scheduled_for: full_date,
      scheduled_for_time: time,
      scheduled_until: scheduled_until,
    });
  }

  /**
   *
   * @param {*} event
   */
  handleChange_formScheduledDelayHours(event) {
    const value = event.target.value;
    if (value >= 0) {
      const full_date = moment(
        `${this.state.scheduled_for_date.format(
          'YYYY-MM-DD'
        )} ${this.state.scheduled_for_time.hours()}:${this.state.scheduled_for_time.minutes()}`
      );
      const scheduled_until = moment(full_date.format())
        .add(value, 'hours')
        .add(this.state.scheduled_delay_minutes, 'minutes');

      this.setState({
        scheduled_delay_hours: value,
        scheduled_for: full_date,
        scheduled_until: scheduled_until,
      });
    }
  }

  /**
   *
   * @param {*} event
   */
  handleChange_formScheduledDelayMinutes(event) {
    const value = event.target.value;
    if (value >= 0) {
      const full_date = moment(
        `${this.state.scheduled_for_date.format(
          'YYYY-MM-DD'
        )} ${this.state.scheduled_for_time.hours()}:${this.state.scheduled_for_time.minutes()}`
      );
      let scheduled_until = moment(full_date.format())
        .add(this.state.scheduled_delay_hours, 'hours')
        .add(value, 'minutes');

      this.setState({
        scheduled_delay_minutes: value,
        scheduled_for: full_date,
        scheduled_until: scheduled_until,
      });
    }
  }

  /**
   *
   * @param {*} event
   */
  handleChange_formMaintenanceDescription(event) {
    this.setState({
      maintenance_event_description: event.target.value,
    });
  }

  /**
   *
   * @param {*} event
   */
  handleChange_formMaintenanceSendNotification(event) {
    this.setState({
      maintenance_event_send_notification: event.target.checked,
    });
  }

  /**
   *
   * @param {*} event
   */
  handleChange_selectEvent(event) {
    const selectedEvent = event.currentTarget.dataset.event;
    this.setState({
      maintenance_selected_event: selectedEvent,
      maintenance_event_description_placeholder:
        this.state.descriptionPlaceholder[selectedEvent],
    });
  }
  /**
   * * MONITORS SELECTION
   */
  getAvailableMonitors = async () => {
    this.setState({
      isLoading: true,
    });
    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (obj && obj.token) {
      const token = obj.token;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors`, {
          params: {
            page: this.state.offset,
            per_page: this.state.perPage,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            pageCount: Math.ceil(data.total / this.state.perPage),
            monitorRows: data.data,
            isLoading: false,
            monitorRowsErrors_status: 'success',
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            //console.log('Error: ', error.message); // => prints: Api is being canceled
          } else {
            if (error.response) {
              this.setState({
                pageCount: 0,
                monitorRows: [],
                isLoading: false,
                monitorRowsErrors:
                  error.response.status === 401
                    ? 'Authentication failed, please log in again'
                    : error.response.data.message,
                monitorRowsErrors_status: 'danger',
              });
            } else {
              // Something happened in setting up the request that triggered an Error
              if (
                typeof error.message === 'undefined' ||
                error.message === null ||
                error.message !== 'API_HAS_BEEN_CANCELED'
              ) {
                this.setState({
                  pageCount: 0,
                  monitorRows: [],
                  isLoading: false,
                  monitorRowsErrors:
                    'Something went wrong, please try again later.',
                  monitorRowsErrors_status: 'danger',
                });
              }
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  /**
   *
   */
  attachMonitor(event) {
    const monitorValue = JSON.parse(event.currentTarget.dataset.value);

    const affected_monitors = [...this.state.affected_monitors];
    // ! Affect the selected monitor to the affected monitors
    if (event.currentTarget.dataset.checked === 'false') {
      this.setState(
        {
          affected_monitors: [...this.state.affected_monitors, monitorValue],
          maintenance_event_send_notification: true,
        },
        () => {}
      );
      // ! Remove the selected monitor to the affected monitors
    } else {
      const found = affected_monitors.find(
        (element) => element.id === monitorValue.id
      );
      const index = affected_monitors.indexOf(found);
      affected_monitors.splice(index, 1);
      this.setState(
        {
          affected_monitors,
          maintenance_event_send_notification:
            affected_monitors.length > 0 ? true : false,
        },
        () => {}
      );
    }
  }

  handleChange_paginationMonitors = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getAvailableMonitors(offset);
      }
    );
  };

  openModal_selectMonitors(event) {
    this.setState(
      {
        showModal_selectMonitors: true,
        currentPage: 0,
        offset: 0,
      },
      () => {
        // Get monitors list
        this.getAvailableMonitors();
      }
    );
  }

  closeModal_selectMonitors(event) {
    this.setState({
      showModal_selectMonitors: !this.state.showModal_selectMonitors,
      modalResponse: '',
    });
  }

  confirmAttachedMonitors(event) {
    //console.log(event)

    this.setState({
      showModal_selectMonitors: !this.state.showModal_selectMonitors,
      modalResponse: '',
    });
  }

  handleChange__removeAttachedMonitor(event) {
    const selectedMonitorId = event.currentTarget.dataset.id;
    const affected_monitors = this.state.affected_monitors;

    const found = affected_monitors.find(
      (element) => element.id === selectedMonitorId
    );
    const index = affected_monitors.indexOf(found);
    affected_monitors.splice(index, 1);

    // update state :
    this.setState({
      affected_monitors,
      maintenance_event_send_notification:
        affected_monitors.legnth > 0 ? true : false,
    });
  }

  handleChange_selectAffectedMonitorStatus(event) {
    const selectedMonitorId = event.currentTarget.dataset.id;
    const affected_monitors = this.state.affected_monitors;

    const found = affected_monitors.find(
      (element) => element.id === selectedMonitorId
    );
    const index = affected_monitors.indexOf(found);
    affected_monitors[index].status = event.target.value;

    // update state :
    this.setState({
      affected_monitors,
    });
  }
  /**
   * * STATUSPAGES SELECTION
   */
  getAvailableStatuspages = async () => {
    this.setState({
      isLoading: true,
    });
    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (obj && obj.token) {
      const token = obj.token;

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/statuspages`,
          {
            params: {
              page: this.state.offset,
              per_page: this.state.perPage,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            pageCount: Math.ceil(data.total / this.state.perPage),
            statuspagesRows: data.data,
            isLoading: false,
            statuspagesRowsErrors_status: 'success',
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            //console.log('Error: ', error.message); // => prints: Api is being canceled
          } else {
            if (error.response) {
              this.setState({
                pageCount: 0,
                statuspagesRows: [],
                isLoading: false,
                statuspagesRowsErrors:
                  error.response.status === 401
                    ? 'Authentication failed, please log in again'
                    : error.response.data.message,
                statuspagesRowsErrors_status: 'danger',
              });
            } else {
              // Something happened in setting up the request that triggered an Error
              if (
                typeof error.message === 'undefined' ||
                error.message === null ||
                error.message !== 'API_HAS_BEEN_CANCELED'
              ) {
                this.setState({
                  pageCount: 0,
                  statuspagesRows: [],
                  isLoading: false,
                  statuspagesRowsErrors:
                    'Something went wrong, please try again later.',
                  statuspagesRowsErrors_status: 'danger',
                });
              }
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  attachStatuspage(event) {
    const statuspageValue = JSON.parse(event.currentTarget.dataset.value);

    const selected_statuspages = [...this.state.selected_statuspages];
    if (event.currentTarget.dataset.checked === 'false') {
      this.setState(
        {
          selected_statuspages: [
            ...this.state.selected_statuspages,
            statuspageValue,
          ],
        },
        () => {}
      );
    } else {
      const found = selected_statuspages.find(
        (element) => element.id === statuspageValue.id
      );
      const index = selected_statuspages.indexOf(found);
      selected_statuspages.splice(index, 1);
      this.setState(
        {
          selected_statuspages,
        },
        () => {}
      );
    }
  }

  handleChange_paginationStatuspages = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getAvailableStatuspages(offset);
      }
    );
  };

  openModal_selectStatuspages(event) {
    this.setState(
      {
        showModal_selectStatuspages: true,
        currentPage: 0,
        offset: 0,
      },
      () => {
        // Get monitors list
        this.getAvailableStatuspages();
      }
    );
  }

  closeModal_selectStatuspages(event) {
    this.setState({
      showModal_selectStatuspages: !this.state.showModal_selectStatuspages,
      modalResponse: '',
    });
  }

  confirmAttachedStatuspages(event) {
    //console.log(event)

    this.setState({
      showModal_selectStatuspages: !this.state.showModal_selectStatuspages,
      modalResponse: '',
    });
  }

  handleChange__removeAttachedStatuspage(event) {
    const selectedStatuspageId = event.currentTarget.dataset.id;
    const selected_statuspages = this.state.selected_statuspages;

    const found = selected_statuspages.find(
      (element) => element.id === selectedStatuspageId
    );
    const index = selected_statuspages.indexOf(found);
    selected_statuspages.splice(index, 1);

    // update state :
    this.setState({
      selected_statuspages,
    });
  }

  /**
   * ! SUBMIT INCIDENT FORM
   * @param {*} event
   * @returns
   */
  handleSubmit = async (event) => {
    event.preventDefault();
    //console.log(event)
    const {
      affected_monitors,
      selected_statuspages,
      maintenance_event_description,
      maintenance_selected_event,
      maintenance_name,
      maintenance_event_send_notification,
      scheduled_for,
      scheduled_until,
      scheduled_remind_prior,
      scheduled_remind_prior_value,
      scheduled_auto_in_progress,
      auto_transition_to_maintenance_state,
      auto_transition_deliver_notifications_at_start,
      scheduled_auto_completed,
      auto_transition_to_operational_state,
      auto_transition_deliver_notifications_at_end,
    } = this.state;

    this.setState({
      isLoading: true,
      formIsSubmited: true,
    });

    if (
      typeof affected_monitors === 'undefined' ||
      affected_monitors === null ||
      affected_monitors.length <= 0 ||
      maintenance_name === '' ||
      maintenance_event_description === ''
    ) {
      let tmp_stateItems = {};
      tmp_stateItems.form_response = 'Please fill in all mandatory fields';
      tmp_stateItems.form_response_status = 'danger';
      tmp_stateItems.isLoading = false;

      this.setState(tmp_stateItems);
      return;
    }

    // Save a new status page
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      //console.log(this.state);

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/incidents`,
          {
            is_active: true,
            name: maintenance_name,
            event_type: maintenance_selected_event,
            event_message: maintenance_event_description,
            monitors: affected_monitors,
            statuspages: selected_statuspages,
            mode: 'scheduled',
            notify: maintenance_event_send_notification,

            // Scheduled parameters
            scheduled_for: scheduled_for.format(),
            scheduled_until: scheduled_until.format(),
            scheduled_remind_prior: scheduled_remind_prior,
            scheduled_remind_prior_value: scheduled_remind_prior_value,
            scheduled_auto_in_progress: scheduled_auto_in_progress,
            auto_transition_to_maintenance_state:
              auto_transition_to_maintenance_state,
            auto_transition_deliver_notifications_at_start:
              auto_transition_deliver_notifications_at_start,
            scheduled_auto_completed: scheduled_auto_completed,
            auto_transition_to_operational_state:
              auto_transition_to_operational_state,
            auto_transition_deliver_notifications_at_end:
              auto_transition_deliver_notifications_at_end,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          // ? Google Analytics Event : Create Status page
          // GA.GAevent('Status Pages' , 'Create Status Page')

          this.setState({
            form_response:
              'Your schedule maintenance has been successfully created',
            form_response_status: 'success',
            createdIncidentId: data.incident.id,
            isLoading: false,
          });
        })
        .catch((error) => {
          let stateItems = {
            isLoading: false,
          };
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const status = error.response.status;
            const data = error.response.data;

            // default message
            stateItems.form_response = data.message;

            if (status === 422) {
              stateItems.form_response = data.message;
              // if(data.validation.length > 0 ){
              // 	data.validation.map(validation_item => {
              // 		switch (validation_item.key) {
              // 			case "hosted_subdomain":
              // 				stateItems.form_hostedSubdomain_error = validation_item.error;
              // 				break;
              // 			case "name":
              // 				stateItems.form_title_error = validation_item.error;
              // 				break;
              // 			case "custom_domain":
              // 				stateItems.form_customDomain_error = validation_item.error;
              // 				break;

              // 		}
              // 	})
              // }

              // }else if(status === 403 && typeof data.data!=='undefined' && data.data !==null){
              // 	stateItems.form_customDomain_error = data.data.isAvailableCustomDomain === false ? 'This custom domain is not available, please try a different one' : ""
              // 	stateItems.form_hostedSubdomain_error = data.data.isAvailableHostedsubdomain === false ? 'This subdomain is not available, please try a different one' : ""
            }
            stateItems.form_response_status = 'danger';
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log('Error', error.message);
          }
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            // Save data in state
            this.setState(stateItems);
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  handleChange_cb_scheduled_remind_prior(event) {
    this.setState({
      scheduled_remind_prior: event.target.checked,
    });
  }
  handleChange_cb_scheduled_remind_prior_value(event) {
    this.setState({
      scheduled_remind_prior_value: event.target.value,
    });
  }

  handleChange_cb_scheduled_auto_in_progress(event) {
    this.setState({
      scheduled_auto_in_progress: event.target.checked,
    });
  }

  handleChange_cb_auto_transition_to_maintenance_state(event) {
    this.setState({
      auto_transition_to_maintenance_state: event.target.checked,
    });
  }

  handleChange_cb_auto_transition_deliver_notifications_at_start(event) {
    this.setState({
      auto_transition_deliver_notifications_at_start: event.target.checked,
    });
  }

  handleChange_cb_scheduled_auto_completed(event) {
    this.setState({
      scheduled_auto_completed: event.target.checked,
    });
  }

  handleChange_cb_auto_transition_to_operational_state(event) {
    this.setState({
      auto_transition_to_operational_state: event.target.checked,
    });
  }

  handleChange_cb_auto_transition_deliver_notifications_at_end(event) {
    console.log(event.target.checked);
    this.setState({
      auto_transition_deliver_notifications_at_end: event.target.checked,
    });
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    let submitButtonContent = (
      <span className="btn-inner load-spinner">
        <span>Schedule Now!</span>
      </span>
    );
    if (this.state.isLoading) {
      submitButtonContent = (
        <span className="btn-inner load-spinner">
          <span className="loader_spinner--content"></span>
          <span>Saving...</span>
        </span>
      );
    }

    const {
      maintenance_name,
      maintenance_name_placeholder,
      maintenance_event_description,
      maintenance_event_description_placeholder,
      maintenance_event_send_notification,
      formIsSubmited,
    } = this.state;

    // Check if the user has an active account, else redirect the user to /intro page to create a new account
    if (this.state.hasActiveAccount === false) {
      // remote current account id from strage
      removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      return <Redirect to="/intro" />;
    }

    if (
      this.state.form_response_status === 'success' &&
      this.state.createdIncidentId !== null
    ) {
      return (
        <Redirect to={`/maintenances/${this.state.createdIncidentId}/edit`} />
      );
    }

    // progress stage marker :
    const maintenanceEventsList = getMaintenanceEvents();
    const selectedEvent_indice = maintenanceEventsList.findIndex(
      (x) => x.id === this.state.maintenance_selected_event
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>Schedule Maintenance | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__monitor--form layout__blockInner">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>Schedule Maintenance</h2>
                </div>
                <div className="l_header__btnaction">
                  <div
                    className="btn btn-primary"
                    onClick={(event) => {
                      this.handleSubmit(event);
                    }}
                  >
                    {submitButtonContent}
                    <span className="avatar-wrapper">
                      <span className="avatar-content"></span>
                    </span>
                  </div>
                </div>
                <div className="l_header__description">
                  <div className="field-group submit-fields-group fluid">
                    <div
                      className={`alert alert-${
                        this.state.form_response_status
                      } ${
                        this.state.form_response === '' ? 'hide' : ''
                      } margin-left-none margin-right-none`}
                    >
                      <div className="">{this.state.form_response}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="layout__body">
                <form method="POST" action="" onSubmit={this.handleSubmit}>
                  <div className="white-card margin-bottom-20">
                    <h2 className="title"></h2>
                    <div className="field-group fluid">
                      <label htmlFor="" className="fluid">
                        Maintenance name *
                      </label>
                      <input
                        type="text"
                        className={`textField fluid ${
                          formIsSubmited === true && maintenance_name === ''
                            ? 'error'
                            : ''
                        }`}
                        value={this.state.maintenance_name}
                        placeholder={maintenance_name_placeholder}
                        onChange={this.handleChange_formMaintenanceName}
                      />
                      <span
                        className={`fieldText_alert--error ${
                          formIsSubmited === true && maintenance_name === ''
                            ? ''
                            : 'hide'
                        }`}
                      >
                        Name is required
                      </span>
                    </div>
                    <div className="field-group fluid scheduled-time">
                      <label htmlFor="" className="fluid">
                        Scheduled Time Timezone: *
                      </label>
                      <DatePicker
                        className={`textField`}
                        defaultValue={this.state.scheduled_for_date}
                        onChange={
                          this.handleChange_formMaintenanceScheduledTime_date
                        }
                      />
                      <TimePicker
                        className={`textField`}
                        defaultValue={this.state.scheduled_for_time}
                        format="HH:mm"
                        onChange={
                          this.handleChange_formMaintenanceScheduledTime_time
                        }
                      />
                      <span className="separator">For</span>
                      <div className="inputWrapper for-hours">
                        <input
                          type="number"
                          className="textField fluid "
                          value={this.state.scheduled_delay_hours}
                          onChange={this.handleChange_formScheduledDelayHours}
                        />
                        <div className="suffix">hours</div>
                      </div>
                      <div className="inputWrapper for-minutes">
                        <input
                          type="number"
                          className="textField fluid "
                          value={this.state.scheduled_delay_minutes}
                          onChange={this.handleChange_formScheduledDelayMinutes}
                        />
                        <div className="suffix">minutes</div>
                      </div>
                    </div>
                    <div className="scheduled-delay-explain">
                      <div className="sd-exp__inner">
                        The maintenance will begin on{' '}
                        <b>
                          {this.state.scheduled_for
                            .utc(0)
                            .format('MMM DD, YYYY HH:mm UTC')}
                        </b>{' '}
                        and will end on{' '}
                        <b>
                          {this.state.scheduled_until
                            .utc(0)
                            .format('MMM DD, YYYY HH:mm UTC')}
                        </b>
                      </div>
                    </div>
                    <div className="field-group fluid event-progress-wrapper hide">
                      <label htmlFor="" className="fluid">
                        Incident Events *
                      </label>
                      <div className="outer-wrapper">
                        <div id="event-progress-bar">
                          <div className="padding"></div>
                          <div className="progress-bar">
                            <span
                              className={`progress ${this.state.maintenance_selected_event.toLowerCase()}`}
                            ></span>
                          </div>
                          <div className="padding"></div>
                        </div>
                        <div className="events">
                          {maintenanceEventsList.map(
                            (maintenanceEvent, index) => {
                              let isActive =
                                index <= selectedEvent_indice ? true : false;

                              return (
                                <div
                                  key={index}
                                  className={`event-wrapper ${maintenanceEvent.name.toLowerCase()}`}
                                  data-event={maintenanceEvent.id}
                                  onClick={this.handleChange_selectEvent}
                                >
                                  <div className="indicator">
                                    <div
                                      className={`dot ${
                                        isActive === true ? 'active' : ''
                                      } ${
                                        this.state
                                          .maintenance_selected_event ===
                                        maintenanceEvent.id
                                          ? 'selected'
                                          : ''
                                      }`}
                                    ></div>
                                  </div>
                                  <div
                                    className={`label ${
                                      isActive === true ? 'active' : ''
                                    } ${
                                      this.state.maintenance_selected_event ===
                                      maintenanceEvent.id
                                        ? 'selected'
                                        : ''
                                    }`}
                                  >
                                    <span>{maintenanceEvent.name}</span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="field-group fluid">
                      <label htmlFor="" className="fluid">
                        Description *
                      </label>
                      <textarea
                        rows={4}
                        className={`textField fluid ${
                          formIsSubmited === true &&
                          maintenance_event_description === ''
                            ? 'error'
                            : ''
                        }`}
                        value={this.state.maintenance_event_description}
                        placeholder={maintenance_event_description_placeholder}
                        onChange={this.handleChange_formMaintenanceDescription}
                      />
                      <span
                        className={`fieldText_alert--error ${
                          formIsSubmited === true &&
                          maintenance_event_description === ''
                            ? ''
                            : 'hide'
                        }`}
                      >
                        Name is required
                      </span>
                    </div>
                    <div className="field-group fluid">
                      <label htmlFor="" className="fluid">
                        Notifications
                      </label>
                      <div className={`checkbox-group fluid`}>
                        <label className="fluid">
                          <input
                            className={`textField `}
                            type="checkbox"
                            checked={
                              this.state.affected_monitors.length <= 0
                                ? false
                                : maintenance_event_send_notification
                            }
                            onChange={
                              this.handleChange_formMaintenanceSendNotification
                            }
                            disabled={
                              this.state.affected_monitors.length <= 0
                                ? true
                                : false
                            }
                          />{' '}
                          Send notifications{' '}
                          <Tooltip
                            placement="top"
                            trigger={['hover']}
                            overlay={
                              <span>
                                Send notifications through multiple alert
                                channels to your team members and subscribers of
                                selected status pages.
                              </span>
                            }
                          >
                            <span className="question-badge"></span>
                          </Tooltip>
                        </label>
                      </div>
                      <span
                        className={`fieldText_alert--error ${
                          this.state.affected_monitors.length <= 0 ? '' : 'hide'
                        }`}
                      >
                        You need to select at least one affected monitors to
                        enable notifications
                      </span>
                    </div>
                  </div>
                  <div className="white-card margin-bottom-20">
                    <div className="field-group fluid affected-monitors-wrapper">
                      <div className="field-group_header">
                        <label htmlFor="" className="">
                          Affected Monitors *
                        </label>
                        <div className="field-group_btn-actions">
                          <div
                            className="btn btn-orange"
                            onClick={this.openModal_selectMonitors}
                          >
                            <AddIcon /> Import monitors
                          </div>
                        </div>
                      </div>
                      {this.state.affected_monitors.length <= 0 ? (
                        <div
                          className={`no-items-selected ${
                            this.state.formIsSubmited === true &&
                            this.state.affected_monitors.length <= 0
                              ? 'error'
                              : ''
                          }`}
                          onClick={this.openModal_selectMonitors}
                        >
                          <span className="no-items-selected_inner">
                            No monitor selected, please choose at least one of
                            your active monitors
                          </span>
                        </div>
                      ) : (
                        <div className="affected_monitors-items">
                          {this.state.affected_monitors.map(
                            (monitorItem, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`affected_monitors-item ${
                                    monitorItem.isActive === true
                                      ? 'is-active'
                                      : ''
                                  } ${
                                    monitorItem.isDown === true ? 'is-down' : ''
                                  }`}
                                >
                                  <StatusIndicator
                                    status={
                                      monitorItem.isActive === false
                                        ? ''
                                        : monitorItem.isDown === true
                                        ? 'danger'
                                        : 'success'
                                    }
                                    title={
                                      monitorItem.isActive === false
                                        ? 'Inactive Monitor'
                                        : monitorItem.isDown === true
                                        ? 'Failure Monitor'
                                        : 'Active Monitor'
                                    }
                                  />
                                  <div className="am_item-wrapper">
                                    <div className="am_item--name">
                                      {monitorItem.name}
                                    </div>
                                    <div className="am_item--status">
                                      <select
                                        disabled
                                        className={`textField fluid`}
                                        value={monitorItem.status}
                                        data-id={monitorItem.id}
                                        onChange={
                                          this
                                            .handleChange_selectAffectedMonitorStatus
                                        }
                                      >
                                        {this.state.affected_monitors_statusList.map(
                                          (i, index) => {
                                            return (
                                              <option key={index} value={i.id}>
                                                {i.name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                    <div className={`am_item--btn_actions`}>
                                      <div
                                        className="btn_action-item remove"
                                        data-id={monitorItem.id}
                                        onClick={
                                          this
                                            .handleChange__removeAttachedMonitor
                                        }
                                      >
                                        <svg
                                          version="1.1"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          className="octicon octicon-trash"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                                          ></path>
                                          <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                                          <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="white-card margin-bottom-20">
                    <div className="field-group fluid affected-monitors-wrapper">
                      <div className="field-group_header">
                        <label htmlFor="" className="">
                          Selected Statuspages *
                        </label>
                        <div className="field-group_btn-actions">
                          <div
                            className="btn btn-orange"
                            onClick={this.openModal_selectStatuspages}
                          >
                            <AddIcon /> Import statuspages
                          </div>
                        </div>
                      </div>
                      {this.state.selected_statuspages.length <= 0 ? (
                        <div
                          className={`no-items-selected ${
                            this.state.formIsSubmited === true &&
                            this.state.selected_statuspages.length <= 0
                              ? 'error'
                              : ''
                          }`}
                          onClick={this.openModal_selectStatuspages}
                        >
                          <span className="no-items-selected_inner">
                            No status page selected, please choose at least one
                            of your active status pages
                          </span>
                        </div>
                      ) : (
                        <div className="affected_monitors-items">
                          {this.state.selected_statuspages.map(
                            (statuspageItem, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`affected_monitors-item ${
                                    statuspageItem.isActive === true
                                      ? 'is-active'
                                      : ''
                                  }  `}
                                >
                                  <div className="am_item-wrapper">
                                    <div className="am_item--name">
                                      {statuspageItem.name}
                                    </div>
                                    <div className={`am_item--btn_actions`}>
                                      <div
                                        className="btn_action-item remove"
                                        data-id={statuspageItem.id}
                                        onClick={
                                          this
                                            .handleChange__removeAttachedStatuspage
                                        }
                                      >
                                        <svg
                                          version="1.1"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          className="octicon octicon-trash"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                                          ></path>
                                          <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                                          <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="white-card margin-bottom-20">
                    <div className="field-group fluid automation-options">
                      <div className="field-group_header">
                        <label htmlFor="">Automation Options</label>
                      </div>
                      <div className="automation-options_content">
                        <div className="ops-category before-ops checkbox-group">
                          <div className="ops-category__title fluid">
                            <b>Before</b> this maintenance starts{' '}
                          </div>
                          <label className="">
                            <input
                              type="checkbox"
                              checked={this.state.scheduled_remind_prior}
                              onChange={
                                this.handleChange_cb_scheduled_remind_prior
                              }
                            />{' '}
                            Remind subscribers
                          </label>
                          <span className="seprator">an </span>
                          <div className="inputWrapper for-hours">
                            <input
                              type="number"
                              className="textField fluid "
                              value={this.state.scheduled_remind_prior_value}
                              onChange={
                                this
                                  .handleChange_cb_scheduled_remind_prior_value
                              }
                            />
                            <div className="suffix">minutes</div>
                          </div>
                          <span className="seprator">before </span>
                        </div>
                        <div className="ops-category at-start-ops checkbox-group">
                          <div className="ops-category__title fluid">
                            <b>At the start time</b> this maintenance{' '}
                          </div>
                          <label className="fluid">
                            <input
                              type="checkbox"
                              checked={this.state.scheduled_auto_in_progress}
                              onChange={
                                this.handleChange_cb_scheduled_auto_in_progress
                              }
                            />{' '}
                            Set status to 'In Progress'
                          </label>
                          <label className="fluid">
                            <input
                              type="checkbox"
                              checked={
                                this.state.auto_transition_to_maintenance_state
                              }
                              onChange={
                                this
                                  .handleChange_cb_auto_transition_to_maintenance_state
                              }
                            />{' '}
                            Set affected components' status to 'Under
                            Maintenance'
                          </label>
                          <label className="fluid">
                            <input
                              type="checkbox"
                              checked={
                                this.state
                                  .auto_transition_deliver_notifications_at_start
                              }
                              onChange={
                                this
                                  .handleChange_cb_auto_transition_deliver_notifications_at_start
                              }
                            />{' '}
                            Notify subscribers
                          </label>
                        </div>
                        <div className="ops-category before-ops checkbox-group">
                          <div className="ops-category__title fluid">
                            <b>At the end of time</b> this maintenance{' '}
                          </div>
                          <label className="fluid">
                            <input
                              type="checkbox"
                              checked={this.state.scheduled_auto_completed}
                              onChange={
                                this.handleChange_cb_scheduled_auto_completed
                              }
                            />{' '}
                            Set status to 'Completed'
                          </label>
                          <label className="fluid">
                            <input
                              type="checkbox"
                              checked={
                                this.state.auto_transition_to_operational_state
                              }
                              onChange={
                                this
                                  .handleChange_cb_auto_transition_to_operational_state
                              }
                            />{' '}
                            Set affected components' status to 'Operational'
                          </label>
                          <label className="fluid">
                            <input
                              type="checkbox"
                              checked={
                                this.state
                                  .auto_transition_deliver_notifications_at_end
                              }
                              onChange={
                                this
                                  .handleChange_cb_auto_transition_deliver_notifications_at_end
                              }
                            />{' '}
                            Notify subscribers
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="white-card margin-bottom-20">
                    <div className="field-group submit-fields-group fluid">
                      <button type="submit" className="btn btn-primary">
                        {submitButtonContent}
                      </button>
                      <div
                        className={`alert alert-${
                          this.state.form_response_status
                        } ${
                          this.state.form_response === '' ? 'hide' : ''
                        } margin-top-none margin-bottom-none margin-right-none`}
                      >
                        <div className="">{this.state.form_response}</div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {
          <ModalRight
            title="Available monitors"
            closeModal={this.closeModal_selectMonitors}
            isDisplay={this.state.showModal_selectMonitors}
          >
            <div className="modal_layout-monitors-list">
              <div className="modal_list modal_layout-monitors-list_wrapper">
                <div className="modal-overflow-height_500">
                  {this.state.isLoading ? (
                    <div className="loading-wrapper">
                      <LoadingSpinner />
                    </div>
                  ) : this.state.monitorRowsErrors !== null ? (
                    <div className="alert alert-danger text-center">
                      {this.state.monitorRowsErrors}
                    </div>
                  ) : this.state.monitorRows.length <= 0 ? (
                    <div className="home-empty-state">
                      <img
                        alt="No monitors"
                        src={icon_no_monitors}
                        width="100px"
                      />
                      <h3>There is No Monitors</h3>
                      <p className="text-muted">
                        Add your first monitor, this will be your dashboard.
                      </p>
                      <Link className="" to={{ pathname: `/monitors/new` }}>
                        <div className="btn btn-primary">
                          <svg
                            version="1.1"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            className="octicon octicon-plus"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"
                            ></path>
                          </svg>
                          <span>New Monitor</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    this.state.monitorRows.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`m-monitor_item ${
                            item.is_active ? '' : 'deactivated'
                          } ${item.is_down ? 'is-down' : ''} ${
                            this.state.affected_monitors.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? 'selected'
                              : ''
                          }`}
                          onClick={this.attachMonitor}
                          data-value={JSON.stringify({
                            id: item.id,
                            isActive: item.is_active,
                            isDown: item.is_down,
                            name: item.name,
                            url: item.url,
                            status:
                              this.state.affected_monitors_statusList[4].id,
                          })}
                          data-checked={
                            this.state.affected_monitors.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? true
                              : false
                          }
                        >
                          <input
                            type="checkbox"
                            checked={
                              this.state.affected_monitors.findIndex(
                                (x) => x.id === item.id
                              ) >= 0
                                ? true
                                : false
                            }
                            onChange={this.attachMonitor}
                          />
                          <div
                            className={`status-circle ${
                              item.is_down === true ? `fail` : `success`
                            } medium ${item.is_active ? '' : 'deactivated'}`}
                          >
                            {item.is_down === true ? (
                              <svg
                                viewBox="0 0 30 30"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m5.78571429 4.83443522-4.75706811-4.75706811-.95127907.95127907 4.75706811 4.75706811-4.75706811 4.75706811.95127907.9512791 4.75706811-4.75706814 4.75706811 4.75706814.9512791-.9512791-4.75706814-4.75706811 4.75706814-4.75706811-.9512791-.95127907z"
                                  fill="#fff"
                                  transform="translate(9.428571 9.428571)"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                viewBox="0 0 30 30"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m0 5.14285714 1.28571429-1.28571428 3.21428571 3.21428571 7.0714286-7.07142857 1.2857143 1.28571429-8.3571429 8.35714285z"
                                  fill="#fff"
                                  transform="translate(8.571429 10.285714)"
                                ></path>
                              </svg>
                            )}
                          </div>
                          <div className="chk_details-content">
                            <span className="chk_details-name">
                              {item.name.toLowerCase()}
                            </span>
                            <span className="chk_details-url">
                              {item.url.length > 20
                                ? item.url.substr(0, 20) + '..'
                                : item.url}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              {this.state.pageCount > 0 && (
                <ReactPaginate
                  previousLabel={''}
                  nextLabel={''}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleChange_paginationMonitors}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              )}
            </div>
            <div className="d-flex margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_selectMonitors}
              >
                Back
              </button>
              {this.state.monitorRows.length > 0 && (
                <button
                  className="btn btn-primary float-left margin-left-10"
                  disabled={this.state.affected_monitors.length <= 0}
                  onClick={this.confirmAttachedMonitors}
                >
                  Attach the selected monitors (
                  <span>{this.state.affected_monitors.length}</span>){' '}
                </button>
              )}
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </ModalRight>
        }
        {
          <ModalRight
            title="Available status pages"
            closeModal={this.closeModal_selectStatuspages}
            isDisplay={this.state.showModal_selectStatuspages}
          >
            <div className="modal_layout-monitors-list">
              <div className="modal_list modal_layout-monitors-list_wrapper">
                <div className="modal-overflow-height_500">
                  {this.state.isLoading ? (
                    <div className="loading-wrapper">
                      <LoadingSpinner />
                    </div>
                  ) : this.state.statuspagesRowsErrors !== null ? (
                    <div className="alert alert-danger text-center">
                      {this.state.statuspagesRowsErrors}
                    </div>
                  ) : this.state.statuspagesRows.length <= 0 ? (
                    <div className="home-empty-state">
                      <img
                        alt="No status page"
                        src={icon_no_statuspages}
                        width="100px"
                      />
                      <h3>No Active Status pages found</h3>
                      <p className="text-muted">
                        Add your status page, this will be your dashboard.
                      </p>
                      <Link className="" to={{ pathname: `/statuspages/new` }}>
                        <div className="btn btn-primary">
                          <svg
                            version="1.1"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            className="octicon octicon-plus"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"
                            ></path>
                          </svg>
                          <span>New Status page</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    this.state.statuspagesRows.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`m-monitor_item ${
                            item.is_active ? '' : 'deactivated'
                          }  ${
                            this.state.selected_statuspages.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? 'selected'
                              : ''
                          }`}
                          onClick={this.attachStatuspage}
                          data-value={JSON.stringify({
                            id: item.id,
                            isActive: item.is_active,
                            name: item.name,
                            customDomain: item.custom_domain,
                            subDomain: item.hosted_subdomain,
                          })}
                          data-checked={
                            this.state.selected_statuspages.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? true
                              : false
                          }
                        >
                          <input
                            type="checkbox"
                            checked={
                              this.state.selected_statuspages.findIndex(
                                (x) => x.id === item.id
                              ) >= 0
                                ? true
                                : false
                            }
                            onChange={this.attachStatuspage}
                          />
                          <div className="chk_details-content">
                            <span className="chk_details-name">
                              {item.name.toLowerCase()}
                            </span>
                            <span className="chk_details-url">
                              {typeof item.custom_domain !== 'undefined' &&
                              item.custom_domain !== null &&
                              item.custom_domain !== ''
                                ? item.custom_domain.length > 20
                                  ? item.custom_domain.substr(0, 20) + '..'
                                  : item.custom_domain
                                : item.hosted_subdomain.length > 0
                                ? `https://${item.hosted_subdomain}.${process.env.REACT_APP_DOMAIN_NAME}`
                                : ''}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              {this.state.pageCount > 0 && (
                <ReactPaginate
                  previousLabel={''}
                  nextLabel={''}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleChange_paginationMonitors}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              )}
            </div>
            <div className="d-flex margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_selectStatuspages}
              >
                Back
              </button>
              {this.state.statuspagesRows.length > 0 && (
                <button
                  className="btn btn-primary float-left margin-left-10"
                  disabled={this.state.selected_statuspages.length <= 0}
                  onClick={this.confirmAttachedStatuspages}
                >
                  Attach the selected status pages (
                  <span>{this.state.selected_statuspages.length}</span>){' '}
                </button>
              )}
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </ModalRight>
        }
      </React.Fragment>
    );
  }
}
