import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

// Tooltip component
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import './../alert-settings.css';

import Navbar from '../../../layout/Navbar/Navbar';

import { getItemStorage } from '../../../../utils/storage';

export default class AlertSettingsPagerDutyNew extends Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      isConnectedAccount: false,
      config_pagerDuty: null,

      form_field_alertCase__recovered: true,
      form_field_alertCase__degraded: false,
      form_field_alertCase__failed: true,
      form_field_alertCase__expiredSSL: false,
      form_field_alertCase__expiredSSL_threshold: 30,

      isLoading: false,
      form_response: '',
      form_response_status: 'warning',
      formIsSubmited: false,

      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Alert Settings',
          link: '/settings/alert',
          isLink: true,
        },
        {
          name: 'New PagerDuty Channel',
          link: '#',
          isLink: false,
        },
      ],
      pagerduty_install_link:
        'https://app.pagerduty.com/install/integration?app_id=P0RHTWA&redirect_url=https://app.odown.io/settings/alert/channels/new/pagerduty&version=2',
    };

    this.connectToPagerDutyAccount = this.connectToPagerDutyAccount.bind(this);
    this.handleChange_form_fields_alert_sendCase =
      this.handleChange_form_fields_alert_sendCase.bind(this);
    this.handleChange_form_fields_alert_sslExpireThreshold =
      this.handleChange_form_fields_alert_sslExpireThreshold.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Capture the config after we connect with PagerDuty
   * @param {*} string
   * @returns
   */
  connectToPagerDutyAccount() {
    const queryURL = this.props.location.search;

    if (typeof queryURL === 'undefined' || queryURL === null || queryURL === '')
      return null;
    const regex = new RegExp(/^\?config\=(.*)$/);
    let response = queryURL.match(regex);
    response = decodeURIComponent(response[1]);
    response = JSON.parse(response);

    if (typeof response !== 'undefined' && response !== null) {
      this.setState({
        isConnectedAccount: true,
        config_pagerDuty: {
          account: response.account,
          integration_keys: response.integration_keys.map((item) => {
            return {
              d: item.id,
              integration_key: item.integration_key,
              name: item.name.replace(/\+/g, ' '),
              type: item.type,
            };
          }),
        },
      });
    }
  }

  handleChange_form_fields_alert_sendCase(event) {
    let isChecked = event.target.checked;

    switch (event.target.value) {
      case 'check-recovered':
        this.setState({
          form_field_alertCase__recovered: isChecked,
        });
        break;
      case 'check-degraded':
        this.setState({
          form_field_alertCase__degraded: isChecked,
        });
        break;
      case 'check-failed':
        this.setState({
          form_field_alertCase__failed: isChecked,
        });
        break;
      case 'check-expiredSSL':
        this.setState({
          form_field_alertCase__expiredSSL: isChecked,
        });
        break;
      default:
        break;
    }
  }

  handleChange_form_fields_alert_sslExpireThreshold(event) {
    let value = parseInt(event.target.value);
    if (typeof value === 'undefined' || value === null || value <= 0)
      value = 30; // as default value

    this.setState({
      form_field_alertCase__expiredSSL_threshold: value,
    });
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    const {
      config_pagerDuty,
      form_field_alertCase__recovered,
      form_field_alertCase__degraded,
      form_field_alertCase__failed,
      form_field_alertCase__expiredSSL,
    } = this.state;

    // loading ... effect
    this.setState({
      isLoading: true,
      formIsSubmited: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (
      localStorageData &&
      localStorageData.token &&
      config_pagerDuty !== null &&
      config_pagerDuty.integration_keys.length > 0
    ) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      let submittedConfig = [];
      config_pagerDuty.integration_keys.map((item) => {
        submittedConfig.push({
          pd_account: config_pagerDuty.account.name,
          pg_integration_key: item.integration_key,
          pg_type: item.type,
          pg_id: item.id,
          pg_service_name: item.name,
        });
      });

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/alert-channels/pagerduty`,
          {
            config: submittedConfig,
            sendRecovery: form_field_alertCase__recovered,
            sendDegraded: form_field_alertCase__degraded,
            sendFailure: form_field_alertCase__failed,
            sslExpiry: form_field_alertCase__expiredSSL,
            sslExpiryThreshold:
              this.state.form_field_alertCase__expiredSSL_threshold,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          if (res.status === 201) {
            // stop loading effect
            this.setState({
              isLoading: false,
              form_response: 'Alert channel created successfully',
              form_response_status: 'success',
            });
          } else {
            // stop loading effect
            this.setState({
              isLoading: false,
              form_response: 'Something went wrong saving your alert settings',
              form_response_status: 'danger',
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              form_response: 'Something went wrong saving your alert settings',
              form_response_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  componentDidMount() {
    this.connectToPagerDutyAccount();
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    let submitButtonContent = (
      <span className="btn-inner load-spinner">
        <span>Save!</span>
      </span>
    );
    if (this.state.isLoading) {
      submitButtonContent = (
        <span className="btn-inner load-spinner">
          <span className="loader_spinner--content"></span>
          <span>Please wait</span>
        </span>
      );
    }

    if (this.state.form_response_status === 'success') {
      return <Redirect to="/settings/alert" />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>New PagerDuty Channel | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__blockInner">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>New PagerDuty Channel</h2>
                </div>
                <div className="l_header__btnaction">
                  {/* <div className="btn btn-primary"><Link  to={`/monitors/5578/settings`}>Add more channels</Link></div> */}
                </div>
                <div className="l_header__description">
                  Send failure or recovery messages to any Pagerduty channel.
                </div>
              </div>
              <div className="layout__body">
                {this.state.isConnectedAccount === false && (
                  <div className="white-card connect-third_party--template">
                    <div className="connect-third_party--template--wrapper">
                      <div className="apps-row">
                        <div className="app-object owner">
                          <div className="app-logo"></div>
                          <div className="app-name"></div>
                        </div>
                        <div className="app-object-separator"></div>
                        <div className="app-object third-party">
                          <div className="app-logo"></div>
                          <div className="app-name"></div>
                        </div>
                      </div>
                      <div className="apps-row-text">
                        Manage all Odown alerts with Pagerduty. Read more about
                        integrating with Pagerduty{' '}
                        <a
                          href="https://odown.io/docs/alerting/pagerduty"
                          target="_blanc"
                        >
                          in our docs
                        </a>
                      </div>
                      <div className="apps-row-action">
                        <Link to="/settings/alert">
                          <button className="btn btn-info">Back</button>
                        </Link>
                        <a
                          href={this.state.pagerduty_install_link}
                          className="btn btn-primary margin-left-10"
                        >
                          Connect PagerDuty
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.isConnectedAccount === true &&
                  this.state.config_pagerDuty !== null && (
                    <div className="white-card">
                      <div className="note-1">
                        Odown uses this data to send alerting events to your
                        PagerDuty account. To change these settings, first
                        remove the integration and then follow the setup
                        sequence again.
                      </div>
                      {this.state.config_pagerDuty.integration_keys.length >
                        1 && (
                        <div className="note-1">
                          You added multiple services. We will automatically
                          create one alert channel per service when you save
                          this channel. Don't worry, you can edit these settings
                          later.
                        </div>
                      )}
                      <form
                        method="POST"
                        action=""
                        onSubmit={this.handleSubmit}
                      >
                        <div className="alert-multiple-services">
                          <div className="field-group fluid">
                            <label className="" htmlFor="">
                              Account
                            </label>
                            <div className="text">
                              {this.state.config_pagerDuty.account.name}
                            </div>
                          </div>
                          <div className="field-group fluid multiple-items">
                            <div className="services-items">
                              {this.state.config_pagerDuty.integration_keys.map(
                                (item, index) => {
                                  return (
                                    <div className="service-item" key={index}>
                                      <ul>
                                        <li>
                                          <label htmlFor="">
                                            Service name{' '}
                                          </label>
                                          <span>{item.name}</span>
                                        </li>
                                        <li>
                                          <label htmlFor="">Service key </label>
                                          <span>{item.integration_key}</span>
                                        </li>
                                      </ul>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="field-group fluid">
                          <label htmlFor="" className="fluid">
                            Sending cases
                          </label>
                          <div className="checkbox-group">
                            <label htmlFor={`check-recovered`}>
                              <span
                                className={`alert-type alert-recovery active `}
                              >
                                <svg
                                  viewBox="0 0 30 30"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m0 5.14285714 1.28571429-1.28571428 3.21428571 3.21428571 7.0714286-7.07142857 1.2857143 1.28571429-8.3571429 8.35714285z"
                                    fill="#fff"
                                    transform="translate(8.571429 10.285714)"
                                  ></path>
                                </svg>
                              </span>
                              <input
                                type="checkbox"
                                id={`check-recovered`}
                                value={`check-recovered`}
                                checked={
                                  this.state.form_field_alertCase__recovered
                                }
                                onChange={
                                  this.handleChange_form_fields_alert_sendCase
                                }
                              />
                              A check recovers
                              <Tooltip
                                placement="top"
                                trigger={['hover']}
                                overlay={
                                  <span>
                                    Receive alerts when the the check is
                                    recovered
                                  </span>
                                }
                              >
                                <span className="question-badge"></span>
                              </Tooltip>
                            </label>
                            <label className="hide" htmlFor={`check-degraded`}>
                              <span
                                className={`alert-type alert-degraded active}`}
                              >
                                <svg
                                  viewBox="0 0 30 30"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m14.8962832 18.244809c-.1719277 0-.3080352-.0573084-.4083264-.1719269-.1002912-.1146186-.1575996-.2650531-.1719269-.4513082l-.472799-4.8139535c-.0286546-.2005824-.0573088-.4620519-.0859635-.7844165-.0286546-.3223646-.0429817-.6268154-.0429817-.9133617v-2.38548589c0-.32952822.0823808-.58025246.2471449-.75218023.1647642-.17192777.4190702-.25789037.7629257-.25789037h.6017442c.3295282 0 .5802525.0859626.7521802.25789037.1719278.17192777.2578904.42265201.2578904.75218023v2.38548589c0 .2865463-.0179089.5909971-.0537272.9133617-.0358182.3223646-.0608907.5909977-.075218.8059074l-.472799 4.7924626c-.0143273.1862551-.0716357.3366896-.1719269.4513082-.1002912.1146185-.2435622.1719269-.4298173.1719269zm-1.1605066 3.2881021v-.6447259c0-.3152009.0859626-.5551798.2578903-.7199439.1719278-.1647641.4154885-.247145.7306894-.247145h.6017442c.3152009 0 .5551798.0823809.7199439.247145s.247145.404743.247145.7199439v.6447259c0 .3152009-.0823809.5551799-.247145.719944s-.404743.2471449-.7199439.2471449h-.6017442c-.3152009 0-.5587616-.0823808-.7306894-.2471449-.1719277-.1647641-.2578903-.4047431-.2578903-.719944z"
                                    fill="#fff"
                                  ></path>
                                </svg>
                              </span>
                              <input
                                type="checkbox"
                                id={`check-degraded`}
                                value={`check-degraded`}
                                checked={
                                  this.state.form_field_alertCase__degraded
                                }
                                onChange={
                                  this.handleChange_form_fields_alert_sendCase
                                }
                              />
                              A check degrades
                              <Tooltip
                                placement="top"
                                trigger={['hover']}
                                overlay={
                                  <span>
                                    Receive alerts when the the check is
                                    degraded
                                  </span>
                                }
                              >
                                <span className="question-badge"></span>
                              </Tooltip>
                            </label>
                            <label htmlFor={`check-failed`}>
                              <span
                                className={`alert-type alert-failure active}`}
                              >
                                <svg
                                  viewBox="0 0 30 30"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m5.78571429 4.83443522-4.75706811-4.75706811-.95127907.95127907 4.75706811 4.75706811-4.75706811 4.75706811.95127907.9512791 4.75706811-4.75706814 4.75706811 4.75706814.9512791-.9512791-4.75706814-4.75706811 4.75706814-4.75706811-.9512791-.95127907z"
                                    fill="#fff"
                                    transform="translate(9.428571 9.428571)"
                                  ></path>
                                </svg>
                              </span>
                              <input
                                type="checkbox"
                                id={`check-failed`}
                                value={`check-failed`}
                                checked={
                                  this.state.form_field_alertCase__failed
                                }
                                onChange={
                                  this.handleChange_form_fields_alert_sendCase
                                }
                              />
                              A check fails
                              <Tooltip
                                placement="top"
                                trigger={['hover']}
                                overlay={
                                  <span>
                                    Receive alerts when the the check fails
                                  </span>
                                }
                              >
                                <span className="question-badge"></span>
                              </Tooltip>
                            </label>
                            <label htmlFor={`check-expiredSSL`}>
                              <span className={`alert-type alert-ssl active}`}>
                                <svg
                                  version="1.1"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  className="octicon octicon-lock"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4 4v2h-.25A1.75 1.75 0 002 7.75v5.5c0 .966.784 1.75 1.75 1.75h8.5A1.75 1.75 0 0014 13.25v-5.5A1.75 1.75 0 0012.25 6H12V4a4 4 0 10-8 0zm6.5 2V4a2.5 2.5 0 00-5 0v2h5zM12 7.5h.25a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25H12z"
                                  ></path>
                                </svg>
                              </span>
                              <input
                                type="checkbox"
                                id={`check-expiredSSL`}
                                value={`check-expiredSSL`}
                                checked={
                                  this.state.form_field_alertCase__expiredSSL
                                }
                                onChange={
                                  this.handleChange_form_fields_alert_sendCase
                                }
                              />
                              An SSL certificate is due to expire in
                              <input
                                type="number"
                                className={`textField small-number margin-left-10`}
                                value={
                                  this.state
                                    .form_field_alertCase__expiredSSL_threshold
                                }
                                placeholder={'30'}
                                min={1}
                                onChange={
                                  this
                                    .handleChange_form_fields_alert_sslExpireThreshold
                                }
                              />
                              {this.state
                                .form_field_alertCase__expiredSSL_threshold ===
                              1
                                ? ' day'
                                : ' days'}
                              <Tooltip
                                placement="top"
                                trigger={['hover']}
                                overlay={
                                  <span>
                                    Receive alerts when the SSL certificate of
                                    the domain used the check is about to expire
                                  </span>
                                }
                              >
                                <span className="question-badge"></span>
                              </Tooltip>
                            </label>
                          </div>
                        </div>
                        <div className="field-group submit-fields-group fluid">
                          <Link to="/settings/alert">
                            <button className="btn btn-info">Cancel</button>
                          </Link>
                          <button
                            type="submit"
                            className="btn btn-primary margin-left-10"
                          >
                            {submitButtonContent}
                          </button>
                          <div
                            className={`response-group text-left ${
                              this.state.form_response_status
                            } message ${
                              this.state.form_response === '' ? 'hide' : ''
                            }`}
                          >
                            <div className="">{this.state.form_response}</div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
