import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { Helmet } from 'react-helmet';
import ReactPaginate from 'react-paginate';
import 'rc-tooltip/assets/bootstrap.css';

import { getIncidentEvents } from '../../../utils/helper';
import { getItemStorage, removeItemStorage } from '../../../utils/storage';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';
import Navbar from '../../layout/Navbar/Navbar';
import ModalRight from './../../layout/Modal/ModalRight';
import Modal from './../../layout/Modal/Modal';
import AppContext from '../../../context/app-context';
import StatusIndicator from '../../layout/StatusIndicator/StatusIndicator';
// Import icons
import icon_no_monitors from './../../../assets/icons/cactus.svg';
import icon_no_statuspages from './../../../assets/icons/content.svg';
import { ReactComponent as AddIcon } from './../../../assets/icons/add.svg';
import { ReactComponent as EditIcon } from './../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from './../../../assets/icons/delete.svg';
import { ReactComponent as MonitorMenuIcon } from './../../../assets/icons/monitors-menu.svg';
import { ReactComponent as EditPenIcon } from './../../../assets/icons/3643749_edit_pen_pencil_write_writing_icon.svg';
import { ReactComponent as RightCircleIcon } from './../../../assets/icons/1608520_arrow_circle_right_icon.svg';

//css
import './Incidents.css';

export default class EditIncidentForm extends Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      incidentId: props.match.params.incidentId,
      incident_name: '',
      incident_name_placeholder: 'Incident Name',
      incident_selected_event: 'INVESTIGATING',
      incident_event_description: '',
      incident_event_description_placeholder:
        'We are currently investigating this issue.',
      incident_event_send_notification: false,
      affected_monitors: [],
      selected_statuspages: [],
      isLoading: false,
      form_response: '',
      form_response_status: 'warning',
      formIsSubmited: false,
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Incidents',
          link: '/incidents',
          isLink: true,
        },
        {
          name: 'loading',
          link: '#',
          isLink: false,
        },
        {
          name: 'Edit',
          link: '#',
          isLink: false,
        },
      ],
      hasActiveAccount: true,
      progressStage: '',
      descriptionPlaceholder: {
        INVESTIGATING: 'We are currently investigating this issue.',
        IDENTIFIED:
          'The issue has been identified and a fix is being implemented.',
        MONITORING:
          'A fix has been implemented and we are monitoring the results.',
        RESOLVED: 'This incident has been resolved.',
      },
      showModal_selectMonitors: false,
      showModal_selectStatuspages: false,
      affected_monitors_statusList: [
        { id: 'OPERATIONAL', name: 'Operational' },
        { id: 'DEGRADED-PERFORMANCE', name: 'Degraded performance' },
        { id: 'PARTIAL-OUTAGE', name: 'Partial outage' },
        { id: 'MAJOR-OUTAGE', name: 'Major outage' },
        { id: 'UNDER-MAINTENANCE', name: 'Under maintenance' },
      ],
      monitorRows: [],
      monitorRowsErrors: null,
      monitorRowsErrors_status: 'warning',
      statuspagesRows: [],
      statuspagesRowsErrors: null,
      statuspagesRowsErrors_status: 'warning',
      modalResponse_status: 'warning',
      modalResponse: '',
      offset: 0,
      // data: [],
      perPage: 10,
      currentPage: 0,
      pageCount: 0,
      historyEvents: [],
      isLoadingLoadData: false,
      showModal_editHistoryEvent: false,
      showModal_removeHistoryEvent: false,
      selectedEventId: null,

      tmp_editModal__status: 'INVESTIGATING',
      tmp_editModal__message: '',
      IncidentNotFound: false,
    };
    this.getAccountData = this.getAccountData.bind(this);
    this.handleChange_formIncidentDescription =
      this.handleChange_formIncidentDescription.bind(this);
    this.handleChange_selectEvent = this.handleChange_selectEvent.bind(this);
    this.getAvailableMonitors = this.getAvailableMonitors.bind(this);
    this.attachMonitor = this.attachMonitor.bind(this);
    this.openModal_selectMonitors = this.openModal_selectMonitors.bind(this);
    this.closeModal_selectMonitors = this.closeModal_selectMonitors.bind(this);
    this.confirmAttachedMonitors = this.confirmAttachedMonitors.bind(this);
    this.handleChange__removeAttachedMonitor =
      this.handleChange__removeAttachedMonitor.bind(this);
    this.handleChange_paginationMonitors =
      this.handleChange_paginationMonitors.bind(this);

    this.getAvailableStatuspages = this.getAvailableStatuspages.bind(this);
    this.attachStatuspage = this.attachStatuspage.bind(this);
    this.handleChange_paginationStatuspages =
      this.handleChange_paginationStatuspages.bind(this);
    this.openModal_selectStatuspages =
      this.openModal_selectStatuspages.bind(this);
    this.closeModal_selectStatuspages =
      this.closeModal_selectStatuspages.bind(this);
    this.confirmAttachedStatuspages =
      this.confirmAttachedStatuspages.bind(this);
    this.handleChange__removeAttachedStatuspage =
      this.handleChange__removeAttachedStatuspage.bind(this);
    this.handleChange_selectAffectedMonitorStatus =
      this.handleChange_selectAffectedMonitorStatus.bind(this);

    this.openModal_editHistoryEvent =
      this.openModal_editHistoryEvent.bind(this);
    this.closeModal_editHistoryEvent =
      this.closeModal_editHistoryEvent.bind(this);
    this.openModal_removeHistoryEvent =
      this.openModal_removeHistoryEvent.bind(this);
    this.closeModal_removeHistoryEvent =
      this.closeModal_removeHistoryEvent.bind(this);
    this.editEvent = this.editEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.handleChange_editForm_historyEvent_status =
      this.handleChange_editForm_historyEvent_status.bind(this);
    this.handleChange_editForm_historyEvent_message =
      this.handleChange_editForm_historyEvent_message.bind(this);
    this.handleChange_formIncidentSendNotification =
      this.handleChange_formIncidentSendNotification.bind(this);
  }

  handleChange_formIncidentDescription(event) {
    this.setState({
      incident_event_description: event.target.value,
    });
  }

  /**
   *
   * @param {*} event
   */
  handleChange_formIncidentSendNotification(event) {
    this.setState({
      incident_event_send_notification: event.target.checked,
    });
  }

  handleChange_selectEvent(event) {
    const selectedEvent = event.currentTarget.dataset.event;
    this.setState({
      incident_selected_event: selectedEvent,
      incident_event_description_placeholder:
        this.state.descriptionPlaceholder[selectedEvent],
    });
  }
  /**
   * * MONITORS SELECTION
   */
  getAvailableMonitors = async () => {
    this.setState({
      isLoading: true,
    });
    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (obj && obj.token) {
      const token = obj.token;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}/monitors`, {
          params: {
            page: this.state.offset,
            per_page: this.state.perPage,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            pageCount: Math.ceil(data.total / this.state.perPage),
            monitorRows: data.data,
            isLoading: false,
            monitorRowsErrors_status: 'success',
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            //console.log('Error: ', error.message); // => prints: Api is being canceled
          } else {
            if (error.response) {
              this.setState({
                pageCount: 0,
                monitorRows: [],
                isLoading: false,
                monitorRowsErrors:
                  error.response.status === 401
                    ? 'Authentication failed, please log in again'
                    : error.response.data.message,
                monitorRowsErrors_status: 'danger',
              });
            } else {
              // Something happened in setting up the request that triggered an Error
              if (
                typeof error.message === 'undefined' ||
                error.message === null ||
                error.message !== 'API_HAS_BEEN_CANCELED'
              ) {
                this.setState({
                  pageCount: 0,
                  monitorRows: [],
                  isLoading: false,
                  monitorRowsErrors:
                    'Something went wrong, please try again later.',
                  monitorRowsErrors_status: 'danger',
                });
              }
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  attachMonitor(event) {
    const monitorValue = JSON.parse(event.currentTarget.dataset.value);

    const affected_monitors = [...this.state.affected_monitors];
    // ! Affect the selected monitor to the affected monitors
    if (event.currentTarget.dataset.checked === 'false') {
      this.setState(
        {
          affected_monitors: [...this.state.affected_monitors, monitorValue],
          incident_event_send_notification: true,
        },
        () => {}
      );
      // ! Remove the selected monitor to the affected monitors
    } else {
      const found = affected_monitors.find(
        (element) => element.id === monitorValue.id
      );
      const index = affected_monitors.indexOf(found);
      affected_monitors.splice(index, 1);
      this.setState(
        {
          affected_monitors,
          incident_event_send_notification:
            affected_monitors.length > 0 ? true : false,
        },
        () => {}
      );
    }
  }

  handleChange_paginationMonitors = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getAvailableMonitors(offset);
      }
    );
  };

  openModal_selectMonitors(event) {
    this.setState(
      {
        showModal_selectMonitors: true,
        currentPage: 0,
        offset: 0,
      },
      () => {
        // Get monitors list
        this.getAvailableMonitors();
      }
    );
  }

  closeModal_selectMonitors(event) {
    this.setState({
      showModal_selectMonitors: !this.state.showModal_selectMonitors,
      modalResponse: '',
    });
  }

  confirmAttachedMonitors(event) {
    //console.log(event)

    this.setState({
      showModal_selectMonitors: !this.state.showModal_selectMonitors,
      modalResponse: '',
    });
  }

  handleChange__removeAttachedMonitor(event) {
    const selectedMonitorId = event.currentTarget.dataset.id;
    const affected_monitors = this.state.affected_monitors;

    const found = affected_monitors.find(
      (element) => element.id === selectedMonitorId
    );
    const index = affected_monitors.indexOf(found);
    affected_monitors.splice(index, 1);

    // update state :
    this.setState({
      affected_monitors,
      incident_event_send_notification:
        affected_monitors.length > 0 ? true : false,
    });
  }

  handleChange_selectAffectedMonitorStatus(event) {
    const selectedMonitorId = event.currentTarget.dataset.id;
    const affected_monitors = this.state.affected_monitors;

    const found = affected_monitors.find(
      (element) => element.id === selectedMonitorId
    );
    const index = affected_monitors.indexOf(found);
    affected_monitors[index].status = event.target.value;

    // update state :
    this.setState({
      affected_monitors,
    });
  }
  /**
   * * STATUSPAGES SELECTION
   */
  getAvailableStatuspages = async () => {
    this.setState({
      isLoading: true,
    });
    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );
    if (obj && obj.token) {
      const token = obj.token;

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/statuspages`,
          {
            params: {
              page: this.state.offset,
              per_page: this.state.perPage,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            pageCount: Math.ceil(data.total / this.state.perPage),
            statuspagesRows: data.data,
            isLoading: false,
            statuspagesRowsErrors_status: 'success',
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            //console.log('Error: ', error.message); // => prints: Api is being canceled
          } else {
            if (error.response) {
              this.setState({
                pageCount: 0,
                statuspagesRows: [],
                isLoading: false,
                statuspagesRowsErrors:
                  error.response.status === 401
                    ? 'Authentication failed, please log in again'
                    : error.response.data.message,
                statuspagesRowsErrors_status: 'danger',
              });
            } else {
              // Something happened in setting up the request that triggered an Error
              if (
                typeof error.message === 'undefined' ||
                error.message === null ||
                error.message !== 'API_HAS_BEEN_CANCELED'
              ) {
                this.setState({
                  pageCount: 0,
                  statuspagesRows: [],
                  isLoading: false,
                  statuspagesRowsErrors:
                    'Something went wrong, please try again later.',
                  statuspagesRowsErrors_status: 'danger',
                });
              }
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  attachStatuspage(event) {
    const statuspageValue = JSON.parse(event.currentTarget.dataset.value);

    const selected_statuspages = [...this.state.selected_statuspages];
    if (event.currentTarget.dataset.checked === 'false') {
      this.setState(
        {
          selected_statuspages: [
            ...this.state.selected_statuspages,
            statuspageValue,
          ],
        },
        () => {}
      );
    } else {
      const found = selected_statuspages.find(
        (element) => element.id === statuspageValue.id
      );
      const index = selected_statuspages.indexOf(found);
      selected_statuspages.splice(index, 1);
      this.setState(
        {
          selected_statuspages,
        },
        () => {}
      );
    }
  }

  handleChange_paginationStatuspages = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getAvailableStatuspages(offset);
      }
    );
  };

  openModal_selectStatuspages(event) {
    this.setState(
      {
        showModal_selectStatuspages: true,
        currentPage: 0,
        offset: 0,
      },
      () => {
        // Get monitors list
        this.getAvailableStatuspages();
      }
    );
  }

  closeModal_selectStatuspages(event) {
    this.setState({
      showModal_selectStatuspages: !this.state.showModal_selectStatuspages,
      modalResponse: '',
    });
  }

  confirmAttachedStatuspages(event) {
    //console.log(event)

    this.setState({
      showModal_selectStatuspages: !this.state.showModal_selectStatuspages,
      modalResponse: '',
    });
  }

  handleChange__removeAttachedStatuspage(event) {
    const selectedStatuspageId = event.currentTarget.dataset.id;
    const selected_statuspages = this.state.selected_statuspages;

    const found = selected_statuspages.find(
      (element) => element.id === selectedStatuspageId
    );
    const index = selected_statuspages.indexOf(found);
    selected_statuspages.splice(index, 1);

    // update state :
    this.setState({
      selected_statuspages,
    });
  }

  /**
   * ! SUBMIT INCIDENT FORM
   * @param {*} event
   * @returns
   */
  handleSubmit = async (event) => {
    event.preventDefault();
    //console.log(event)
    const {
      incidentId,
      affected_monitors,
      selected_statuspages,
      incident_event_description,
      incident_selected_event,
      incident_event_send_notification,
    } = this.state;

    this.setState({
      isLoading: true,
      formIsSubmited: true,
    });

    if (
      typeof affected_monitors === 'undefined' ||
      affected_monitors === null ||
      affected_monitors.length <= 0 ||
      incident_event_description === ''
    ) {
      let tmp_stateItems = {};
      tmp_stateItems.form_response = 'Please fill in all mandatory fields';
      tmp_stateItems.form_response_status = 'danger';
      tmp_stateItems.isLoading = false;

      this.setState(tmp_stateItems);
      return;
    }

    // Save a new status page
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      //console.log(this.state);

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/incidents/${incidentId}`,
          {
            is_active: true,
            event_type: incident_selected_event,
            event_message: incident_event_description,
            monitors: affected_monitors,
            statuspages: selected_statuspages,
            notify: incident_event_send_notification,
            mode: 'realtime',
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          // ? Google Analytics Event : Create Status page
          // GA.GAevent('Status Pages' , 'Create Status Page')

          this.setState({
            form_response: 'Your incident has been successfully updated',
            form_response_status: 'success',
            historyEvents: [
              {
                id: data.event.id,
                type: incident_selected_event,
                message: incident_event_description,
                created_at: data.event.created_at,
                monitors: data.monitors,
              },
              ...this.state.historyEvents,
            ],
            incident_event_description: '', // init the description ( event message )
            isLoading: false,
          });
        })
        .catch((error) => {
          let stateItems = {
            isLoading: false,
          };
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const status = error.response.status;
            const data = error.response.data;

            // default message
            stateItems.form_response = data.message;

            if (status === 422) {
              stateItems.form_response = data.message;
              // if(data.validation.length > 0 ){
              // 	data.validation.map(validation_item => {
              // 		switch (validation_item.key) {
              // 			case "hosted_subdomain":
              // 				stateItems.form_hostedSubdomain_error = validation_item.error;
              // 				break;
              // 			case "name":
              // 				stateItems.form_title_error = validation_item.error;
              // 				break;
              // 			case "custom_domain":
              // 				stateItems.form_customDomain_error = validation_item.error;
              // 				break;

              // 		}
              // 	})
              // }

              // }else if(status === 403 && typeof data.data!=='undefined' && data.data !==null){
              // 	stateItems.form_customDomain_error = data.data.isAvailableCustomDomain === false ? 'This custom domain is not available, please try a different one' : ""
              // 	stateItems.form_hostedSubdomain_error = data.data.isAvailableHostedsubdomain === false ? 'This subdomain is not available, please try a different one' : ""
            }
            stateItems.form_response_status = 'danger';
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log('Error', error.message);
          }
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            // Save data in state
            this.setState(stateItems);
          }
        });
    } else {
      this.setState({
        isLoading: false,
        form_response: 'You are not authorized to do this operation',
        form_response_status: 'danger',
      });
      return;
    }
  };

  /**
   *
   *
   * @memberof EditMonitorForm
   */
  getAccountData = async () => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    //console.log('accountID : ' , accountId)
    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/${accountId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, //'JWT token...'
            'X-Odown-Account': accountId,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => {
          //console.log(res);
          res = res.data;
          if (typeof res !== 'undefined' && res !== null) {
            const data = res;
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              hasActiveAccount: false,
            });
            // remote current account id from storage ( connectedUserData element )
            this.context.cleanUserAccount(accountId);
          }
        });
    } else {
    }
  };

  /**
   * GET INCIDENT DATA FOR EDIT
   */
  getIncidentData = async () => {
    this.setState({
      isLoadingLoadData: true,
    });

    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/incidents/${this.state.incidentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, //'JWT token...'
              'X-Odown-Account': accountId,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          if (typeof data !== 'undefined' && data !== null) {
            const breadcrumbs_items = this.state.breadcrumbs_items;
            breadcrumbs_items[2].name = data.name;
            breadcrumbs_items[2].link = '/incidents/' + data.id + '/edit';
            breadcrumbs_items[2].isLink = true;

            let lastUsedMonitors = [];
            let lastUsedStatusPages = [];
            let incident_selected_event = this.state.incident_selected_event;

            // Sort the events' list to get the last event -> then we capture thier monitors
            if (
              typeof data.events !== 'undefined' &&
              data.events !== null &&
              data.events.length > 0
            ) {
              data.events.sort(
                (a, b) =>
                  moment(b.created_at).unix() - moment(a.created_at).unix()
              );
              if (
                data.events[0].monitors !== null &&
                data.events[0].monitors.length > 0
              ) {
                lastUsedMonitors = data.events[0].monitors.map((i) => {
                  return {
                    id: i.id,
                    isActive: i.is_active,
                    isDown: i.is_down,
                    name: i.name,
                    url: i.url,
                    status: i.status,
                  };
                });
                incident_selected_event = data.events[0].type;
              }
            }
            // Capture the last used statuspages
            if (
              typeof data.statuspages !== 'undefined' &&
              data.statuspages !== null &&
              data.statuspages.length > 0
            ) {
              lastUsedStatusPages = data.statuspages;
            }

            this.setState({
              incident_name: data.name,
              affected_monitors: lastUsedMonitors,
              selected_statuspages: lastUsedStatusPages,
              incident_selected_event: incident_selected_event,
              isLoadingLoadData: false,
              breadcrumbs_items: breadcrumbs_items,
              historyEvents: data.events,
              incident_event_send_notification:
                lastUsedMonitors.length > 0 ? true : false,
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              IncidentNotFound: true,
              isLoadingLoadData: false,
              breadcrumbs_items: (this.state.breadcrumbs_items[2] = {
                name: 'Not found',
                link: '#',
                isLink: false,
              }),
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        IncidentNotFound: true,
        isLoadingLoadData: false,
        breadcrumbs_items: (this.state.breadcrumbs_items[2] = {
          name: 'Not found',
          link: '#',
          isLink: false,
        }),
      });
    }
  };

  openModal_editHistoryEvent(event) {
    const selectedEventId = event.currentTarget.dataset.id;
    const selectedEvent = this.state.historyEvents.find(
      (x) => x.id === selectedEventId
    );

    this.setState(
      {
        showModal_editHistoryEvent: true,
        selectedEventId: selectedEventId,
        tmp_editModal__message:
          typeof selectedEvent !== 'undefined' && selectedEvent !== null
            ? selectedEvent.message
            : this.state.tmp_editModal__message,
        tmp_editModal__status:
          typeof selectedEvent !== 'undefined' && selectedEvent !== null
            ? selectedEvent.type
            : this.state.tmp_editModal__status,
        modalResponse: '',
      },
      () => {
        // Get monitors list
        // this.getAvailableMonitors();
      }
    );
  }
  openModal_removeHistoryEvent(event) {
    const selectedEventId = event.currentTarget.dataset.id;
    this.setState(
      {
        showModal_removeHistoryEvent: true,
        selectedEventId: selectedEventId,
        modalResponse: '',
      },
      () => {
        // Get monitors list
        // this.getAvailableMonitors();
      }
    );
  }
  closeModal_editHistoryEvent(event) {
    this.setState({
      showModal_editHistoryEvent: !this.state.showModal_editHistoryEvent,
      modalResponse: '',
    });
  }
  closeModal_removeHistoryEvent(event) {
    this.setState({
      showModal_removeHistoryEvent: !this.state.showModal_removeHistoryEvent,
      modalResponse: '',
    });
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  editEvent = async (event) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/incidents/${this.state.incidentId}/events/${this.state.selectedEventId}`,
          {
            message: this.state.tmp_editModal__message,
            type: this.state.tmp_editModal__status,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          const selectedEventId = this.state.selectedEventId;

          let historyEvents = this.state.historyEvents;
          const indexHistoryEvents = historyEvents.findIndex(
            (obj) => obj.id === selectedEventId
          );
          historyEvents[indexHistoryEvents].message =
            this.state.tmp_editModal__message;
          historyEvents[indexHistoryEvents].type =
            this.state.tmp_editModal__status;

          // stop loading effect
          this.setState({
            isLoading: false,
            modalResponse: 'The update has been edited successfully',
            modalResponse_status: 'success',
            historyEvents: historyEvents,
            showModal_editHistoryEvent: false,
          });
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              modalResponse: 'An error occurred while modifying your update',
              modalResponse_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        modalResponse: 'You are not authorized to do this operation',
        modalResponse_status: 'danger',
      });
      return;
    }
  };

  deleteEvent = async (event) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/incidents/${this.state.incidentId}/events/${this.state.selectedEventId}`,
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          const selectedEventId = this.state.selectedEventId;

          // stop loading effect
          this.setState({
            isLoading: false,
            // modalResponse: "History event removed successfully",
            modalResponse: '',
            // modalResponse_status: 'success',
            historyEvents: this.state.historyEvents.filter(function (obj) {
              return obj.id !== selectedEventId;
            }),
            showModal_removeHistoryEvent: false,
          });
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              modalResponse: 'An error occurred while deleting your update',
              modalResponse_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        modalResponse: 'You are not authorized to do this operation',
        modalResponse_status: 'danger',
      });
      return;
    }
  };

  handleChange_editForm_historyEvent_status(event) {
    this.setState({
      tmp_editModal__status: event.target.value,
    });
  }
  handleChange_editForm_historyEvent_message(event) {
    this.setState({
      tmp_editModal__message: event.target.value,
    });
  }

  componentDidMount() {
    // Get current account Data
    this.getAccountData();

    // Get status page info
    this.getIncidentData();
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    let submitButtonContent = (
      <span className="btn-inner load-spinner">
        <span>Update Incident!</span>
      </span>
    );
    if (this.state.isLoading) {
      submitButtonContent = (
        <span className="btn-inner load-spinner">
          <span className="loader_spinner--content"></span>
          <span>Updating...</span>
        </span>
      );
    }

    const {
      incident_event_description,
      incident_event_description_placeholder,
      formIsSubmited,
      historyEvents,
      incident_event_send_notification,
    } = this.state;

    // Check if the user has an active account, else redirect the user to /intro page to create a new account
    if (this.state.hasActiveAccount === false) {
      // remote current account id from strage
      removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      return <Redirect to="/intro" />;
    }

    // progress stage marker :
    const incidentEventsList = getIncidentEvents();
    const selectedEvent_indice = incidentEventsList.findIndex(
      (x) => x.id === this.state.incident_selected_event
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>Update Incident | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris incident-form-edit">
          <div className="container">
            <div className="layout__settings--form layout__blockInner ">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>Update incident</h2>
                </div>
                {this.state.IncidentNotFound === false && (
                  <>
                    <div className="l_header__btnaction">
                      <div
                        className="btn btn-primary"
                        onClick={(event) => {
                          this.handleSubmit(event);
                        }}
                      >
                        {submitButtonContent}
                        <span className="avatar-wrapper">
                          <span className="avatar-content"></span>
                        </span>
                      </div>
                    </div>
                    <div className="l_header__description">
                      {this.state.isLoadingLoadData === false &&
                        this.state.IncidentNotFound === false &&
                        typeof this.state.incident_name !== 'undefined' &&
                        this.state.incident_name !== null && (
                          <InstantlyEditIncidentName
                            name={this.state.incident_name}
                            incidentId={this.state.incidentId}
                          />
                        )}
                      <div className="field-group submit-fields-group fluid">
                        <div
                          className={`alert alert-${
                            this.state.form_response_status
                          } ${
                            this.state.form_response === '' ? 'hide' : ''
                          } margin-left-none margin-right-none`}
                        >
                          <div className="">{this.state.form_response}</div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="layout__body">
                {this.state.isLoadingLoadData === true ? (
                  <div className="loading-wrapper">
                    <LoadingSpinner />
                  </div>
                ) : this.state.IncidentNotFound === true ? (
                  <div className="layout__notFound white-card">
                    <h2>Not Found</h2>
                    <p>
                      Unfortunately, this incident is not available on the
                      current account.
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="layout__historyItems">
                      <div className="">
                        <label>Update History</label>
                        {this.state.isLoadingLoadData ? (
                          <div className="loading-wrapper">
                            <LoadingSpinner />
                          </div>
                        ) : historyEvents.length <= 0 ? (
                          <div className="no-items">No update history</div>
                        ) : (
                          <div className="historyItems--wrapper">
                            <ul>
                              {historyEvents.map((item, index) => {
                                return (
                                  <li className="" key={index}>
                                    <div className="historyEvents--timeline">
                                      <span className="timeline-icon">
                                        <i className="status-icon"></i>
                                      </span>
                                    </div>
                                    <div className="historyEvents--item">
                                      <div className="msg">{item.message}</div>
                                      <div className="outline-info">
                                        <span className="outline_info-date">
                                          {moment(item.created_at).format(
                                            'MMM DD, hh:mm A'
                                          )}
                                        </span>
                                        <span>.</span>
                                        <span
                                          className={`outline_info-event_type ${item.type.toLowerCase()}`}
                                        >
                                          {getIncidentEvents(item.type)}
                                        </span>
                                        <span>.</span>
                                        <div className="outline_actions">
                                          <div className="outline_actions--affected_monitors">
                                            <Tooltip
                                              placement="top"
                                              trigger={['hover']}
                                              overlay={
                                                <span className="tooltip--events-item_affected-monitors">
                                                  {item.monitors !== null &&
                                                    item.monitors.length >
                                                      0 && (
                                                      <>
                                                        <h3>
                                                          Affected Monitors
                                                        </h3>
                                                        <ul>
                                                          {item.monitors.map(
                                                            (
                                                              monitorItem,
                                                              index
                                                            ) => {
                                                              return (
                                                                <li key={index}>
                                                                  <span
                                                                    className={`icon ${monitorItem.status}`}
                                                                  ></span>
                                                                  <label>
                                                                    {
                                                                      monitorItem.name
                                                                    }
                                                                  </label>
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                        </ul>
                                                      </>
                                                    )}
                                                </span>
                                              }
                                            >
                                              <MonitorMenuIcon />
                                            </Tooltip>
                                          </div>
                                          <div
                                            className="outline_actions--edit"
                                            data-id={item.id}
                                            onClick={
                                              this.openModal_editHistoryEvent
                                            }
                                          >
                                            <Tooltip
                                              placement="top"
                                              trigger={['hover']}
                                              overlay={
                                                <span>Edit this update</span>
                                              }
                                            >
                                              <EditIcon />
                                            </Tooltip>
                                          </div>
                                          <div
                                            className="outline_actions--delete"
                                            data-id={item.id}
                                            onClick={
                                              this.openModal_removeHistoryEvent
                                            }
                                          >
                                            <Tooltip
                                              placement="top"
                                              trigger={['hover']}
                                              overlay={
                                                <span>Remove this update</span>
                                              }
                                            >
                                              <DeleteIcon />
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <form method="POST" action="" onSubmit={this.handleSubmit}>
                      <div className="white-card margin-bottom-20">
                        <h2 className="title"></h2>
                        <div className="field-group fluid event-progress-wrapper">
                          <label htmlFor="" className="fluid">
                            Incident status *
                          </label>
                          <div className="outer-wrapper">
                            <div id="event-progress-bar">
                              <div className="padding"></div>
                              <div className="progress-bar">
                                <span
                                  className={`progress ${this.state.incident_selected_event.toLowerCase()}`}
                                ></span>
                              </div>
                              <div className="padding"></div>
                            </div>
                            <div className="events">
                              {incidentEventsList.map(
                                (incidentEvent, index) => {
                                  let isActive =
                                    index <= selectedEvent_indice
                                      ? true
                                      : false;

                                  return (
                                    <div
                                      key={index}
                                      className={`event-wrapper ${incidentEvent.name.toLowerCase()}`}
                                      data-event={incidentEvent.id}
                                      onClick={this.handleChange_selectEvent}
                                    >
                                      <div className="indicator">
                                        <div
                                          className={`dot ${
                                            isActive === true ? 'active' : ''
                                          } ${
                                            this.state
                                              .incident_selected_event ===
                                            incidentEvent.id
                                              ? 'selected'
                                              : ''
                                          }`}
                                        ></div>
                                      </div>
                                      <div
                                        className={`label ${
                                          isActive === true ? 'active' : ''
                                        } ${
                                          this.state.incident_selected_event ===
                                          incidentEvent.id
                                            ? 'selected'
                                            : ''
                                        }`}
                                      >
                                        <span>{incidentEvent.name}</span>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="field-group fluid">
                          <label htmlFor="" className="fluid">
                            Description *
                          </label>
                          <textarea
                            rows={4}
                            className={`textField fluid ${
                              formIsSubmited === true &&
                              incident_event_description === ''
                                ? 'error'
                                : ''
                            }`}
                            value={this.state.incident_event_description}
                            placeholder={incident_event_description_placeholder}
                            onChange={this.handleChange_formIncidentDescription}
                          />
                          <span
                            className={`fieldText_alert--error ${
                              formIsSubmited === true &&
                              incident_event_description === ''
                                ? ''
                                : 'hide'
                            }`}
                          >
                            Description is required
                          </span>
                        </div>
                        <div className="field-group fluid">
                          <label htmlFor="" className="fluid">
                            Notifications
                          </label>
                          <div className={`checkbox-group fluid`}>
                            <label className="fluid">
                              <input
                                className={`textField `}
                                type="checkbox"
                                checked={
                                  this.state.affected_monitors.length <= 0
                                    ? false
                                    : incident_event_send_notification
                                }
                                onChange={
                                  this.handleChange_formIncidentSendNotification
                                }
                                disabled={
                                  this.state.affected_monitors.length <= 0
                                    ? true
                                    : false
                                }
                              />{' '}
                              Send notifications{' '}
                              <Tooltip
                                placement="top"
                                trigger={['hover']}
                                overlay={
                                  <span>
                                    Send notifications through multiple alert
                                    channels to your team members and
                                    subscribers of selected status pages.
                                  </span>
                                }
                              >
                                <span className="question-badge"></span>
                              </Tooltip>
                            </label>
                          </div>
                          <span
                            className={`fieldText_alert--error ${
                              this.state.affected_monitors.length <= 0
                                ? ''
                                : 'hide'
                            }`}
                          >
                            You need to select at least one affected monitors to
                            enable notifications
                          </span>
                        </div>
                      </div>
                      <div className="white-card margin-bottom-20">
                        <div className="field-group fluid affected-monitors-wrapper">
                          <div className="field-group_header">
                            <label htmlFor="" className="">
                              Affected Monitors *
                            </label>
                            <div className="field-group_btn-actions">
                              <div
                                className="btn btn-orange"
                                onClick={this.openModal_selectMonitors}
                              >
                                <AddIcon /> Import monitors
                              </div>
                            </div>
                          </div>
                          {this.state.isLoadingLoadData ? (
                            <div className="loading-wrapper">
                              <LoadingSpinner />
                            </div>
                          ) : this.state.affected_monitors.length <= 0 ? (
                            <div
                              className={`no-items-selected ${
                                this.state.formIsSubmited === true &&
                                this.state.affected_monitors.length <= 0
                                  ? 'error'
                                  : ''
                              }`}
                              onClick={this.openModal_selectMonitors}
                            >
                              <span className="no-items-selected_inner">
                                No monitor selected, please choose at least one
                                of your active monitors
                              </span>
                            </div>
                          ) : (
                            <div className="affected_monitors-items">
                              {this.state.affected_monitors.map(
                                (monitorItem, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={`affected_monitors-item ${
                                        monitorItem.isActive === true
                                          ? 'is-active'
                                          : ''
                                      } ${
                                        monitorItem.isDown === true
                                          ? 'is-down'
                                          : ''
                                      }`}
                                    >
                                      <StatusIndicator
                                        status={
                                          monitorItem.isActive === false
                                            ? ''
                                            : monitorItem.isDown === true
                                            ? 'danger'
                                            : 'success'
                                        }
                                        title={
                                          monitorItem.isActive === false
                                            ? 'Inactive Monitor'
                                            : monitorItem.isDown === true
                                            ? 'Failure Monitor'
                                            : 'Active Monitor'
                                        }
                                      />
                                      <div className="am_item-wrapper">
                                        <div className="am_item--name">
                                          {monitorItem.name}
                                        </div>
                                        <div className="am_item--status">
                                          <select
                                            className={`textField fluid`}
                                            value={monitorItem.status}
                                            data-id={monitorItem.id}
                                            onChange={
                                              this
                                                .handleChange_selectAffectedMonitorStatus
                                            }
                                          >
                                            {this.state.affected_monitors_statusList.map(
                                              (i, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={i.id}
                                                  >
                                                    {i.name}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                        <div className={`am_item--btn_actions`}>
                                          <div
                                            className="btn_action-item remove"
                                            data-id={monitorItem.id}
                                            onClick={
                                              this
                                                .handleChange__removeAttachedMonitor
                                            }
                                          >
                                            <svg
                                              version="1.1"
                                              width="16"
                                              height="16"
                                              viewBox="0 0 24 24"
                                              className="octicon octicon-trash"
                                              aria-hidden="true"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                                              ></path>
                                              <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                                              <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="white-card margin-bottom-20">
                        <div className="field-group fluid affected-monitors-wrapper">
                          <div className="field-group_header">
                            <label htmlFor="" className="">
                              Selected Statuspages *
                            </label>
                            <div className="field-group_btn-actions">
                              <div
                                className="btn btn-orange"
                                onClick={this.openModal_selectStatuspages}
                              >
                                <AddIcon /> Import statuspages
                              </div>
                            </div>
                          </div>
                          {this.state.isLoadingLoadData ? (
                            <div className="loading-wrapper">
                              <LoadingSpinner />
                            </div>
                          ) : this.state.selected_statuspages.length <= 0 ? (
                            <div
                              className={`no-items-selected ${
                                this.state.formIsSubmited === true &&
                                this.state.selected_statuspages.length <= 0
                                  ? 'error'
                                  : ''
                              }`}
                              onClick={this.openModal_selectStatuspages}
                            >
                              <span className="no-items-selected_inner">
                                No status page selected, please choose at least
                                one of your active status pages
                              </span>
                            </div>
                          ) : (
                            <div className="affected_monitors-items">
                              {this.state.selected_statuspages.map(
                                (statuspageItem, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={`affected_monitors-item ${
                                        statuspageItem.isActive === true
                                          ? 'is-active'
                                          : ''
                                      }  `}
                                    >
                                      <div className="am_item-wrapper">
                                        <div className="am_item--name">
                                          {statuspageItem.name}
                                        </div>
                                        <div className={`am_item--btn_actions`}>
                                          <div
                                            className="btn_action-item remove"
                                            data-id={statuspageItem.id}
                                            onClick={
                                              this
                                                .handleChange__removeAttachedStatuspage
                                            }
                                          >
                                            <svg
                                              version="1.1"
                                              width="16"
                                              height="16"
                                              viewBox="0 0 24 24"
                                              className="octicon octicon-trash"
                                              aria-hidden="true"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                                              ></path>
                                              <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                                              <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="white-card margin-bottom-20">
                        <div className="field-group submit-fields-group fluid">
                          <button type="submit" className="btn btn-primary">
                            {submitButtonContent}
                          </button>
                          <div
                            className={`alert alert-${
                              this.state.form_response_status
                            } ${
                              this.state.form_response === '' ? 'hide' : ''
                            } margin-top-none margin-bottom-none margin-right-none`}
                          >
                            <div className="">{this.state.form_response}</div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {
          <ModalRight
            title="Available monitors"
            closeModal={this.closeModal_selectMonitors}
            isDisplay={this.state.showModal_selectMonitors}
          >
            <div className="modal_layout-monitors-list">
              <div className="modal_list modal_layout-monitors-list_wrapper">
                <div className="modal-overflow-height_500">
                  {this.state.isLoading ? (
                    <div className="loading-wrapper">
                      <LoadingSpinner />
                    </div>
                  ) : this.state.monitorRowsErrors !== null ? (
                    <div className="alert alert-danger text-center">
                      {this.state.monitorRowsErrors}
                    </div>
                  ) : this.state.monitorRows.length <= 0 ? (
                    <div className="home-empty-state">
                      <img
                        alt="No monitor"
                        src={icon_no_monitors}
                        width="100px"
                      />
                      <h3>There is No Monitors</h3>
                      <p className="text-muted">
                        Add your first monitor, this will be your dashboard.
                      </p>
                      <Link className="" to={{ pathname: `/monitors/new` }}>
                        <div className="btn btn-primary">
                          <svg
                            version="1.1"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            className="octicon octicon-plus"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"
                            ></path>
                          </svg>
                          <span>New Monitor</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    this.state.monitorRows.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`m-monitor_item ${
                            item.is_active ? '' : 'deactivated'
                          } ${item.is_down ? 'is-down' : ''} ${
                            this.state.affected_monitors.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? 'selected'
                              : ''
                          }`}
                          onClick={this.attachMonitor}
                          data-value={JSON.stringify({
                            id: item.id,
                            isActive: item.is_active,
                            isDown: item.is_down,
                            name: item.name,
                            url: item.url,
                            status:
                              this.state.affected_monitors_statusList[0].id,
                          })}
                          data-checked={
                            this.state.affected_monitors.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? true
                              : false
                          }
                        >
                          <input
                            type="checkbox"
                            checked={
                              this.state.affected_monitors.findIndex(
                                (x) => x.id === item.id
                              ) >= 0
                                ? true
                                : false
                            }
                            onChange={this.attachMonitor}
                          />
                          <div
                            className={`status-circle ${
                              item.is_down === true ? `fail` : `success`
                            } medium ${item.is_active ? '' : 'deactivated'}`}
                          >
                            {item.is_down === true ? (
                              <svg
                                viewBox="0 0 30 30"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m5.78571429 4.83443522-4.75706811-4.75706811-.95127907.95127907 4.75706811 4.75706811-4.75706811 4.75706811.95127907.9512791 4.75706811-4.75706814 4.75706811 4.75706814.9512791-.9512791-4.75706814-4.75706811 4.75706814-4.75706811-.9512791-.95127907z"
                                  fill="#fff"
                                  transform="translate(9.428571 9.428571)"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                viewBox="0 0 30 30"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m0 5.14285714 1.28571429-1.28571428 3.21428571 3.21428571 7.0714286-7.07142857 1.2857143 1.28571429-8.3571429 8.35714285z"
                                  fill="#fff"
                                  transform="translate(8.571429 10.285714)"
                                ></path>
                              </svg>
                            )}
                          </div>
                          <div className="chk_details-content">
                            <span className="chk_details-name">
                              {item.name.toLowerCase()}
                            </span>
                            <span className="chk_details-url">
                              {item.url.length > 20
                                ? item.url.substr(0, 20) + '..'
                                : item.url}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              {this.state.pageCount > 0 && (
                <ReactPaginate
                  previousLabel={''}
                  nextLabel={''}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleChange_paginationMonitors}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              )}
            </div>
            <div className="d-flex margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_selectMonitors}
              >
                Back
              </button>
              {this.state.monitorRows.length > 0 && (
                <button
                  className="btn btn-primary float-left margin-left-10"
                  disabled={this.state.affected_monitors.length <= 0}
                  onClick={this.confirmAttachedMonitors}
                >
                  Attach the selected monitors (
                  <span>{this.state.affected_monitors.length}</span>){' '}
                </button>
              )}
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </ModalRight>
        }
        {
          <ModalRight
            title="Available status pages"
            closeModal={this.closeModal_selectStatuspages}
            isDisplay={this.state.showModal_selectStatuspages}
          >
            <div className="modal_layout-monitors-list">
              <div className="modal_list modal_layout-monitors-list_wrapper">
                <div className="modal-overflow-height_500">
                  {this.state.isLoading ? (
                    <div className="loading-wrapper">
                      <LoadingSpinner />
                    </div>
                  ) : this.state.statuspagesRowsErrors !== null ? (
                    <div className="alert alert-danger text-center">
                      {this.state.statuspagesRowsErrors}
                    </div>
                  ) : this.state.statuspagesRows.length <= 0 ? (
                    <div className="home-empty-state">
                      <img
                        alt="No status page"
                        src={icon_no_statuspages}
                        width="100px"
                      />
                      <h3>No Active Status pages found</h3>
                      <p className="text-muted">
                        Add your status page, this will be your dashboard.
                      </p>
                      <Link className="" to={{ pathname: `/statuspages/new` }}>
                        <div className="btn btn-primary">
                          <svg
                            version="1.1"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            className="octicon octicon-plus"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.75 2a.75.75 0 01.75.75V7h4.25a.75.75 0 110 1.5H8.5v4.25a.75.75 0 11-1.5 0V8.5H2.75a.75.75 0 010-1.5H7V2.75A.75.75 0 017.75 2z"
                            ></path>
                          </svg>
                          <span>New Status page</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    this.state.statuspagesRows.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`m-monitor_item ${
                            item.is_active ? '' : 'deactivated'
                          }  ${
                            this.state.selected_statuspages.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? 'selected'
                              : ''
                          }`}
                          onClick={this.attachStatuspage}
                          data-value={JSON.stringify({
                            id: item.id,
                            isActive: item.is_active,
                            name: item.name,
                            customDomain: item.custom_domain,
                            subDomain: item.hosted_subdomain,
                          })}
                          data-checked={
                            this.state.selected_statuspages.findIndex(
                              (x) => x.id === item.id
                            ) >= 0
                              ? true
                              : false
                          }
                        >
                          <input
                            type="checkbox"
                            checked={
                              this.state.selected_statuspages.findIndex(
                                (x) => x.id === item.id
                              ) >= 0
                                ? true
                                : false
                            }
                            onChange={this.attachStatuspage}
                          />
                          <div className="chk_details-content">
                            <span className="chk_details-name">
                              {item.name.toLowerCase()}
                            </span>
                            <span className="chk_details-url">
                              {typeof item.custom_domain !== 'undefined' &&
                              item.custom_domain !== null &&
                              item.custom_domain !== ''
                                ? item.custom_domain.length > 20
                                  ? item.custom_domain.substr(0, 20) + '..'
                                  : item.custom_domain
                                : item.hosted_subdomain.length > 0
                                ? `https://${item.hosted_subdomain}.${process.env.REACT_APP_DOMAIN_NAME}`
                                : ''}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              {this.state.pageCount > 0 && (
                <ReactPaginate
                  previousLabel={''}
                  nextLabel={''}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handleChange_paginationMonitors}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              )}
            </div>
            <div className="d-flex margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_selectStatuspages}
              >
                Back
              </button>
              {this.state.statuspagesRows.length > 0 && (
                <button
                  className="btn btn-primary float-left margin-left-10"
                  disabled={this.state.selected_statuspages.length <= 0}
                  onClick={this.confirmAttachedStatuspages}
                >
                  Attach the selected status pages (
                  <span>{this.state.selected_statuspages.length}</span>){' '}
                </button>
              )}
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </ModalRight>
        }
        {this.state.historyEvents.length > 0 && (
          <ModalRight
            title="Edit update"
            closeModal={this.closeModal_editHistoryEvent}
            isDisplay={this.state.showModal_editHistoryEvent}
          >
            <div className="modal_layout-wrapper">
              <div className="modal_form">
                <div className="modal-overflow-height_500">
                  <form>
                    <div className="field-group">
                      <label className="fluid">Status</label>
                      <select
                        className={`textField`}
                        value={this.state.tmp_editModal__status}
                        onChange={
                          this.handleChange_editForm_historyEvent_status
                        }
                      >
                        {getIncidentEvents().map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="field-group">
                      <label className="fluid">Message</label>
                      <textarea
                        className={`textField fluid`}
                        value={this.state.tmp_editModal__message}
                        onChange={
                          this.handleChange_editForm_historyEvent_message
                        }
                      ></textarea>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="d-flex margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_editHistoryEvent}
              >
                Back
              </button>
              {
                <button
                  className="btn btn-primary float-left margin-left-10"
                  onClick={this.editEvent}
                >
                  Update!
                </button>
              }
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </ModalRight>
        )}
        {this.state.showModal_removeHistoryEvent ? (
          <Modal
            title="Removing of update history"
            closeModal={this.closeModal_removeHistoryEvent}
            fixedWidthWindow="small-window"
          >
            <div>
              Are you sure you want to remove this update? please note that it
              will also be removed on the selected status page
            </div>
            <div className="d-flex justify-content-flex-end margin-top-20">
              <button
                className="btn btn-info float-left margin-left-10"
                onClick={this.closeModal_removeHistoryEvent}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger float-right margin-left-10"
                onClick={this.deleteEvent}
              >
                Remove
              </button>
            </div>
            <div
              className={`modal-response alert alert-${
                this.state.modalResponse_status
              } ${this.state.modalResponse === '' ? 'hide' : ''}`}
            >
              {this.state.modalResponse}
            </div>
          </Modal>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

export class InstantlyEditIncidentName extends Component {
  static contextType = AppContext;
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      name:
        typeof props.name !== 'undefined' && props.name !== ''
          ? props.name
          : null,
      incidentId: props.incidentId,
      isLoading: false,
      form_response_status: 'danger',
      form_response: '',
      mode: 'preview',
    };

    this.handleChange_incidentName = this.handleChange_incidentName.bind(this);
    this.startEditingIncidentName = this.startEditingIncidentName.bind(this);
    this.setIncidentName = this.setIncidentName.bind(this);
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  handleChange_incidentName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  /**
   *
   * @param {*} event
   */
  startEditingIncidentName = (event) => {
    this.setState({ mode: 'editing' });
  };

  setIncidentName = async (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });

    // Save a new status page
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/incidents/${this.state.incidentId}/name`,
          {
            name: this.state.name,
          },
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          let stateItems = {
            isLoading: false,
            name: this.state.name,
            form_response_status: 'success',
            mode: 'preview',
            form_response: 'Your incident name has been successfully updated',
          };

          this.setState(stateItems);

          setTimeout(() => {
            this.setState({
              form_response: '',
            });
          }, 2000);
        })
        .catch((error) => {
          let stateItems = {
            isLoading: false,
            mode: 'preview',
          };
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            stateItems.form_response =
              'Something went wrong, please try again later!';
            stateItems.form_response_status = 'danger';
            //console.log('Error', error.message);
          }
          if (
            typeof error.message === 'undefined' ||
            error.message === null ||
            error.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            // Save data in state
            this.setState(stateItems);
          }

          setTimeout(() => {
            this.setState({
              form_response: '',
            });
          }, 2000);
        });
    }
  };

  render() {
    return (
      <div className="title-changeable-form">
        <div className="text-instantly-editor">
          <form
            className={`txtInstEdit--editing ${
              this.state.mode === 'preview' ? 'hide' : ''
            }`}
            onSubmit={this.setIncidentName}
          >
            <input
              className={`editing-content`}
              type="text"
              value={this.state.name !== null ? this.state.name : ''}
              placeholder={``}
              onChange={this.handleChange_incidentName}
            />
            <div
              className={`save-icon ${
                this.state.mode === 'preview' ? 'hide' : ''
              }`}
              onClick={this.setIncidentName}
            >
              <RightCircleIcon />
            </div>
          </form>
          <div
            className={`txtInstEdit--preview ${
              this.state.mode === 'editing' ? 'hide' : ''
            }`}
          >
            <div className="preview-content">{this.state.name}</div>
            <div
              className={`edit-icon ${
                this.state.mode === 'editing' ? 'hide' : ''
              }`}
              onClick={this.startEditingIncidentName}
            >
              <EditPenIcon />
            </div>
          </div>
        </div>
        {this.state.form_response !== '' && (
          <div
            className={`absolute-alert right-absolute-alert alert alert-${
              this.state.form_response_status
            } ${
              this.state.form_response === '' ? 'hide' : ''
            } margin-top-none margin-bottom-none margin-right-none`}
          >
            <div className="">{this.state.form_response}</div>
          </div>
        )}
      </div>
    );
  }
}
