import React from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import axios from "axios";
import { removeItemStorage, getItemStorage } from '../../../../utils/storage';

// import { TextField , Button} from '@material-ui/core';
import AppContext from '../../../../context/app-context';

export default class Signout extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isConnected: false,
      sessionToken: null,
    };
  }

  async componentDidMount() {
    const obj = getItemStorage(`${process.env.REACT_APP_AUTH_NAME_STORAGE}`);

    if (obj && obj.token) {
      // const { token } = obj;
      await removeItemStorage(`${process.env.REACT_APP_AUTH_NAME_STORAGE}`);
      await removeItemStorage(`${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`);
      this.context.logout(); // update user session global state
      this.context.emptyConnectedUser(); // remove the current user Data from the global state
      this.context.emptyCurrentAccount(); // remove the current account data

      this.setState({
        isLoading: false,
        isConnected: false,
        sessionToken: null,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    //console.log(this.state.sessionToken);

    if (this.state.isLoading) {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    }
    if (this.state.sessionToken === null) {
      // redirect to dashboard home page

      return <Redirect to="/signin" />;
    }
    return (
      <>
        <Helmet>
          <title>Signout | Odown</title>
        </Helmet>
        <div className="layout__session layout__form--login">
          <div className="layout__session--container">
            <h1>Sign out ....</h1>
          </div>
        </div>
      </>
    );
  }
}
