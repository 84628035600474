import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Tooltip from 'rc-tooltip';
import { getItemStorage } from '../../../utils/storage';
// load Components
import Navbar from '../../layout/Navbar/Navbar';
import LoadingSpinner from './../../layout/LoadingSpinner/LoadingSpinner';
import Modal from './../../layout/Modal/Modal';
import AppContext from '../../../context/app-context';
import StatusIndicator from '../../layout/StatusIndicator/StatusIndicator';
import { getDefaultValidAccountId } from './../../authentification/SecurityController';
import { getMaintenanceEvents } from './../../../utils/helper';
import './../../layout/Pagination/Pagination.css';
// Import icons
import icon_no_maintenances from './../../../assets/icons/tool-box.png';
import { ReactComponent as EditPenIcon } from './../../../assets/icons/3643749_edit_pen_pencil_write_writing_icon.svg';
// import css
import './Maintenances.css';

/**
 *
 */
export default class Maintenances extends React.Component {
  static contextType = AppContext;

  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      // data: [],
      perPage: 10,
      currentPage: 0,
      pageCount: 0,
      // monitors: [],
      accountId: null,
      isLoading: false,
      maintenancesList: [],
      maintenancesList_error: null,
      // isLoading_monitorsStats : false
      breadcrumbs_items: [
        {
          name: 'Home',
          link: '/',
          isLink: true,
        },
        {
          name: 'Maintenances',
          link: '/',
          isLink: false,
        },
      ],
      responseList_message: null,
      responseList_status: 'warning',
      showModal_deleteMaintenance: false,
      modalResponse: '',
      modalResponse_status: 'warning',
      selectedMaintenanceId: null,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.openModal_deleteMaintenance =
      this.openModal_deleteMaintenance.bind(this);
    this.closeModal_deleteMaintenance =
      this.closeModal_deleteMaintenance.bind(this);
    this.deleteMaintenances = this.deleteMaintenances.bind(this);
  }

  /**
   *
   */
  receivedData = async () => {
    this.setState({
      isLoading: true,
    });
    const obj = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    if (obj && obj.token) {
      const token = obj.token;

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/${this.state.accountId}/incidents`,
          {
            params: {
              page: this.state.offset,
              per_page: this.state.perPage,
              mode: 'scheduled',
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: this.signal.token,
          }
        )
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            maintenancesList: data.data,
            pageCount: Math.ceil(data.total / this.state.perPage),
            isLoading: false,
            responseList_message: null,
            responseList_status: 'success',
          });
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              isLoading: false,
              maintenancesList_error:
                typeof error.response.data.error.message !== 'undefined'
                  ? error.response.data.error.message
                  : error.response.data.message,
              responseList_message: error.response.data.message,
              responseList_status: 'danger',
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            if (
              typeof error.message === 'undefined' ||
              error.message === null ||
              error.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.setState({
                isLoading: false,
                maintenancesList_error:
                  'Something went wrong, please try again later.',
                responseList_message:
                  'Something went wrong, please try again later.',
                responseList_status: 'danger',
              });
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  render__itemsButtonEdit(__ID) {
    return (
      <Link to={`/maintenances/${__ID}/edit`}>
        <EditPenIcon />
      </Link>
    );
  }

  openModal_deleteMaintenance(event) {
    this.setState({
      showModal_deleteMaintenance: true,
      selectedMaintenanceId: event.currentTarget.dataset.id,
      modalResponse: '',
    });
  }

  closeModal_deleteMaintenance(event) {
    this.setState({
      showModal_deleteMaintenance: !this.state.showModal_deleteMaintenance,
      modalResponse: '',
    });
  }

  deleteMaintenances = async (event) => {
    const localStorageData = await getItemStorage(
      `${process.env.REACT_APP_AUTH_NAME_STORAGE}`
    );
    const accountId = await getItemStorage(
      `${process.env.REACT_APP_STORAGE__ACCOUNT_KEY}`,
      'string'
    );

    if (localStorageData && localStorageData.token) {
      const { token } = localStorageData;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, //'JWT token...'
        'X-Odown-Account': accountId,
      };

      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}accounts/${accountId}/incidents/${this.state.selectedMaintenanceId}`,
          {
            headers: headers,
            cancelToken: this.signal.token,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const selectedMaintenanceId = this.state.selectedMaintenanceId;

            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse: 'Maintenance removed successfully',
              modalResponse_status: 'success',
              maintenancesList: this.state.maintenancesList.filter(
                function (obj) {
                  return obj.id !== selectedMaintenanceId;
                }
              ),
              showModal_deleteMaintenance: false,
            });
          } else {
            // stop loading effect
            this.setState({
              isLoading: false,
              modalResponse: 'Something went wrong removing your incident',
              modalResponse_status: 'danger',
            });
          }
        })
        .catch((err) => {
          if (
            typeof err.message === 'undefined' ||
            err.message === null ||
            err.message !== 'API_HAS_BEEN_CANCELED'
          ) {
            this.setState({
              isLoading: false,
              modalResponse: 'Something went wrong removing your incident',
              modalResponse_status: 'danger',
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
        modalResponse: 'You are not authorized to do this operation',
        modalResponse_status: 'danger',
      });
      return;
    }
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage; // this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData(offset);
      }
    );
  };

  async componentDidMount() {
    const targetAccountId = await getDefaultValidAccountId(
      this.context.connectedUserData
    );
    if (typeof targetAccountId !== 'undefined') {
      this.setState(
        {
          accountId: targetAccountId,
        },
        async () => {
          this.receivedData();
        }
      );
    }
    //console.log('Monitors > componentDidMount > targetAccountId : ' , targetAccountId)
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
    //console.log('............. Unmount monitors component');
  }

  render() {
    const { isLoading } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>Maintenances | Odown</title>
        </Helmet>
        <Navbar
          breadcrumbs_items={this.state.breadcrumbs_items}
          background="white"
        />
        <div className="layout__content--wrapper bg-gris">
          <div className="container">
            <div className="layout__settings--form layout__blockInner">
              <div className="layout__header">
                <div className="l_header__title">
                  <h2>Maintenances </h2>
                </div>
                <div className="l_header__btnaction">
                  {typeof this.context.accountData !== 'undefined' &&
                  this.context.accountData !== null &&
                  (this.context.accountData.plan === null ||
                    this.context.accountData.plan.statuspage_features
                      .incident_management === false) ? (
                    <Tooltip
                      defaultVisible={true}
                      placement="left"
                      trigger={['hover']}
                      overlay={
                        <span>
                          {`This feature is not available on your ${
                            this.context.accountData.plan !== null &&
                            this.context.accountData?.plan?.public_name !== null
                              ? `'${this.context.accountData?.plan?.public_name}'`
                              : ''
                          } plan, please upgrade to unlock this premium feature`}
                          <br />
                          <Link to={`/settings/billing`}>
                            Upgrade your plan now
                          </Link>
                        </span>
                      }
                    >
                      <Link to="/maintenances/new" className="btn btn-primary">
                        Schedule maintenance
                      </Link>
                    </Tooltip>
                  ) : (
                    <Link to="/maintenances/new" className="btn btn-primary">
                      Schedule maintenance
                    </Link>
                  )}
                </div>
                {/* <div className="l_header__description">
									{
										this.state.exceed_account_user_limit === true &&
										<div className="alert alert-warning" >You've hit your plan limit of users. Remove users or outstanding invites, or <Link to="/settings/billing">upgrade your plan</Link></div>
									}
								</div> */}
              </div>
              <div className="layout__body">
                <div className="white-card">
                  <table
                    className={`default-table incidents-items ${
                      this.state.maintenancesList.length <= 0 ? 'empty' : ''
                    }`}
                  >
                    <thead
                      className={
                        this.state.maintenancesList.length <= 0 ? 'hide' : ''
                      }
                    >
                      <tr>
                        <th></th>
                        <th align="left">Name</th>
                        <th align="center">Updates</th>
                        <th align="center">Last updated</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="5">
                            <div className="loading-wrapper">
                              <LoadingSpinner />
                            </div>
                          </td>
                        </tr>
                      ) : this.state.responseList_status !== 'success' ? (
                        <tr>
                          <td colSpan="5">
                            <div className="alert alert-danger text-center">
                              {this.state.responseList_message}
                            </div>
                          </td>
                        </tr>
                      ) : this.state.maintenancesList.length <= 0 ? (
                        <tr>
                          <td colSpan="5">
                            <div className="home-empty-state">
                              <img
                                alt="No scheduled maintenance"
                                src={icon_no_maintenances}
                                width="100px"
                              />
                              <h3>No Maintenance found</h3>
                              <p className="text-muted">
                                Communicate scheduled maintenances to your team
                                and your customers/subscribers in beautifully
                                simple status pages.
                              </p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        this.state.maintenancesList.map((item, index) => {
                          // Get the lastest event : sort the events list by the updated date

                          if (item.events.length > 0) {
                            item.events.sort(
                              (a, b) =>
                                moment(b.created_at).unix() -
                                moment(a.created_at).unix()
                            );
                          }

                          return (
                            <tr data-id={item.id} key={index}>
                              <td
                                className="incident_tabColumn-status"
                                align="center"
                              >
                                <StatusIndicator
                                  status={
                                    getMaintenanceEvents().find(
                                      (i) => item.events[0].type === i.id
                                    ).status
                                  }
                                  title={``}
                                />
                              </td>
                              <td
                                className="incident_tabColumn-info"
                                align="left"
                              >
                                <div className="field-content incident_row--basic-info">
                                  <span className="incident_name">
                                    <Link to={`/maintenances/${item.id}/edit`}>
                                      {item.name}
                                    </Link>
                                  </span>
                                  {item.events.length > 0 && (
                                    <span
                                      className={`incident_last-event ${item.events[0].type.toLowerCase()}`}
                                    >
                                      {item.events[0].type}
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td
                                className="incident_tabColumn-events"
                                align="center"
                              >
                                <div className="field-content">
                                  {item.events.length > 0
                                    ? `${item.events.length}  ${
                                        item.events.length > 1
                                          ? 'updates'
                                          : 'update'
                                      }`
                                    : 'No updates'}
                                </div>
                              </td>
                              <td
                                className="incident_tabColumn-date"
                                align="center"
                              >
                                <div
                                  className="field-content  "
                                  title={moment(item.updated_at).format(
                                    'dddd, MMMM Do YYYY, h:mm A'
                                  )}
                                >
                                  {
                                    moment(item.updated_at).fromNow()
                                    //`${(item.subscriptions.filter(e => e.activated === true)).length} / ${item.subscriptions.length}`
                                  }
                                </div>
                              </td>
                              <td className="incident_tabColumn-btn">
                                <div className="btns_action">
                                  <div className="btn_action-item edit">
                                    {this.render__itemsButtonEdit(item.id)}
                                  </div>
                                  <div
                                    className="btn_action-item remove"
                                    data-id={item.id}
                                    onClick={this.openModal_deleteMaintenance}
                                  >
                                    <svg
                                      version="1.1"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      className="octicon octicon-trash"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"
                                      ></path>
                                      <path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path>
                                      <path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path>
                                    </svg>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  {this.state.pageCount > 0 && (
                    <ReactPaginate
                      previousLabel={''}
                      nextLabel={''}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  )}
                  {this.state.showModal_deleteMaintenance ? (
                    <Modal
                      title="Deleting of scheduled maintenance"
                      closeModal={this.closeModal_deleteMaintenance}
                      fixedWidthWindow="small-window"
                    >
                      <div>
                        Deleting this incident will erase all event data history
                        and therefore it will not be displayed on status pages.
                      </div>
                      <div className="d-flex justify-content-flex-end margin-top-20">
                        <button
                          className="btn btn-info float-left margin-left-10"
                          onClick={this.closeModal_deleteMaintenance}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-danger float-right margin-left-10"
                          onClick={this.deleteMaintenances}
                        >
                          Delete
                        </button>
                      </div>
                      <div
                        className={`modal-response alert alert-${
                          this.state.modalResponse_status
                        } ${this.state.modalResponse === '' ? 'hide' : ''}`}
                      >
                        {this.state.modalResponse}
                      </div>
                    </Modal>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
