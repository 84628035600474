import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { getItemStorage, setItemStorage } from '../../../../utils/storage';
import { Helmet } from 'react-helmet';
import { verifySession } from './../../../authentification/SecurityController';
import GA from './../../../../components/Tracking/GoogleAnalytics';
// import { TextField , Button} from '@material-ui/core';
import AppContext from '../../../../context/app-context';
import { ReactComponent as Logo } from './../../../../assets/logo/logo.svg';
import {sendDiscordNotification} from '../../../../utils/helper';

export default class Signup extends React.Component {
  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      signup_email: '',
      signup_email_placeholder: 'Email Address',
      signup_email_validate: false,
      signup_email_start_tapping: false,

      signup_password: '',
      signup_password_placeholder: 'Password',
      signup_password_validate: false,
      signup_password_start_tapping: false,

      signup_agreeTerms: false,

      signup_response: '',
      signup_response_status: 'warning',
      isLoading: false,
      sessionToken: null,
      email_regex_validator:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      isReadyToSubmit: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange_signupEmail = this.handleChange_signupEmail.bind(this);
    this.handleChange_signupPassword =
      this.handleChange_signupPassword.bind(this);
    this.hanleChange_signupAgreeTerms =
      this.hanleChange_signupAgreeTerms.bind(this);
  }
  static contextType = AppContext;

  handleChange_signupEmail(event) {
    let signup_email_validate = false;
    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== '' &&
      event.target.value.match(this.state.email_regex_validator) != null
    ) {
      signup_email_validate = true;
    }

    let isReadyToSubmit = false;
    if (
      signup_email_validate === true &&
      this.state.signup_password_validate === true &&
      this.state.signup_agreeTerms === true
    ) {
      isReadyToSubmit = true;
    }

    this.setState({
      signup_email_start_tapping: true,
      signup_email: event.target.value,
      signup_email_validate: signup_email_validate,
      isReadyToSubmit: isReadyToSubmit,
    });
  }

  handleChange_signupPassword(event) {
    let signup_password_validate = false;
    if (
      typeof event.target.value !== 'undefined' &&
      event.target.value !== ''
    ) {
      signup_password_validate = true;
    }
    let isReadyToSubmit = false;
    if (
      this.state.signup_email_validate === true &&
      signup_password_validate === true &&
      this.state.signup_agreeTerms === true
    ) {
      isReadyToSubmit = true;
    }
    this.setState({
      signup_password_start_tapping: true,
      signup_password: event.target.value,
      signup_password_validate: signup_password_validate,
      isReadyToSubmit: isReadyToSubmit,
    });
  }

  hanleChange_signupAgreeTerms(event) {
    const nextAgreeTermsStatus = !this.state.signup_agreeTerms;
    let isReadyToSubmit = false;
    if (
      this.state.signup_email_validate === true &&
      this.state.signup_password_validate === true &&
      nextAgreeTermsStatus === true
    ) {
      isReadyToSubmit = true;
    }
    //console.log('isReadyToSubmit : ' , isReadyToSubmit);
    this.setState({
      signup_agreeTerms: nextAgreeTermsStatus,
      isReadyToSubmit: isReadyToSubmit,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      signup_email,
      signup_password,
      signup_password_validate,
      signup_email_validate,
    } = this.state;

    this.setState({
      isLoading: true,
    });

    if (this.state.signup_agreeTerms === true) {
      // ! Check the email address field
      if (
        typeof signup_email === 'undefined' ||
        signup_email === null ||
        signup_email_validate === false
      ) {
        this.setState({
          isLoading: false,
          signup_response: 'Email address must have a valid form',
          signup_response_status: 'danger',
        });
        return;
      }

      // ! Check the password field
      if (
        typeof signup_password === 'undefined' ||
        signup_password === null ||
        signup_password_validate === false ||
        signup_password.length < 4
      ) {
        this.setState({
          isLoading: false,
          signup_response:
            'Password too short, try a mix of characters and numbers',
          signup_response_status: 'danger',
        });
        return;
      }

      //post request to api
      let paramsPOST = {
        email: signup_email,
        password: signup_password,
      };

      // get invite id if exisite ( pending token )
      const INVITE_TOKEN = await getItemStorage(
        `${process.env.REACT_APP_STORAGE__INVITE_TOKEN}`,
        'string'
      );
      if (
        typeof INVITE_TOKEN !== 'undefined' &&
        INVITE_TOKEN !== null &&
        INVITE_TOKEN !== ''
      ) {
        paramsPOST['inviteId'] = INVITE_TOKEN;
      }

      await axios
        .post(`${process.env.REACT_APP_API_URL}users/signup`, paramsPOST, {
          cancelToken: this.signal.token,
        })
        .then((res) => res.data)
        .then((data) => {
          //console.log('data : ' , data);
          // const status = res.status

          if (
            typeof data.session_token !== 'undefined' &&
            data.session_token !== null
          ) {
            sendDiscordNotification({email: signup_email});
            // ? Google Analytics Event : Successful Sign up
            GA.GAevent('Users', 'Successful Sign up');

            this.setState(
              {
                isLoading: false,
                signup_response: data.message,
                signup_response_status: 'success',
                sessionToken: data.session_token,
              },
              async () => {
                // save token as session in localStorage
                setItemStorage(`${process.env.REACT_APP_AUTH_NAME_STORAGE}`, {
                  token: data.session_token,
                });
                await this.context.login(); // update user session global state

                this.context.setConnectedUserData(data);
                this.context.getCurrentAccountData();
              }
            );
          } else {
            this.setState({
              isLoading: false,
              signup_response:
                'An error occurred while registering your account, please try again later',
              signup_response_status: 'danger',
            });
          }
        })
        .catch((error) => {
          //console.log('error : ' , error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            this.setState({
              isLoading: false,
              signup_response: error.response.data.message,
              signup_response_status: 'danger',
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            if (
              typeof error.message === 'undefined' ||
              error.message === null ||
              error.message !== 'API_HAS_BEEN_CANCELED'
            ) {
              this.setState({
                isLoading: false,
                signup_response:
                  'An error occurred while registering your account, please try again later',
                signup_response_status: 'danger',
              });
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
        signup_response: 'Please read and accept our terms and privacy policy',
        signup_response_status: 'danger',
      });
    }
  };

  componentDidMount() {
    verifySession()
      .then((token) => {
        this.setState({
          sessionToken: token,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        this.context.logout(); // update user session global state
      });
  }

  componentWillUnmount() {
    this.signal.cancel('API_HAS_BEEN_CANCELED');
  }

  render() {
    let submitButtonContent = (
      <span className="btn-inner load-spinner">
        <span>Create account</span>
      </span>
    );
    if (this.state.isLoading) {
      submitButtonContent = (
        <span className="btn-inner load-spinner">
          <span className="loader_spinner--content"></span>
          <span>Please wait</span>
        </span>
      );
    }

    const {
      signup_email_validate,
      signup_email_start_tapping,
      signup_password_validate,
      signup_password_start_tapping,
    } = this.state;

    // if (this.state.isLoading) {
    // 	return (<div><p>Loading...</p></div>);
    // }
    if (this.state.sessionToken !== null) {
      // redirect to dashboard home page
      return <Redirect to="/" />;
      // return (
      // 	<div className >
      // 		<div >Connected</div>
      // 		<button onClick={this.handleSignout} >Sign out</button>
      // 	</div>
      // );
    }
    return (
      <>
        <Helmet>
          <title>Sign up to Odown and Start Your Free 14-Day Trial</title>
          <meta
            name="description"
            content="Quickly set up infrastructure monitoring and a status page in minutes. Sign in to onboard your team, share alerts, and collaborate with ease. Get started now."
          />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://app.odown.io/signup" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Signup | Odown" />
          <meta
            property="og:description"
            content="Be the first to know about your website issues before your customers do with real-time website monitoring combined with instant alerts via email, Slack, Webhooks or SMS."
          />
          <meta
            property="og:image"
            content="https://app.odown.io/general-image-og--home-page.jpg"
          />

          {/* <!-- Twitter Meta Tags -- /> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="app.odown.io" />
          <meta property="twitter:url" content="https://app.odown.io/signup" />
          <meta name="twitter:title" content="Signup | Odown" />
          <meta
            name="twitter:description"
            content="Be the first to know about your website issues before your customers do with real-time website monitoring combined with instant alerts via email, Slack, Webhooks or SMS."
          />
          <meta
            name="twitter:image"
            content="https://app.odown.io/general-image-og--home-page.jpg"
          />
        </Helmet>
        <div className="layout__session layout__form--login bg-gris">
          <div className="layout__session--container">
            <div className="logo">
              <Logo />
            </div>
            <h1>Create Odown Account</h1>
            <form method="POST" action="" onSubmit={this.handleSubmit}>
              <div className="form-container">
                <div
                  className={`response-group ${
                    this.state.signup_response_status
                  } message ${this.state.signup_response === '' ? 'hide' : ''}`}
                >
                  <div className="">{this.state.signup_response}</div>
                </div>
                <div className="field-group">
                  <label htmlFor="" className="fluid">
                    Email
                  </label>
                  <input
                    type="email"
                    className={`textField fluid ${
                      signup_email_start_tapping === true &&
                      signup_email_validate === false
                        ? 'error'
                        : ''
                    }`}
                    placeholder={this.state.signup_email_placeholder}
                    value={this.state.signup_email}
                    onChange={this.handleChange_signupEmail}
                  />
                </div>
                <div className="field-group">
                  <label htmlFor="" className="fluid">
                    Password
                  </label>
                  <input
                    type="password"
                    className={`textField fluid ${
                      signup_password_start_tapping === true &&
                      signup_password_validate === false
                        ? 'error'
                        : ''
                    }`}
                    placeholder={this.state.signup_password_placeholder}
                    value={this.state.signup_password}
                    onChange={this.handleChange_signupPassword}
                  />
                </div>
                <div className="field-group field-checkbox checkbox-group">
                  <label htmlFor="agreeTerms">
                    <input
                      type="checkbox"
                      id="agreeTerms"
                      checked={this.state.signup_agreeTerms}
                      onChange={this.hanleChange_signupAgreeTerms}
                    />{' '}
                    I agree to{' '}
                    <a href="https://odown.io/terms" target="_blanc">
                      Terms
                    </a>{' '}
                    and{' '}
                    <a href="https://odown.io/privacy" target="_blanc">
                      Privacy policy
                    </a>
                  </label>
                </div>

                <div className="field-group submit-fields-group fluid">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!this.state.isReadyToSubmit}
                  >
                    {submitButtonContent}
                  </button>
                </div>
              </div>
            </form>
            <div className="other-sign-block">
              <label htmlFor="">Already have an account?</label>
              <Link to="/signin">Log in</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
